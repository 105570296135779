import { useGateways } from "../context/GatewayContext";
import ContentFramework from "../components/ContentFramework";
import { useWalletContext } from "../context/WalletContext";
import InfoBox from "../components/InfoBox";
import GatewayLocationForm from "../components/form/GatewayLocationForm";
import GatewayPageHeader from '../components/GatewayPageHeader';
import { useParams } from "@tanstack/react-router";
import { useEffect } from "react";
import PageContainer from "../components/layout/PageContainer";
import 'mapbox-gl/dist/mapbox-gl.css';
import { FC } from "react";

const GatewayLocation: FC = () => {
  const { isConnected } = useWalletContext();
  const { gateways, activeGateway, setActiveGateway } = useGateways();
  const { gatewayId } = useParams({ from: '/gateway/$gatewayId/location' });

  useEffect(() => {
    if (gatewayId && gateways) {
      const gateway = gateways.find(g => g.id === gatewayId);
      if (gateway) {
        setActiveGateway(gateway);
      }
    }
  }, [gatewayId, gateways, setActiveGateway]);

  if (!isConnected || !activeGateway) return null;

  return (
    <ContentFramework>
      <div className="min-h-screen">
        <PageContainer>
          <div className="flex flex-col">
            <GatewayPageHeader 
              gatewayName={activeGateway.name}
            />
            <h1 className="text-4xl font-bold mb-3">Location</h1>
            <div className="flex flex-col">
              <InfoBox
                heading="Gateway position"
                body="Please provide the precise location of the gateway. Inaccurate location may reduce your rewards. The exact location not shared publically."
                className="flex-shrink-0"
              />
              <div className="mt-4 h-[calc(100vh-28rem)]">
                <GatewayLocationForm serial={activeGateway.id} variant="gateway" />
              </div>
            </div>
          </div>
        </PageContainer>
      </div>
    </ContentFramework>
  );
};

export default GatewayLocation; 