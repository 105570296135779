import { FC } from 'react';
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

const ExternalWalletLogin: FC = () => {
  return (
    <div className="rounded-lg bg-gray-800/50 p-4">
      <WalletMultiButton />
    </div>
  );
};

export default ExternalWalletLogin; 