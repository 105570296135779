import { toast } from "react-hot-toast";
import { gwRequest } from '../../services/backend/SrcfulApiRequests';
import { WalletInterface } from '../../wallets/types';

export async function handleSubmitFlow(
  message: string, 
  wallet: WalletInterface, 
  onSuccess: () => void, 
  onError: () => void
) {
  const toastId = toast.loading("Waiting for signature...");
  try {
    const signedMessage = await wallet.signMessage(message);
    if (!signedMessage) {
      toast.dismiss(toastId);
      toast.error('Failed to sign message');
      return;
    }
    const response = await gwRequest(signedMessage);
    toast.dismiss(toastId);
    if (response === true) {
      onSuccess();
    } else {
      // we should basically never get here as this would mean the request failed to send
      toast.error('Failed connect, please try again');
      console.error('Failed to set configuration request: ', message);
    }
  } catch (error) {
    toast.dismiss(toastId);
    console.error('Something went wrong: ', error);
    onError();
  }
} 