import { useGateways, isHardwareGateway } from "../context/GatewayContext";
import ContentFramework from "../components/ContentFramework";
import { useWalletContext } from "../context/WalletContext";
import { useParams } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { ArrowTopRightOnSquareIcon, ChevronDownIcon, ChevronUpIcon, BellIcon } from "@heroicons/react/24/outline";
import InfoRow from "../components/InfoRow";
import { DerInfo } from "../services/backend/SrcfulPublicApi";
import GatewayPageHeader from '../components/GatewayPageHeader';
import { gatewayInfo } from '../constants/infoTexts';
import PageContainer from '../components/layout/PageContainer';
import { GatewayIcon, CloudGatewayIcon } from '../components/icons/icons';
import { ActionButton } from '../components/ui/ActionButton';
import { toast } from "react-hot-toast";
import { DimoAuthProvider } from "@dimo-network/login-with-dimo";
import type { DimoAuthData } from '../components/cloud-onboarding/types';
import DimoLoginButton from '../components/DimoLoginButton';
import GatewayMessageManager from '../services/GatewayMessageManager';
import { CircleStackIcon, ClockIcon, CpuChipIcon, BeakerIcon, ArrowPathIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import SrcfulMessageProtocolFactory from '../services/backend/SrcfulMessageProtocolFactory';
import { transferGatewayOwnership } from "../services/backend/SrcfulApiRequests";
import { useRouter } from "@tanstack/react-router";
import { isFeatureEnabled } from '../config/featureFlags';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Cell } from 'recharts';
import {
  fetchGatewayRewards,
  processRewardsForGraph,
  calculateTotalRewards,
  GatewayRewardsData,
  RewardsGraphDataItem
} from '../services/RewardsService';
import { CustomTooltip } from '../components/rewards/CustomTooltip';

const formatLastSeen = (timestamp: number) => {
  const now = Date.now();
  const diff = Math.floor((now - timestamp) / 1000);
  const minutes = Math.floor(diff / 60);
  const date = new Date(timestamp);
  const userLocale = navigator.language;
  const timeStr = date.toLocaleTimeString(userLocale, { hour: '2-digit', minute: '2-digit' });
  
  if (minutes < 1) return `Just now (${timeStr})`;
  if (minutes === 1) return `1 minute ago (${timeStr})`;
  if (minutes < 60) return `${minutes} minutes ago (${timeStr})`;
  
  // If more than 24 hours, show full date
  if (diff > 86400) {
    return date.toLocaleString(userLocale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }
  
  // If more than an hour but less than 24 hours
  const hours = Math.floor(minutes / 60);
  return `${hours} hour${hours > 1 ? 's' : ''} ago (${timeStr})`;
};

const formatDerLastSeen = (isoTimestamp: string) => {
  const date = new Date(isoTimestamp);
  const userLocale = navigator.language;
  
  // If more than 24 hours ago, show full date
  const now = Date.now();
  const diff = now - date.getTime();
  
  if (diff > 86400000) { // more than 24 hours
    return date.toLocaleString(userLocale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }
  
  // If less than 24 hours, show relative time
  const minutes = Math.floor(diff / 60000);
  const timeStr = date.toLocaleTimeString(userLocale, { hour: '2-digit', minute: '2-digit' });
  
  if (minutes < 1) return `Just now (${timeStr})`;
  if (minutes === 1) return `1 minute ago (${timeStr})`;
  if (minutes < 60) return `${minutes} minutes ago (${timeStr})`;
  
  const hours = Math.floor(minutes / 60);
  return `${hours} hour${hours > 1 ? 's' : ''} ago (${timeStr})`;
};

const formatUptime = (milliseconds: number) => {
  const seconds = Math.floor(milliseconds / 1000);
  const days = Math.floor(seconds / (24 * 60 * 60));
  const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);

  const parts = [];
  if (days > 0) parts.push(`${days}d`);
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0) parts.push(`${minutes}m`);
  
  // If less than a minute, show seconds
  if (parts.length === 0) {
    return `${Math.floor(seconds)}s`;
  }

  return parts.join(' ');
};

const DerSection = ({ ders }: { ders?: DerInfo[] }) => {
  if (!ders || ders.length === 0) {
    return null;
  }

  return (
    <>
      <div className="h-px bg-gray-200/10 my-8" />
      <div>
        <h2 className="text-base font-bold leading-7">
          Distributed Energy Resources
        </h2>
      </div>
      <div className="mt-5">
        {ders.map((der) => (
          <InfoRow
            key={der.sn}
            label={der.type}
            tooltip={gatewayInfo.der_lastSeen.tooltip}
            value={der.lastSeen}
            displayValue={
              <span className={`${
                new Date(der.lastSeen).getTime() < Date.now() - 15 * 60 * 1000 
                ? 'text-red-500' 
                : ''
              }`}>
                {formatDerLastSeen(der.lastSeen)}
              </span>
            }
          />
        ))}
      </div>
    </>
  );
};

const ReauthenticationSection = ({ gatewayId, publicKey }: { gatewayId: string; publicKey: string | null }) => {
  const [isCreatingSession, setIsCreatingSession] = useState(false);

  const handleReauth = async () => {
    if (!publicKey) {
      toast.error('Please connect your wallet first');
      return;
    }

    setIsCreatingSession(true);
    try {
      const response = await fetch('https://huawei.srcful.dev/gateway_session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ownerWallet: publicKey,
          type: 'reauth_oauth',
          redirect_url: 'https://app.srcful.io',
          gateway_serial: gatewayId
        })
      });

      if (!response.ok) {
        throw new Error('Failed to create Huawei session');
      }

      const session = await response.json();
      
      // Redirect to Huawei OAuth page
      const huaweiOAuthUrl = `https://oauth2.fusionsolar.huawei.com/rest/dp/uidm/oauth2/v1/authorize?response_type=code&client_id=742215509&redirect_uri=https://huawei.srcful.dev/oauth/callback&state=${session.session_id}`;
      window.location.href = huaweiOAuthUrl;
    } catch (error) {
      console.error('Failed to create Huawei session:', error);
      toast.error('Failed to start Huawei authentication');
    } finally {
      setIsCreatingSession(false);
    }
  };

  return (
    <div className="mt-8 space-y-4">
      <div className="flex items-center gap-4">
        <h2 className="text-xl font-semibold">Reauthentication</h2>
      </div>
      <div className="space-y-4 bg-gray-800/50 p-6 rounded-lg border border-gray-700">
        <p className="text-gray-300">
          If you need to refresh your Huawei FusionSolar authentication, you can do so here. 
          This will redirect you to Huawei's login page to reauthorize access to your installation data.
        </p>
        <ActionButton
          onClick={handleReauth}
          variant="primary"
          isLoading={isCreatingSession}
          loadingText="Preparing Authentication..."
          disabled={!publicKey}
        >
          Reauthorize Huawei Access
        </ActionButton>
      </div>
    </div>
  );
};

const DimoManagementSection = ({ gatewayId, publicKey }: { gatewayId: string; publicKey: string | null }) => {
  const [dimoAuthData, setDimoAuthData] = useState<DimoAuthData | null>(null);

  return (
    <div className="mt-8 space-y-4">
      <div className="flex items-center gap-4">
        <h2 className="text-xl font-semibold">DIMO Vehicle Management</h2>
      </div>
      <div className="space-y-4 bg-gray-800/50 p-6 rounded-lg border border-gray-700">
        <p className="text-gray-300">
          Connect to DIMO to manage which vehicles you want to share with Sourceful. You can add or remove vehicles and control access permissions.
        </p>
        <div className="w-full">
          {publicKey ? (
            <DimoAuthProvider>
              <DimoLoginButton
                onSuccess={setDimoAuthData}
                className="w-full"
              />
            </DimoAuthProvider>
          ) : (
            <p className="text-sm text-gray-400">Please connect your wallet to manage DIMO vehicles.</p>
          )}
        </div>
      </div>

      {/* Shared Vehicles Section */}
      {dimoAuthData?.vehicles && dimoAuthData.vehicles.length > 0 && (
        <div className="mt-8">
          <div className="flex items-center gap-4 mb-4">
            <h2 className="text-xl font-semibold">Shared Vehicles</h2>
            <div className="text-sm text-gray-400">
              {dimoAuthData.vehicles.length} vehicle{dimoAuthData.vehicles.length !== 1 ? 's' : ''} available
            </div>
          </div>
          <div className="bg-gray-800/50 p-6 rounded-lg border border-gray-700 space-y-3">
            {dimoAuthData.vehicles.map((vehicle) => (
              <div key={vehicle.tokenId} className="bg-gray-800/80 p-3 rounded">
                <div className="space-y-1">
                  <span className="font-mono text-sm text-gray-300">Vehicle ID: {vehicle.tokenId}</span>
                  <div className="text-sm text-gray-400">
                    {vehicle.definition.make} {vehicle.definition.model} ({vehicle.definition.year})
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

interface SystemInfo {
  time_utc_sec: number;
  temperature_celsius: number;
  processes_average: {
    last_1min: number;
    last_5min: number;
    last_15min: number;
  };
  uptime_seconds: number;
  memory_MB: {
    total: number;
    available: number;
    free: number;
    used: number;
    percent_used: number;
  };
}

const SystemInfoCard = ({ title, icon: Icon, children }: { 
  title: string | React.ReactNode; 
  icon: React.ComponentType<{ className?: string }>;
  children: React.ReactNode;
}) => (
  <div className="bg-[#2d2d2d] rounded-lg p-4 flex flex-col">
    {typeof title === 'string' ? (
      <div className="flex items-center gap-2 mb-3">
        <Icon className="w-5 h-5 text-green-500" />
        <h4 className="text-sm font-medium text-gray-300">{title}</h4>
      </div>
    ) : (
      <div className="mb-3">
        {title}
      </div>
    )}
    {children}
  </div>
);

const ProgressBar = ({ percentage, color }: { percentage: number; color: string }) => (
  <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
    <div 
      className={`h-full ${color} transition-all duration-500`}
      style={{ width: `${percentage}%` }}
    />
  </div>
);

const ProcessLoadBar = ({ load }: { load: number }) => {
  const getColor = (load: number) => {
    if (load < 0.5) return "bg-green-500";
    if (load < 0.8) return "bg-yellow-500";
    return "bg-red-500";
  };

  return (
    <ProgressBar 
      percentage={Math.min(load * 100, 100)} 
      color={getColor(load)} 
    />
  );
};

const SystemInfoDisplay = ({ systemInfo, gatewayId }: { systemInfo: SystemInfo; gatewayId: string }) => {
  const [showRebootDialog, setShowRebootDialog] = useState(false);
  const [isRebooting, setIsRebooting] = useState(false);

  const handleReboot = async () => {
    setIsRebooting(true);
    try {
      await GatewayMessageManager.getInstance().fetch(
        '/api/system/reboot',
        {
          method: 'POST'
        },
        {
          gatewayId,
          timeout: 5000 // Short timeout since we expect no response
        }
      );

      toast.success('Gateway is rebooting. This may take a few minutes.');
      
      // Keep showing the rebooting state for 30 seconds
      setTimeout(() => {
        setIsRebooting(false);
        setShowRebootDialog(false);
        toast.success('Gateway reboot initiated. The gateway will be unavailable for a few minutes.');
      }, 30000);
    } catch (error) {
      // Even if we get a timeout error, the reboot command might have gone through
      toast.success('Gateway reboot likely initiated. The gateway will be unavailable for a few minutes.');
      
      // Keep showing the rebooting state for 30 seconds
      setTimeout(() => {
        setIsRebooting(false);
        setShowRebootDialog(false);
      }, 30000);
    }
  };

  const formatUptime = (seconds: number) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);

    return `${days}d ${hours}h ${minutes}m`;
  };

  const getTemperatureColor = (temp: number) => {
    if (temp < 45) return "text-green-500";
    if (temp < 60) return "text-yellow-500";
    return "text-red-500";
  };

  return (
    <div className="mt-4 space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-gray-100">System Information</h3>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Temperature and Uptime Card */}
        <div className="grid grid-cols-2 gap-4">
          <SystemInfoCard title="Temperature" icon={BeakerIcon}>
            <div className="flex items-baseline">
              <span className={`text-2xl font-bold ${getTemperatureColor(systemInfo.temperature_celsius)}`}>
                {systemInfo.temperature_celsius.toFixed(1)}
              </span>
              <span className="text-gray-400 ml-1">°C</span>
            </div>
          </SystemInfoCard>

          <SystemInfoCard title="Uptime" icon={ClockIcon}>
            <div className="text-xl font-bold text-gray-100">
              {formatUptime(systemInfo.uptime_seconds)}
            </div>
          </SystemInfoCard>
        </div>

        {/* Memory Usage Card */}
        <SystemInfoCard title="Memory Usage" icon={CircleStackIcon}>
          <div className="space-y-2">
            <ProgressBar 
              percentage={systemInfo.memory_MB.percent_used} 
              color={systemInfo.memory_MB.percent_used > 80 ? "bg-red-500" : "bg-green-500"} 
            />
            <div className="flex justify-between text-sm">
              <span className="text-gray-400">
                Used: {systemInfo.memory_MB.used.toFixed(0)} MB
              </span>
              <span className="text-gray-400">
                Total: {systemInfo.memory_MB.total.toFixed(0)} MB
              </span>
            </div>
          </div>
        </SystemInfoCard>

        {/* System Load Card */}
        <SystemInfoCard title="System Load" icon={CpuChipIcon}>
          <div className="space-y-3">
            <div className="space-y-1">
              <div className="flex justify-between text-sm">
                <span className="text-gray-400">1 min</span>
                <span className="text-gray-300">{systemInfo.processes_average.last_1min.toFixed(2)}</span>
              </div>
              <ProcessLoadBar load={systemInfo.processes_average.last_1min} />
            </div>

            <div className="space-y-1">
              <div className="flex justify-between text-sm">
                <span className="text-gray-400">5 min</span>
                <span className="text-gray-300">{systemInfo.processes_average.last_5min.toFixed(2)}</span>
              </div>
              <ProcessLoadBar load={systemInfo.processes_average.last_5min} />
            </div>

            <div className="space-y-1">
              <div className="flex justify-between text-sm">
                <span className="text-gray-400">15 min</span>
                <span className="text-gray-300">{systemInfo.processes_average.last_15min.toFixed(2)}</span>
              </div>
              <ProcessLoadBar load={systemInfo.processes_average.last_15min} />
            </div>
          </div>
        </SystemInfoCard>

        {/* System Reboot Card */}
        <SystemInfoCard title="System Reboot" icon={ExclamationTriangleIcon}>
          <div className="flex-1" />
          <div className="flex items-center justify-center mt-4">
            <button
              onClick={() => setShowRebootDialog(true)}
              className="w-48 bg-red-600 hover:bg-red-700 rounded-lg px-4 py-2.5 text-white font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex flex-row items-center justify-center gap-2"
              disabled={isRebooting}
            >
              <ExclamationTriangleIcon className={`w-6 h-6 ${isRebooting ? 'animate-spin' : ''}`} />
              <span className="text-sm">{isRebooting ? 'Rebooting...' : 'Reboot'}</span>
            </button>
          </div>
        </SystemInfoCard>
      </div>

      <div className="text-xs text-gray-500 mt-4">
        Last updated: {new Date(systemInfo.time_utc_sec * 1000).toLocaleString()}
      </div>

      {/* Reboot Confirmation Dialog */}
      {showRebootDialog && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-[#2d2d2d] rounded-lg p-6 max-w-md w-full space-y-4">
            <div className="flex items-center gap-3 text-red-500">
              <ExclamationTriangleIcon className="w-6 h-6" />
              <h4 className="text-lg font-semibold">Confirm Gateway Reboot</h4>
            </div>
            
            <div className="space-y-3 text-gray-300">
              <p>Are you sure you want to reboot this gateway? This will:</p>
              <ul className="list-disc list-inside space-y-1 text-sm">
                <li>Temporarily disconnect the gateway from the network</li>
                <li>Interrupt any ongoing operations or data collection</li>
                <li>Take several minutes to complete</li>
              </ul>
              <p className="text-sm text-yellow-500">
                Note: The gateway will be unavailable during the reboot process.
              </p>
            </div>

            <div className="flex justify-end gap-3 pt-4">
              <button
                onClick={() => setShowRebootDialog(false)}
                className="px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white font-medium rounded-md transition-colors"
                disabled={isRebooting}
              >
                Cancel
              </button>
              <button
                onClick={handleReboot}
                className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white font-medium rounded-md transition-colors flex items-center gap-2"
                disabled={isRebooting}
              >
                {isRebooting ? (
                  <>
                    <ArrowPathIcon className="w-4 h-4 animate-spin" />
                    Rebooting...
                  </>
                ) : (
                  <>
                    <ArrowPathIcon className="w-4 h-4" />
                    Confirm Reboot
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface ExpandableBoxProps {
  title: string;
  children: React.ReactNode;
}

const ExpandableBox: React.FC<ExpandableBoxProps> = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-glass rounded-xl p-4 mt-6">
      <button
        className="w-full flex justify-between items-center"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h2 className="text-base font-bold leading-7">{title}</h2>
        {isExpanded ? (
          <ChevronUpIcon className="h-5 w-5" />
        ) : (
          <ChevronDownIcon className="h-5 w-5" />
        )}
      </button>
      {isExpanded && <div className="mt-4">{children}</div>}
    </div>
  );
};

const TransferGatewaySection = ({ gatewayId }: { gatewayId: string }) => {
  const [recipientAddress, setRecipientAddress] = useState('');
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [isTransferring, setIsTransferring] = useState(false);
  const [transferRewards, setTransferRewards] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { publicKey, wallet } = useWalletContext();
  const { refreshGateways, setActiveGateway } = useGateways();
  const router = useRouter();

  // Fetch rewards data to display available points
  const [rewardsData, setRewardsData] = useState<{ total: number } | null>(null);
  const [isLoadingRewards, setIsLoadingRewards] = useState(false);

  useEffect(() => {
    // Skip if we don't have required parameters
    if (!gatewayId || !publicKey) {
      return;
    }

    setIsLoadingRewards(true);

    const loadRewardsData = async () => {
      try {
        const walletAddress = publicKey.toString();
        const data = await fetchGatewayRewards(gatewayId, walletAddress);
        
        // Just get the betaInfo part with total
        setRewardsData(data?.betaInfo || null);
      } catch (err) {
        console.error('Error fetching rewards for transfer:', err);
        setRewardsData(null);
      } finally {
        setIsLoadingRewards(false);
      }
    };

    loadRewardsData();
  }, [gatewayId, publicKey]);

  const validateSolanaAddress = (address: string) => {
    // Basic Solana address validation - checks if it's a base58 string of correct length
    const base58Regex = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
    return base58Regex.test(address);
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const address = e.target.value;
    setRecipientAddress(address);
    setIsValidAddress(validateSolanaAddress(address));
  };

  const handleTransferInitiate = () => {
    if (!isValidAddress || !publicKey || !wallet) {
      toast.error('Please connect your wallet and enter a valid Solana address');
      return;
    }
    
    // Show confirmation dialog instead of immediately transferring
    setShowConfirmDialog(true);
  };

  const handleTransfer = async () => {
    // Hide the confirmation dialog
    setShowConfirmDialog(false);
    
    if (!isValidAddress || !publicKey || !wallet) {
      toast.error('Please connect your wallet and enter a valid Solana address');
      return;
    }

    setIsTransferring(true);
    const toastId = toast.loading("Preparing transfer...");

    try {
      // Create the transfer ownership message
      const message = SrcfulMessageProtocolFactory.createTransferOwnershipMessage({
        walletPublicKey: publicKey.toString(),
        gatewayId,
        newOwner: recipientAddress
      });

      // Sign the message
      const signedMessage = await wallet.signMessage(message);
      if (!signedMessage) {
        throw new Error('Failed to sign message');
      }

      // Send the transfer request
      const result = await transferGatewayOwnership(signedMessage, transferRewards);
      console.log('Transfer result:', result);

      // Only proceed with cleanup if transfer was successful
      if (result) {
        console.log('Transfer successful');
        // First clear active gateway to stop all subscriptions
        setActiveGateway(null);

        // Show success message and navigate away immediately
        toast.success('Gateway transferred successfully');
        

        // Add a delay before refreshing to allow backend to process the transfer
        console.log('Waiting for 1 seconds');
        await new Promise(resolve => setTimeout(resolve, 1000));
        console.log('1 seconds done');

        router.navigate({ to: '/' });

        // After successful transfer and delay, refresh the gateways list
        await refreshGateways();
      } else {
        throw new Error('Transfer failed - no confirmation from backend');
      }
    } catch (error) {
      console.error('Error transferring gateway:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to transfer gateway');
    } finally {
      setIsTransferring(false);
      toast.dismiss(toastId);
    }
  };

  return (
    <ExpandableBox title="Transfer Gateway">
      <div className="space-y-4 bg-red-950/30 p-6 rounded-lg border border-red-900">
        <div className="flex items-start gap-3">
          <ExclamationTriangleIcon className="w-6 h-6 text-red-500 flex-shrink-0 mt-1" />
          <div className="space-y-2">
            <p className="text-red-400 font-medium">Warning: Dangerous Operation</p>
            <p className="text-gray-300">
            <ul className="list-disc list-inside space-y-1 text-sm">
                <li>Transferring gateway ownership is irreversible.</li>
                <li>The new owner will have complete control over this gateway.</li>
                <li>The new owner will recieve any future rewards, historical rewards are not transferred.</li>
                <li>Make sure you enter the correct wallet address of the recipient.</li>
              </ul>
            </p>
          </div>
        </div>
        <div className="space-y-3">
          <label htmlFor="recipient-address" className="block text-sm font-medium text-gray-300">
            Recipient Wallet Address
          </label>
          <input
            id="recipient-address"
            type="text"
            value={recipientAddress}
            onChange={handleAddressChange}
            className={`w-full px-4 py-2 bg-gray-800 border ${
              recipientAddress && !isValidAddress ? 'border-red-500' : 'border-gray-700'
            } rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500`}
            placeholder="Enter Solana wallet address"
            disabled={isTransferring}
          />
          {recipientAddress && !isValidAddress && (
            <p className="text-sm text-red-500">Please enter a valid Solana wallet address</p>
          )}
        </div>

        {/* Rewards Transfer Option */}
        <div className="mt-4 pt-4 border-t border-red-900">
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="transfer-rewards"
                name="transfer-rewards"
                type="checkbox"
                checked={transferRewards}
                onChange={(e) => setTransferRewards(e.target.checked)}
                className="h-4 w-4 rounded border-gray-600 bg-gray-800 text-green-500 focus:ring-2 focus:ring-green-500"
                disabled={isTransferring}
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="transfer-rewards" className="font-medium text-gray-300">
                Transfer Rewards with Gateway
              </label>
              <p className="text-gray-400 mt-1">
                Include all accumulated rewards with this transfer. 
                {isLoadingRewards ? (
                  <span className="ml-1 italic">Loading rewards data...</span>
                ) : rewardsData ? (
                  <span className="ml-1 text-green-500 font-medium">
                    {rewardsData.total.toLocaleString()} Points available to transfer
                  </span>
                ) : (
                  <span className="ml-1 italic">No rewards data available</span>
                )}
              </p>
              <p className="text-yellow-500 text-xs mt-2">
                Note: Transferring rewards is permanent and cannot be undone.
              </p>
            </div>
          </div>
        </div>

        <ActionButton
          onClick={handleTransferInitiate}
          variant="destructive"
          disabled={!isValidAddress || isTransferring}
          isLoading={isTransferring}
          loadingText="Transferring..."
        >
          Transfer Gateway
        </ActionButton>
      </div>

      {/* Confirmation Dialog */}
      {showConfirmDialog && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-gray-800 rounded-lg p-6 max-w-md w-full space-y-4 border border-red-900">
            <div className="flex items-center gap-3 text-red-500">
              <ExclamationTriangleIcon className="w-6 h-6" />
              <h4 className="text-lg font-semibold">Confirm Gateway Transfer</h4>
            </div>
            
            <div className="space-y-3 text-gray-300">
              <p>You are about to transfer this gateway to:</p>
              <div className="bg-gray-900 p-3 rounded font-mono text-sm break-all">
                {recipientAddress}
              </div>
              
              {transferRewards && rewardsData && (
                <div className="mt-4 p-3 bg-green-900/20 border border-green-800 rounded">
                  <p className="font-medium text-green-400">
                    Including {rewardsData.total.toLocaleString()} Points with this transfer
                  </p>
                </div>
              )}
              
              <p className="text-yellow-500 font-medium mt-4">
                Warning: This action cannot be undone!
              </p>
            </div>

            <div className="flex justify-end gap-3 pt-4">
              <button
                onClick={() => setShowConfirmDialog(false)}
                className="px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white font-medium rounded-md transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleTransfer}
                className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white font-medium rounded-md transition-colors"
              >
                Confirm Transfer
              </button>
            </div>
          </div>
        </div>
      )}
    </ExpandableBox>
  );
};

// Total Rewards InfoRow with expandable graph
const TotalRewardsInfoRow = ({ gatewayId, walletAddress }: { gatewayId: string; walletAddress: string | null }) => {
  const [rewardsData, setRewardsData] = useState<GatewayRewardsData | null>(null);
  const [graphData, setGraphData] = useState<RewardsGraphDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  // Reset state when gatewayId changes
  useEffect(() => {
    // Clear existing data when gateway changes
    setRewardsData(null);
    setGraphData([]);
    setError(null);
    setIsExpanded(false);
  }, [gatewayId]);

  useEffect(() => {
    // Skip if we don't have required parameters
    if (!gatewayId || !walletAddress) {
      setIsLoading(false);
      return;
    }

    let isMounted = true;
    setIsLoading(true);

    const loadRewardsData = async () => {
      try {
        const data = await fetchGatewayRewards(gatewayId, walletAddress);
        
        // Only update state if the component is still mounted
        if (isMounted) {
          setRewardsData(data);
          
          // Process data for the graph
          const processedData = processRewardsForGraph(data);
          setGraphData(processedData);
          
          setError(null);
        }
      } catch (err) {
        console.error('Error fetching rewards:', err);
        if (isMounted) {
          setError(err instanceof Error ? err.message : 'Failed to fetch rewards data');
          setRewardsData(null);
          setGraphData([]);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    loadRewardsData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [gatewayId, walletAddress]);

  const formatTotal = (total: number) => {
    return total.toLocaleString();
  };

  // Format date for X-axis
  const formatXAxis = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
  };

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleMouseEnter = (data: any, index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  // Special action buttons for the InfoRow
  const actionButtons = (
    <button
      onClick={toggleExpanded}
      className="ml-2 p-1 text-gray-400 hover:text-white rounded-md hover:bg-white/10 transition-colors"
      title={isExpanded ? "Hide rewards history" : "Show rewards history"}
    >
      {isExpanded ? (
        <ChevronUpIcon className="h-4 w-4" />
      ) : (
        <ChevronDownIcon className="h-4 w-4" />
      )}
    </button>
  );

  if (isLoading) {
    return (
      <>
        <InfoRow
          label="Total Rewards"
          value="Loading..."
          tooltip="Total points earned by this gateway"
        />
      </>
    );
  }

  if (error || !walletAddress) {
    return (
      <>
        <InfoRow
          label="Total Rewards"
          value={!walletAddress ? "Connect wallet to view" : "Error loading rewards"}
          tooltip="Total points earned by this gateway"
        />
      </>
    );
  }

  const totalRewards = walletAddress ? calculateTotalRewards(rewardsData, walletAddress) : 0;

  return (
    <>
      <InfoRow
        label="Total Rewards"
        value={formatTotal(totalRewards)}
        displayValue={
          <div className="flex items-center">
            <span className="text-green-500 font-medium">
              {formatTotal(totalRewards)} Points
            </span>
            {actionButtons}
          </div>
        }
        tooltip="Total points earned by this gateway"
      />
      
      {isExpanded && graphData.length > 0 && (
        <div className="mt-4 mb-6">
          <h3 className="text-sm font-medium text-gray-300 mb-4">Last 30 Days Rewards History</h3>
          <div className="h-64 w-full">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={graphData}
                margin={{ top: 5, right: 20, left: 20, bottom: 30 }}
                onMouseLeave={handleMouseLeave}
                className="recharts-bar-chart"
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                <XAxis 
                  dataKey="date" 
                  tickFormatter={formatXAxis}
                  stroke="#9CA3AF"
                  tick={{ fill: '#9CA3AF' }}
                  tickMargin={10}
                />
                <YAxis 
                  stroke="#9CA3AF"
                  tick={{ fill: '#9CA3AF' }}
                  tickFormatter={(value) => value.toLocaleString()}
                />
                <Tooltip 
                  content={<CustomTooltip walletAddress={walletAddress} />}
                  cursor={{ fill: 'transparent' }}
                />
                <Bar 
                  dataKey="rewards" 
                  fill="#10B981"
                  barSize={20}
                  radius={[2, 2, 0, 0]}
                  name="Daily Rewards"
                  onMouseEnter={handleMouseEnter}
                >
                  {graphData.map((entry, index) => {
                    // Determine bar color based on data
                    let fillColor = "#10B981"; // Default green for regular rewards
                    
                    // If hover state is active, use white for the hovered bar
                    if (index === activeIndex) {
                      fillColor = "#FFFFFF"; // White for hover state
                    }
                    // Otherwise, use the standard color logic
                    else {
                      // If amount is 0, use gray
                      if (entry.rewards === 0) {
                        fillColor = "#6B7280"; // Gray for zero rewards
                      } 
                      // If wallet doesn't match current wallet but has rewards, use blue (different owner)
                      else if (entry.wallet && walletAddress && entry.wallet !== walletAddress && entry.rewards > 0) {
                        fillColor = "#3B82F6"; // Blue for different owner
                      }
                    }
                    
                    return <Cell key={`cell-${index}`} fill={fillColor} />;
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="text-center text-xs mt-3 space-y-1">
            <p className="text-gray-500">
              Rewards are calculated daily and may take up to 24 hours to appear
            </p>
            <div className="flex justify-center items-center gap-4 text-xs">
              <div className="flex items-center">
                <div className="w-3 h-3 rounded-sm bg-green-600 mr-1"></div>
                <span className="text-gray-400">Your rewards</span>
              </div>
              <div className="flex items-center">
                <div className="w-3 h-3 rounded-sm bg-blue-600 mr-1"></div>
                <span className="text-gray-400">Other owner</span>
              </div>
              <div className="flex items-center">
                <div className="w-3 h-3 rounded-sm bg-gray-600 mr-1"></div>
                <span className="text-gray-400">No rewards</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const userLocale = navigator.language;
  return date.toLocaleString(userLocale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};


const GatewayOverview = () => {
  const { isConnected, publicKey } = useWalletContext();
  const { gateways, activeGateway, setActiveGateway } = useGateways();
  const { gatewayId } = useParams({ from: '/gateway/$gatewayId' });
  const [lastSeen, setLastSeen] = useState('');
  const [systemInfo, setSystemInfo] = useState<SystemInfo | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gatewayId && gateways) {
      const gateway = gateways.find(g => g.id === gatewayId);
      if (gateway) {
        setActiveGateway(gateway);
        // Clear system info when switching gateways
        setSystemInfo(null);
      }
    }
  }, [gatewayId, gateways, setActiveGateway]);

  useEffect(() => {
    const updateLastSeen = () => {
      if (activeGateway?.state?.timestamp) {
        setLastSeen(formatLastSeen(activeGateway.state.timestamp));
      }
    };

    updateLastSeen(); // Initial call
    const intervalId = setInterval(updateLastSeen, 60000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [activeGateway?.state?.timestamp]);

  const isCloudGateway = (id: string): boolean => {
    const cloudPrefixes = ['hse-', 'hdi-', 'hhw-'];
    return cloudPrefixes.some(prefix => id.toLowerCase().startsWith(prefix));
  };

  const handleGetSystemInfo = async () => {
    if (!activeGateway) return;

    setIsLoading(true);
    try {
      const response = await GatewayMessageManager.getInstance().fetch(
        '/api/system',
        {
          method: 'GET'
        },
        {
          gatewayId: activeGateway.id
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to get system info: ${response.status}`);
      }

      const data = await response.json<SystemInfo>();
      setSystemInfo(data);
    } catch (error) {
      toast.error('Failed to get system information');
      console.error('Error fetching system info:', error);
      // Clear system info on error
      setSystemInfo(null);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isConnected || !activeGateway) return null;

  const formatPublicKey = (key: string) => {
    if (!key) return "not in backend";
    return `${key.slice(0, 4)}...${key.slice(-4)}`;
  };

  return (
    <ContentFramework>
      <PageContainer>
        <GatewayPageHeader 
          gatewayName={activeGateway.name}
          gatewayState={activeGateway.state?.status?.state}
        />
        <h1 className="text-4xl font-bold mb-6">Overview</h1>

        {/* Gateway Information */}
        <div className="w-full">
          <div>
            <h2 className="text-base font-bold leading-7">
              Energy Gateway Information
            </h2>
          </div>
          <div className="mt-5">
              <InfoRow
                label={gatewayInfo.name.label}
                value={activeGateway.name}
                isCopyable={true}
                tooltip={gatewayInfo.name.tooltip}
              />
              <InfoRow
                label="Type"
                value={isHardwareGateway(activeGateway) ? "Hardware Gateway" : 
                  activeGateway.id.startsWith('hse-') ? "SolarEdge Cloud Gateway" :
                  activeGateway.id.startsWith('hhw-') ? "Huawei Cloud Gateway" :
                  activeGateway.id.startsWith('hdi-') ? "DIMO Cloud Gateway" :
                  "Cloud Gateway"
                }
                displayValue={
                  <div className="flex items-center gap-2">
                    {isHardwareGateway(activeGateway) ? (
                      <GatewayIcon state="none" className="w-5 h-5" />
                    ) : (
                      <CloudGatewayIcon state="none" className="w-5 h-5" />
                    )}
                    <span>
                      {isHardwareGateway(activeGateway) ? "Hardware Gateway" :
                        activeGateway.id.startsWith('hse-') ? "SolarEdge Cloud Gateway" :
                        activeGateway.id.startsWith('hhw-') ? "Huawei Cloud Gateway" :
                        activeGateway.id.startsWith('hdi-') ? "DIMO Cloud Gateway" :
                        "Cloud Gateway"
                      }
                    </span>
                  </div>
                }
                tooltip="The type of gateway - either a physical hardware gateway or a specific type of cloud-based gateway"
              />
              <InfoRow
                label={gatewayInfo.serial.label}
                value={activeGateway.id}
                displayValue={
                  <a
                    href={`https://explorer.sourceful.energy/egw/${activeGateway.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 hover:bg-white/10 rounded-md transition-colors px-2 py-1"
                  >
                    <span>{activeGateway.id}</span>
                    <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                  </a>
                }
                isCopyable={true}
                tooltip={gatewayInfo.serial.tooltip}
              />
              {isHardwareGateway(activeGateway) && (
                <InfoRow
                  label={gatewayInfo.publicKey.label}
                  value={activeGateway.publicKey}
                  displayValue={formatPublicKey(activeGateway.publicKey)}
                  isCopyable={true}
                  tooltip={gatewayInfo.publicKey.tooltip}
                />
              )}
              <InfoRow
                label={gatewayInfo.h3Index.label}
                value={activeGateway.h3Index}
                displayValue={
                  <a
                    href={`https://explorer.sourceful.energy/hex/${activeGateway.h3Index}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 hover:bg-white/10 rounded-md transition-colors px-2 py-1"
                  >
                    <span>{activeGateway.h3Index}</span>
                    <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                  </a>
                }
                isCopyable={true}
                tooltip={gatewayInfo.h3Index.tooltip}
              />
              {activeGateway.timeZone && (
                <InfoRow
                  label={gatewayInfo.timeZone.label}
                  value={activeGateway.timeZone}
                  tooltip={gatewayInfo.timeZone.tooltip}
                />
              )}
              {activeGateway.state?.timestamp && (
                <InfoRow
                  label={gatewayInfo.lastSeen.label}
                  value={activeGateway.state.timestamp.toString()}
                  displayValue={
                    <span className={`${Date.now() - activeGateway.state.timestamp > 15 * 60 * 1000 ? 'text-red-500' : ''}`}>
                      {lastSeen}
                    </span>
                  }
                  tooltip={gatewayInfo.lastSeen.tooltip}
                />
              )}
              {isHardwareGateway(activeGateway) && (
                <>
                  <InfoRow
                    label={gatewayInfo.uptime.label}
                    value={activeGateway.state?.status?.uptime?.toString()}
                    displayValue={activeGateway.state?.status?.uptime ? formatUptime(activeGateway.state.status.uptime) : 'Unknown'}
                    tooltip={gatewayInfo.uptime.tooltip}
                  />
                  <InfoRow
                    label={gatewayInfo.firmware.label}
                    value={activeGateway.state?.status?.version}
                    displayValue={activeGateway.state?.status?.version || 'Unknown'}
                    tooltip={gatewayInfo.firmware.tooltip}
                  />
                </>
              )}
              
              {/* Add Total Rewards InfoRow with expandable graph */}
              <TotalRewardsInfoRow 
                gatewayId={activeGateway.id} 
                walletAddress={publicKey ? publicKey.toString() : null}
              />
          </div>
        </div>

        {/* DER Section */}
        <DerSection ders={activeGateway.ders} />

        {/* Reauthentication Section - only for Huawei cloud gateways */}
        {activeGateway.id.startsWith('hhw-') && (
          <ReauthenticationSection 
            gatewayId={activeGateway.id} 
            publicKey={publicKey?.toString() || null} 
          />
        )}

        {/* DIMO Management Section - only for DIMO cloud gateways */}
        {activeGateway.id.startsWith('hdi-') && (
          <DimoManagementSection 
            gatewayId={activeGateway.id} 
            publicKey={publicKey?.toString() || null} 
          />
        )}

        {/* Divider */}
        <div className="h-px bg-gray-200/10 my-8" />

        {/* Notifications Section */}
        <div className="mt-8">
          <div className="flex items-center mb-4">
            <BellIcon className="h-5 w-5 text-gray-400 mr-2" />
            <h2 className="text-xl font-semibold text-gray-200">History</h2>
          </div>
          
          <div className="space-y-4">
            {activeGateway?.state?.status?.messages && activeGateway.state.status.messages.length > 0 ? (
              activeGateway.state.status.messages
                .slice()
                .sort((a: any, b: any) => b.timestamp - a.timestamp)
                .map((notification: any) => (
                  <div
                    key={notification.id}
                    className="bg-gray-800/50 rounded-lg p-4 border border-gray-700/50"
                  >
                    <div className="flex justify-between items-start">
                      <div className="flex-grow">
                        <div className="flex items-center space-x-2 mb-2">
                          <span className={`
                            inline-block px-2 py-1 text-xs rounded-full
                            ${notification.type === 'info' ? 'bg-blue-900/50 text-blue-200' : ''}
                            ${notification.type === 'error' ? 'bg-red-900/50 text-red-200' : ''}
                            ${notification.type === 'warning' ? 'bg-yellow-900/50 text-yellow-200' : ''}
                          `}>
                            {notification.type}
                          </span>
                          <span className="text-xs text-gray-400">
                            {formatTimestamp(notification.timestamp)}
                          </span>
                        </div>
                        <p className="text-sm text-gray-200">{notification.message}</p>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div className="text-center py-8">
                <p className="text-gray-400">Nothing to see here...</p>
              </div>
            )}
          </div>
        </div>

        {!isCloudGateway(activeGateway.id) && (
          <>
            <div className="h-px bg-gray-200/10 my-8" />
            <div className="mt-6">
              <button
                onClick={handleGetSystemInfo}
                disabled={isLoading}
                className="px-4 py-2 bg-green-600 hover:bg-green-700 text-white font-medium rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? 'Loading...' : 'Get System Info'}
              </button>

              {systemInfo && (
                <SystemInfoDisplay systemInfo={systemInfo} gatewayId={activeGateway.id} />
              )}
            </div>
          </>
        )}

        {/* Transfer Gateway Section - only show if feature flag is enabled */}
        {isFeatureEnabled('ENABLE_GATEWAY_TRANSFER') && (
          <TransferGatewaySection gatewayId={activeGateway.id} />
        )}
      </PageContainer>
    </ContentFramework>
  );
};

export default GatewayOverview; 