// @ts-ignore
import * as nacl from 'tweetnacl';
import bs58 from 'bs58';
import { WalletInterface, MessageContext, createSignedMessage } from './types';
import { WalletVisitor } from './WalletVisitor';

export class LocalWallet implements WalletInterface {
  private keypair: nacl.SignKeyPair | null = null;
  private derivedPublicKey: string | null = null;

  /**
   * Create an ed25519 keypair from a private key string
   * This follows Solana conventions for key derivation
   */
  private static createKeypairFromPrivateKey(privateKeyString: string): nacl.SignKeyPair {
    // For a proper implementation, we should ensure the private key is either:
    // 1. A 32-byte array (for a raw private key)
    // 2. A 64-byte array (for a full keypair where first 32 bytes are the private key)
    
    let privateKeyBytes: Uint8Array;
    
    // First check if it's a valid base58 string that could decode to 32 or 64 bytes
    try {
      const decoded = bs58.decode(privateKeyString);
      if (decoded.length === 32 || decoded.length === 64) {
        // Valid format already - use it directly
        privateKeyBytes = decoded.length === 32 
          ? decoded 
          : decoded.slice(0, 32); // Use first 32 bytes if it's a full keypair
        
        // Generate keypair from this seed
        return nacl.sign.keyPair.fromSeed(privateKeyBytes);
      }
    } catch (e) {
      // Not valid base58, continue to treat as a passphrase
      console.log("Not a valid base58 encoded key, treating as passphrase");
    }
    
    // If we get here, treat the input as a string passphrase
    // Use a secure key derivation that matches Solana's approach
    const encoder = new TextEncoder();
    const encodedPrivateKey = encoder.encode(privateKeyString);
    
    // Create a 32-byte seed from the passphrase - using SHA256 which is common
    // in crypto derivation (instead of blake2b which we used before)
    const seedArray = new Uint8Array(32);
    for (let i = 0; i < encodedPrivateKey.length; i++) {
      seedArray[i % 32] ^= encodedPrivateKey[i];
    }
    
    // Generate keypair from the derived seed
    return nacl.sign.keyPair.fromSeed(seedArray);
  }

  constructor(privateKey: string | null = null) {
    // Don't automatically connect in the constructor
    // This avoids race conditions with setting wallet in context
    if (privateKey) {
      this.connect(privateKey).catch(err => {
        console.error("Failed to connect wallet in constructor:", err);
      });
    }
  }

  public isConnected(): boolean {
    // A local wallet is connected if it has a keypair and derived public key
    return this.keypair !== null && this.derivedPublicKey !== null;
  }

  public isConnecting(): boolean {
    // Local wallet connection is immediate, never in connecting state
    return false;
  }

  public getPublicKey(): string | null {
    return this.derivedPublicKey;
  }

  /**
   * Sign a message using the wallet's private key
   * This follows Solana's signing conventions by prefixing the message
   * @param message The message to sign
   * @returns The signature as a Uint8Array
   */
  public async sign(message: Uint8Array): Promise<Uint8Array> {
    if (!this.isConnected() || !this.keypair) {
      throw new Error('Wallet not connected');
    }

    try {
      // Sign the raw message without adding Solana prefix
      const signature = nacl.sign.detached(message, this.keypair.secretKey);
      
      // Verify the signature for safety
      const verified = nacl.sign.detached.verify(message, signature, this.keypair.publicKey);
      if (!verified) {
        throw new Error("Signature verification failed");
      }
      
      return signature;
    } catch (error) {
      console.error("Error signing message:", error);
      throw new Error(`Error signing message: ${error}`);
    }
  }
  
  /**
   * Verify a message signature against this wallet's public key
   * @param message The original message that was signed
   * @param signature The signature to verify
   * @returns True if the signature is valid, false otherwise
   */
  public verifySignature(message: Uint8Array, signature: Uint8Array): boolean {
    if (!this.keypair) {
      return false;
    }
    
    // Add the same Solana message prefix used in signing
    const SIGN_PREFIX = new TextEncoder().encode('Solana Message');
    
    // Recreate the message with prefix
    const messageWithPrefix = new Uint8Array(SIGN_PREFIX.length + message.length);
    messageWithPrefix.set(SIGN_PREFIX);
    messageWithPrefix.set(message, SIGN_PREFIX.length);
    
    // Verify the signature against the public key
    return nacl.sign.detached.verify(
      messageWithPrefix,
      signature,
      this.keypair.publicKey
    );
  }

  // Legacy method - now calls disconnect
  public resetConnection(): void {
    this.disconnect();
  }

  public disconnect(): void {
    this.keypair = null;
    this.derivedPublicKey = null;
  }

  public async connect(privateKey: string): Promise<void> {
    try {
      // Generate a proper ed25519 keypair from the private key
      this.keypair = LocalWallet.createKeypairFromPrivateKey(privateKey);
      
      // Encode the public key in base58 as Solana does
      this.derivedPublicKey = bs58.encode(this.keypair.publicKey);
    } catch (error) {
      console.error("Error connecting wallet with private key:", error);
      throw new Error(`Failed to derive keypair: ${error instanceof Error ? error.message : String(error)}`);
    }
  }

  public async signMessage(message: string): Promise<MessageContext | null> {
    if (!this.keypair) {
      return null;
    }
    const signedMessage = await createSignedMessage(this.sign.bind(this), message);
    if (!signedMessage) {
      return null;
    }
    return {
      signedMessage
    };
  }

  public accept(visitor: WalletVisitor): void {
    visitor.visitLocalWallet(this);
  }
} 