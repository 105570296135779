import { FC, useState } from 'react';
import { ActionButton } from '../../../ui/ActionButton';
import { SolarEdgeCredentials, StepProps } from '../../types';

interface SolarEdgeCredentialsProps extends StepProps {
  credentials: SolarEdgeCredentials;
  setCredentials: (creds: SolarEdgeCredentials) => void;
  isValidating: boolean;
  validationError: string | null;
}

const SolarEdgeCredentialsStep: FC<SolarEdgeCredentialsProps> = ({
  credentials,
  setCredentials,
  onBack,
  onNext,
  isValidating,
  validationError
}) => {
  const [showApiKey, setShowApiKey] = useState(false);

  return (
    <div className="space-y-8">
      <div className="space-y-6">
        <div className="flex items-center gap-4">
          <h2 className="text-xl font-semibold">SolarEdge Credentials</h2>
        </div>
        <div className="space-y-4">
          <div className="space-y-2">
            <label htmlFor="siteId" className="block text-sm font-medium text-gray-200">
              Site ID
            </label>
            <input
              type="text"
              id="siteId"
              value={credentials.siteId}
              onChange={(e) => setCredentials({ ...credentials, siteId: e.target.value })}
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
              placeholder="Enter your SolarEdge Site ID"
            />
          </div>

          <div className="space-y-2">
            <label htmlFor="apiKey" className="block text-sm font-medium text-gray-200">
              API Key
            </label>
            <div className="relative">
              <input
                type={showApiKey ? "text" : "password"}
                id="apiKey"
                value={credentials.apiKey}
                onChange={(e) => setCredentials({ ...credentials, apiKey: e.target.value })}
                className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent pr-10"
                placeholder="Enter your SolarEdge API Key"
              />
              <button
                type="button"
                onClick={() => setShowApiKey(!showApiKey)}
                className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-400 hover:text-gray-300"
              >
                {showApiKey ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                )}
              </button>
            </div>
            <p className="mt-1 text-sm text-gray-400">
              You can generate an API key in your SolarEdge account settings
            </p>
          </div>

          <p className="text-sm text-gray-400">
            Need help finding your Site ID and API Key? 
            <a 
              href="https://help.energyid.eu/en/apps/solaredge/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-300 ml-1"
            >
              View instructions
              <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
              </svg>
            </a>
          </p>

          {validationError && (
            <div className="p-4 rounded-lg bg-red-900/50 border border-red-500/50">
              <p className="text-red-200">{validationError}</p>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between">
        <ActionButton
          onClick={onBack}
          variant="secondary"
          disabled={isValidating}
        >
          Back
        </ActionButton>
        <ActionButton
          onClick={onNext}
          variant="primary"
          disabled={!credentials.siteId || !credentials.apiKey || isValidating}
          isLoading={isValidating}
          loadingText="Creating Gateway..."
        >
          Create Gateway
        </ActionButton>
      </div>
    </div>
  );
};

export default SolarEdgeCredentialsStep; 