import { FC } from 'react';
import { ActionButton } from '../../../ui/ActionButton';
import { StepProps } from '../../types';
import { HuaweiIcon } from '../../../icons/brand_icons';
import { toast } from 'react-hot-toast';

interface HuaweiCredentialsProps extends StepProps {
  publicKey: string | null;
  location: {
    latitude: string;
    longitude: string;
  };
  isCreatingSession: boolean;
  setIsCreatingSession: (value: boolean) => void;
}

const HuaweiCredentialsStep: FC<HuaweiCredentialsProps> = ({
  onBack,
  publicKey,
  location,
  isCreatingSession,
  setIsCreatingSession
}) => {
  const handleHuaweiAuth = async () => {
    if (!publicKey) {
      toast.error('Please connect your wallet first');
      return;
    }

    setIsCreatingSession(true);
    try {
      const response = await fetch('https://huawei.srcful.dev/gateway_session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ownerWallet: publicKey,
          longitude: parseFloat(location.longitude),
          latitude: parseFloat(location.latitude),
          redirect_url: 'https://app.srcful.io'
        })
      });

      if (!response.ok) {
        throw new Error('Failed to create Huawei session');
      }

      const session = await response.json();
      
      // Redirect to Huawei OAuth page
      const huaweiOAuthUrl = `https://oauth2.fusionsolar.huawei.com/rest/dp/uidm/oauth2/v1/authorize?response_type=code&client_id=742215509&redirect_uri=https://huawei.srcful.dev/oauth/callback&state=${session.session_id}`;
      window.location.href = huaweiOAuthUrl;
    } catch (error) {
      console.error('Failed to create Huawei session:', error);
      toast.error('Failed to start Huawei authentication');
    } finally {
      setIsCreatingSession(false);
    }
  };

  return (
    <div className="space-y-8">
      <div className="space-y-6">
        <div className="flex items-center gap-4">
          <h2 className="text-xl font-semibold">Huawei FusionSolar Authentication</h2>
        </div>
        
        <div className="space-y-4 bg-gray-800/50 p-6 rounded-lg border border-gray-700">
          <div className="flex items-center gap-3">
            <HuaweiIcon height={30} className="text-white" />
            <h3 className="text-lg font-medium">Connect Your Huawei FusionSolar Account</h3>
          </div>
          <p className="text-gray-300">
            You'll be redirected to Huawei FusionSolar to authenticate and authorize access to your installation data. 
            After successful authentication, your gateway will be created automatically.<br/><br/>
            Note: Your FusionSolar account must have the owner role to be able to connect.
          </p>
          <div className="space-y-2 text-sm text-gray-400">
            <p>The process will:</p>
            <ul className="list-disc ml-4 space-y-1">
              <li>Redirect you to Huawei FusionSolar login</li>
              <li>Ask for your permission to access installation data</li>
              <li>Create your gateway automatically after approval</li>
              <li>Return you to this application</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <ActionButton
          onClick={onBack}
          variant="secondary"
          disabled={isCreatingSession}
        >
          Back
        </ActionButton>
        <ActionButton
          onClick={handleHuaweiAuth}
          variant="primary"
          isLoading={isCreatingSession}
          loadingText="Preparing Authentication..."
          disabled={!publicKey}
        >
          Continue to Huawei
        </ActionButton>
      </div>
    </div>
  );
};

export default HuaweiCredentialsStep; 