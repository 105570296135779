/**
 * Rewards Service
 * 
 * Handles GraphQL queries related to rewards data
 */

/**
 * Interface for the daily rewards data
 */
export interface RewardsDailyData {
  ts: string;
  amount: number;
  wallet: string;
}

/**
 * Interface for the rewards data response from the API
 */
export interface GatewayRewardsData {
  betaInfo: {
    total: number;
  };
  betaDaily: Array<{
    gwId: string;
    sn: string;
    rewards: RewardsDailyData[];
  }>;
}

/**
 * Interface for the graph data item
 */
export interface RewardsGraphDataItem {
  date: string;
  rewards: number;
  wallet: string;
  gwId?: string;  // Gateway ID
  derDetails?: {
    [sn: string]: number;  // DER serial number to rewards amount mapping
  };
}

/**
 * Interface for the top gateways by rewards
 */
export interface TopGatewayRewards {
  gwId: string;
  amount: number;
  name: string;
}

/**
 * Interface for the top wallets by rewards
 */
export interface TopWalletRewards {
  wallet: string;
  amount: number;
  numerOfGateways: number;
}

/**
 * Enum for time period selection
 */
export enum RewardsTimePeriod {
  CURRENT_MONTH = 'CURRENT_MONTH',
  LAST_FULL_WEEK = 'LAST_FULL_WEEK'
}

/**
 * Fetches rewards data for a specific gateway and wallet
 * If gatewayId is empty, fetches total rewards for the wallet across all gateways
 * 
 * @param gatewayId The ID of the gateway (can be empty to get all wallet rewards)
 * @param walletAddress The wallet address
 * @returns The rewards data or null if there was an error
 */
export const fetchGatewayRewards = async (
  gatewayId: string,
  walletAddress: string
): Promise<GatewayRewardsData | null> => {
  try {
    // Different query based on whether a gatewayId is provided
    let queryStr;
    
    if (gatewayId) {
      // Query for a specific gateway
      queryStr = `
        query daily_rewards {
          token {
            rewards {
              betaInfo(wallet:"${walletAddress}", gwId:"${gatewayId}") {
                total
              }
              betaDaily(gwId:"${gatewayId}") {
                gwId
                sn
                rewards {
                  ts
                  amount
                  wallet
                }
              }
            }
          }
        }
      `;
    } else {
      // Query for total wallet rewards (no specific gateway)
      queryStr = `
        query wallet_rewards {
          token {
            rewards {
              betaInfo(wallet:"${walletAddress}") {
                total
              }
            }
          }
        }
      `;
    }

    const query = JSON.stringify({ query: queryStr });

    const response = await fetch('https://api.srcful.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: query
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    
    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    return result.data.token.rewards;
  } catch (error) {
    console.error('Error fetching rewards:', error);
    return null;
  }
};

/**
 * Processes the raw rewards data into graph-friendly format
 * Aggregates rewards across all DERs for each date
 * 
 * @param rewardsData The raw rewards data from the API
 * @returns Formatted data ready for graphing
 */
export const processRewardsForGraph = (
  rewardsData: GatewayRewardsData | null
): RewardsGraphDataItem[] => {
  if (!rewardsData || !rewardsData.betaDaily || rewardsData.betaDaily.length === 0) {
    return [];
  }

  // Create a map to aggregate rewards by date
  const dailyAggregates: Map<string, {
    total: number;
    wallet: string;
    derDetails: { [sn: string]: number };
    gwId: string;
  }> = new Map();

  // Process all DERs for the gateway
  rewardsData.betaDaily.forEach(der => {
    der.rewards.forEach(reward => {
      const dateKey = reward.ts;
      const existingDay = dailyAggregates.get(dateKey);

      if (existingDay) {
        // Add to existing day's total
        existingDay.total += reward.amount;
        // Store individual DER amount
        existingDay.derDetails[der.sn] = reward.amount;
      } else {
        // Create new day entry
        dailyAggregates.set(dateKey, {
          total: reward.amount,
          wallet: reward.wallet,
          derDetails: { [der.sn]: reward.amount },
          gwId: der.gwId
        });
      }
    });
  });

  // Convert to array format
  const dailyRewards = Array.from(dailyAggregates.entries()).map(([date, data]) => ({
    date,
    rewards: data.total,
    wallet: data.wallet,
    derDetails: data.derDetails,
    gwId: data.gwId
  }));
  
  // Sort by date ascending
  dailyRewards.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  
  return dailyRewards;
};

/**
 * Calculates total rewards earned by the specified wallet from gateway data
 * 
 * @param rewardsData The rewards data
 * @param walletAddress The wallet address to calculate rewards for
 * @returns The total rewards amount
 */
export const calculateTotalRewards = (
  rewardsData: GatewayRewardsData | null,
  walletAddress: string
): number => {
  // If betaInfo is available, use it
  if (rewardsData?.betaInfo?.total !== undefined) {
    return rewardsData.betaInfo.total;
  }
  
  // Otherwise calculate from daily data
  if (!rewardsData || !rewardsData.betaDaily || rewardsData.betaDaily.length === 0) {
    return 0;
  }
  
  return rewardsData.betaDaily[0].rewards
    .filter(item => item.wallet === walletAddress)
    .reduce((sum, item) => sum + item.amount, 0);
};

/**
 * Fetches top gateways by rewards for a specific time period
 * 
 * @param timePeriod The time period to fetch data for
 * @returns The top gateways data or null if there was an error
 */
export const fetchTopGatewaysByRewards = async (
  timePeriod: RewardsTimePeriod
): Promise<TopGatewayRewards[] | null> => {
  try {
    const queryStr = `
      query top_gateways {
        stats {
          betaRewardsTopListByGw(timePeriod: ${timePeriod}) {
            gwId
            amount
            name
          }
        }
      }
    `;

    const query = JSON.stringify({ query: queryStr });

    const response = await fetch('https://api.srcful.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: query
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    
    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    return result.data.stats.betaRewardsTopListByGw;
  } catch (error) {
    console.error('Error fetching top gateways by rewards:', error);
    return null;
  }
};

/**
 * Fetches top wallets by rewards for a specific time period
 * 
 * @param timePeriod The time period to fetch data for
 * @returns The top wallets data or null if there was an error
 */
export const fetchTopWalletsByRewards = async (
  timePeriod: RewardsTimePeriod
): Promise<TopWalletRewards[] | null> => {
  try {
    const queryStr = `
      query top_wallets {
        stats {
          betaRewardsTopListByWallet(timePeriod: ${timePeriod}) {
            wallet
            amount
            numerOfGateways
          }
        }
      }
    `;

    const query = JSON.stringify({ query: queryStr });

    const response = await fetch('https://api.srcful.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: query
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    
    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    return result.data.stats.betaRewardsTopListByWallet;
  } catch (error) {
    console.error('Error fetching top wallets by rewards:', error);
    return null;
  }
}; 