// Feature flags configuration
export const featureFlags = {
  // ENABLE_GATEWAY_TRANSFER: process.env.REACT_APP_ENABLE_GATEWAY_TRANSFER === 'true',
  ENABLE_DIMO_GATEWAY: process.env.REACT_APP_ENABLE_DIMO_GATEWAY === 'true',
  ENABLE_GATEWAY_TRANSFER: true,
  ENABLE_RECOVER_WALLETLESS: false,
} as const;

// Type for the feature flags
export type FeatureFlags = typeof featureFlags;

// Helper function to check if a feature is enabled
export const isFeatureEnabled = (feature: keyof FeatureFlags): boolean => {
  return featureFlags[feature];
}; 