import { FC, useState, useCallback } from 'react';
import ContentFramework from "../components/ContentFramework";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import { useGateways } from "../context/GatewayContext";
import { Button } from '../components/ui/Button';
import { toast } from "react-hot-toast";
import { BeakerIcon } from "@heroicons/react/24/outline";
import { useWalletContext } from '../context/WalletContext';
import { LocalWallet} from '../wallets';
import * as bs58 from 'bs58';
import { forceWalletDisconnect } from '../utils/WalletUtils';
import { APP_VERSION } from "../config/version";

const DebugPage: FC = () => {
  const { setWallet, isConnected, publicKey: contextPublicKey, disconnect, isConnecting, wallet: contextWallet } = useWalletContext();
  const { gateways, activeGateway, isLoading, error } = useGateways();
  
  // Use a single state object for section expansion to reduce rerenders
  const [expandedSections, setExpandedSections] = useState({
    storage: false,
    wallet: false,
    gateway: false
  });
  
  // Create toggle handlers with useCallback
  const toggleStorageSection = useCallback(() => {
    setExpandedSections(prev => ({ ...prev, storage: !prev.storage }));
  }, []);
  
  const toggleWalletSection = useCallback(() => {
    setExpandedSections(prev => ({ ...prev, wallet: !prev.wallet }));
  }, []);
  
  const toggleGatewaySection = useCallback(() => {
    setExpandedSections(prev => ({ ...prev, gateway: !prev.gateway }));
  }, []);
  
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});
  const [privateKey, setPrivateKey] = useState('');
  const [derivedPublicKey, setDerivedPublicKey] = useState('');
  const [localWallet, setLocalWallet] = useState<LocalWallet | null>(null);

  const getLocalStorageItems = useCallback(() => {
    const items: Record<string, { isJson: boolean; value: any }> = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        const value = localStorage.getItem(key) || '';
        try {
          const parsedValue = JSON.parse(value);
          items[key] = { 
            isJson: typeof parsedValue === 'object' && parsedValue !== null, 
            value: parsedValue 
          };
        } catch {
          items[key] = { isJson: false, value: value };
        }
      }
    }
    return items;
  }, []);

  const toggleExpand = useCallback((path: string) => {
    setExpandedItems(prev => ({
      ...prev,
      [path]: !prev[path]
    }));
  }, []);

  const renderJsonValue = (value: any, path: string = ''): JSX.Element => {
    if (typeof value === 'object' && value !== null) {
      const isExpanded = expandedItems[path];
      const keyName = path.split('.').pop() || '';
      return (
        <div>
          <div className="flex items-center">
            <button
              onClick={() => toggleExpand(path)}
              className="flex items-center space-x-2 text-sm text-gray-400 hover:text-gray-200"
            >
              {isExpanded ? (
                <IoChevronDown className="w-4 h-4 flex-shrink-0" />
              ) : (
                <IoChevronForward className="w-4 h-4 flex-shrink-0" />
              )}
              <span className="text-blue-400">
                {keyName}
                {Array.isArray(value) && ` (${value.length})`}
              </span>
            </button>
          </div>
          {isExpanded && (
            <div className="ml-4 mt-2 space-y-1">
              {Object.entries(value).map(([key, val]) => (
                <div key={key} className="break-all">
                  {typeof val === 'object' && val !== null ? (
                    renderJsonValue(val, `${path}.${key}`)
                  ) : (
                    <div className="flex flex-wrap">
                      <span className="text-blue-400 mr-2">{key}:</span>
                      <span className="text-gray-200 break-all whitespace-pre-wrap">
                        {JSON.stringify(val)}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }
    return (
      <span className="text-gray-200 break-all whitespace-pre-wrap">
        {JSON.stringify(value)}
      </span>
    );
  };

  const ExpandableSection = ({ 
    title, 
    isExpanded, 
    onToggle,
    children 
  }: { 
    title: string;
    isExpanded: boolean;
    onToggle: () => void;
    children: React.ReactNode;
  }) => (
    <div className="bg-glass p-4 rounded-lg">
      <button
        onClick={onToggle}
        className="flex items-center space-x-2 w-full text-left"
        type="button"
      >
        <span className="text-xl font-semibold">{title}</span>
        {isExpanded ? (
          <IoChevronDown className="w-5 h-5" />
        ) : (
          <IoChevronForward className="w-5 h-5" />
        )}
      </button>
      {isExpanded && (
        <div className="mt-4 space-y-2">
          {children}
        </div>
      )}
    </div>
  );

  const handleClearLocalStorage = () => {
    window.localStorage.clear();
    toast.success('Local storage cleared');
    // Force a re-render to update the displayed storage items
    toggleStorageSection();
  };

  const handleCreateLocalWallet = async () => {
    if (!privateKey) {
      toast.error('Please enter a valid private key');
      return;
    }

    // Indicate connecting status
    toast.loading('Creating local wallet...', { id: 'creating-wallet' });
    
    try {
      // Create a new wallet instance but don't set it in context yet
      const wallet = new LocalWallet();
      
      // Explicitly await the connection to complete
      await wallet.connect(privateKey);
      
      // Check if the wallet is properly connected
      if (!wallet.isConnected()) {
        toast.error('Failed to connect wallet. Please try again.', { id: 'creating-wallet' });
        return;
      }
      
      // Get the derived public key
      const derivedPK = wallet.getPublicKey();
      if (!derivedPK) {
        toast.error('Failed to derive public key from wallet', { id: 'creating-wallet' });
        return;
      }
      
      // Update local state
      setLocalWallet(wallet);
      setDerivedPublicKey(derivedPK);
      
      // Let user know wallet was created but not yet applied to context
      toast.success(`Local wallet created! Public key: ${derivedPK.substring(0, 10)}...`, { id: 'creating-wallet' });
    } catch (error) {
      console.error('Error connecting wallet:', error);
      toast.error('Failed to connect wallet: ' + (error instanceof Error ? error.message : String(error)), 
        { id: 'creating-wallet' });
    }
  };

  const derivePublicKey = () => {
    handleCreateLocalWallet().catch(err => {
      console.error('Error in derivePublicKey:', err);
    });
  };

  return (
    <ContentFramework>
      <div className="w-full max-w-3xl p-4 text-gray-200">
        <div className="flex items-center gap-4 mb-6">
          <BeakerIcon className="w-8 h-8 text-gray-400" />
          <h1 className="text-4xl font-bold">Debug</h1>
          <span className="text-sm text-gray-400 ml-auto">Version: {APP_VERSION}</span>
        </div>
        
        <div className="space-y-4">
          {/* Gateway Context Section */}
          <ExpandableSection
            title="Gateway Context"
            isExpanded={expandedSections.gateway}
            onToggle={toggleGatewaySection}
          >
            <div className="space-y-2">
              <div className="border border-gray-700 rounded p-3">
                <div className="font-mono text-sm text-blue-400">Loading State</div>
                <div className="mt-1 font-mono text-sm">
                  {isLoading ? "Loading..." : "Ready"}
                </div>
              </div>
              
              <div className="border border-gray-700 rounded p-3">
                <div className="font-mono text-sm text-blue-400">Error State</div>
                <div className="mt-1 font-mono text-sm break-all">
                  {error ? error.message : "No errors"}
                </div>
              </div>

              <div className="border border-gray-700 rounded p-3">
                <div className="font-mono text-sm text-blue-400">Active Gateway</div>
                <div className="font-mono text-sm w-full break-all">
                  {renderJsonValue(activeGateway || {}, 'activeGateway')}
                </div>
              </div>

              <div className="border border-gray-700 rounded p-3">
                <div className="font-mono text-sm text-blue-400">All Gateways</div>
                <div className="font-mono text-sm w-full break-all">
                  {renderJsonValue(gateways || [], 'gateways')}
                </div>
              </div>
            </div>
          </ExpandableSection>

          {/* Wallet Information Section */}
          <ExpandableSection
            title="Wallet Information"
            isExpanded={expandedSections.wallet}
            onToggle={toggleWalletSection}
          >
            <div className="space-y-2">
              <div className="border border-gray-700 rounded p-3">
                <div className="font-mono text-sm text-blue-400">Connection Status</div>
                <div className="mt-1 font-mono text-sm">
                  {isConnected ? "Connected" : "Disconnected"}
                </div>
              </div>
              <div className="border border-gray-700 rounded p-3">
                <div className="font-mono text-sm text-blue-400">Wallet Public Key</div>
                <div className="mt-1 font-mono text-sm break-all">
                  {contextPublicKey || "Not available"}
                </div>
              </div>
              <div className="border border-gray-700 rounded p-3">
                <div className="font-mono text-sm text-blue-400">Wallet Name</div>
                <div className="mt-1 font-mono text-sm break-words">
                  {contextWallet && typeof contextWallet === 'object' && 'walletAdapter' in contextWallet ? 
                    (contextWallet.walletAdapter && typeof contextWallet.walletAdapter === 'object' && 
                     'name' in contextWallet.walletAdapter ? 
                      String(contextWallet.walletAdapter.name) : 'External Wallet') 
                    : (contextWallet ? 'Local or Guest Wallet' : "Not available")}
                </div>
              </div>
              
              {/* New Wallet Debug Section */}
              <div className="border border-gray-700 rounded p-3">
                <div className="font-mono text-sm text-blue-400">Wallet Debug Information</div>
                <div className="mt-2 space-y-2">
                  <div className="grid grid-cols-2 gap-1">
                    <div className="text-gray-400">Context Connected:</div>
                    <div className="font-mono">{isConnected ? 'Yes' : 'No'}</div>
                  </div>
                  <div className="grid grid-cols-2 gap-1">
                    <div className="text-gray-400">Context Connecting:</div>
                    <div className="font-mono">{isConnecting ? 'Yes' : 'No'}</div>
                  </div>
                  <div className="grid grid-cols-2 gap-1">
                    <div className="text-gray-400">Wallet Type:</div>
                    <div className="font-mono">
                      {contextWallet ? 'Solana External' : 'Internal/Local Wallet'}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-1">
                    <div className="text-gray-400">Current Location:</div>
                    <div className="font-mono">{window.location.pathname}</div>
                  </div>
                  <button
                    onClick={() => {
                      const info = {
                        contextConnected: isConnected,
                        contextConnecting: isConnecting,
                        contextPublicKey: contextPublicKey,
                        solanaConnected: isConnected,
                        solanaPublicKey: contextPublicKey || null,
                        location: window.location.pathname
                      };
                      console.table(info);
                      toast.success('Wallet debug info logged to console');
                    }}
                    className="p-2 bg-blue-600 text-white rounded w-full mt-2"
                  >
                    Log Wallet State to Console
                  </button>
                </div>
              </div>
              
              {/* Current Wallet Context Indicator */}
              <div className="card">
                <h3>Active Wallet Context Indicator</h3>
                <div className="content">
                  <div>
                    <strong>Status:</strong> {isConnected ? 'Connected' : 'Disconnected'}
                  </div>
                  <div>
                    <strong>Public Key:</strong> {contextPublicKey ? contextPublicKey.toString() : 'No active wallet'}
                  </div>
                  <div className="button-group">
                    <button 
                      onClick={async () => {
                        try {
                          const localWallet = new LocalWallet();
                          // Await the connection to complete
                          await localWallet.connect("test-private-key-" + Date.now());
                          // Only set wallet if connected
                          if (localWallet.isConnected()) {
                            setWallet(localWallet);
                            toast.success('Test local wallet applied successfully');
                          } else {
                            toast.error('Failed to connect local wallet');
                          }
                        } catch (error) {
                          console.error('Error applying test local wallet:', error);
                          toast.error('Failed to apply test local wallet');
                        }
                      }}
                    >
                      Apply Test Local Wallet
                    </button>
                    <button 
                      onClick={() => {
                        disconnect();
                        forceWalletDisconnect();
                        toast.success('Wallet disconnected');
                        // Redirect to login page after a short delay
                        setTimeout(() => {
                          window.location.href = '/login';
                        }, 1000);
                      }}
                    >
                      Disconnect Current Wallet
                    </button>
                  </div>
                </div>
              </div>
              
              {/* Local Wallet Form */}
              <div className="p-4 bg-glass border border-gray-700 rounded-lg mb-4">
                <h2 className="text-lg font-bold text-gray-200 mb-2">Local Wallet</h2>
                <div className="mb-3">
                  <div className="font-mono text-sm text-blue-400 mb-1">Private Key</div>
                  <input
                    type="text"
                    value={privateKey}
                    onChange={(e) => setPrivateKey(e.target.value)}
                    placeholder="Enter private key"
                    className="w-full p-2 bg-gray-800 text-gray-200 border border-gray-600 rounded mb-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <Button onClick={derivePublicKey} className="w-full mb-2 bg-blue-600 hover:bg-blue-700 text-white">
                    Create & Derive Public Key
                  </Button>
                </div>
                
                {derivedPublicKey && (
                  <div className="border border-gray-600 rounded p-3 mb-3 bg-gray-800/50">
                    <div className="font-mono text-sm text-green-400">Derived Public Key</div>
                    <div className="mt-1 font-mono text-sm break-all text-white">
                      {derivedPublicKey}
                    </div>
                    <div className="mt-2 text-xs text-gray-400">
                      This is a real ed25519 public key derived from your private key using proper Solana wallet derivation.
                      It will match the public key you'd get in a real Solana wallet if you used the same private key.
                      The private key can be either a valid base58-encoded key or any passphrase.
                    </div>
                    
                    {localWallet && (
                      <>
                        <Button 
                          onClick={() => {
                            // Check if the wallet is properly connected
                            if (localWallet.isConnected()) {
                              // Show a loading toast
                              toast.loading('Applying local wallet to context...', { id: 'applying-wallet' });
                              
                              // Short timeout to ensure UI state is updated before wallet changes
                              setTimeout(() => {
                                try {
                                  // Set the wallet in the context
                                  setWallet(localWallet);
                                  toast.success('Local wallet applied to context', { id: 'applying-wallet' });
                                } catch (error) {
                                  console.error('Error applying wallet to context:', error);
                                  toast.error('Failed to apply wallet: ' + 
                                    (error instanceof Error ? error.message : String(error)), 
                                    { id: 'applying-wallet' });
                                }
                              }, 100);
                            } else {
                              toast.error('Local wallet is not connected. Please create a new wallet.');
                            }
                          }} 
                          className="w-full mt-3 bg-green-600 hover:bg-green-700 text-white"
                        >
                          Apply Local Wallet
                        </Button>
                        
                        <Button
                          onClick={async () => {
                            try {
                              if (!localWallet.isConnected()) {
                                toast.error('Wallet is not connected');
                                return;
                              }
                              
                              // Create a test message
                              const testMessage = new TextEncoder().encode('Test message for signing');
                              
                              // Sign the message
                              const signature = await localWallet.sign(testMessage);
                              
                              // Verify the signature
                              const isValid = localWallet.verifySignature?.(testMessage, signature);
                              
                              // Show results
                              console.log('Test message:', 'Test message for signing');
                              console.log('Signature (base58):', bs58.encode(signature));
                              console.log('Signature (hex):', Buffer.from(signature).toString('hex'));
                              console.log('Verification result:', isValid);
                              
                              toast.success(`Signature verified: ${isValid ? 'VALID ✓' : 'INVALID ✗'}`);
                            } catch (error) {
                              console.error('Error testing signature:', error);
                              toast.error('Signature test failed: ' + 
                                (error instanceof Error ? error.message : String(error)));
                            }
                          }}
                          className="w-full mt-2 bg-blue-600 hover:bg-blue-700 text-white"
                        >
                          Test Signing & Verification
                        </Button>
                      </>
                    )}
                  </div>
                )}
                
                {!derivedPublicKey && (
                  <div className="text-sm text-gray-400 italic">
                    Enter a private key and click "Create & Derive Public Key" to generate a wallet.
                  </div>
                )}
              </div>
            </div>
          </ExpandableSection>

          {/* Local Storage Section */}
          <ExpandableSection
            title="Local Storage"
            isExpanded={expandedSections.storage}
            onToggle={toggleStorageSection}
          >
            <div className="space-y-4">
              <div className="flex justify-end">
                <Button
                  onClick={handleClearLocalStorage}
                  variant="secondary"
                  className="bg-red-500 hover:bg-red-600 text-white border-red-400"
                >
                  Clear Local Storage
                </Button>
              </div>

              {Object.entries(getLocalStorageItems()).map(([key, { isJson, value }]) => (
                <div key={key} className="border border-gray-700 rounded p-3 w-full">
                  {isJson ? (
                    <div className="font-mono text-sm w-full">
                      {renderJsonValue(value, key)}
                    </div>
                  ) : (
                    <>
                      <div className="font-mono text-sm text-blue-400 break-all">{key}</div>
                      <div className="mt-1 font-mono text-sm break-all">
                        {value}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </ExpandableSection>
        </div>
      </div>
    </ContentFramework>
  );
};

export default DebugPage; 