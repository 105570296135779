// Types for solar data
export interface SolarLatestData {
  ts: string;
  power_W: number; // Power in Watts
}

export interface SolarHistogramData {
  ts: string;
  power_W: number; // Power in Watts
}

export interface SolarDailyData {
  ts: string;
  power_kWh: number; // Energy in kilowatt-hours
}

export interface SolarData {
  latest: SolarLatestData;
  today_kWh: number; // Today's production in kilowatt-hours
  dailykWh: SolarDailyData[];
  histogram: SolarHistogramData[];
}

export type TimeframeOption = '12h' | 'day' | 'week' | 'month';
export type ResolutionOption = '2m' | '15m' | '1h' | '4h';

class SolarDataService {
  private static instance: SolarDataService;
  private readonly apiUrl = 'https://api.srcful.dev/';

  private constructor() {}

  public static getInstance(): SolarDataService {
    if (!SolarDataService.instance) {
      SolarDataService.instance = new SolarDataService();
    }
    return SolarDataService.instance;
  }

  private async executeGraphQLQuery(query: string): Promise<any> {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Content-Length', query.length.toString());

    try {
      const response = await fetch(this.apiUrl, {
        method: 'POST',
        body: query,
        headers: requestHeaders,
      });

      const json = await response.json();
      
      if (json.errors) {
        console.error('GraphQL query error:', json.errors);
        throw new Error(json.errors[0]?.message || 'Unknown GraphQL error');
      }

      return json.data;
    } catch (err) {
      console.error('Error executing GraphQL query:', err);
      throw err;
    }
  }

  /**
   * Fetch solar data including current power, today's total, and histogram
   * @param gatewayId - ID of the gateway
   * @param timeframe - Time period to fetch data for (day, week, month)
   * @param resolution - Data resolution (15m, 1h, 4h)
   * @param customStartDate - Optional custom start date
   * @param customEndDate - Optional custom end date
   * @returns Promise with solar data
   */
  public async getSolarData(
    gatewayId: string,
    timeframe: TimeframeOption,
    resolution: ResolutionOption,
    customStartDate?: Date,
    customEndDate?: Date
  ): Promise<SolarData | null> {
    if (!gatewayId) return null;
    
    try {
      // Set up date ranges based on selected timeframe
      const now = new Date();
      let startDate = customStartDate || new Date();
      let endDate = customEndDate || now;
      
      // Only calculate dates if no custom dates provided
      if (!customStartDate && !customEndDate) {
        if (timeframe === 'day') {
          startDate.setDate(now.getDate() - 1);
        } else if (timeframe === 'week') {
          startDate.setDate(now.getDate() - 7);
        } else if (timeframe === 'month') {
          startDate.setMonth(now.getMonth() - 1);
        }
        endDate = now;
      }
      
      const query = JSON.stringify({
        query: `
          query get_solar_data {
            derData {
              solar(gwId:"${gatewayId}") {
                latest {
                  ts
                  power
                }
                today
                histogram(start:"${startDate.toISOString()}", stop:"${endDate.toISOString()}", resolution:"${resolution}") {
                  ts
                  power
                }
              }
            }   
          }
        `
      });

      const data = await this.executeGraphQLQuery(query);
      
      // Transform the data to match our new interface with unit indicators
      const solarData = data?.derData?.solar;
      if (solarData) {
        // Map the API response to our interface with renamed properties
        return {
          latest: {
            ts: solarData.latest.ts,
            power_W: solarData.latest.power
          },
          today_kWh: solarData.today,
          dailykWh: solarData.dailykWh || [],
          histogram: solarData.histogram.map((item: any) => ({
            ts: item.ts,
            power_W: item.power
          }))
        };
      }
      return null;
    } catch (err) {
      console.error(`Error fetching solar data for gateway ${gatewayId}:`, err);
      return null;
    }
  }

  /**
   * Fetch daily solar data for the last 30 days
   * @param gatewayId - ID of the gateway
   * @returns Promise with daily solar data
   */
  public async getDailySolarData(gatewayId: string): Promise<SolarDailyData[]> {
    if (!gatewayId) return [];
    
    try {
      const now = new Date();
      let startDate = new Date(now);
      startDate.setDate(now.getDate() - 30);
      
      const startDateStr = startDate.toISOString().split('T')[0];
      const endDateStr = now.toISOString().split('T')[0];
      
      const query = JSON.stringify({
        query: `
          query get_daily_solar_data {
            derData {
              solar(gwId:"${gatewayId}") {
                dailykWh(start:"${startDateStr}", stop:"${endDateStr}") {
                  ts
                  power
                }
              }
            }   
          }
        `
      });

      const data = await this.executeGraphQLQuery(query);
      
      // Transform the data to match our new interface with unit indicators
      const dailyData = data?.derData?.solar?.dailykWh || [];
      return dailyData.map((item: any) => ({
        ts: item.ts,
        power_kWh: item.power
      }));
    } catch (err) {
      console.error(`Error fetching daily solar data for gateway ${gatewayId}:`, err);
      return [];
    }
  }
}

// Export singleton instance
export default SolarDataService.getInstance(); 