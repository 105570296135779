import { useEffect, useState } from "react";
import { Link, useNavigate } from "@tanstack/react-router";
import { useGateways, Gateway, isHardwareGateway } from "../context/GatewayContext";
import { getUnreadNotificationCount, getNotificationTimestamp } from '../utils/notificationUtils';
import { RocketLaunchIcon, ChatBubbleLeftRightIcon, CloudIcon } from "@heroicons/react/24/solid";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GatewayIcon, MeterIcon, SolarIcon, IconState, CloudGatewayIcon, VehicleIcon } from '../components/icons/icons';
import { OAuthErrorHandler } from '../components/OAuthErrorHandler';
import WalletRewardsCard from '../components/rewards/WalletRewardsCard';

const DerStatusIcons = ({ gateway }: { gateway: Gateway }) => {
  const getDerState = (derType: string): IconState => {
    if (!gateway.ders) return 'none';
    
    const relevantDers = gateway.ders.filter(der => der.type.toLowerCase().includes(derType.toLowerCase()));
    if (relevantDers.length === 0) return 'none';

    // Check timestamps of all DERs of this type
    const now = Date.now();
    let hasLate = false;
    let hasVeryLate = false;

    // Different thresholds for vehicles vs other DER types
    const isVehicle = derType.toLowerCase() === 'vehicle';
    const lateThreshold = isVehicle ? 120 : 10; // 2 hours for vehicles, 10 minutes for others
    const veryLateThreshold = isVehicle ? 240 : 60; // 4 hours for vehicles, 1 hour for others

    relevantDers.forEach(der => {
      const timestamp = new Date(der.lastSeen).getTime();
      const diffMinutes = (now - timestamp) / 1000 / 60;
      
      if (diffMinutes > veryLateThreshold) hasVeryLate = true;
      else if (diffMinutes > lateThreshold) hasLate = true;
    });

    if (hasVeryLate) return 'inactive';
    if (hasLate) return 'disabled';
    return 'active';
  };

  const meterState = getDerState('meter');
  const solarState = getDerState('solar');
  const batteryState = getDerState('battery');
  const vehicleState = getDerState('vehicle');

  if (!meterState && !solarState && !batteryState && !vehicleState) return null;

  // Don't show device links for cloud gateways
  const isCloud = !isHardwareGateway(gateway);

  return (
    <div className="flex justify-center gap-4 mt-2">
      <div className="flex items-center gap-3">
        {meterState !== 'none' && (
          isCloud ? (
            <MeterIcon state={meterState} className="w-6 h-6" />
          ) : (
            <Link 
              to="/gateway/$gatewayId/devices"
              params={{ gatewayId: gateway.id }}
              className="der-icon hover:opacity-80 transition-opacity z-30"
              onClick={e => e.stopPropagation()}
              style={{ touchAction: 'none', cursor: 'pointer' }}
            >
              <MeterIcon state={meterState} className="w-6 h-6" />
            </Link>
          )
        )}
        {solarState !== 'none' && (
          isCloud ? (
            <SolarIcon state={solarState} className="w-8 h-8" />
          ) : (
            <Link 
              to="/gateway/$gatewayId/devices"
              params={{ gatewayId: gateway.id }}
              className="der-icon hover:opacity-80 transition-opacity z-30"
              onClick={e => e.stopPropagation()}
              style={{ touchAction: 'none', cursor: 'pointer' }}
            >
              <SolarIcon state={solarState} className="w-8 h-8" />
            </Link>
          )
        )}
        {batteryState !== 'none' && (
          isCloud ? (
            <SolarIcon className={`w-7 h-7 ${batteryState}`} />
          ) : (
            <Link 
              to="/gateway/$gatewayId/devices"
              params={{ gatewayId: gateway.id }}
              className="der-icon hover:opacity-80 transition-opacity z-30"
              onClick={e => e.stopPropagation()}
              style={{ touchAction: 'none', cursor: 'pointer' }}
            >
              <SolarIcon className={`w-7 h-7 ${batteryState}`} />
            </Link>
          )
        )}
        {vehicleState !== 'none' && (
          isCloud ? (
            <VehicleIcon state={vehicleState} className="w-7 h-7" />
          ) : (
            <Link 
              to="/gateway/$gatewayId/devices"
              params={{ gatewayId: gateway.id }}
              className="der-icon hover:opacity-80 transition-opacity z-30"
              onClick={e => e.stopPropagation()}
              style={{ touchAction: 'none', cursor: 'pointer' }}
            >
              <VehicleIcon state={vehicleState} className="w-7 h-7" />
            </Link>
          )
        )}
      </div>
    </div>
  );
};

const GatewayCard = ({ gateway, isDragging = false }: { gateway: Gateway, isDragging?: boolean }) => {
  const navigate = useNavigate();

  const getGatewayState = (gateway: Gateway) => {
    // For cloud gateways, return unknown
    if (!isHardwareGateway(gateway)) return 'unknown';
    
    if (!gateway.state?.timestamp) return 'unknown';
    
    const now = Date.now();
    const diffMinutes = (now - gateway.state.timestamp) / 1000 / 60;
    
    if (diffMinutes < 10) return 'active';
    if (diffMinutes > 60) return 'inactive';
    return 'disabled';
  };

  const gatewayState = getGatewayState(gateway);
  const unreadCount = getUnreadNotificationCount(
    gateway.id,
    gateway.state?.status?.messages ?? [],
    getNotificationTimestamp(gateway.id)
  );

  const handleCardClick = (e: React.MouseEvent) => {
    // Don't navigate if clicking on interactive elements
    if (e.target instanceof Element && 
        (e.target.closest('.notification-bubble') || 
         e.target.closest('.der-icon'))) {
      return;
    }
    
    if (!isDragging) {
      navigate({ to: '/gateway/$gatewayId', params: { gatewayId: gateway.id } });
    }
  };

  const GatewayIconComponent = isHardwareGateway(gateway) ? GatewayIcon : CloudGatewayIcon;

  return (
    <div 
      className="relative"
      onClick={handleCardClick}
    >
      {/* Notification bubble */}
      {unreadCount > 0 && (
        <Link 
          to="/notifications"
          className="notification-bubble absolute -top-2 -right-2 w-6 h-6 bg-green-500 rounded-full flex items-center justify-center text-white text-sm font-bold z-30 hover:opacity-80 transition-opacity"
          onClick={e => e.stopPropagation()}
        >
          {unreadCount}
        </Link>
      )}
      
      <div className={`block p-2 sm:p-4 md:p-6 bg-glass rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-200 h-[140px] sm:h-[170px] md:h-[200px] ${isDragging ? 'cursor-grabbing' : 'cursor-grab'}`}>
        <div className="flex flex-col h-full">
          <div className="flex-grow flex flex-col items-center justify-center space-y-2 sm:space-y-3">
            <GatewayIconComponent 
              state={gatewayState} 
              className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12"
            />
            <h3 className="text-sm sm:text-base md:text-lg font-medium text-gray-200 text-center">
              {gateway.name}
            </h3>
          </div>
          
          <div className="h-8 sm:h-10 md:h-12 flex items-center justify-center">
            <DerStatusIcons gateway={gateway} />
          </div>
        </div>
      </div>
    </div>
  );
};

const OnboardGatewayCard = ({ isDragging = false }: { isDragging?: boolean }) => {
  return (
    <div className={`block p-2 sm:p-4 md:p-6 bg-glass rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-200 h-[140px] sm:h-[170px] md:h-[200px]`}>
      <div className="flex flex-col h-full">
        <div className="flex-grow flex flex-col items-center justify-center space-y-2 sm:space-y-3">
          <RocketLaunchIcon className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 text-gray-300" />
          <h3 className="text-sm sm:text-base md:text-lg font-medium text-gray-200 text-center">
            Add Hardware Gateway
          </h3>
        </div>
        <div className="h-8 sm:h-10 md:h-12" />
      </div>
    </div>
  );
};

const OnboardCloudGatewayCard = ({ isDragging = false }: { isDragging?: boolean }) => {
  return (
    <div className={`block p-2 sm:p-4 md:p-6 bg-glass rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-200 h-[140px] sm:h-[170px] md:h-[200px]`}>
      <div className="flex flex-col h-full">
        <div className="flex-grow flex flex-col items-center justify-center space-y-2 sm:space-y-3">
          <CloudIcon className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 text-gray-300" />
          <h3 className="text-sm sm:text-base md:text-lg font-medium text-gray-200 text-center">
            Add Cloud Gateway
          </h3>
        </div>
        <div className="h-8 sm:h-10 md:h-12" />
      </div>
    </div>
  );
};

const SupportCard = ({ isDragging = false }: { isDragging?: boolean }) => {
  return (
    <div className={`block p-2 sm:p-4 md:p-6 bg-glass rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-200 h-[140px] sm:h-[170px] md:h-[200px]`}>
      <div className="flex flex-col h-full">
        <div className="flex-grow flex flex-col items-center justify-center space-y-2 sm:space-y-3">
          <ChatBubbleLeftRightIcon className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 text-gray-300" />
          <h3 className="text-sm sm:text-base md:text-lg font-medium text-gray-200 text-center">
            Support
          </h3>
        </div>
        <div className="h-8 sm:h-10 md:h-12" />
      </div>
    </div>
  );
};

// Add these type definitions near the top of the file

interface CardItem {
  id: string;
  type: 'gateway' | 'onboard' | 'onboard-cloud' | 'support' | 'wallet-rewards';
  gateway?: Gateway;
}

// Add a type for the complete order
type CompleteOrder = {
  gatewayIds: string[];
  onboardPosition: number;
};

// Modify DragHandle to be a simple filled circle
const DragHandle = () => (
  <div 
    className="absolute top-1 left-1 w-10 h-10 rounded-full bg-gray-700/20 hover:bg-gray-600/30 transition-colors cursor-grab flex items-center justify-center z-30"
    style={{ touchAction: 'none' }}
  />
);

// Modify SortableCard to use the drag handle
const SortableCard = ({ item }: { item: CardItem }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ 
    id: item.id
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 50 : 'auto',
  };

  return (
    <div 
      ref={setNodeRef} 
      style={style} 
      className="relative"
    >
      {/* Card content */}
      {item.type === 'gateway' ? (
        <Link 
          to="/gateway/$gatewayId"
          params={{ gatewayId: item.gateway!.id }}
        >
          <GatewayCard gateway={item.gateway!} isDragging={isDragging} />
        </Link>
      ) : item.type === 'onboard' ? (
        <Link to="/onboarding-wizard">
          <OnboardGatewayCard isDragging={isDragging} />
        </Link>
      ) : item.type === 'onboard-cloud' ? (
        <Link to="/onboarding/cloud">
          <OnboardCloudGatewayCard isDragging={isDragging} />
        </Link>
      ) : item.type === 'wallet-rewards' ? (
        <WalletRewardsCard isDragging={isDragging} />
      ) : (
        <a href="https://support.sourceful.energy" target="_blank" rel="noopener noreferrer">
          <SupportCard isDragging={isDragging} />
        </a>
      )}

      {/* Drag handle */}
      <div {...attributes} {...listeners}>
        <DragHandle />
      </div>
    </div>
  );
};

const HomePage = () => {
  const { gateways, isLoading } = useGateways();
  const [orderedItems, setOrderedItems] = useState<CardItem[]>([]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (!isLoading) {
      try {
        const savedOrder = localStorage.getItem('gatewayOrder');
        if (savedOrder) {
          const orderData = JSON.parse(savedOrder);
          
          // Validate the parsed data has the expected structure
          if (orderData && 
              Array.isArray(orderData.gatewayIds) && 
              typeof orderData.onboardPosition === 'number') {
            
            const ordered = orderData.gatewayIds
              .map((id: string): CardItem | undefined => {
                const gateway = gateways.find(g => g.id === id);
                if (gateway) {
                  return { id: gateway.id, type: 'gateway', gateway };
                }
                return undefined;
              })
              .filter((item: CardItem | undefined): item is CardItem => item !== undefined);
            
            // Add any new gateways that weren't in the saved order
            const newGateways = gateways
              .filter(g => !orderData.gatewayIds.includes(g.id))
              .map(g => ({ id: g.id, type: 'gateway' as const, gateway: g }));
            
            // Create complete list with onboard and support cards at the saved position
            const allItems = [...ordered, ...newGateways];
            const onboardCard = { id: 'onboard', type: 'onboard' as const };
            const onboardCloudCard = { id: 'onboard-cloud', type: 'onboard-cloud' as const };
            const supportCard = { id: 'support', type: 'support' as const };
            
            // Ensure onboardPosition is within valid bounds
            const validPosition = Math.min(
              Math.max(0, orderData.onboardPosition), 
              allItems.length
            );
            
            // Insert onboard, cloud onboard, and support cards at the saved position
            allItems.splice(validPosition, 0, onboardCard, onboardCloudCard, supportCard);
            
            setOrderedItems(allItems);
          } else {
            // Invalid saved data, fall back to default order
            setOrderedItems([
              { id: 'wallet-rewards', type: 'wallet-rewards' },
              ...gateways.map(g => ({ id: g.id, type: 'gateway' as const, gateway: g })),
              { id: 'onboard', type: 'onboard' },
              { id: 'onboard-cloud', type: 'onboard-cloud' },
              { id: 'support', type: 'support' }
            ]);
          }
        } else {
          // No saved order, use default with wallet-rewards card
          setOrderedItems([
            { id: 'wallet-rewards', type: 'wallet-rewards' },
            ...gateways.map(g => ({ id: g.id, type: 'gateway' as const, gateway: g })),
            { id: 'onboard', type: 'onboard' },
            { id: 'onboard-cloud', type: 'onboard-cloud' },
            { id: 'support', type: 'support' }
          ]);
        }
      } catch (error) {
        // If there's any error parsing the saved order, fall back to default
        console.error('Error loading saved gateway order:', error);
        setOrderedItems([
          { id: 'wallet-rewards', type: 'wallet-rewards' },
          ...gateways.map(g => ({ id: g.id, type: 'gateway' as const, gateway: g })),
          { id: 'onboard', type: 'onboard' },
          { id: 'onboard-cloud', type: 'onboard-cloud' },
          { id: 'support', type: 'support' }
        ]);
      }
    }
  }, [gateways, isLoading]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setOrderedItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        
        const newOrder = arrayMove(items, oldIndex, newIndex);
        
        // Save the complete order to localStorage
        const onboardPosition = newOrder.findIndex(item => item.type === 'onboard');
        const orderData: CompleteOrder = {
          gatewayIds: newOrder
            .filter(item => item.type === 'gateway')
            .map(item => item.id),
          onboardPosition
        };
        
        localStorage.setItem('gatewayOrder', JSON.stringify(orderData));
        
        return newOrder;
      });
    }
  };

  return (
    <>
      <OAuthErrorHandler />
      <div className="flex h-full flex-col items-center justify-start pt-6 pb-12">
        <div className="w-[90vw] sm:w-[85vw] md:w-[48rem] mb-8">
          <div className="bg-glass rounded-xl p-6 shadow-lg">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-100 mb-2">
              Your Sourceful Energy Dashboard
            </h1>
          </div>
        </div>

        {/* Gateway Grid */}
        <div className="w-[90vw] sm:w-[85vw] md:w-[48rem]">
          {isLoading ? (
            <div className="text-center text-gray-200">Loading gateways...</div>
          ) : (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={orderedItems.map(item => item.id)}
                strategy={rectSortingStrategy}
              >
                <div className="grid grid-cols-3 gap-2 sm:gap-4 md:gap-6">
                  {orderedItems.map((item) => (
                    <SortableCard
                      key={item.id}
                      item={item}
                    />
                  ))}
                </div>
              </SortableContext>
            </DndContext>
          )}
        </div>
      </div>
    </>
  );
};

export default HomePage; 