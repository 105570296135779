import { FC } from 'react';

const IntroStep: FC = () => {
  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white">Wallet Recovery</h2>
      
      <div className="space-y-4 text-gray-300">
        <p>
          This wizard will help you recover your wallet using your gateway device. 
          Follow the steps to recover access to your wallet.
        </p>
        
        <div className="bg-blue-500/10 p-4 rounded-lg border border-blue-500/20">
          <h3 className="font-semibold mb-2 text-blue-200">What you'll need:</h3>
          <ul className="list-disc ml-6 space-y-2">
            <li>Your gateway device powered on and nearby</li>
            <li>Bluetooth enabled on your device</li>
            <li>Your wallet recovery access key</li>
          </ul>
        </div>
        
        <p>
          The recovery process consists of the following steps:
        </p>
        
        <ol className="list-decimal ml-6 space-y-2">
          <li>Connect to your gateway via Bluetooth</li>
          <li>Verify gateway device information</li>
          <li>Enter your wallet recovery access key</li>
          <li>Complete the recovery process</li>
        </ol>
      </div>
    </div>
  );
};

export default IntroStep; 