import { FC } from 'react';
import { ActionButton } from '../ui/ActionButton';

interface BluetoothStepProps {
  onConnect: () => void;
  isConnecting: boolean;
  isConnected: boolean;
  hasConnectionFailed: boolean;
}

const BluetoothStep: FC<BluetoothStepProps> = ({ 
  onConnect,
  isConnecting,
  isConnected,
  hasConnectionFailed
}) => {
  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white">Connect to Your Gateway</h2>
      
      <div className="space-y-4 text-gray-300">
        <p>
          To recover your wallet, you need to connect to your gateway via Bluetooth.
          Make sure your gateway is powered on and Bluetooth is enabled on your device.
        </p>
        
        <div className="bg-blue-500/10 p-4 rounded-lg border border-blue-500/20">
          <h3 className="font-semibold mb-2 text-blue-200">Connection Steps:</h3>
          <ol className="list-decimal ml-6 space-y-2">
            <li>Enable Bluetooth on your device and browser (we recommend Chrome)</li>
            <li>Locate the button on your gateway and double-click it rapidly</li>
            <li>Click the "Connect to Gateway" button below</li>
          </ol>
        </div>
        
        {isConnected && (
          <div className="bg-green-500/10 p-4 rounded-lg border border-green-500/20">
            <p className="text-green-200 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
              Successfully connected to gateway
            </p>
          </div>
        )}
        
        {hasConnectionFailed && (
          <div className="bg-yellow-500/10 p-4 rounded-lg border border-yellow-500/20">
            <h3 className="font-semibold mb-2 text-yellow-200">If Connection Failed:</h3>
            <div className="space-y-4">
              <div>
                <h4 className="font-medium mb-1">1. Try Again</h4>
                <p className="text-sm">
                  - Simply try connecting again.
                  <br/>
                  - Try reloading the window and restart the recovery process.
                </p>
              </div>
              <div>
                <h4 className="font-medium mb-1">2. Check Bluetooth Settings</h4>
                <p className="text-sm">
                  - Disconnect other Bluetooth devices
                  <br/>
                  - Turn Bluetooth off and on again, alternatively restart your device
                  <br/>
                  - Make sure you're within 2 meters of the gateway
                </p>
              </div>
            </div>
          </div>
        )}
        
        <div className="mt-6">
          <ActionButton
            onClick={onConnect}
            disabled={isConnected}
            isLoading={isConnecting}
            loadingText="Connecting to Gateway..."
            variant="primary"
            className="w-full py-3"
          >
            {isConnected ? 'Connected' : 'Connect to Gateway'}
          </ActionButton>
        </div>
      </div>
    </div>
  );
};

export default BluetoothStep; 