import React from 'react';

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
  walletAddress?: string | null;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ 
  active, 
  payload, 
  label,
  walletAddress 
}) => {
  if (active && payload && payload.length) {
    // Format date for display
    const date = new Date(label || '');
    const formattedDate = date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    
    const rewardValue = payload[0].value;
    const wallet = payload[0].payload.wallet;
    
    return (
      <div className="bg-gray-800 p-3 rounded-md border border-gray-700 shadow-md">
        <p className="text-gray-200 mb-1">{formattedDate}</p>
        <p className="text-green-400 font-medium">
          Rewards: {rewardValue.toLocaleString()} points
        </p>
        {wallet && (
          <p className="text-gray-300 text-xs mt-1">
            Wallet: {wallet.slice(0, 6)}...{wallet.slice(-4)}
          </p>
        )}
        {rewardValue === 0 && (
          <p className="text-yellow-400 text-xs mt-1">
            Gateway offline or no rewards earned
          </p>
        )}
        {wallet && walletAddress && wallet !== walletAddress && rewardValue > 0 && (
          <p className="text-blue-400 text-xs mt-1">
            Different owner
          </p>
        )}
      </div>
    );
  }

  return null;
}; 