const BIFROST_API_URL = 'https://bifrost.srcful.dev';

class HttpError extends Error {
  constructor(
    public status: number,
    message: string,
    public details?: string
  ) {
    super(message);
    this.name = 'HttpError';
  }
}

interface AuthRequest {
  delegatedKey: string;
}

interface SessionUrlResponse {
  session_url: string;
  session_id: string;
}

interface SessionResponse {
  delegatedKey: string;
  expiresAt: number;
  token: string | null;
}

interface ErrorResponse {
  detail: string;
}

export class BifrostService {
  private currentSessionId: string | null = null;

  /**
   * Create a new authentication session
   * @param publicKey The public key to use for the session
   * @returns The session ID
   */
  public async createSession(publicKey: string): Promise<string> {
    try {
      if (!publicKey) {
        throw new Error('Public key is required');
      }

      const response = await fetch(`${BIFROST_API_URL}/api/auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          delegatedKey: publicKey,
        } as AuthRequest),
      });

      if (!response.ok) {
        const error: ErrorResponse = await response.json();
        throw new HttpError(response.status, 'Failed to create session', error.detail);
      }

      const data: SessionUrlResponse = await response.json();
      this.currentSessionId = data.session_id;
      return this.currentSessionId;
    } catch (error) {
      console.error('Error creating session:', error);
      throw error;
    }
  }

  /**
   * Get the current session state
   * @returns The current session state including token if set
   */
  public async getSession(): Promise<SessionResponse> {
    if (!this.currentSessionId) {
      throw new Error('No active session');
    }

    try {
      const response = await fetch(`${BIFROST_API_URL}/api/auth/${this.currentSessionId}`);
      
      if (!response.ok) {
        const error: ErrorResponse = await response.json();
        throw new HttpError(response.status, `Failed to get session (${response.status})`, error.detail);
      }

      return await response.json();
    } catch (error) {
      console.error('Error getting session:', error);
      throw error;
    }
  }

  /**
   * Check if the current session has a token set
   */
  public async hasToken(): Promise<boolean> {
    const session = await this.getSession();
    return session.token !== null;
  }

  /**
   * Get the current session ID
   */
  public getCurrentSessionId(): string | null {
    return this.currentSessionId;
  }

  /**
   * Clear the current session
   */
  public clearSession(): void {
    this.currentSessionId = null;
  }
} 