import { WALLET_DISCONNECT_KEY } from '../App';

/**
 * Checks if the user has manually disconnected their wallet
 * 
 * @returns {boolean} True if the wallet was manually disconnected
 */
export const isWalletDisconnected = (): boolean => {
  return localStorage.getItem(WALLET_DISCONNECT_KEY) === 'true';
}

/**
 * Sets the wallet as manually disconnected
 */
export const setWalletDisconnected = (): void => {
  localStorage.setItem(WALLET_DISCONNECT_KEY, 'true');
}

/**
 * Clears the wallet disconnect state, used when a wallet successfully connects
 */
export const clearWalletDisconnectState = (): void => {
  localStorage.removeItem(WALLET_DISCONNECT_KEY);
}

/**
 * Forces a complete wallet disconnect across the application
 * This includes:
 * 1. Setting the disconnect flag
 * 2. Clearing any adapter-specific localStorage data
 * 3. Clearing any wallet-specific claims or data
 */
export const forceWalletDisconnect = (): void => {
  // Set our own disconnect flag
  setWalletDisconnected();
  
  // Clear any stored adapter connection data
  // (Different adapters may use different storage keys)
  
  // Standard Solana localStorage keys
  const solanaLocalStorageKeys = [
    'walletName',
    'wallet',
    'solana-wallet',
    'solflare-wallet',
    'phantom-wallet'
  ];
  
  // Clear any stored data for known wallets
  solanaLocalStorageKeys.forEach(key => {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.error(`Error clearing localStorage for key ${key}:`, e);
    }
  });
  
  // Clear our secure storage keys used for private key login
  const secureStorageKeys = [
    'srcful_user_preferences',   // Our private key storage
    'srcful_dark_mode_enabled',  // Our flag for having an encrypted key
    'srcful_session_preference', // Session storage backup for private key
    'srcful_session_theme'       // Session storage backup for flag
  ];
  
  // Clear all our secure storage keys
  secureStorageKeys.forEach(key => {
    try {
      localStorage.removeItem(key);
      sessionStorage.removeItem(key);
      console.log(`Cleared secure storage key: ${key}`);
    } catch (e) {
      console.error(`Error clearing secure storage for key ${key}:`, e);
    }
  });
  
  // Clear any wallet-related data using a more robust approach
  const localStorageKeys = Object.keys(localStorage);
  localStorageKeys.forEach(key => {
    // Clear gateway claims - wallet data in format gateway-claims-${walletPubkey}
    if (key.startsWith('gateway-claims-')) {
      try {
        localStorage.removeItem(key);
      } catch (e) {
        console.error(`Error clearing gateway claims for key ${key}:`, e);
      }
    }
    
    // Clear notification timestamps
    if (key.startsWith('notification-timestamp-')) {
      try {
        localStorage.removeItem(key);
      } catch (e) {
        console.error(`Error clearing notification timestamp for key ${key}:`, e);
      }
    }
  });
} 