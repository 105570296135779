import { FC, useState, useEffect, useCallback } from 'react';
import { MeterData, fetchMeterData } from '../services/backend/SrcfulPublicApi';
import { InformationCircleIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer, BarChart, Bar, Cell } from 'recharts';
import { Tooltip } from './Tooltip';
import { DerSerialNumber } from './DerSerialNumber';

interface PowerMeterProps {
  lastSeen: string;
  sn: string;
  gatewayName: string;
  gatewayId: string;
  maxPowerKw: number;
}

interface MeterState {
  data: MeterData | null;
  isLoading: boolean;
  error: string | null;
}

export const PowerMeter: FC<PowerMeterProps> = ({ 
  lastSeen, 
  sn, 
  gatewayName, 
  maxPowerKw 
}) => {
  const [meterState, setMeterState] = useState<MeterState>({
    data: null,
    isLoading: false,
    error: null
  });
  const [showPhaseGraph, setShowPhaseGraph] = useState<Record<string, boolean>>({});

  // Helper function to safely format numbers
  const formatNumber = (value: number | undefined | null, decimals: number = 2) => {
    if (value === undefined || value === null) return '0.000';
    return value.toFixed(decimals);
  };


  // Function to fetch meter data
  const updateMeterData = useCallback(async () => {
    try {
      const data = await fetchMeterData(sn);
      
      if (!data?.powerConsumption || data.powerConsumption.length === 0) {
        setMeterState({
          data: null,
          error: 'No data available',
          isLoading: false
        });
        return;
      }

      setMeterState({
        data,
        error: null,
        isLoading: false
      });
    } catch (err) {
      setMeterState({
        data: null,
        error: 'No data available',
        isLoading: false
      });
    }
  }, [sn]);

  // Set up polling for meter data every 10 seconds
  useEffect(() => {
    let isActive = true;

    const pollData = async () => {
      if (!isActive) return;
      await updateMeterData();
    };

    // Initial update
    pollData();

    // Set up interval
    const intervalId = setInterval(pollData, 10000);

    // Cleanup
    return () => {
      isActive = false;
      clearInterval(intervalId);
    };
  }, [updateMeterData]);

  return (
    <div className="px-0 py-0">
      {/* Meter Header */}
      <div className="mb-4">
        <DerSerialNumber sn={sn} lastSeen={lastSeen} />
      </div>

      {/* Meter Data Display */}
      {meterState.error && !meterState.data ? (
        <div className="bg-gray-800/50 rounded-lg p-2 border border-gray-700">
          <p className="text-center text-gray-400">{meterState.error}</p>
        </div>
      ) : meterState.data?.powerConsumption && (
        <div className="space-y-6 mt-1">
          {/* Power Overview */}
          <div className={`grid grid-cols-1 ${!showPhaseGraph[`total_${sn}`] ? 'md:grid-cols-2' : ''} gap-4`}>
            {/* Current Power Consumption */}
            <div className={`bg-gray-700/30 rounded-lg p-2 ${showPhaseGraph[`total_${sn}`] ? 'md:col-span-2' : ''}`}>
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-2">
                  <h3 className="text-sm text-gray-400">Current Power Consumption{showPhaseGraph[`total_${sn}`] ? ' (kW)' : ''}</h3>
                  <Tooltip text="Total power consumption\nacross all three phases">
                    <InformationCircleIcon className="w-4 h-4 text-gray-400 cursor-help" />
                  </Tooltip>
                </div>
                <button
                  onClick={() => setShowPhaseGraph(prev => ({ ...prev, [`total_${sn}`]: !prev[`total_${sn}`] }))}
                  className="text-xs text-gray-500 flex items-center gap-1 hover:text-gray-300 transition-colors"
                >
                  <span>{showPhaseGraph[`total_${sn}`] ? 'Show current value' : 'Show graph'}</span>
                  <ChartBarIcon className={`w-4 h-4 ${showPhaseGraph[`total_${sn}`] ? 'rotate-180' : ''}`} />
                </button>
              </div>
              {showPhaseGraph[`total_${sn}`] ? (
                <div className="h-[300px]">
                  {meterState.data?.powerConsumption.length > 0 && (
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        data={[...meterState.data.powerConsumption]
                          .sort((a, b) => new Date(a.ts).getTime() - new Date(b.ts).getTime())}
                        margin={{ top: 5, right: 0, left: -20, bottom: 20 }}
                      >
                        <defs>
                          <linearGradient id="powerGradient" x1="0" y1="1" x2="0" y2="0">
                            <stop offset="0%" stopColor="#22C55E" />
                            <stop offset="50%" stopColor="#FCD34D" />
                            <stop offset="100%" stopColor="#EF4444" />
                          </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                        <XAxis 
                          dataKey="ts"
                          tickFormatter={(ts) => new Date(ts).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                          stroke="#9CA3AF"
                          tick={(props) => {
                            const { x, y, payload } = props;
                            return (
                              <g transform={`translate(${x},${y})`}>
                                <text
                                  x={0}
                                  y={0}
                                  dy={-7}
                                  dx={10}
                                  textAnchor="end"
                                  fill="#9CA3AF"
                                  transform="rotate(-45)"
                                  style={{ fontSize: '11px' }}
                                >
                                  {new Date(payload.value).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                  })}
                                </text>
                              </g>
                            );
                          }}
                          height={50}
                          tickMargin={20}
                          interval={5}
                        />
                        <YAxis 
                          stroke="#9CA3AF"
                          tick={{ fill: '#9CA3AF' }}
                          tickFormatter={(value) => `${value.toFixed(1)}`}
                        />
                        <RechartsTooltip
                          contentStyle={{
                            backgroundColor: '#1F2937',
                            border: '1px solid #374151',
                            borderRadius: '0.375rem'
                          }}
                          labelStyle={{ color: '#9CA3AF' }}
                          labelFormatter={(ts: string) => new Date(ts).toLocaleString()}
                          formatter={(value: number) => [`${value.toFixed(3)} kW`, '']}
                        />
                        <Line
                          type="monotone"
                          dataKey="power"
                          name="Total Power"
                          stroke="url(#powerGradient)"
                          dot={false}
                          strokeWidth={2}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  )}
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className="flex items-baseline gap-2">
                    <p className="text-3xl font-bold text-green-500">
                      {formatNumber(meterState.data?.powerConsumption[meterState.data.powerConsumption.length - 1]?.power || 0, 3)} kW
                    </p>
                    <p className="text-sm text-gray-400">
                      ({formatNumber(((meterState.data?.powerConsumption[meterState.data.powerConsumption.length - 1]?.power || 0) / maxPowerKw) * 100, 1)}%)
                    </p>
                  </div>
                  <div className="flex items-baseline gap-2 mt-1">
                    <div className="flex items-baseline gap-1">
                      <p className="text-xs text-gray-400">Today:</p>
                      <p className="text-xs font-medium text-green-500">
                        {formatNumber(meterState.data?.dailyPowerConsumption?.[meterState.data.dailyPowerConsumption.length - 1]?.power || 0, 2)} kWh
                      </p>
                    </div>
                    <div className="text-gray-500 text-xs">•</div>
                    <div className="flex items-baseline gap-1">
                      <p className="text-xs text-gray-400">Yesterday:</p>
                      <p className="text-xs font-medium text-gray-400">
                        {formatNumber(meterState.data?.dailyPowerConsumption?.[meterState.data.dailyPowerConsumption.length - 2]?.power || 0, 2)} kWh
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Current Power Delivery - Only show when not showing graph */}
            {!showPhaseGraph[`total_${sn}`] && (
              <div className="bg-gray-700/30 rounded-lg p-2">
                <div className="flex items-center gap-2 mb-2">
                  <h3 className="text-sm text-gray-400">Current Power Delivery</h3>
                  <Tooltip text="Total power being delivered\nback to the power grid">
                    <InformationCircleIcon className="w-4 h-4 text-gray-400 cursor-help" />
                  </Tooltip>
                </div>
                <div className="flex items-baseline gap-2">
                  <p className="text-3xl font-bold text-blue-500">
                    {formatNumber(meterState.data?.latest?.delivery || 0, 3)} kW
                  </p>
                  <p className="text-sm text-gray-400">
                    ({formatNumber(((meterState.data?.latest?.delivery || 0) / maxPowerKw) * 100, 1)}%)
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Phase Power Distribution */}
          <div className="bg-gray-700/30 rounded-lg p-2">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <h3 className="text-sm text-gray-400">
                  {showPhaseGraph[sn] ? 'Phase Power History (kW)' : 'Phase Power Distribution'}
                </h3>
                <Tooltip text={showPhaseGraph[sn] 
                  ? "Historical power values\nfor each phase"
                  : "Power consumption per phase\nPhases should be balanced\nfor optimal efficiency"
                }>
                  <InformationCircleIcon className="w-4 h-4 text-gray-400 cursor-help" />
                </Tooltip>
              </div>
              <button
                onClick={() => setShowPhaseGraph(prev => ({ ...prev, [sn]: !prev[sn] }))}
                className="text-xs text-gray-500 flex items-center gap-1 hover:text-gray-300 transition-colors"
              >
                <span>{showPhaseGraph[sn] ? 'Show current values' : 'Show graph'}</span>
                <ChartBarIcon className={`w-4 h-4 ${showPhaseGraph[sn] ? 'rotate-180' : ''}`} />
              </button>
            </div>

            {showPhaseGraph[sn] ? (
              <>
                <div className="h-[300px]">
                  {meterState.data?.powerConsumption.length > 0 && (
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        data={[...meterState.data.powerConsumption]
                          .sort((a, b) => new Date(a.ts).getTime() - new Date(b.ts).getTime())}
                        margin={{ top: 5, right: 0, left: -20, bottom: 20 }}
                      >
                        <defs>
                          <linearGradient id="powerGradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#EF4444" />
                            <stop offset="50%" stopColor="#FCD34D" />
                            <stop offset="100%" stopColor="#22C55E" />
                          </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                        <XAxis 
                          dataKey="ts"
                          tickFormatter={(ts) => new Date(ts).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                          stroke="#9CA3AF"
                          tick={(props) => {
                            const { x, y, payload } = props;
                            return (
                              <g transform={`translate(${x},${y})`}>
                                <text
                                  x={0}
                                  y={0}
                                  dy={-7}
                                  dx={10}
                                  textAnchor="end"
                                  fill="#9CA3AF"
                                  transform="rotate(-45)"
                                  style={{ fontSize: '11px' }}
                                >
                                  {new Date(payload.value).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                  })}
                                </text>
                              </g>
                            );
                          }}
                          height={50}
                          tickMargin={20}
                          interval={5}
                        />
                        <YAxis 
                          stroke="#9CA3AF"
                          tick={{ fill: '#9CA3AF' }}
                          tickFormatter={(value) => `${value.toFixed(1)}`}
                        />
                        <RechartsTooltip
                          contentStyle={{
                            backgroundColor: '#1F2937',
                            border: '1px solid #374151',
                            borderRadius: '0.375rem'
                          }}
                          labelStyle={{ color: '#9CA3AF' }}
                          labelFormatter={(ts: string) => new Date(ts).toLocaleString()}
                          formatter={(value: number) => [`${value.toFixed(3)} kW`, '']}
                        />
                        <Legend 
                          verticalAlign="top"
                          wrapperStyle={{ paddingBottom: '10px' }}
                        />
                        <Line
                          type="monotone"
                          dataKey="l1"
                          name="Phase 1"
                          stroke="#86EFAC"
                          dot={false}
                          strokeWidth={2}
                        />
                        <Line
                          type="monotone"
                          dataKey="l2"
                          name="Phase 2"
                          stroke="#FDE047"
                          dot={false}
                          strokeWidth={2}
                        />
                        <Line
                          type="monotone"
                          dataKey="l3"
                          name="Phase 3"
                          stroke="#FCA5A5"
                          dot={false}
                          strokeWidth={2}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  )}
                </div>
                  <p className="text-xs text-gray-500 text-right">
                    Last updated: {
                      (() => {
                        const lastDataPoint = meterState.data?.powerConsumption?.[meterState.data?.powerConsumption?.length - 1];
                        return lastDataPoint?.ts
                          ? new Date(lastDataPoint.ts).toLocaleString()
                          : 'No data available';
                      })()
                    }
                  </p>
              </>
            ) : (
              <div className="space-y-4">
                {['L1', 'L2', 'L3'].map((phase, index) => {
                  const phaseKey = `consumption${phase}` as 'consumptionL1' | 'consumptionL2' | 'consumptionL3';
                  let phaseValue = meterState.data?.latest?.[phaseKey] || 0;
                  const phaseMaxPower = maxPowerKw / 3;
                  const percentage = Math.min((phaseValue / phaseMaxPower) * 100, 100);
                  
                  return (
                    <div key={phase} className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <span className="text-gray-300">{phase}</span>
                      </div>
                      <div className="flex-1 mx-4">
                        <div className="h-2 bg-gray-600 rounded-full">
                          <div
                            className="h-full rounded-full transition-all duration-300"
                            style={{ 
                              width: `${percentage}%`,
                              background: percentage <= 50
                                ? `linear-gradient(90deg, rgb(34 197 94) ${100 - (percentage * 2)}%, rgb(234 179 8) 100%)`
                                : `linear-gradient(90deg, rgb(234 179 8) ${200 - (percentage * 2)}%, rgb(239 68 68) 100%)`
                            }}
                          />
                        </div>
                      </div>
                      <div className="text-right">
                        <span className={`text-gray-300 w-20 block ${
                          percentage > 75 ? 'text-red-500' : 
                          percentage > 50 ? 'text-yellow-500' : 
                          'text-green-500'
                        }`}>
                          {formatNumber(phaseValue, 3)} kW
                        </span>
                        <span className="text-xs text-gray-500">
                          {formatNumber(percentage, 1)}%
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {/* Daily Power Consumption */}
          <div className="bg-gray-700/30 rounded-lg p-2">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <h3 className="text-sm text-gray-400">Daily Power Consumption (kWh)</h3>
                <Tooltip text="Total energy consumed per day in kilowatt-hours (kWh)">
                  <InformationCircleIcon className="w-4 h-4 text-gray-400 cursor-help" />
                </Tooltip>
              </div>
            </div>
            {meterState.data?.dailyPowerConsumption && (
              <>
                <div className="h-[300px]">
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={(() => {
                        const data = meterState.data?.dailyPowerConsumption?.map((day: { ts: string; power: number }) => {
                          const date = new Date(day.ts);
                          const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
                          return {
                            date: localDate.toISOString(),
                            power: day.power
                          };
                        }) ?? [];
                        
                        // Calculate average and standard deviation for coloring
                        const avg = data.reduce((sum, day) => sum + day.power, 0) / data.length;
                        const stdDev = Math.sqrt(
                          data.reduce((sum, day) => sum + Math.pow(day.power - avg, 2), 0) / data.length
                        );
                        
                        const coloredData = data.map(day => ({
                          ...day,
                          fill: day.power < avg - stdDev ? '#22C55E' :  // Low consumption - green
                                day.power > avg + stdDev ? '#EF4444' :  // High consumption - red
                                '#FCD34D'                               // Average consumption - yellow
                        }));

                        // Store the colored data for Cell components to use
                        (window as any).__chartData = coloredData;

                        return coloredData;
                      })()}
                      margin={{ top: 5, right: 0, left: -20, bottom: 20 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                      <XAxis
                        dataKey="date"
                        stroke="#9CA3AF"
                        interval={1}
                        tick={(props) => {
                          const { x, y, payload } = props;
                          return (
                            <g transform={`translate(${x},${y})`}>
                              <text
                                x={0}
                                y={0}
                                dy={-7}
                                dx={10}
                                textAnchor="end"
                                fill="#9CA3AF"
                                transform="rotate(-45)"
                                style={{ fontSize: '11px' }}
                              >
                                {new Date(payload.value).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })}
                              </text>
                            </g>
                          );
                        }}
                        height={50}
                        tickMargin={20}
                      />
                      <YAxis
                        stroke="#9CA3AF"
                        tick={{ fill: '#9CA3AF' }}
                        tickFormatter={(value) => `${value.toFixed(1)}`}
                      />
                      <RechartsTooltip
                        contentStyle={{
                          backgroundColor: '#1F2937',
                          border: '1px solid #374151',
                          borderRadius: '0.375rem'
                        }}
                        labelStyle={{ color: '#9CA3AF' }}
                        formatter={(value: number) => [`${value.toFixed(2)} kWh`, 'Energy Consumed']}
                        labelFormatter={(date: string) => new Date(date).toLocaleDateString(undefined, { 
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                        cursor={{ fill: 'transparent' }}
                      />
                      <Bar
                        dataKey="power"
                        name="Daily Energy"
                        fill="#22C55E"
                        stroke="none"
                        isAnimationActive={true}
                        activeBar={{ fill: '#FFFFFF' }}
                      >
                        {meterState.data?.dailyPowerConsumption?.map((_, index) => (
                          <Cell key={`cell-${index}`} fill={(window as any).__chartData?.[index]?.fill} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <div className="flex justify-center gap-6 mt-4 text-sm">
                  <div className="flex items-center gap-2">
                    <div className="w-3 h-3 rounded-full bg-[#22C55E]" />
                    <span className="text-gray-400">Low Usage</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-3 h-3 rounded-full bg-[#FCD34D]" />
                    <span className="text-gray-400">Average Usage</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-3 h-3 rounded-full bg-[#EF4444]" />
                    <span className="text-gray-400">High Usage</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}; 