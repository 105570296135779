import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { FC, useEffect, useState } from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { GatewayProvider } from "./context/GatewayContext";
import { BleProvider } from "./context/BleContext";
import { NavigationProvider } from "./context/NavigationContext";
import { WalletContextProvider } from "./context/WalletContext";
import { Toaster } from "react-hot-toast";
import { AppContent } from './components/AppContent';
import SolanaWalletConnector from './components/SolanaWalletConnector';
import { 
  PhantomWalletAdapter,
  CoinbaseWalletAdapter,
  SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { storeOAuthError } from "./components/OAuthErrorHandler";

require("@solana/wallet-adapter-react-ui/styles.css");

// Create a new client
const queryClient = new QueryClient();

// Storage key for tracking manual wallet disconnects
export const WALLET_DISCONNECT_KEY = 'wallet_manually_disconnected';

const App: FC = () => {
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = clusterApiUrl(network);
  
  // Check if wallet was manually disconnected
  const [shouldAutoConnect] = useState(() => {
    return localStorage.getItem(WALLET_DISCONNECT_KEY) !== 'true';
  });
  
  // Initialize the wallet adapters
  const wallets = [
    new PhantomWalletAdapter(),
    new CoinbaseWalletAdapter(),
    new SolflareWalletAdapter(),
  ];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const error = storeOAuthError(params);
    
    if (error) {
      // Just store the error and clean up URL
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={shouldAutoConnect}>
          <WalletModalProvider>
            <WalletContextProvider>
              <SolanaWalletConnector />
              <GatewayProvider>
                <BleProvider>
                  <NavigationProvider>
                    <Toaster
                      position="top-center"
                      toastOptions={{
                        style: {
                          background: '#1f2937', // dark gray background
                          color: '#fff', // white text
                          borderRadius: '0.375rem',
                          border: '1px solid rgba(75, 85, 99, 0.4)', // subtle border
                        },
                        success: {
                          iconTheme: {
                            primary: '#10B981', // green
                            secondary: '#1f2937',
                          },
                        },
                        error: {
                          iconTheme: {
                            primary: '#EF4444', // red
                            secondary: '#1f2937',
                          },
                        },
                        loading: {
                          iconTheme: {
                            primary: '#6B7280', // gray
                            secondary: '#1f2937',
                          },
                        },
                      }}
                    />
                    <AppContent />
                  </NavigationProvider>
                </BleProvider>
              </GatewayProvider>
            </WalletContextProvider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </QueryClientProvider>
  );
};

export default App;
