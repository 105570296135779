import { FC, useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ActionButton } from '../../ui/ActionButton';
import { LocationData, StepProps } from '../types';

// Mapbox access token
mapboxgl.accessToken =
  "pk.eyJ1IjoiamFjb2ItazI4OSIsImEiOiJjbHNrbnJubjQwNGtwMmtyMDd3cTBmem5nIn0.-D_8MJk7ENckqUh9PD_g2A";

interface LocationStepProps extends StepProps {
  location: LocationData;
  setLocation: (loc: LocationData) => void;
}

const LocationStep: FC<LocationStepProps> = ({ location, setLocation, onBack, onNext }) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const marker = useRef<mapboxgl.Marker | null>(null);
  const [zoom] = useState<number>(4);
  const updateTimeout = useRef<NodeJS.Timeout>();

  const isValidLatitude = (lat: string) => {
    const num = parseFloat(lat);
    return !isNaN(num) && num >= -90 && num <= 90;
  };

  const isValidLongitude = (lng: string) => {
    const num = parseFloat(lng);
    return !isNaN(num) && num >= -180 && num <= 180;
  };

  const isValidLocation = isValidLatitude(location.latitude) && isValidLongitude(location.longitude);

  const updateLocationState = (lng: number, lat: number) => {
    if (updateTimeout.current) {
      clearTimeout(updateTimeout.current);
    }
    updateTimeout.current = setTimeout(() => {
      setLocation({
        longitude: lng.toFixed(6),
        latitude: lat.toFixed(6)
      });
    }, 100);
  };

  useEffect(() => {
    if (!map.current && mapContainer.current) {
      const initialLng = parseFloat(location.longitude) || 10.9;
      const initialLat = parseFloat(location.latitude) || 50.2;

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/dark-v11",
        center: [initialLng, initialLat],
        zoom: zoom,
        minZoom: 2.7,
      });

      const el = document.createElement('div');
      el.innerHTML = `
        <svg height="40px" width="40px" viewBox="0 0 293.334 293.334" xml:space="preserve" fill="#4ADE80"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path style="fill:#4ADE80;" d="M146.667,0C94.903,0,52.946,41.957,52.946,93.721c0,22.322,7.849,42.789,20.891,58.878 c4.204,5.178,11.237,13.331,14.903,18.906c21.109,32.069,48.19,78.643,56.082,116.864c1.354,6.527,2.986,6.641,4.743,0.212 c5.629-20.609,20.228-65.639,50.377-112.757c3.595-5.619,10.884-13.483,15.409-18.379c6.554-7.098,12.009-15.224,16.154-24.084 c5.651-12.086,8.882-25.466,8.882-39.629C240.387,41.962,198.43,0,146.667,0z M146.667,144.358 c-28.892,0-52.313-23.421-52.313-52.313c0-28.887,23.421-52.307,52.313-52.307s52.313,23.421,52.313,52.307 C198.98,120.938,175.559,144.358,146.667,144.358z"></path> <circle style="fill:#4ADE80;" cx="146.667" cy="90.196" r="21.756"></circle> </g> </g> </g></svg>
      `;
      el.style.cursor = 'pointer';

      marker.current = new mapboxgl.Marker(el, { offset: [0, -20] })
        .setLngLat([initialLng, initialLat])
        .addTo(map.current);

      map.current.on('moveend', () => {
        if (!map.current) return;
        const center = map.current.getCenter();
        updateLocationState(center.lng, center.lat);
      });

      map.current.on('move', () => {
        if (!map.current || !marker.current) return;
        const center = map.current.getCenter();
        marker.current.setLngLat([center.lng, center.lat]);
      });

      setTimeout(() => {
        map.current?.resize();
      }, 0);

      const resizeHandler = () => {
        if (map.current) {
          map.current.resize();
        }
      };

      window.addEventListener('resize', resizeHandler);

      return () => {
        if (updateTimeout.current) {
          clearTimeout(updateTimeout.current);
        }
        window.removeEventListener('resize', resizeHandler);
        if (marker.current) {
          marker.current.remove();
          marker.current = null;
        }
        if (map.current) {
          map.current.remove();
          map.current = null;
        }
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="space-y-8">
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">Installation Location</h2>
        <p className="text-gray-300">Select the location of your solar installation:</p>

        <div className="bg-glass h-[300px] w-full overflow-hidden rounded-lg">
          <div ref={mapContainer} className="h-full w-full relative">
            <div className="absolute top-4 left-4 bg-glass text-gray-200 p-3 rounded-lg shadow-lg z-10">
              <p>Longitude: {location.longitude}</p>
              <p>Latitude: {location.latitude}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <ActionButton
          onClick={onBack}
          variant="secondary"
        >
          Back
        </ActionButton>
        <ActionButton
          onClick={onNext}
          variant="primary"
          disabled={!isValidLocation}
        >
          Next
        </ActionButton>
      </div>
    </div>
  );
};

export default LocationStep; 