import { useState } from "react";
import { useGateways, Gateway, isHardwareGateway } from "../context/GatewayContext";
import { useWalletContext } from "../context/WalletContext";
import { toast } from "react-hot-toast";
import GatewayMessageManager from '../services/GatewayMessageManager';

interface EchoResponse {
  echo: string;
}

const EchoPage = () => {
  const { gateways, isLoading } = useGateways();
  const { publicKey, signMessage } = useWalletContext();
  const [selectedGateway, setSelectedGateway] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getGatewayState = (gateway: Gateway) => {
    if (!isHardwareGateway(gateway)) return 'unknown';
    
    if (!gateway.state?.timestamp) return 'unknown';
    
    const now = Date.now();
    const diffMinutes = (now - gateway.state.timestamp) / 1000 / 60;
    
    if (diffMinutes < 10) return 'active';
    if (diffMinutes > 60) return 'inactive';
    return 'disabled';
  };

  // Filter out cloud gateways
  const isCloudGateway = (id: string): boolean => {
    const cloudPrefixes = ['hse-', 'hdi-', 'hhw-'];
    return cloudPrefixes.some(prefix => id.toLowerCase().startsWith(prefix));
  };

  const filteredGateways = gateways?.filter(gateway => !isCloudGateway(gateway.id)) || [];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!publicKey || !signMessage || !selectedGateway || !message.trim()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await GatewayMessageManager.getInstance().fetch(
        '/api/echo',
        {
          method: 'POST',
          body: message.trim()
        },
        {
          gatewayId: selectedGateway
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to send echo message: ${response.status}`);
      }
      
      const data = await response.json<EchoResponse>();
      toast.success(`Echo response received: ${data.echo}`);
      setMessage(''); // Clear the message field
    } catch (error) {
      toast.error('Failed to send echo message');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex h-full flex-col items-center justify-start pt-6 pb-12">
      <div className="w-[90vw] sm:w-[85vw] md:w-[48rem] mb-8">
        <div className="bg-glass rounded-xl p-6 shadow-lg">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-100 mb-6">
            Echo Page
          </h1>

          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="gateway" className="block text-sm font-medium text-gray-200 mb-2">
                Select Gateway
              </label>
              <select
                id="gateway"
                value={selectedGateway}
                onChange={(e) => setSelectedGateway(e.target.value)}
                className={`w-full p-2 rounded-md bg-[#2d2d2d] text-gray-200 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-green-500`}
                disabled={isLoading || isSubmitting}
              >
                <option value="">Select a gateway...</option>
                {filteredGateways.map((gateway) => {
                  const state = getGatewayState(gateway);
                  let stateColor = '';
                  switch (state) {
                    case 'active':
                      stateColor = 'text-green-400';
                      break;
                    case 'inactive':
                      stateColor = 'text-red-400';
                      break;
                    case 'disabled':
                      stateColor = 'text-yellow-400';
                      break;
                    default:
                      stateColor = 'text-gray-400';
                  }
                  return (
                    <option 
                      key={gateway.id} 
                      value={gateway.id}
                      className={stateColor}
                    >
                      {gateway.name} ({gateway.id})
                    </option>
                  );
                })}
              </select>
            </div>

            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-200 mb-2">
                Message
              </label>
              <input
                type="text"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full p-2 rounded-md bg-[#2d2d2d] text-gray-200 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="Enter your message..."
                disabled={isSubmitting}
              />
            </div>

            <button
              type="submit"
              className="w-full py-2 px-4 bg-green-600 hover:bg-green-700 text-white font-medium rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={isSubmitting || !selectedGateway || !message.trim()}
            >
              {isSubmitting ? 'Sending...' : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EchoPage; 