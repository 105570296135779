import React, { FC, useState, useCallback, useMemo, useEffect } from 'react';
import ContentFramework from "../components/ContentFramework";
import PageContainer from "../components/layout/PageContainer";
import { useBle } from "../context/BleContext";
import * as ble from "../services/ble/";
import { toast } from "react-hot-toast";
import { 
  IntroStep, 
  BluetoothStep,
  DeviceInfoStep,
  AccessKeyStep,
  CompletionStep,
} from '../components/wallet-recovery';
import { ActionButton } from "../components/ui/ActionButton";
import { recoverWalletlessWallet } from '../services/backend/SrcfulApiRequests';

interface Step {
  title: string;
  description: string;
  component: FC<any>;
}

const RecoverWallet: FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isBluetoothConnected, setIsBluetoothConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [hasConnectionFailed, setHasConnectionFailed] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState({
    serialNumber: '',
    publicKey: ''
  });
  const [isRecovering, setIsRecovering] = useState(false);
  const [recoveredPrivateKey, setRecoveredPrivateKey] = useState('');
  const [recoveredPublicKey, setRecoveredPublicKey] = useState('');
  
  const bleApi = useBle();

  const handleDisconnect = useCallback(() => {
    console.log("Disconnecting from gateway...");
    setIsBluetoothConnected(false);
    setHasConnectionFailed(true);
    setIsConnecting(false);
    bleApi.manager.disconnect();
  }, [bleApi.manager]);

  const fetchDeviceInfo = useCallback(async () => {
    try {
      const response = await bleApi.fetch(
        ble.API_CRYPTO,
        ble.Method.GET,
        {},
      );
      
      const newDeviceInfo = {
        serialNumber: response.payload["serialNumber"],
        publicKey: response.payload["publicKey"],
      };
      
      setDeviceInfo(newDeviceInfo);
      return newDeviceInfo;
    } catch (error) {
      console.error('Failed to fetch device information:', error);
      toast.error('Failed to fetch device information');
      handleDisconnect();
      return null;
    }
  }, [bleApi, handleDisconnect]);

  const handleConnect = useCallback(async () => {
    if (bleApi.manager.isConnected() || isConnecting) return;

    console.log("Connecting to gateway...");
    
    setIsConnecting(true);
    setHasConnectionFailed(false);
    
    try {
      console.log("Awaiting connection...");
      const connected = await bleApi.manager.connect();
      console.log("Connection result:", connected);
      
      if (connected) {
        setIsBluetoothConnected(true);
        await fetchDeviceInfo();
      } else {
        toast.error("Failed to connect to gateway.");
        setHasConnectionFailed(true);
        handleDisconnect();
      }
    } catch (error) {
      console.error('Failed to connect:', error);
      toast.error("Error connecting to gateway.");
      setHasConnectionFailed(true);
      handleDisconnect();
    }
    
    setIsConnecting(false);
  }, [bleApi.manager, isConnecting, handleDisconnect, fetchDeviceInfo]);

  // Set up BLE connection status observer
  useEffect(() => {
    function observerCallback(m: ble.IManager) {
      console.log("Connection status changed: ", m.isConnected());
      if (m.isConnected()) {
        setIsBluetoothConnected(true);
      } else {
        setIsBluetoothConnected(false);
      }
    }

    bleApi.manager.subscribeToConnectionStatus(observerCallback);

    return () => {
      bleApi.manager.unsubscribeFromConnectionStatus(observerCallback);
    };
  }, [bleApi.manager]);

  const handleRecoverWallet = useCallback(async (accessKey: string, deviceMessage: string, signature: string) => {
    setIsRecovering(true);
    
    try {
      console.log("Recovering wallet with parameters:", {
        accessKey,
        deviceMessage,
        signature
      });
      
      // Call the backend service to recover the wallet
      const result = await recoverWalletlessWallet(accessKey, deviceMessage, signature);
      
      setRecoveredPrivateKey(result.privateKey);
      setRecoveredPublicKey(result.publicKey);
      
      toast.success("Wallet recovered successfully!");
      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.error('Failed to recover wallet:', error);
      toast.error("Failed to recover wallet. Please check your access key and try again.");
    } finally {
      setIsRecovering(false);
    }
  }, [currentStep]);

  const steps: Step[] = useMemo(() => [
    {
      title: "Introduction",
      description: "Overview of the recovery process",
      component: IntroStep
    },
    {
      title: "Connect Device",
      description: "Connect to your gateway",
      component: () => (
        <BluetoothStep 
          onConnect={handleConnect}
          isConnecting={isConnecting}
          isConnected={isBluetoothConnected}
          hasConnectionFailed={hasConnectionFailed}
        />
      )
    },
    {
      title: "Device Information",
      description: "Verify gateway details",
      component: () => (
        <DeviceInfoStep 
          deviceInfo={deviceInfo}
          isLoading={currentStep === 2 && (!deviceInfo.serialNumber || !deviceInfo.publicKey)}
        />
      )
    },
    {
      title: "Access Key",
      description: "Enter recovery key",
      component: () => (
        <AccessKeyStep 
          onSubmit={handleRecoverWallet}
          isSubmitting={isRecovering}
          bleApi={bleApi}
        />
      )
    },
    {
      title: "Complete",
      description: "Recovery complete",
      component: () => (
        <CompletionStep 
          recoveredPrivateKey={recoveredPrivateKey}
          recoveredPublicKey={recoveredPublicKey}
          deviceInfo={deviceInfo}
        />
      )
    }
  ], [
    deviceInfo,
    currentStep,
    isBluetoothConnected,
    isConnecting,
    hasConnectionFailed,
    isRecovering,
    recoveredPrivateKey,
    recoveredPublicKey,
    handleConnect,
    handleRecoverWallet,
    bleApi
  ]);

  // Memoize the current step component to prevent unnecessary re-renders
  const CurrentStepComponent = useMemo(() => steps[currentStep].component, [steps, currentStep]);

  const handleNext = useCallback(() => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep, steps.length]);

  const handleBack = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  }, [currentStep]);

  const isNextDisabled = () => {
    if (currentStep === 1 && !isBluetoothConnected) return true;
    if (currentStep === 3) return true; // Disable on access key step, use the Submit button instead
    return false;
  };

  return (
    <ContentFramework>
      <PageContainer>
        <div className="flex flex-col space-y-6">
          {/* Progress indicator */}
          <div className="w-full max-w-4xl mx-auto mt-4">
            <div className="flex justify-between">
              {steps.map((step, index) => (
                <div key={index} className="flex flex-col items-center">
                  <div 
                    className={`h-8 w-8 rounded-full flex items-center justify-center 
                      ${index < currentStep 
                        ? 'bg-green-500 text-white' 
                        : index === currentStep 
                          ? 'bg-blue-500 text-white' 
                          : 'bg-gray-700 text-gray-400'
                      }`}
                  >
                    {index < currentStep ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                    ) : (
                      index + 1
                    )}
                  </div>
                  <div className="text-xs mt-1 text-gray-400 hidden sm:block">{step.title}</div>
                </div>
              ))}
            </div>
            <div className="relative flex items-center justify-between mt-2">
              <div className="absolute left-0 right-0 h-0.5 bg-gray-700" />
              {steps.map((_, index) => (
                <div 
                  key={index}
                  className={`h-0.5 ${
                    index < currentStep
                      ? 'bg-green-500'
                      : index === currentStep
                        ? 'bg-blue-500'
                        : 'bg-gray-700'
                  } ${index === 0 ? 'w-0' : index === steps.length - 1 ? 'w-0' : 'w-full'}`}
                />
              ))}
            </div>
          </div>

          {/* Current step content */}
          <div className="w-full max-w-4xl mx-auto">
            <CurrentStepComponent />
          </div>

          {/* Navigation buttons */}
          <div className="w-full max-w-4xl mx-auto mt-8 flex justify-between">
            <ActionButton
              onClick={handleBack}
              disabled={currentStep === 0}
              variant="secondary"
              className="px-8"
            >
              Back
            </ActionButton>

            {currentStep < steps.length - 1 && currentStep !== 3 && (
              <ActionButton
                onClick={handleNext}
                disabled={isNextDisabled()}
                variant="primary"
                className="px-8"
              >
                Next
              </ActionButton>
            )}
          </div>
        </div>
      </PageContainer>
    </ContentFramework>
  );
};

export default RecoverWallet; 