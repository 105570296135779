import { MessageContext } from "../../wallets/types";


const apiFetch = async (query: string) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Content-Length", query.length.toString());

    const response = await fetch("https://api.srcful.dev/", {
        method: "POST",
        body: query,
        headers: requestHeaders,
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, details: ${errorText}`);
    }

    return await response.json();
}

export const fetchGatewayState = async (gateway_id: string, signedMessage: MessageContext): Promise<Record<string, any>> => {
    const authParams = {
      message: signedMessage.signedMessage.message,
      signature: signedMessage.signedMessage.signature,
      id: gateway_id,
      subKey: "state",
      ...(signedMessage.authToken && { delegationToken: signedMessage.authToken })
    };

    const query = JSON.stringify({
      query: `
        query get_config_wallet {
          gatewayConfiguration {
            configuration(walletAuth: {
              message: "${authParams.message}"
              signature: "${authParams.signature}"
              id: "${authParams.id}"
              subKey: "${authParams.subKey}"
              ${signedMessage.authToken ? `delegationToken: "${authParams.delegationToken}"` : ''}
            }) {
              data
            }
          }
        }
      `
    });

   try {
    const response = await apiFetch(query);
    return JSON.parse(response.data.gatewayConfiguration.configuration.data);
   } catch (error) {
    console.error('Error fetching gateway state:', error);
    throw new Error('Failed to fetch gateway state');
   }
};


export async function gwRequest(signedMessage: MessageContext) {
  return setConfiguration_(signedMessage, "request");
}

const setConfiguration_ = async (signedMessage: MessageContext, subKey: string) => {
    const query = JSON.stringify({
        query: `
            mutation {
                setConfiguration(messageConfigurationInputType: {
                    message: "${signedMessage.signedMessage.message}",
                    signature: "${signedMessage.signedMessage.signature}"
                    ${signedMessage.authToken ? `delegationToken: "${signedMessage.authToken}"` : ''}
                }) {
                    success
                }
            }
        `
    });

    console.log(query);

    try {
      const response = await apiFetch(query);
      return response.data.setConfiguration.success;
    } catch (error) {
      console.error('Error setting configuration:', error);
      throw new Error('Failed to set configuration');
    }
  }

export const setLocation = async (message: MessageContext) => {
   //UPDATE
   const mutation = {
    query: `
      mutation($location: LocationInput!) {
        setLocation(location: $location){
          success
        }
      }`,
    variables: {
      location: {
        message: message.signedMessage.message,
        signature: message.signedMessage.signature,
        ...(message.authToken && { delegationToken: message.authToken })
      },
    },
  };

  try {
    const query = JSON.stringify(mutation);
    const response = await apiFetch(query);

    if (!response.data.setLocation?.success) {
      throw new Error('Location update was not successful: ' + JSON.stringify(response));
    }

    return response.data.setLocation.success;
  } catch (error) {
    console.error('Error setting location:', error);
    throw error;
  }
}

export async function transferGatewayOwnership(signedMessage: MessageContext, transferPreviouslyEarnedRewards: boolean = false) {
    const query = JSON.stringify({
        query: `
            mutation {
                transferOwnership(
                    signature: "${signedMessage.signedMessage.signature}", 
                    message: "${signedMessage.signedMessage.message}", 
                    transferPreviouslyEarnedRewards: ${transferPreviouslyEarnedRewards}
                ) {
                    ts
                    newOwner
                    oldOwner
                }
            }
        `
    });

    try {
        const response = await apiFetch(query);
        return response.data.transferOwnership;
    } catch (error) {
        console.error('Error transferring ownership:', error);
        throw error;
    }
}

/**
 * Recovers a wallet using the access key and signed message from the gateway
 * @param accessKey The recovery access key (entered by the user)
 * @param deviceMessage The message signed by the device
 * @param signature The signature of the message
 * @returns The recovered wallet private key and public key
 */
export const recoverWalletlessWallet = async (
  accessKey: string,
  deviceMessage: string,
  signature: string
): Promise<{ privateKey: string; publicKey: string }> => {
  // Fixed API key for the walletless signing service
  const apiKey = "happy-happy-energy";
  
  // Remove dashes from the accessKey
  const formattedAccessKey = accessKey.replace(/-/g, "");
  
  const query = JSON.stringify({
    query: `
      mutation recover {
        walletlessSigning {
          recover(
            apiKey: "${apiKey}", 
            accessKey: "${formattedAccessKey}", 
            deviceMessage: "${deviceMessage}", 
            signature: "${signature}"
          ) {
            privateKey
            publicKey
          }
        }
      }
    `
  });

  try {
    const response = await apiFetch(query);
    
    if (response.errors) {
      console.error('GraphQL errors:', response.errors);
      throw new Error(response.errors[0]?.message || 'Failed to recover wallet');
    }
    
    return response.data.walletlessSigning.recover;
  } catch (error) {
    console.error('Error recovering wallet:', error);
    throw new Error('Failed to recover wallet');
  }
};

export async function isGatewayOwner(gatewayIds: string[], signedMessage: MessageContext) {
  // Build the query parameters
  const messageParams = `message: "${signedMessage.signedMessage.message}", messageSignature: "${signedMessage.signedMessage.signature}"`;
  const delegationTokenParam = signedMessage.authToken ? `, delegationToken: "${signedMessage.authToken}"` : '';

  const query = JSON.stringify({
    query: `
      query test {
        gatewayConfiguration {
          validateOwnership(${messageParams}${delegationTokenParam}) {
            owner
          }
        }
      }
    `
  });

  try {
    const response = await apiFetch(query);
    return response.data.gatewayConfiguration.validateOwnership.owner;
  } catch (error) {
    console.error('Error validating ownership:', error);
    throw error;
  }
}