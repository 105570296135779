import { useParams } from "@tanstack/react-router";
import React, { useState } from "react";
import { useGateways } from "../context/GatewayContext";
import ContentFramework from "../components/ContentFramework";
import InfoRow from "../components/InfoRow";
import { toast } from "react-hot-toast";
import SrcfulMessageProtocolFactory from '../services/backend/SrcfulMessageProtocolFactory';
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import GatewayPageHeader from '../components/GatewayPageHeader';
import { handleSubmitFlow } from '../components/utils/gatewayUtils';
import WifiConfigForm from '../components/form/WifiConfigForm';
import PageContainer from "../components/layout/PageContainer";
import { useWalletContext } from '../context/WalletContext';

interface ExpandableBoxProps {
  title: string;
  children: React.ReactNode;
}

const ExpandableBox: React.FC<ExpandableBoxProps> = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="w-full max-w-3xl p-4 text-gray-200 bg-glass dark:text-white">
      <button
        className="w-full flex justify-between items-center"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h2 className="text-base font-bold leading-7">{title}</h2>
        {isExpanded ? (
          <ChevronUpIcon className="h-5 w-5" />
        ) : (
          <ChevronDownIcon className="h-5 w-5" />
        )}
      </button>
      {isExpanded && <div className="mt-4">{children}</div>}
    </div>
  );
};

const NetworkInterface = ({ title, ipAddress, connectedSSID }: { title: string; ipAddress: string | undefined; connectedSSID?: string }) => (
  <div className="border border-gray-700 rounded-lg bg-gray-800/50 p-4">
    <h3 className="text-sm font-semibold mb-2">{title}</h3>
    {title === "WiFi" && (
      <InfoRow
        label="Connected to"
        value={connectedSSID || "Not Connected"}
      />
    )}
    <InfoRow
      label="IP Address"
      value={ipAddress || "Not Connected"}
      isCopyable={Boolean(ipAddress)}
    />
  </div>
);

const WifiConfigSection = ({
  selectedNetwork,
  setSelectedNetwork,
  password,
  setPassword
}: {
  selectedNetwork: string;
  setSelectedNetwork: (network: string) => void;
  password: string;
  setPassword: (password: string) => void;
}) => {
  const { publicKey, wallet } = useWalletContext();
  const { activeGateway } = useGateways();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isScanning, setIsScanning] = useState(false);

  const availableSSIDs: string[] = activeGateway?.state?.network?.wifi?.ssids || [];

  const handleScan = async () => {
    if (!publicKey || !activeGateway || !wallet) return;
    setIsScanning(true);
    
    const message = SrcfulMessageProtocolFactory.createWifiScanMessage({
      walletPublicKey: publicKey.toString(),
      gatewayId: activeGateway.id
    });

    await handleSubmitFlow(message, wallet, () => {
      toast.success('WiFi scan initiated');
      setTimeout(() => {
        setIsScanning(false);
      }, 30000);
    }, () => {
      toast.error('Failed to initiate WiFi scan');
      setIsScanning(false);
    });
  };

  const handleSubmit = async (ssid: string, psk: string) => {
    if (!publicKey || !activeGateway || !wallet) return { success: false };

    setSelectedNetwork(ssid);
    setPassword(psk);
    setIsSubmitting(true);

    const message = SrcfulMessageProtocolFactory.createWifiConfigMessage({
      walletPublicKey: publicKey.toString(),
      gatewayId: activeGateway.id,
      ssid,
      psk
    });

    try {
      await handleSubmitFlow(message, wallet, () => {
        toast.success('WiFi configuration sent successfully');
      }, () => {
        toast.error('Failed to set WiFi configuration');
      });
      return { success: true };
    } catch (error) {
      return { success: false };
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-4">
      <p className="text-sm text-gray-400 mb-4">
        ⚠️ Changing WiFi settings may disconnect your gateway. Make sure you have a backup connection method available.
      </p>
      <WifiConfigForm
        availableSSIDs={availableSSIDs}
        onSubmit={handleSubmit}
        onScan={handleScan}
        isScanning={isScanning}
        isSubmitting={isSubmitting}
        initialSSID={selectedNetwork}
        initialPassword={password}
      />
    </div>
  );
};

interface GatewayNetworkProps {
  gateways: any[] | null;
  activeGateway: any;
  setActiveGateway: (gateway: any) => void;
  gatewayId?: string;
  isConnected: boolean;
}

class GatewayNetwork extends React.Component<GatewayNetworkProps> {
  state = {
    selectedNetwork: '',
    password: ''
  };

  componentDidUpdate(prevProps: GatewayNetworkProps) {
    const { gatewayId, gateways, setActiveGateway } = this.props;
    if (gatewayId && gateways && (gatewayId !== prevProps.gatewayId || gateways !== prevProps.gateways)) {
      const gateway = gateways.find(g => g.id === gatewayId);
      if (gateway) {
        setActiveGateway(gateway);
      }
    }
  }

  render() {
    const { isConnected, activeGateway } = this.props;
    const { selectedNetwork, password } = this.state;

    if (!isConnected || !activeGateway) return null;

    return (
      <ContentFramework>
        <PageContainer>
          <GatewayPageHeader 
            gatewayName={activeGateway.name}
          />
          <h1 className="text-4xl font-bold mb-6">Network Configuration</h1>
          
          {/* Network Interfaces */}
          <div className="w-full">
            <h2 className="text-base font-bold leading-7 mb-4">Network Interfaces</h2>
            <div className="space-y-4">
              <NetworkInterface 
                title="Ethernet" 
                ipAddress={activeGateway.state?.network?.address?.interfaces?.eth0} 
              />
              <NetworkInterface 
                title="WiFi" 
                ipAddress={activeGateway.state?.network?.address?.interfaces?.wlan0}
                connectedSSID={activeGateway.state?.network?.wifi?.connected}
              />
            </div>
          </div>

          {/* WiFi Configuration */}
          <div className="mt-6">
            <ExpandableBox title="WiFi Configuration">
              <WifiConfigSection 
                selectedNetwork={selectedNetwork}
                setSelectedNetwork={(network: string) => this.setState({ selectedNetwork: network })}
                password={password}
                setPassword={(pwd: string) => this.setState({ password: pwd })}
              />
            </ExpandableBox>
          </div>
        </PageContainer>
      </ContentFramework>
    );
  }
}

const GatewayNetworkWrapper = () => {
  const { isConnected } = useWalletContext();
  const { gateways, activeGateway, setActiveGateway } = useGateways();
  const { gatewayId } = useParams({ from: '/gateway/$gatewayId/network' });

  return (
    <GatewayNetwork
      isConnected={isConnected}
      gateways={gateways}
      activeGateway={activeGateway}
      setActiveGateway={setActiveGateway}
      gatewayId={gatewayId}
    />
  );
};

export default GatewayNetworkWrapper; 