import { redirect } from "@tanstack/react-router";

export const authLoader = async () => {
  // We can access the context here because it's during route loading
  const walletContext = document.querySelector('[data-wallet-context]');
  const isConnected = walletContext?.getAttribute('data-is-connected') === 'true';
  
  if (!isConnected) {
    throw redirect({
      to: '/login',
      replace: true
    });
  }
  return {};
}; 