import { FC, useState, useCallback, useRef, useEffect } from 'react';
import React from 'react';

interface TooltipProps {
  children: React.ReactNode;
  text: string;
}

type TooltipPosition = {
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
};

export const Tooltip: FC<TooltipProps> = ({ children, text }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState<TooltipPosition>({ vertical: 'top', horizontal: 'center' });
  const tooltipRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  
  // Process text to handle \n properly
  const processedText = text.split('\\n').map((line, index, array) => (
    <React.Fragment key={index}>
      {line}
      {index < array.length - 1 && <br />}
    </React.Fragment>
  ));

  const updatePosition = useCallback(() => {
    if (!tooltipRef.current || !contentRef.current) return;

    const triggerRect = tooltipRef.current.getBoundingClientRect();
    const tooltipRect = contentRef.current.getBoundingClientRect();
    const padding = 8; // Minimum padding from screen edges

    // Calculate available space in each direction
    const spaceAbove = triggerRect.top;
    const spaceBelow = window.innerHeight - triggerRect.bottom;
    const spaceLeft = triggerRect.left;
    const spaceRight = window.innerWidth - triggerRect.right;

    // Determine vertical position
    let vertical: 'top' | 'bottom';
    if (spaceBelow >= tooltipRect.height + padding) {
      vertical = 'bottom';
    } else if (spaceAbove >= tooltipRect.height + padding) {
      vertical = 'top';
    } else {
      // If neither top nor bottom has enough space, use the side with more space
      vertical = spaceBelow > spaceAbove ? 'bottom' : 'top';
    }

    // Determine horizontal position
    let horizontal: 'left' | 'center' | 'right';
    const centerOffset = (tooltipRect.width - triggerRect.width) / 2;
    
    if (spaceLeft >= centerOffset && spaceRight >= centerOffset) {
      horizontal = 'center';
    } else if (spaceRight >= tooltipRect.width + padding) {
      horizontal = 'left';
    } else if (spaceLeft >= tooltipRect.width + padding) {
      horizontal = 'right';
    } else {
      // If no ideal position, choose the side with more space
      horizontal = spaceRight > spaceLeft ? 'left' : 'right';
    }

    setPosition({ vertical, horizontal });
  }, []);

  useEffect(() => {
    const handleResize = () => updatePosition();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [updatePosition]);

  const handleMouseEnter = () => {
    setIsVisible(true);
    // Use setTimeout to ensure the tooltip content is rendered before measuring
    setTimeout(updatePosition, 0);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    e.preventDefault();
    setIsVisible(!isVisible);
    if (!isVisible) {
      // Only update position when showing
      setTimeout(updatePosition, 0);
    }
  };

  // Generate position-specific classes
  const getPositionClasses = () => {
    const classes = ['absolute', 'z-50', 'transition-all', 'duration-200'];
    
    // Vertical positioning
    if (position.vertical === 'top') {
      classes.push('bottom-[calc(100%+5px)]');
    } else {
      classes.push('top-[calc(100%+5px)]');
    }
    
    // Horizontal positioning
    if (position.horizontal === 'center') {
      classes.push('left-1/2 -translate-x-1/2');
    } else if (position.horizontal === 'left') {
      classes.push('left-0');
    } else {
      classes.push('right-0');
    }

    return classes.join(' ');
  };

  return (
    <div 
      className="relative inline-block"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsVisible(false)}
      onTouchStart={handleTouchStart}
      ref={tooltipRef}
    >
      {children}
      <div 
        ref={contentRef}
        className={`
          pointer-events-none w-max max-w-[16rem]
          ${getPositionClasses()}
          ${isVisible ? 'opacity-100' : 'opacity-0'}
        `}
      >
        <div className="relative whitespace-pre-line rounded bg-gray-900 px-3 py-2 text-sm text-gray-100 shadow-lg">
          {processedText}
          <div 
            className={`
              absolute w-0 h-0 left-1/2 -translate-x-1/2
              border-4 border-transparent
              ${position.vertical === 'top' ? 'top-full border-t-gray-900' : 'bottom-full border-b-gray-900'}
              ${position.horizontal !== 'center' ? 'hidden' : ''}
            `}
          />
        </div>
      </div>
    </div>
  );
}; 