import { LocalWallet } from './LocalWallet';
import { WalletInterface, MessageContext, createSignedMessage } from './types';
import * as nacl from 'tweetnacl';
import bs58 from 'bs58';
import { WalletVisitor } from './WalletVisitor';

export class DelegatedWallet extends LocalWallet implements WalletInterface {
  private jwtToken: string | null = null;
  private createdAt: number | null = null;
  private expiresAt: number | null = null;
  private issuer: string | null = null;

  constructor(jwtToken: string | null = null) {
    // Generate a new keypair
    const keypair = nacl.sign.keyPair();
    // Convert the private key to base58 string
    const privateKey = bs58.encode(keypair.secretKey);
    super(privateKey);
    
    if (jwtToken) {
      this.setJwtToken(jwtToken);
    }
  }

  /**
   * Get the JWT token associated with this wallet
   */
  public getJwtToken(): string | null {
    return this.jwtToken;
  }

  public getLocalPublicKey(): string {
    return super.getPublicKey() || '';
  }

  public getPublicKey(): string | null{
    // Get the public key from the underlying issuer that we are delegated
    if (!this.issuer) {
      throw new Error('Issuer not set');
    }
    return this.issuer;
  }

  /**
   * Set a new JWT token for this wallet and extract its claims
   */
  public setJwtToken(token: string): void {
    this.jwtToken = token;
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      // Check for both standard and custom claim names
      this.createdAt = payload.iat || payload.created;
      this.expiresAt = payload.exp || payload.expiration;
      this.issuer = payload.iss || payload.issuer;

      // assert that delegated key is the same as the public key
      if (this.getLocalPublicKey() !== payload.delegatedKey) {
        throw new Error('Delegated key does not match public key');
      }

    } catch (error) {
      console.error('Error parsing JWT token:', error);
      throw new Error('Invalid JWT token format');
    }
  }

  /**
   * Get the issuer (iss) claim from the JWT
   */
  public getIssuer(): string | null {
    return this.issuer;
  }

  /**
   * Get the issued at (iat) timestamp from the JWT
   */
  public getIssuedAt(): number | null {
    return this.createdAt;
  }

  /**
   * Get the expiration (exp) timestamp from the JWT
   */
  public getExpiration(): number | null {
    return this.expiresAt;
  }

  /**
   * Check if the JWT token has expired
   */
  public isExpired(): boolean {
    if (!this.expiresAt) {
      return true;
    }
    return Date.now() >= this.expiresAt * 1000; // Convert to milliseconds
  }

  /**
   * Override connect to handle JWT token
   */
  public async connect(privateKey: string, jwtToken?: string): Promise<void> {
    // First connect the underlying LocalWallet
    await super.connect(privateKey);
    
    // If a JWT token was provided, set it
    if (jwtToken) {
      this.setJwtToken(jwtToken);
    }
  }

  /**
   * Override disconnect to clear JWT token and claims
   */
  public disconnect(): void {
    super.disconnect();
    this.jwtToken = null;
    this.createdAt = null;
    this.expiresAt = null;
    this.issuer = null;
  }

  /**
   * Override isConnected to also check for JWT token if required
   * @param requireJwt Whether to require a valid JWT token for the wallet to be considered connected
   */
  public isConnected(requireJwt: boolean = false): boolean {
    const baseConnected = super.isConnected();
    
    if (!baseConnected) {
      return false;
    }
    
    if (requireJwt) {
      return this.jwtToken !== null && this.createdAt !== null && this.expiresAt !== null && !this.isExpired();
    }
    
    return true;
  }

  public async signMessage(message: string): Promise<MessageContext | null> {
    if (!this.isConnected()) {
      return null;
    }

    const signedMessage = await createSignedMessage(this.sign.bind(this), message);
    if (!signedMessage) {
      return null;
    }
    if (!this.jwtToken) {
      throw new Error('JWT token not set');
    }
    return {
      signedMessage,
      authToken: this.jwtToken
    };
  }

  public accept(visitor: WalletVisitor): void {
    visitor.visitDelegatedWallet(this);
  }
}