import { FC, useState } from 'react';
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";

interface CompletionStepProps {
  recoveredPrivateKey: string;
  recoveredPublicKey?: string;
  deviceInfo: {
    serialNumber: string;
  };
}

const CompletionStep: FC<CompletionStepProps> = ({ 
  recoveredPrivateKey,
  recoveredPublicKey,
  deviceInfo
}) => {
  const [showPrivateKey, setShowPrivateKey] = useState(false);

  const handleCopyPrivateKey = () => {
    navigator.clipboard.writeText(recoveredPrivateKey)
      .then(() => toast.success('Copied private key to clipboard'))
      .catch(() => toast.error('Failed to copy to clipboard'));
  };

  const handleCopyPublicKey = () => {
    if (recoveredPublicKey) {
      navigator.clipboard.writeText(recoveredPublicKey)
        .then(() => toast.success('Copied public key to clipboard'))
        .catch(() => toast.error('Failed to copy to clipboard'));
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white">Wallet Recovery Complete</h2>
      
      <div className="space-y-4 text-gray-300">
        <div className="bg-green-500/10 p-4 rounded-lg border border-green-500/20">
          <p className="text-green-200 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
            Successfully recovered your wallet for gateway {deviceInfo.serialNumber}
          </p>
        </div>
        
        <p>
          Your wallet keys have been successfully recovered. Please copy and store these keys in a secure location.
          You can use the private key to import your wallet into compatible cryptocurrency wallets.
        </p>
        
        <div className="mt-6 space-y-6">
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <label className="block text-sm font-medium text-gray-400">
                Recovered Private Key
              </label>
              <button
                onClick={() => setShowPrivateKey(!showPrivateKey)}
                className="text-blue-500 hover:text-blue-400 flex items-center gap-1 text-sm"
              >
                {showPrivateKey ? (
                  <>
                    <EyeSlashIcon className="h-4 w-4" />
                    Hide
                  </>
                ) : (
                  <>
                    <EyeIcon className="h-4 w-4" />
                    Show
                  </>
                )}
              </button>
            </div>
            
            <div className="relative">
              <div 
                className="w-full bg-gray-800 border border-gray-600 rounded-md px-3 py-3 font-mono text-sm break-all overflow-x-auto"
              >
                {showPrivateKey ? (
                  recoveredPrivateKey
                ) : (
                  '••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••'
                )}
              </div>
              
              <button
                onClick={handleCopyPrivateKey}
                className="absolute right-2 top-2 p-1 rounded-md hover:bg-gray-700 transition-colors"
                title="Copy to clipboard"
              >
                <DocumentDuplicateIcon className="h-5 w-5 text-gray-400 hover:text-blue-400" />
              </button>
            </div>
          </div>

          {recoveredPublicKey && (
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-400">
                Recovered Public Key
              </label>
              <div className="relative">
                <div 
                  className="w-full bg-gray-800 border border-gray-600 rounded-md px-3 py-3 font-mono text-sm break-all overflow-x-auto"
                >
                  {recoveredPublicKey}
                </div>
                
                <button
                  onClick={handleCopyPublicKey}
                  className="absolute right-2 top-2 p-1 rounded-md hover:bg-gray-700 transition-colors"
                  title="Copy to clipboard"
                >
                  <DocumentDuplicateIcon className="h-5 w-5 text-gray-400 hover:text-blue-400" />
                </button>
              </div>
            </div>
          )}
        </div>
        
        <div className="bg-yellow-500/10 p-4 rounded-lg border border-yellow-500/20 mt-6">
          <h3 className="font-semibold mb-2 text-yellow-200">Important Security Notice:</h3>
          <ul className="list-disc ml-6 space-y-2 text-sm">
            <li>Never share your private key with anyone</li>
            <li>Store it in a secure password manager or write it down and keep it in a safe place</li>
            <li>Anyone with access to this key will have complete control over your wallet</li>
            <li>This key will only be shown once - make sure you save it now</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CompletionStep; 