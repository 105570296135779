import { useState } from "react";
import ContentFramework from "../components/ContentFramework";
import PageContainer from "../components/layout/PageContainer";
import GatewayLocationForm from "../components/form/GatewayLocationForm";
import InfoBox from "../components/InfoBox";
import 'mapbox-gl/dist/mapbox-gl.css';

const GatewayLocationTool = () => {
  const [gatewayId, setGatewayId] = useState("");

  return (
    <ContentFramework>
      <PageContainer>
        <div className="flex flex-col space-y-6 min-h-screen">
          <h1 className="text-4xl font-bold">Gateway Location Tool</h1>
          
          <InfoBox
            heading="Set Gateway Location"
            body="This tool allows you to set the location for any gateway by providing its ID. Please ensure you have the correct permissions to modify the gateway's location."
          />

          <div className="flex flex-col space-y-2">
            <label 
              htmlFor="gatewayId" 
              className="block text-sm font-medium text-gray-300"
            >
              Gateway ID
            </label>
            <input
              type="text"
              id="gatewayId"
              value={gatewayId}
              onChange={(e) => setGatewayId(e.target.value)}
              placeholder="Enter gateway ID"
              className="flex-1 bg-gray-700 text-white rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500"
            />
          </div>

          <div className="flex-1 min-h-0">
            <InfoBox
              heading="Gateway position"
              body={gatewayId.trim() 
                ? "Please provide the precise location of the gateway. Inaccurate location may reduce your rewards. The exact location not shared publically."
                : "Select a location on the map. Once you've entered a gateway ID above, you'll be able to set this location for that gateway."
              }
            />
            <div className="mt-4" style={{ height: 'calc(100vh - 400px)', minHeight: '400px' }}>
              <GatewayLocationForm 
                serial={gatewayId.trim()} 
                variant="gateway" 
              />
            </div>
          </div>
        </div>
      </PageContainer>
    </ContentFramework>
  );
};

export default GatewayLocationTool; 