import { FC, useState, useEffect, useCallback } from 'react';
import { FaSpinner } from "react-icons/fa";
import InfoRow from "../../components/InfoRow";
import * as ble from "../../services/ble";
import { toast } from "react-hot-toast";
import { gatewayInfo } from '../../constants/infoTexts';
import WifiConfigForm from '../form/WifiConfigForm';

export enum NetworkState {
  CHECKING_NAME,
  SCANNING_WIFI,
  SHOW_FORM,
  CONNECTED
}

export interface NetworkData {
  state: NetworkState;
  networks: string[];
  selectedNetwork: string | null;
  password: string;
  gatewayName?: string;
}

const TYPING_SPEED = 50; // ms per character
const FADE_DURATION = 500; // ms for fade transition
const LOADING_MESSAGES = [
  "Checking if your gateway is awake... 🌅",
  "Teaching your gateway to dance... 💃",
  "Counting virtual sheep... 🐑",
  "Brewing some digital coffee... ☕",
  "Tuning the quantum harmonizer... 🎵",
  "Training carrier pigeons for backup... 🕊️",
  "Untangling wireless signals... 🌐",
  "Polishing the virtual brass... ✨",
  "Warming up the internet tubes... 🌡️",
  "Feeding the hamsters powering the server... 🐹",
  "Convincing electrons to flow in the right direction... ⚡",
  "Asking AI if it dreams of electric sheep... 🤖",
  "Performing network yoga exercises... 🧘",
  "Consulting the ancient scrolls of TCP/IP... 📜",
  "Teaching quantum physics to your packets... 📦",
  "Negotiating with stubborn bits and bytes... 💬",
  "Summoning the WiFi spirits... 👻",
  "Calculating the meaning of life, internet, and everything... 🌌",
  "Reticulating splines... 🔄",
  "Deploying rubber duck debugging squad... 🦆",
  "Channeling the power of cloud computing... ☁️",
  "Performing ritual network dance... 💫",
  "Asking ChatGPT for connection advice... 🤔",
  "Searching for network ninjas... 🥷",
  "Calibrating the flux capacitor... ⚡",
  "Pushing buttons and crossing fingers... 🤞",
  "Consulting the IT crowd... 💻",
  "Gathering magic internet fairy dust... ✨",
  "Debugging with coffee and hope... ☕"
];

interface TypewriterProps {
  text: string;
  onComplete?: () => void;
}

const Typewriter: FC<TypewriterProps> = ({ text, onComplete }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText(prev => prev + text[currentIndex]);
        setCurrentIndex(prev => prev + 1);
      }, TYPING_SPEED);

      return () => clearTimeout(timeout);
    } else if (onComplete) {
      onComplete();
    }
  }, [text, currentIndex, onComplete]);

  return <span>{displayedText}</span>;
};

const useRotatingMessage = (
  messages: string[], 
  showDuration: number = 3000,
  hideDuration: number = 2000,
  initialDelay: number = 5000
) => {
  const [currentMessage, setCurrentMessage] = useState<string | null>(null);
  const [isInitialDelay, setIsInitialDelay] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [usedMessages, setUsedMessages] = useState<Set<number>>(new Set());

  // Get a random unused message
  const getRandomMessage = useCallback(() => {
    const availableIndices = Array.from(
      { length: messages.length },
      (_, i) => i
    ).filter(i => !usedMessages.has(i));

    // If all messages have been used, reset the used messages
    if (availableIndices.length === 0) {
      setUsedMessages(new Set());
      return Math.floor(Math.random() * messages.length);
    }

    const randomIndex = Math.floor(Math.random() * availableIndices.length);
    const selectedIndex = availableIndices[randomIndex];
    setUsedMessages(prev => new Set(prev).add(selectedIndex));
    return selectedIndex;
  }, [messages.length, usedMessages]);

  // Handle typing completion
  const handleTypeComplete = useCallback(() => {
    setIsTyping(false);
  }, []);

  // Main effect to handle the entire message lifecycle
  useEffect(() => {
    let timer: NodeJS.Timeout;

    const startNextMessage = () => {
      const index = getRandomMessage();
      setCurrentMessage(messages[index]);
      setIsTyping(true);
      setIsFading(false);
    };

    if (isInitialDelay) {
      // Initial delay before first message
      timer = setTimeout(() => {
        setIsInitialDelay(false);
        startNextMessage();
      }, initialDelay);
    } else if (!currentMessage) {
      // Wait hideDuration before starting next message
      timer = setTimeout(startNextMessage, hideDuration);
    } else if (!isTyping && !isFading) {
      // Message is fully shown, wait showDuration before fading
      timer = setTimeout(() => {
        setIsFading(true);
        // After fade completes, clear message and wait hideDuration
        setTimeout(() => {
          setCurrentMessage(null);
        }, FADE_DURATION);
      }, showDuration);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isInitialDelay, currentMessage, isTyping, isFading, messages, getRandomMessage, showDuration, hideDuration, initialDelay]);

  return {
    message: currentMessage,
    isTyping,
    isFading,
    onTypeComplete: handleTypeComplete
  };
};

// Pure function to check gateway name
export async function checkGatewayName(
  bleApi: ble.Api,
  currentState: NetworkState,
  retryCount: number = 0,
  maxRetries: number = 3,
  delayMs: number = 4000
): Promise<{ newState: NetworkState; name?: string }> {
  if (currentState !== NetworkState.CHECKING_NAME) {
    return { newState: currentState };
  }

  try {
    const nameResponse = await bleApi.fetch(ble.API_NAME, ble.Method.GET, {});
    if (nameResponse.payload.name && !nameResponse.payload.exception) {
      return { 
        newState: NetworkState.CONNECTED,
        name: nameResponse.payload.name 
      };
    }
    
    // If we haven't reached max retries yet, wait and try again
    if (retryCount < maxRetries - 1) {
      console.log(`Name check attempt ${retryCount + 1} failed, retrying in ${delayMs}ms...`);
      await new Promise(resolve => setTimeout(resolve, delayMs));
      return checkGatewayName(bleApi, currentState, retryCount + 1, maxRetries, delayMs);
    }
    
    // If we've exhausted all retries, move to scanning WiFi
    console.log(`Name check failed after ${maxRetries} attempts, moving to scanning WiFi`);
    return { newState: NetworkState.SCANNING_WIFI };
  } catch (error) {
    // If we haven't reached max retries yet, wait and try again
    if (retryCount < maxRetries - 1) {
      console.error(`Name check attempt ${retryCount + 1} failed with error, retrying in ${delayMs}ms:`, error);
      await new Promise(resolve => setTimeout(resolve, delayMs));
      return checkGatewayName(bleApi, currentState, retryCount + 1, maxRetries, delayMs);
    }
    
    console.error(`Name check failed after ${maxRetries} attempts with error:`, error);
    return { newState: NetworkState.SCANNING_WIFI };
  }
}

// Pure function to scan for networks
export async function scanNetworks(
  bleApi: ble.Api,
  currentState: NetworkState
): Promise<{ newState: NetworkState; networks?: string[] }> {
  if (currentState !== NetworkState.SCANNING_WIFI) {
    return { newState: currentState };
  }

  try {
    await new Promise(resolve => setTimeout(resolve, 2000));
    const wifiResponse = await bleApi.fetch(ble.API_WIFI, ble.Method.GET, {});
    
    if (wifiResponse.payload.ssids) {
      return {
        newState: NetworkState.SHOW_FORM,
        networks: wifiResponse.payload.ssids
      };
    }
    return { newState: NetworkState.SHOW_FORM };
  } catch (error) {
    console.error('WiFi scan failed:', error);
    toast.error('Failed to scan for networks');
    return { newState: NetworkState.SHOW_FORM };
  }
}

// Pure function to submit network configuration
export async function submitNetworkConfig(
  bleApi: ble.Api,
  selectedNetwork: string | null,
  password: string
): Promise<{ success: boolean; error?: string }> {
  if (!selectedNetwork) {
    return { success: false, error: 'Please select a network' };
  }

  try {
    await bleApi.fetch(ble.API_WIFI, ble.Method.POST, {
      ssid: selectedNetwork,
      psk: password
    });
    return { success: true };
  } catch (error) {
    console.error('Failed to configure WiFi:', error);
    return { 
      success: false, 
      error: 'Failed to configure WiFi. Please try again.' 
    };
  }
}

interface NetworkStepProps {
  state: NetworkState;
  networks: string[];
  selectedNetwork: string | null;
  password: string;
  gatewayName?: string;
  bleApi: ble.Api;
  setState: (state: NetworkState) => void;
}

const NetworkStep: FC<NetworkStepProps> = ({
  state,
  networks,
  selectedNetwork,
  password,
  gatewayName,
  bleApi,
  setState
}) => {
  const { message, isTyping, isFading, onTypeComplete } = useRotatingMessage(
    LOADING_MESSAGES,
    1000,  // showDuration - show completed message for 1 second
    2000,  // hideDuration - wait 2 seconds between messages
    2000   // initialDelay - wait 2 seconds before first message
  );

  if (state === NetworkState.CONNECTED && gatewayName) {
    return (
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-white">Network Connected</h2>
        <div className="bg-green-500/10 p-4 rounded-lg border border-green-500/20">
          <InfoRow
            label={gatewayInfo.name.label}
            tooltip={gatewayInfo.name.tooltip}
            value={gatewayName}
            isCopyable
          />
          <p className="mt-4 text-sm text-green-300">
            Your gateway is successfully connected to the network.
          </p>
        </div>
      </div>
    );
  }

  const renderLoadingContent = (title: string) => (
    <div className="space-y-6 text-center">
      <h2 className="text-2xl font-bold text-white">{title}</h2>
      <div className="flex flex-col items-center space-y-4">
        <FaSpinner className="animate-spin text-4xl text-blue-500" />
        <div 
          className={`h-8 text-gray-300 transition-opacity duration-500 ${
            isFading ? 'opacity-0' : 'opacity-100'
          }`}
        >
          {message && (
            isTyping ? (
              <Typewriter text={message} onComplete={onTypeComplete} />
            ) : (
              <span>{message}</span>
            )
          )}
        </div>
      </div>
    </div>
  );

  if (state === NetworkState.CHECKING_NAME) {
    return renderLoadingContent("Checking Connection Status");
  }

  if (state === NetworkState.SCANNING_WIFI) {
    return renderLoadingContent("Scanning for Networks");
  }

  const handleWifiSubmit = async (ssid: string, psk: string) => {
    try {
      await bleApi.fetch(ble.API_WIFI, ble.Method.POST, {
        ssid,
        psk
      });
      
      // Set state to checking immediately after successful submission
      setState(NetworkState.CHECKING_NAME);
      
      // After successful submission, check the name to update state with retries
      // const result = await checkGatewayName(bleApi, NetworkState.CHECKING_NAME, 0, 3, 2000);
      // if (result.newState === NetworkState.CONNECTED) {
      //   toast.success('Successfully connected to network');
      // }
      
      return { success: true };
    } catch (error) {
      console.error('Failed to configure WiFi:', error);
      toast.error('Failed to configure WiFi. Please try again.');
      return { success: false };
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white">Configure Internet Connection</h2>
      <WifiConfigForm 
        availableSSIDs={networks}
        onSubmit={handleWifiSubmit}
        isSubmitting={false}
        showScanButton={false}
        initialSSID={selectedNetwork || ''}
        initialPassword={password}
      />
    </div>
  );
};

export default NetworkStep; 