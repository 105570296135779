import { FC, useState } from 'react';
import { FaSpinner } from "react-icons/fa";
import * as ble from "../../services/ble";
import { toast } from "react-hot-toast";
import WifiConfigForm from '../form/WifiConfigForm';

export enum NetworkState {
  CHECKING_CONNECTION,
  SCANNING_WIFI,
  SHOW_FORM,
  CONNECTED
}

export interface NetworkData {
  state: NetworkState;
  networks: string[];
  selectedNetwork: string | null;
  password: string;
  connectedSSID?: string;
}

// Pure function to check wifi connection status
export async function checkWifiConnection(
  bleApi: ble.Api,
  currentState: NetworkState
): Promise<{ newState: NetworkState; connectedSSID?: string }> {
  if (currentState !== NetworkState.CHECKING_CONNECTION) {
    return { newState: currentState };
  }

  try {
    const wifiResponse = await bleApi.fetch(ble.API_WIFI, ble.Method.GET, {});
    console.log("WiFi status check response:", wifiResponse.payload);
    
    if (wifiResponse.payload.connected) {
      return { 
        newState: NetworkState.CONNECTED,
        connectedSSID: wifiResponse.payload.connected 
      };
    }
    return { newState: NetworkState.SCANNING_WIFI };
  } catch (error) {
    console.error('WiFi status check failed:', error);
    return { newState: NetworkState.SCANNING_WIFI };
  }
}

// Pure function to scan for networks
export async function scanNetworks(
  bleApi: ble.Api,
  currentState: NetworkState
): Promise<{ newState: NetworkState; networks?: string[] }> {
  if (currentState !== NetworkState.SCANNING_WIFI) {
    return { newState: currentState };
  }

  try {
    await new Promise(resolve => setTimeout(resolve, 2000));
    const wifiResponse = await bleApi.fetch(ble.API_WIFI, ble.Method.GET, {});
    
    if (wifiResponse.payload.ssids) {
      return {
        newState: NetworkState.SHOW_FORM,
        networks: wifiResponse.payload.ssids
      };
    }
    return { newState: NetworkState.SHOW_FORM };
  } catch (error) {
    console.error('WiFi scan failed:', error);
    toast.error('Failed to scan for networks');
    return { newState: NetworkState.SHOW_FORM };
  }
}

// Update the polling function to return the connected SSID
const pollWifiConnection = async (bleApi: ble.Api, maxAttempts = 12, delayMs = 4000): Promise<{ connected: boolean; ssid?: string }> => {
  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    try {
      const wifiResponse = await bleApi.fetch(ble.API_WIFI, ble.Method.GET, {});
      console.log(`WiFi check attempt ${attempt + 1}:`, wifiResponse.payload);
      
      if (wifiResponse.payload.connected) {
        return { connected: true, ssid: wifiResponse.payload.connected };
      }
    } catch (error) {
      console.error(`WiFi check attempt ${attempt + 1} failed:`, error);
    }
    
    // If not connected and not last attempt, wait before next try
    if (attempt < maxAttempts - 1) {
      await new Promise(resolve => setTimeout(resolve, delayMs));
    }
  }
  return { connected: false };
};

interface NetworkStepProps {
  state: NetworkState;
  networks: string[];
  selectedNetwork: string | null;
  password: string;
  connectedSSID?: string;
  bleApi: ble.Api;
  setState: (state: NetworkState) => void;
  setNetworkData: (data: NetworkData | ((prev: NetworkData) => NetworkData)) => void;
}

const NetworkStep: FC<NetworkStepProps> = ({
  state,
  networks,
  selectedNetwork,
  password,
  connectedSSID,
  bleApi,
  setState,
  setNetworkData
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (state === NetworkState.CHECKING_CONNECTION) {
    return (
      <div className="space-y-6 text-center">
        <h2 className="text-2xl font-bold text-white">Checking Connection Status</h2>
        <div className="flex flex-col items-center space-y-4">
          <FaSpinner className="animate-spin text-4xl text-blue-500" />
          <p className="text-gray-300">
            Checking gateway connection...
          </p>
        </div>
      </div>
    );
  }

  if (state === NetworkState.SCANNING_WIFI) {
    return (
      <div className="space-y-6 text-center">
        <h2 className="text-2xl font-bold text-white">Scanning for Networks</h2>
        <div className="flex flex-col items-center space-y-4">
          <FaSpinner className="animate-spin text-4xl text-blue-500" />
          <p className="text-gray-300">
            Scanning for available WiFi networks...
          </p>
        </div>
      </div>
    );
  }

  const handleWifiSubmit = async (ssid: string, psk: string) => {
    if (isSubmitting) return { success: false };
    
    setIsSubmitting(true);
    try {
      // Post the WiFi configuration
      await bleApi.fetch(ble.API_WIFI, ble.Method.POST, {
        ssid,
        psk
      });
      
      // Start polling for connection status
      const { connected, ssid: connectedSsid } = await pollWifiConnection(bleApi);
      
      if (connected && connectedSsid) {
        setState(NetworkState.CONNECTED);
        setNetworkData((prev: NetworkData) => ({ 
          ...prev, 
          connectedSSID: connectedSsid 
        }));
        toast.success('Successfully connected to network');
        return { success: true };
      } else {
        toast.error('Failed to establish WiFi connection after multiple attempts');
        return { success: false };
      }
    } catch (error) {
      console.error('Failed to configure WiFi:', error);
      toast.error('Failed to configure WiFi. Please try again.');
      return { success: false };
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white">Configure Internet Connection</h2>
      
      {/* Show current connection status if connected */}
      {state === NetworkState.CONNECTED && connectedSSID && (
        <div className="bg-green-500/10 p-4 rounded-lg border border-green-500/20 mb-6">
          <div className="flex items-center">
            <div className="h-2 w-2 rounded-full bg-green-500 mr-2"></div>
            <p className="text-green-300">
              Connected to network: <span className="font-medium">{connectedSSID}</span>
            </p>
          </div>
        </div>
      )}

      {/* Show form for both connected and disconnected states */}
      <WifiConfigForm 
        availableSSIDs={networks}
        onSubmit={handleWifiSubmit}
        isSubmitting={isSubmitting}
        showScanButton={false}
        initialSSID={selectedNetwork || ''}
        initialPassword={password}
      />
    </div>
  );
};

export default NetworkStep; 