import React, { useState, useEffect } from 'react';
import { Link } from '@tanstack/react-router';
import { useWalletContext } from '../../context/WalletContext';
import { fetchGatewayRewards } from '../../services/RewardsService';
import { ZapIcon } from '../icons/icons';

interface WalletRewardsCardProps {
  isDragging?: boolean;
}

const WalletRewardsCard: React.FC<WalletRewardsCardProps> = ({ isDragging = false }) => {
  const [totalRewards, setTotalRewards] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { publicKey } = useWalletContext();

  useEffect(() => {
    // Skip if no wallet is connected
    if (!publicKey) {
      setTotalRewards(null);
      setError(null);
      return;
    }

    const fetchWalletRewards = async () => {
      setIsLoading(true);
      try {
        const walletAddress = publicKey.toString();
        
        // Use the gwId parameter as an empty string to get all rewards for this wallet
        const data = await fetchGatewayRewards('', walletAddress);
        
        if (data?.betaInfo?.total !== undefined) {
          setTotalRewards(data.betaInfo.total);
          setError(null);
        } else {
          setTotalRewards(0);
          setError('No rewards data available');
        }
      } catch (err) {
        console.error('Error fetching wallet rewards:', err);
        setError('Failed to load rewards');
        setTotalRewards(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWalletRewards();
  }, [publicKey]);

  const formatTotal = (total: number) => {
    return total.toLocaleString();
  };

  return (
    <Link to="/rewards">
      <div className={`block p-2 sm:p-4 md:p-6 bg-glass rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-200 h-[140px] sm:h-[170px] md:h-[200px]`}>
        <div className="flex flex-col h-full">
          <div className="flex-grow flex flex-col items-center justify-center space-y-2 sm:space-y-3">
            <div className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12">
              <ZapIcon className="w-full h-full" />
            </div>
            <h3 className="text-sm sm:text-base md:text-lg font-medium text-gray-200 text-center">
              Your Rewards
            </h3>
            {isLoading ? (
              <p className="text-gray-400 text-sm text-center">Loading...</p>
            ) : error ? (
              <p className="text-gray-400 text-sm text-center">
                {!publicKey ? 'Connect wallet to view' : error}
              </p>
            ) : (
              <p className="text-green-400 text-lg sm:text-xl md:text-2xl font-bold text-center">
                {formatTotal(totalRewards || 0)} Points
              </p>
            )}
          </div>
          <div className="h-8 sm:h-10 md:h-12" />
        </div>
      </div>
    </Link>
  );
};

export default WalletRewardsCard; 