import { FC, useState, useRef, useEffect, useMemo } from "react";
import { useNavigation } from "../context/NavigationContext";
import { SourcfulEnergyLogo } from "./icons/brand_icons";
import WalletConnect from "./wallet/WalletConnect";
import { RocketLaunchIcon, CloudIcon, PlusIcon, BoltIcon, BellIcon } from "@heroicons/react/24/solid";
import { Link } from "@tanstack/react-router";
import { useGateways } from "../context/GatewayContext";

const Header: FC = () => {
  const { navOpen, toggleNav } = useNavigation();
  const { gateways } = useGateways();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Calculate total notifications
  const totalNotifications = useMemo(() => {
    if (!gateways) return 0;
    return gateways.reduce((total, gateway) => {
      return total + (gateway.state?.status?.messages?.length || 0);
    }, 0);
  }, [gateways]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMenuClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    toggleNav();
  };

  return (
    <header className="z-50">
      <nav className="bg-[#242526] px-4 py-2 lg:p-4 lg:px-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-8">
            <a className="flex items-center text-gray-50" href="/">
              <SourcfulEnergyLogo className="h-8 lg:h-10" />
            </a>
            <div className="hidden lg:flex items-center gap-6 ml-8">
              <Link
                to="/rewards"
                className="flex items-center text-base text-gray-300 hover:text-white transition-colors"
              >
                <BoltIcon className="h-5 w-5 mr-1.5" />
                <span>Rewards</span>
              </Link>
              <Link
                to="/notifications"
                className="flex items-center text-base text-gray-300 hover:text-white transition-colors"
              >
                <BellIcon className="h-5 w-5 mr-1.5" />
                <span>Notifications</span>
                {totalNotifications > 0 && (
                  <span className="ml-2 bg-gray-600 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                    {totalNotifications}
                  </span>
                )}
              </Link>
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center text-base text-gray-300 hover:text-white transition-colors"
                >
                  <PlusIcon className="h-5 w-5 mr-1.5" />
                  <span>Add Gateway</span>
                </button>
                
                {isDropdownOpen && (
                  <div className="absolute left-0 top-full mt-2 w-48 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5 z-50">
                    <div className="py-1" role="menu">
                      <Link
                        to="/onboarding-wizard"
                        className="flex items-center px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white"
                        role="menuitem"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        <RocketLaunchIcon className="h-4 w-4 mr-2" />
                        <span>Hardware Gateway</span>
                      </Link>
                      <Link
                        to="/onboarding/cloud"
                        className="flex items-center px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white"
                        role="menuitem"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        <CloudIcon className="h-4 w-4 mr-2" />
                        <span>Cloud Gateway</span>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="hidden lg:block">
              <WalletConnect variant="header" />
            </div>
            <button 
              className="lg:hidden relative w-[30px] h-[30px] focus:outline-none"
              onMouseDown={handleMenuClick}
            >
              <div className={`absolute w-[30px] h-[3px] bg-white transition-all duration-300 ${
                navOpen ? 'rotate-45 top-[13.5px]' : 'rotate-0 top-[5px]'
              }`} />
              <div className={`absolute w-[30px] h-[3px] bg-white transition-all duration-300 ${
                navOpen ? 'opacity-0 top-[13.5px]' : 'opacity-100 top-[13.5px]'
              }`} />
              <div className={`absolute w-[30px] h-[3px] bg-white transition-all duration-300 ${
                navOpen ? '-rotate-45 top-[13.5px]' : 'rotate-0 top-[22px]'
              }`} />
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
