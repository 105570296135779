import { FC, useState } from 'react';
import { GuestWallet } from '../../wallets';
import { Button } from '../ui/Button';
import { toast } from 'react-hot-toast';

interface GuestWalletLoginProps {
  onSuccess: (wallet: GuestWallet) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}

export const GuestWalletLogin: FC<GuestWalletLoginProps> = ({
  onSuccess,
  isLoading,
  setIsLoading
}) => {
  const [guestPublicKey, setGuestPublicKey] = useState('');

  const handleLogin = async () => {
    if (!guestPublicKey) {
      toast.error('Please enter a valid public key');
      return;
    }

    setIsLoading(true);
    try {
      const guestWallet = new GuestWallet(null);
      await guestWallet.connect(guestPublicKey);
      
      if (guestWallet.isConnected()) {
        onSuccess(guestWallet);
        toast.success('Guest wallet connected successfully');
      } else {
        toast.error('Failed to connect guest wallet');
      }
    } catch (error) {
      console.error('Error connecting guest wallet:', error);
      toast.error('Failed to connect guest wallet: ' + (error instanceof Error ? error.message : String(error)));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div>
        <label htmlFor="guestPublicKey" className="block text-sm font-medium text-gray-300 mb-1">
          Guest Public Key
        </label>
        <input
          id="guestPublicKey"
          type="text"
          value={guestPublicKey}
          onChange={(e) => setGuestPublicKey(e.target.value)}
          placeholder="Enter guest public key"
          className="w-full p-2 bg-gray-800 text-gray-200 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          disabled={isLoading}
        />
      </div>
      <Button
        onClick={handleLogin}
        className="w-full bg-blue-600 hover:bg-blue-700 text-white"
        disabled={isLoading}
      >
        {isLoading ? 'Connecting...' : 'Connect Guest Wallet'}
      </Button>
    </div>
  );
}; 