import { FC } from 'react';
import { toast } from 'react-hot-toast';
import { LocalWallet } from '../../wallets';
import OTPInput from './OTPInput';

interface SavedKeyVerificationProps {
  onSuccess: (wallet: LocalWallet) => void;
  onLogout: () => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  verificationCode: string;
  setVerificationCode: (code: string) => void;
}

// Storage keys with names completely unrelated to wallets or keys
const PRIVATE_KEY_STORAGE_KEY = 'srcful_user_preferences';

const SavedKeyVerification: FC<SavedKeyVerificationProps> = ({
  onSuccess,
  onLogout,
  isLoading,
  setIsLoading,
  verificationCode,
  setVerificationCode
}) => {
  const handleVerifyAndLogin = async () => {
    if (!verificationCode.trim() || verificationCode.length !== 6 || !/^\d+$/.test(verificationCode)) {
      toast.error('Please enter a valid 6-digit code');
      return;
    }

    setIsLoading(true);
    const encryptedKey = localStorage.getItem(PRIVATE_KEY_STORAGE_KEY);
    
    if (!encryptedKey) {
      toast.error('No stored key found');
      setIsLoading(false);
      return;
    }
    
    try {
      // Decrypt the key using the verification code
      const decryptedKey = await encryptDecrypt(encryptedKey, verificationCode);
      
      const localWallet = new LocalWallet();
      await localWallet.connect(decryptedKey);
      
      if (localWallet.isConnected()) {
        onSuccess(localWallet);
      } else {
        // If connection fails, could be wrong code or corrupted key
        toast.error('Authentication failed. Invalid code or key.');
      }
    } catch (error) {
      console.error('Error reconnecting with stored private key:', error);
      toast.error('Failed to authenticate: ' + (error instanceof Error ? error.message : String(error)));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full max-w-md">
      <div className="rounded-lg bg-gray-800/50 p-4 space-y-3">
        <h3 className="text-white text-center">Enter Your 6-Digit Code</h3>
        <p className="text-gray-400 text-sm text-center">
          A private key is stored encrypted. Enter your 6-digit code to decrypt it.
        </p>
        
        <div className="flex justify-center py-2">
          <OTPInput
            length={6}
            value={verificationCode}
            onChange={setVerificationCode}
            onComplete={handleVerifyAndLogin}
            maskDelay={1200}
          />
        </div>
        
        <button
          onClick={handleVerifyAndLogin}
          disabled={isLoading}
          className="w-full p-2 bg-blue-600 hover:bg-blue-700 text-white rounded transition-colors"
        >
          {isLoading ? 'Verifying...' : 'Unlock & Login'}
        </button>
        <button
          onClick={onLogout}
          className="w-full p-2 bg-red-600 hover:bg-red-700 text-white rounded transition-colors mt-2"
        >
          Remove Stored Key
        </button>
      </div>
    </div>
  );
};

// Enhanced encryption/decryption function using SHA-256
const encryptDecrypt = async (text: string, code: string): Promise<string> => {
  // Get SHA-256 hash of the code
  const hashedCode = await hashPasscode(code);
  
  // Pad the hashed code to match the text length by repeating it
  const paddedHashedCode = hashedCode.repeat(Math.ceil(text.length / hashedCode.length)).slice(0, text.length);
  
  // XOR each character with the corresponding character in the hashed code
  return Array.from(text)
    .map((char, i) => {
      // XOR the char code with the digit from the hashed code
      const xorValue = char.charCodeAt(0) ^ paddedHashedCode.charCodeAt(i);
      return String.fromCharCode(xorValue);
    })
    .join('');
};

// SHA-256 hash function that returns a hex string
const hashPasscode = async (code: string): Promise<string> => {
  // Create a SHA-256 hash of the passcode
  const msgUint8 = stringToBytes(code);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  
  return hashHex;
};

// Convert a string to a Uint8Array for crypto operations
const stringToBytes = (str: string): Uint8Array => {
  return new TextEncoder().encode(str);
};

export default SavedKeyVerification; 