import { FC, useState, useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { DelegatedWallet } from '../../wallets';
import { BifrostService } from '../../services/BifrostService';
import { QRCodeSVG } from 'qrcode.react';

interface DelegatedLoginProps {
  onSuccess: (wallet: DelegatedWallet) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  bifrostService: BifrostService;
}

const DelegatedLogin: FC<DelegatedLoginProps> = ({ 
  onSuccess, 
  isLoading, 
  setIsLoading,
  bifrostService
}) => {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [delegatedWalletLocalPubKey, setDelegatedWalletLocalPubKey] = useState('');
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const countdownRef = useRef<NodeJS.Timeout | null>(null);
  const isPollingRef = useRef(false);

  const clearPolling = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    if (countdownRef.current) {
      clearInterval(countdownRef.current);
      countdownRef.current = null;
    }
    isPollingRef.current = false;
    setTimeRemaining(null);
  };

  const formatTime = (seconds: number): string => {
    return `${Math.floor(seconds)} seconds`;
  };

  const handleDelegatedLogin = async () => {
    if (isPollingRef.current) {
      return; // Prevent multiple polling sessions
    }

    setIsLoading(true);
    try {
      // Create a new delegated wallet
      const delegatedWallet = new DelegatedWallet();
      setDelegatedWalletLocalPubKey(delegatedWallet.getLocalPublicKey());
      
      // Create a session with the wallet's public key
      const sessionId = await bifrostService.createSession(delegatedWallet.getLocalPublicKey());
      
      // Get initial session to get expiration time
      const session = await bifrostService.getSession();
      const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds
      const initialTimeRemaining = session.expiresAt - currentTime;
      
      // Store the session ID
      setSessionId(sessionId);
      
      // Show the session URL to the user
      toast.success('Session created! Please scan the QR code with your mobile app.');
      
      isPollingRef.current = true;

      // Poll every 4 seconds for up to 3 minutes (180 seconds)
      intervalRef.current = setInterval(async () => {
        if (!isPollingRef.current) {
          return; // Stop if polling was cancelled
        }

        try {
          const session = await bifrostService.getSession();
          
          // If we have a token, we're done
          if (session.token) {
            delegatedWallet.setJwtToken(session.token);
            // Set the wallet in the context
            onSuccess(delegatedWallet);
            toast.success('Login completed!');
            bifrostService.clearSession();
            clearPolling();
            setIsLoading(false);
            return;
          }
          
          // Update time remaining based on session expiration
          const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds
          const timeUntilExpiry = session.expiresAt - currentTime;
          setTimeRemaining(timeUntilExpiry);
          
          const pollingInterval = 4; // seconds
          if (timeUntilExpiry <= pollingInterval * 4) {
            // Create a new session before the current one expires
            const newSessionId = await bifrostService.createSession(delegatedWallet.getLocalPublicKey() || '');
            setSessionId(newSessionId);
            toast('Session expiring soon. New session created. Please scan the new QR code.', {
              icon: '⚠️',
              duration: 5000
            });
            setTimeRemaining(60*3);
          }
        } catch (error) {
          // Check if the error is a 404, which means the user canceled in the app
          if (error instanceof Error && error.name === 'HttpError' && (error as any).status === 404) {
            clearPolling();
            setSessionId(null);
            setIsLoading(false);
            toast.error('Login request was canceled in the mobile app. Please try again.');
            return;
          }

          console.error('Error polling session:', error);
          // For other errors, try to create a new session
          try {
            const newSessionId = await bifrostService.createSession(delegatedWallet.getLocalPublicKey() || '');
            setSessionId(newSessionId);
            toast('Session error. New session created. Please scan the new QR code.', {
              icon: '⚠️',
              duration: 5000
            });
          } catch (retryError) {
            console.error('Error creating new session:', retryError);
            toast.error('Failed to create new session. Please try again.');
            clearPolling();
            setSessionId(null);
            setIsLoading(false);
          }
        }
      }, 4000);

      // Start countdown timer
      setTimeRemaining(initialTimeRemaining);
      countdownRef.current = setInterval(() => {
        setTimeRemaining(prev => {
          if (prev === null || prev <= 0) {
            clearPolling();
            return null;
          }
          return prev - 1;
        });
      }, 1000);

    } catch (error) {
      console.error('Error in delegated login:', error);
      toast.error('Failed to start delegated login: ' + (error instanceof Error ? error.message : String(error)));
      setIsLoading(false);
      clearPolling();
    }
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      clearPolling();
      bifrostService.clearSession();
    };
  }, [bifrostService]);

  return (
    <div className="w-full max-w-md">
      <div className="rounded-lg bg-gray-800/50 p-4 space-y-3">
        <h3 className="text-white text-center">Delegated Login</h3>
        <p className="text-gray-400 text-sm text-center">
          {sessionId ? 'Scan this QR code with your mobile app to complete login.' : 'Login using your mobile app.'}
        </p>
        
        {sessionId ? (
          <div className="flex flex-col items-center space-y-4">
            <p className="text-gray-400 text-sm text-center">
              Local Key: {delegatedWalletLocalPubKey}
            </p>
            {timeRemaining !== null && (
              <p className="text-gray-400 text-sm text-center">
                Time remaining: <span className="font-mono">{formatTime(timeRemaining)}</span>
              </p>
            )}
            <div className="p-4 bg-white rounded-lg">
              <QRCodeSVG
                value={`https://app.sourceful.energy/login/delegate?session=${sessionId}&type=qr`}
                size={200}
                level="H"
                includeMargin={true}
              />
            </div>
            <p className="text-gray-400 text-sm text-center">
              Or click <a href={`https://app.sourceful.energy/login/delegate?session=${sessionId}&type=link`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">here</a> to open in your mobile app.
            </p>
          </div>
        ) : (
          <button
            onClick={handleDelegatedLogin}
            disabled={isLoading}
            className="w-full p-2 bg-blue-600 hover:bg-blue-700 text-white rounded transition-colors"
          >
            {isLoading ? 'Creating Session...' : 'Start Delegated Login'}
          </button>
        )}
      </div>
    </div>
  );
};

export default DelegatedLogin; 