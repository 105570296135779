import { FC } from "react";
import { useWalletContext } from "../context/WalletContext";
import { useGateways } from "../context/GatewayContext";
import ContentFramework from "../components/ContentFramework";
import InfoBox from "../components/InfoBox";
import GatewayLocationForm from "../components/form/GatewayLocationForm";

const Gateway: FC = () => {
  const { isConnected, publicKey } = useWalletContext();
  const { activeGateway} = useGateways();

  
  if (!isConnected || !activeGateway) return null;

  return (
    <ContentFramework>
      <div className="my-4 flex w-full flex-col items-center gap-4 md:my-0">
        {/* Gateway Information */}
        <div className="bg-glass w-full p-4 text-gray-200 lg:w-1/2 dark:text-white">
          <h1 className="mb-10 font-srcful text-4xl font-bold">
            {activeGateway.name}
          </h1>
          <div className="w-full">
            <div>
              <h2 className="text-base font-bold leading-7">
                Energy Gateway Information
              </h2>
            </div>
            <div className="mt-5">
              <dl className="divide-y divide-gray-200">
                <div className="py-5 md:flex md:justify-between md:pr-4">
                  <dt className="text-md font-semibold leading-6">Name</dt>
                  <dd className="text-md mt-1 leading-6 sm:col-span-2 sm:mt-0">
                    {activeGateway.name}
                  </dd>
                </div>
                <div className="py-5 md:flex md:justify-between md:pr-4">
                  <dt className="text-md font-semibold leading-6">
                    Serial / Id
                  </dt>
                  <dd className="text-md mt-1 leading-6 sm:col-span-2 sm:mt-0">
                    {activeGateway.id}
                  </dd>
                </div>
                <div className="py-5 md:flex md:justify-between md:pr-4">
                  <dt className="text-md font-semibold leading-6">
                    Wallet publickey
                  </dt>
                  <dd className="text-md mt-1 truncate leading-6 sm:col-span-2 sm:mt-0">
                    {publicKey}
                  </dd>
                </div>
                <div className="py-5 md:flex md:justify-between md:pr-4">
                  <dt className="text-md font-semibold leading-6">H3-Index</dt>
                  <dd className="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0">
                    {activeGateway.h3Index}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>

        {/* Location Section */}
        <div className="bg-glass w-full p-4 text-gray-200 lg:w-1/2 dark:text-white">
          <InfoBox
            heading="Gateway position"
            body="Please provide the precise latitude and longitude of your DER for accurate verification. Inaccurate location may reduce your rewards. Your exact location is only shared with Sourceful and will not appear in the explorer."
          />
          <GatewayLocationForm serial={activeGateway.id} />
        </div>
      </div>
    </ContentFramework>
  );
};

export default Gateway; 