import { FC, useEffect, useRef } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletContext } from '../context/WalletContext';
import { clearWalletDisconnectState } from '../utils/WalletUtils';
import { SolanaWalletAdapter } from '../wallets/SolanaWalletAdapter';

/**
 * This component is responsible for connecting Solana wallets to our WalletContext.
 * It creates the appropriate adapter when a Solana wallet connects and injects it into our context.
 */
const SolanaWalletConnector: FC = () => {
  // Get external wallet state 
  const externalWalletState = useWallet();
  
  // Get our context's setWallet function and current wallet
  const { setWallet, wallet, disconnect } = useWalletContext();
  
  // Keep track of previous connection state to detect actual changes
  const prevConnectedRef = useRef<boolean>(externalWalletState.connected);
  const prevPublicKeyRef = useRef<string | null>(externalWalletState.publicKey?.toString() || null);
  
  // Adapt external wallet when its state changes
  useEffect(() => {
    // Get current state values
    const currentConnected = externalWalletState.connected;
    const currentPublicKey = externalWalletState.publicKey?.toString() || null;
    
    // Check if there's an actual change in connection state
    const connectionChanged = prevConnectedRef.current !== currentConnected;
    const publicKeyChanged = prevPublicKeyRef.current !== currentPublicKey;
    
    // Only proceed if there's an actual change
    if (connectionChanged || publicKeyChanged) {
      // Update refs for next comparison
      prevConnectedRef.current = currentConnected;
      prevPublicKeyRef.current = currentPublicKey;
      
      // Create an adapter for the external wallet if possible
      if (currentConnected && externalWalletState.publicKey && externalWalletState.signMessage) {
        const externalAdapter = new SolanaWalletAdapter(externalWalletState);
        
        // Only update if we don't already have this wallet
        const isNewConnection = !wallet || 
          wallet.getPublicKey() !== externalAdapter.getPublicKey();
          
        if (isNewConnection) {
          // We've established a connection to an external wallet, inject it into our context
          setWallet(externalAdapter);
          
          // Clear the manual disconnect flag since we have a successful connection
          clearWalletDisconnectState();
        }
      } 
      // External wallet disconnected
      else if (!currentConnected) {
        // Only handle disconnect if we have a wallet currently active
        if (wallet) {
          // Simply call our context's disconnect method
          // This will properly clean up the wallet state
          disconnect();
        }
      }
    }
  }, [externalWalletState, wallet, setWallet, disconnect]);

  // This component doesn't render anything
  return null;
};

export default SolanaWalletConnector; 