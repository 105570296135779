import React, { useState, useEffect } from 'react';
import { useWalletContext } from '../context/WalletContext';
import { 
  fetchGatewayRewards, 
  fetchTopGatewaysByRewards, 
  fetchTopWalletsByRewards,
  RewardsTimePeriod,
  TopGatewayRewards,
  TopWalletRewards,
  RewardsGraphDataItem,
  processRewardsForGraph
} from '../services/RewardsService';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, Legend, LineChart, Line } from 'recharts';
import { BoltIcon } from '@heroicons/react/24/solid';
import { useGateways } from '../context/GatewayContext';
import { useRouter } from "@tanstack/react-router";
import { ArrowLeftIcon } from '@heroicons/react/24/solid';

// Define colors for charts
const CHART_COLORS = [
  '#0BE898', // Primary green
  '#FF6B6B', // Coral red
  '#4ECDC4', // Turquoise
  '#FFD93D', // Bright yellow
  '#9B89B3'  // Muted purple
];

// Add new type for view mode
const enum ViewMode {
  TOTAL = 'total',
  DAILY = 'daily'
}

// Add new interface for selected gateway details
interface SelectedGatewayDetails {
  gwId: string;
  name: string;
  date: string;
  derDetails: {
    [sn: string]: number;
  };
}

// Add new interface for leaderboard loading state
interface LeaderboardState {
  isLoading: boolean;
  error: string | null;
}

const RewardsPage: React.FC = (): JSX.Element => {
  const { publicKey } = useWalletContext();
  const { gateways } = useGateways();
  const router = useRouter();
  const [totalRewards, setTotalRewards] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [timePeriod, setTimePeriod] = useState<RewardsTimePeriod>(RewardsTimePeriod.CURRENT_MONTH);
  const [userGatewayRewards, setUserGatewayRewards] = useState<{gwId: string; name: string; amount: number}[]>([]);
  const [userWalletRank, setUserWalletRank] = useState<{currentMonth: number | null; lastWeek: number | null; totalWallets: {currentMonth: number; lastWeek: number}} | null>(null);
  const [userGatewayRanks, setUserGatewayRanks] = useState<{gwId: string; name: string; rank: number}[]>([]);
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.TOTAL);
  const [combinedDailyRewards, setCombinedDailyRewards] = useState<{
    isLoading: boolean;
    error: string | null;
    data: Record<string, RewardsGraphDataItem[]>;
  }>({
    isLoading: false,
    error: null,
    data: {}
  });
  const [selectedGatewayDetails, setSelectedGatewayDetails] = useState<SelectedGatewayDetails | null>(null);
  const [currentMonthGateways, setCurrentMonthGateways] = useState<TopGatewayRewards[] | null>(null);
  const [lastWeekGateways, setLastWeekGateways] = useState<TopGatewayRewards[] | null>(null);
  const [currentMonthWallets, setCurrentMonthWallets] = useState<TopWalletRewards[] | null>(null);
  const [lastWeekWallets, setLastWeekWallets] = useState<TopWalletRewards[] | null>(null);
  const [leaderboardState, setLeaderboardState] = useState<LeaderboardState>({
    isLoading: false,
    error: null
  });
  
  

  // Fetch leaderboard data once on load
  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const [currentMonth, lastWeek, currentMonthWalletsData, lastWeekWalletsData] = await Promise.all([
          fetchTopGatewaysByRewards(RewardsTimePeriod.CURRENT_MONTH),
          fetchTopGatewaysByRewards(RewardsTimePeriod.LAST_FULL_WEEK),
          fetchTopWalletsByRewards(RewardsTimePeriod.CURRENT_MONTH),
          fetchTopWalletsByRewards(RewardsTimePeriod.LAST_FULL_WEEK)
        ]);
        
        setCurrentMonthGateways(currentMonth);
        setLastWeekGateways(lastWeek);
        setCurrentMonthWallets(currentMonthWalletsData);
        setLastWeekWallets(lastWeekWalletsData);
        
        // Update ranks if we have a wallet connected
        if (publicKey && currentMonth && gateways) {
          const walletAddress = publicKey.toString();
          
          // Update wallet ranks for both periods
          const currentMonthRank = currentMonthWalletsData?.findIndex(wallet => wallet.wallet === walletAddress) ?? -1;
          const lastWeekRank = lastWeekWalletsData?.findIndex(wallet => wallet.wallet === walletAddress) ?? -1;
          setUserWalletRank({
            currentMonth: currentMonthRank !== -1 ? currentMonthRank + 1 : null,
            lastWeek: lastWeekRank !== -1 ? lastWeekRank + 1 : null,
            totalWallets: {
              currentMonth: currentMonthWalletsData?.length || 0,
              lastWeek: lastWeekWalletsData?.length || 0
            }
          });

          // Update gateway ranks
          const userGatewayIds = gateways.map(gw => gw.id);
          const gatewayRanks = userGatewayIds.map(gwId => {
            const currentMonthIndex = currentMonth.findIndex(gw => gw.gwId === gwId);
            const gateway = currentMonth.find(gw => gw.gwId === gwId);
            return {
              gwId,
              name: gateway?.name || `Gateway ${gwId.slice(0, 6)}...`,
              rank: currentMonthIndex !== -1 ? currentMonthIndex + 1 : 0
            };
          }).filter(gw => gw.rank > 0);
          
          setUserGatewayRanks(gatewayRanks);
        }
      } catch (err) {
        console.error('Error fetching leaderboard data:', err);
        setLeaderboardState(prev => ({ 
          ...prev,
          error: 'Failed to load leaderboard data' 
        }));
      }
    };

    fetchLeaderboardData();
  }, [publicKey, gateways]);

  // Fetch user's total rewards and gateway data
  useEffect(() => {
    // Add function to fetch total wallet rewards
    const fetchWalletTotal = async () => {
      if (!publicKey) return;
      try {
        const walletAddress = publicKey.toString();
        const totalData = await fetchGatewayRewards('', walletAddress);
        if (totalData?.betaInfo?.total !== undefined) {
          setTotalRewards(totalData.betaInfo.total);
        }
      } catch (err) {
        console.error('Error fetching total rewards:', err);
      }
    };

    // Add function to fetch daily rewards for all gateways
    const fetchAllGatewayDailyRewards = async () => {
      if (!publicKey || !gateways?.length) return;

      setIsLoading(true);
      setCombinedDailyRewards(prev => ({
        ...prev,
        isLoading: true,
        error: null
      }));

      try {
        const walletAddress = publicKey.toString();
        
        // Fetch and process data for each gateway
        const promises = gateways.map(async gateway => {
          const data = await fetchGatewayRewards(gateway.id, walletAddress);
          const processedData = processRewardsForGraph(data);
          
          // Filter out rewards that belong to other wallets
          const filteredData = processedData.map(item => ({
            ...item,
            rewards: item.wallet === walletAddress ? item.rewards : 0
          }));
          
          return {
            gwId: gateway.id,
            name: gateway.name || `Gateway ${gateway.id.slice(0, 6)}...`,
            data: filteredData,
            total: calculateLast30DaysTotal(filteredData)
          };
        });

        const results = await Promise.all(promises);

        // Update user gateway rewards with actual totals
        const updatedGatewayRewards = results.map(gateway => ({
          gwId: gateway.gwId,
          name: gateway.name,
          amount: gateway.total
        }));

        setUserGatewayRewards(updatedGatewayRewards);

        // Get all unique dates across all gateways
        const allDates = new Set<string>();
        results.forEach(result => {
          result.data.forEach(item => {
            allDates.add(item.date);
          });
        });

        // Sort dates chronologically
        const sortedDates = Array.from(allDates).sort();

        // Create the final data structure with filled-in dates
        const combinedData: Record<string, RewardsGraphDataItem[]> = {};
        
        results.forEach(gateway => {
          // Create a map for quick lookup of existing data points
          const dataMap = new Map(
            gateway.data.map(item => [item.date, item])
          );

          // Create an entry for each date, filling in zeros for missing dates
          combinedData[gateway.gwId] = sortedDates.map(date => {
            const existingData = dataMap.get(date);
            if (existingData) {
              return existingData;
            }
            // Fill in missing dates with zero values
            return {
              date,
              rewards: 0,
              wallet: walletAddress,
              gwId: gateway.gwId,
              derDetails: {}
            };
          });
        });

        setCombinedDailyRewards({
          isLoading: false,
          error: null,
          data: combinedData
        });
        setIsLoading(false);
        setError(null);
      } catch (err) {
        console.error('Error fetching daily rewards:', err);
        setCombinedDailyRewards(prev => ({
          ...prev,
          isLoading: false,
          error: 'Failed to load daily rewards'
        }));
        setIsLoading(false);
        setError('Failed to load rewards data');
      }
    };

  
    if (!publicKey) {
      setTotalRewards(null);
      setError(null);
      return;
    }

    // Fetch wallet total and daily rewards
    fetchWalletTotal();
    fetchAllGatewayDailyRewards();
  }, [publicKey, gateways]);

  // Format wallet address for display
  const formatWalletAddress = (address: string) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  // Format gateway name for display
  const formatGatewayName = (name: string, gwId: string) => {
    if (name) return name;
    return `Gateway ${gwId.slice(0, 6)}...`;
  };

  // Format number with commas
  const formatNumber = (num: number) => {
    return num.toLocaleString();
  };

  // Get ordinal suffix for numbers (1st, 2nd, 3rd, etc.)
  const getOrdinalSuffix = (num: number) => {
    const j = num % 10;
    const k = num % 100;
    if (j === 1 && k !== 11) {
      return num + "st";
    }
    if (j === 2 && k !== 12) {
      return num + "nd";
    }
    if (j === 3 && k !== 13) {
      return num + "rd";
    }
    return num + "th";
  };

  // Prepare data for the user's gateway rewards chart
  const prepareUserGatewayChartData = () => {
    if (!userGatewayRewards || userGatewayRewards.length === 0) return [];
    
    // Sort by amount descending
    return [...userGatewayRewards].sort((a, b) => b.amount - a.amount);
  };

  // Add function to handle point click in the chart
  const handlePointClick = (data: any) => {
    if (viewMode === ViewMode.DAILY && data.payload) {
      const gateway = userGatewayRewards.find(gw => gw.gwId === data.payload.gwId);
      if (gateway && data.payload.derDetails) {
        setSelectedGatewayDetails({
          gwId: data.payload.gwId,
          name: gateway.name,
          date: data.payload.date,
          derDetails: data.payload.derDetails
        });
      }
    }
  };

  // Modify the CustomTooltip component to show DER details
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      
      return (
        <div className="bg-gray-800 p-3 rounded-md border border-gray-700 shadow-md">
          {viewMode === ViewMode.DAILY ? (
            <>
              <p className="text-gray-200 font-medium">{formatDate(data.date)}</p>
              {payload.map((entry: any, index: number) => (
                <div key={entry.dataKey} className="mt-2">
                  <p className="text-gray-300">{entry.name}</p>
                  <p className="font-medium" style={{ color: CHART_COLORS[index % CHART_COLORS.length] }}>
                    {formatNumber(entry.value)} Points
                  </p>
                </div>
              ))}
              <p className="text-gray-400 text-xs mt-2">Click for DER details</p>
            </>
          ) : (
            <>
              {data.name && (
                <p className="text-gray-200 font-medium">{data.name}</p>
              )}
              {data.gwId && !data.name && (
                <p className="text-gray-200 font-medium">Gateway {data.gwId.slice(0, 6)}...</p>
              )}
              {data.wallet && (
                <p className="text-gray-200 font-medium">Wallet {formatWalletAddress(data.wallet)}</p>
              )}
              <p className="text-green-400 font-medium mt-1">
                {formatNumber(data.amount)} Points
              </p>
              {data.numerOfGateways && (
                <p className="text-gray-300 text-xs mt-1">
                  {data.numerOfGateways} Gateways
                </p>
              )}
            </>
          )}
        </div>
      );
    }
    return null;
  };


  // Calculate total rewards for the last 30 days from daily data
  const calculateLast30DaysTotal = (data: RewardsGraphDataItem[]): number => {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    return data
      .filter(item => new Date(item.date) >= thirtyDaysAgo)
      .reduce((sum, item) => sum + item.rewards, 0);
  };

  // Format date for display
  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        console.error('Invalid date:', dateString);
        return dateString;
      }
      return date.toLocaleDateString(undefined, { 
        month: 'short', 
        day: 'numeric'
      });
    } catch (err) {
      console.error('Error formatting date:', dateString, err);
      return dateString;
    }
  };


  // Calculate last complete day's rewards and date
  const getLastDayInfo = (data: Record<string, RewardsGraphDataItem[]>): { total: number; date: string } => {
    const now = new Date();
    const lastCompleteDay = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 1));
    const lastCompleteDayStr = `${lastCompleteDay.toISOString().split('T')[0]}T00:00:00`;
    
    // Get all rewards for the last complete day
    const lastDayRewards = Object.values(data)
      .flat()
      .filter(item => item.date === lastCompleteDayStr);

    const total = lastDayRewards.reduce((sum, item) => sum + item.rewards, 0);

    return {
      total,
      date: formatDate(lastCompleteDayStr)
    };
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <div className="flex items-center gap-4 mb-4">
          <button
            onClick={() => router.navigate({ to: '/' })}
            className="p-2 rounded-full hover:bg-gray-800 transition-colors"
          >
            <ArrowLeftIcon className="w-6 h-6 text-gray-400" />
          </button>
          <h1 className="text-3xl font-bold text-white">Your Rewards and Ranks (beta)</h1>
        </div>
        <p className="text-gray-400">Track your rewards and see how your gateways are performing</p>
      </div>

      {/* User's rewards summary */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-12">
        {/* All time total card */}
        <div className="bg-glass rounded-xl p-6 shadow-lg">
          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="w-16 h-16 bg-[#0be898]/20 rounded-full flex items-center justify-center">
              <BoltIcon className="w-8 h-8 text-[#0be898]" />
            </div>
            <h2 className="text-xl font-medium text-gray-200">All Time Total</h2>
            {isLoading ? (
              <div className="animate-pulse">
                <div className="bg-gray-700 h-10 w-40 rounded-md"></div>
              </div>
            ) : error && !publicKey ? (
              <p className="text-gray-400">Connect wallet to view</p>
            ) : error ? (
              <p className="text-gray-400">{error}</p>
            ) : (
              <p className="text-3xl font-bold text-[#0be898]">{formatNumber(totalRewards || 0)} Points</p>
            )}
          </div>
        </div>

        {/* Recent rewards card */}
        <div className="bg-glass rounded-xl p-6 shadow-lg">
          <div className="flex flex-col items-center space-y-3">
            <div className="w-12 h-12 bg-[#0be898]/20 rounded-full flex items-center justify-center mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-[#0be898]">
                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z" clipRule="evenodd" />
              </svg>
            </div>
            <h2 className="text-xl font-medium text-gray-200">Recent Rewards</h2>
            {isLoading ? (
              <div className="animate-pulse flex space-x-4">
                <div className="bg-gray-700 h-10 w-32 rounded-md"></div>
                <div className="bg-gray-700 h-10 w-32 rounded-md"></div>
              </div>
            ) : error && !publicKey ? (
              <p className="text-gray-400">Connect wallet to view</p>
            ) : error ? (
              <p className="text-gray-400">{error}</p>
            ) : (
              <div className="flex items-center space-x-6">
                <div className="text-center">
                  <p className="text-sm text-gray-400">Last 30 Days</p>
                  <p className="text-xl font-bold text-[#0be898]">
                    {formatNumber(
                      Object.values(combinedDailyRewards.data)
                        .flat()
                        .reduce((sum, item) => sum + item.rewards, 0)
                    )} Points
                  </p>
                </div>
                <div className="h-12 w-px bg-gray-700"></div>
                <div className="text-center">
                  {(() => {
                    const lastDayInfo = getLastDayInfo(combinedDailyRewards.data);
                    return (
                      <>
                        <p className="text-sm text-gray-400">{lastDayInfo.date}</p>
                        <p className="text-xl font-bold text-[#0be898]">
                          {formatNumber(lastDayInfo.total)} Points
                        </p>
                      </>
                    );
                  })()}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Wallet rank card */}
        <div className="bg-glass rounded-xl p-6 shadow-lg">
          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="w-16 h-16 bg-[#F75C77]/20 rounded-full flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8 text-[#F75C77]">
                <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
              </svg>
            </div>
            <h2 className="text-xl font-medium text-gray-200">Your Wallet Rank</h2>
            {isLoading ? (
              <div className="animate-pulse bg-gray-700 h-10 w-40 rounded-md"></div>
            ) : !publicKey ? (
              <p className="text-gray-400">Connect wallet to view</p>
            ) : userWalletRank ? (
              <div className="text-center space-y-2">
                <div className="flex items-center justify-center space-x-4">
                  <div>
                    <p className="text-sm text-gray-400">Current Month</p>
                    <p className="text-2xl font-bold text-[#F75C77]">
                      {userWalletRank.currentMonth ? getOrdinalSuffix(userWalletRank.currentMonth) : '-'}
                    </p>
                    <p className="text-gray-400 text-xs">
                      of {userWalletRank.totalWallets.currentMonth} wallets
                    </p>
                  </div>
                  <div className="h-12 w-px bg-gray-700"></div>
                  <div>
                    <p className="text-sm text-gray-400">Last Week</p>
                    <p className="text-2xl font-bold text-[#F75C77]">
                      {userWalletRank.lastWeek ? getOrdinalSuffix(userWalletRank.lastWeek) : '-'}
                    </p>
                    <p className="text-gray-400 text-xs">
                      of {userWalletRank.totalWallets.lastWeek} wallets
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <p className="text-gray-400">Not ranked yet</p>
            )}
          </div>
        </div>

        {/* Gateway count card */}
        <div className="bg-glass rounded-xl p-6 shadow-lg">
          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="w-16 h-16 bg-[#0be898]/20 rounded-full flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8 text-[#0be898]">
                <path d="M11.584 2.376a.75.75 0 01.832 0l9 6a.75.75 0 11-.832 1.248L12 3.901 3.416 9.624a.75.75 0 01-.832-1.248l9-6z" />
                <path fillRule="evenodd" d="M20.25 10.332v9.918H21a.75.75 0 010 1.5H3a.75.75 0 010-1.5h.75v-9.918a.75.75 0 01.634-.74A49.109 49.109 0 0112 9c2.59 0 5.134.202 7.616.592a.75.75 0 01.634.74zm-7.5 2.418a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75zm3-.75a.75.75 0 01.75.75v6.75a.75.75 0 01-1.5 0v-6.75a.75.75 0 01.75-.75zM9 12.75a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75z" clipRule="evenodd" />
              </svg>
            </div>
            <h2 className="text-xl font-medium text-gray-200">Your Gateways</h2>
            {isLoading ? (
              <div className="animate-pulse bg-gray-700 h-10 w-40 rounded-md"></div>
            ) : !publicKey ? (
              <p className="text-gray-400">Connect wallet to view</p>
            ) : (
              <div className="text-center">
                <p className="text-4xl font-bold text-[#0be898]">{userGatewayRewards.length}</p>
                <p className="text-gray-400 text-sm mt-1">earning rewards</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* User's gateway rewards */}
      <div className="mb-12">
        <div className="bg-glass rounded-xl p-6 shadow-lg">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h2 className="text-xl font-medium text-gray-200">Your Gateway Rewards</h2>
              <p className="text-sm text-gray-400">Last 30 days rewards history</p>
            </div>
            <div className="bg-gray-800/50 rounded-lg p-1">
              <button
                onClick={() => setViewMode(ViewMode.TOTAL)}
                className={`px-4 py-2 rounded-md transition-colors ${
                  viewMode === ViewMode.TOTAL
                    ? 'bg-[#0be898] text-black font-medium'
                    : 'text-gray-300 hover:bg-gray-700'
                }`}
              >
                Total
              </button>
              <button
                onClick={() => setViewMode(ViewMode.DAILY)}
                className={`px-4 py-2 rounded-md transition-colors ${
                  viewMode === ViewMode.DAILY
                    ? 'bg-[#0be898] text-black font-medium'
                    : 'text-gray-300 hover:bg-gray-700'
                }`}
              >
                Daily
              </button>
            </div>
          </div>

          {isLoading ? (
            <div className="animate-pulse space-y-4">
              <div className="bg-gray-700 h-64 w-full rounded-md"></div>
            </div>
          ) : !publicKey ? (
            <p className="text-gray-400 text-center py-8">Connect wallet to view your gateway rewards</p>
          ) : userGatewayRewards.length === 0 ? (
            <div className="text-center py-8">
              <p className="text-gray-400">No gateway rewards found for this time period</p>
              <p className="text-gray-500 text-sm mt-2">Your gateways may not be earning rewards yet or may not be in the top performers list</p>
            </div>
          ) : (
            <div>
              <div className="h-64 mb-4">
                <ResponsiveContainer width="100%" height="100%">
                  {viewMode === ViewMode.TOTAL ? (
                    <BarChart
                      data={prepareUserGatewayChartData()}
                      margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                      <XAxis
                        dataKey="name"
                        stroke="#888"
                        tickFormatter={(value, index) => {
                          const gateway = prepareUserGatewayChartData()[index];
                          return formatGatewayName(gateway.name, gateway.gwId);
                        }}
                        height={60}
                        interval={0}
                        tick={{ fontSize: 14 }}
                      />
                      <YAxis stroke="#888" />
                      <Tooltip
                        content={<CustomTooltip />}
                        contentStyle={{ backgroundColor: '#1d1d1d', border: '1px solid #333' }}
                        cursor={{ fill: 'rgba(11, 232, 152, 0.1)' }}
                      />
                      <Bar 
                        dataKey="amount" 
                        fill="#0BE898"
                        onMouseOver={(data: any) => {
                          const bar = document.querySelector(`[name="amount-${data.index}"]`) as HTMLElement;
                          if (bar) {
                            bar.setAttribute('fill', '#FFFFFF');
                          }
                        }}
                        onMouseOut={(data: any) => {
                          const bar = document.querySelector(`[name="amount-${data.index}"]`) as HTMLElement;
                          if (bar) {
                            bar.setAttribute('fill', CHART_COLORS[data.index % CHART_COLORS.length]);
                          }
                        }}
                      >
                        {prepareUserGatewayChartData().map((entry, index) => (
                          <Cell 
                            key={`cell-${index}`} 
                            fill={CHART_COLORS[index % CHART_COLORS.length]}
                            name={`amount-${index}`}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  ) : (
                    <LineChart
                      margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
                      onClick={handlePointClick}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                      <XAxis
                        dataKey="date"
                        stroke="#888"
                        tickFormatter={formatDate}
                        type="category"
                        allowDuplicatedCategory={false}
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        minTickGap={20}
                      />
                      <YAxis 
                        stroke="#888"
                        domain={[0, 'auto']}
                      />
                      <Tooltip
                        content={<CustomTooltip />}
                        contentStyle={{ backgroundColor: '#1d1d1d', border: '1px solid #333' }}
                      />
                      {userGatewayRewards.map((gateway, index) => {
                        const data = combinedDailyRewards.data[gateway.gwId] || [];
                        return (
                          <Line
                            key={gateway.gwId}
                            data={data}
                            type="monotone"
                            dataKey="rewards"
                            name={formatGatewayName(gateway.name, gateway.gwId)}
                            stroke={CHART_COLORS[index % CHART_COLORS.length]}
                            strokeWidth={2}
                            dot={{ fill: CHART_COLORS[index % CHART_COLORS.length], r: 4 }}
                            activeDot={{ r: 6 }}
                            connectNulls={false}
                          />
                        );
                      })}
                      <Legend />
                    </LineChart>
                  )}
                </ResponsiveContainer>
              </div>

              {/* DER Details Modal */}
              {selectedGatewayDetails && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
                  <div className="bg-gray-800 rounded-lg p-6 max-w-lg w-full mx-4">
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-xl font-medium text-gray-200">
                        {formatGatewayName(selectedGatewayDetails.name, selectedGatewayDetails.gwId)}
                      </h3>
                      <button
                        onClick={() => setSelectedGatewayDetails(null)}
                        className="text-gray-400 hover:text-white"
                      >
                        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                    <p className="text-gray-400 mb-4">
                      DER Rewards for {formatDate(selectedGatewayDetails.date)}
                    </p>
                    <div className="space-y-2">
                      {Object.entries(selectedGatewayDetails.derDetails).map(([sn, amount]) => (
                        <div
                          key={sn}
                          className="flex justify-between items-center p-3 bg-gray-700/30 rounded-lg"
                        >
                          <div className="text-gray-300">
                            <div className="font-medium">DER {sn.slice(-6)}</div>
                            <div className="text-xs text-gray-400">{sn}</div>
                          </div>
                          <div className="text-green-400 font-medium">
                            {formatNumber(amount)} Points
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Gateway Rankings */}
      <div className="mb-12">
        <div className="bg-glass rounded-xl p-6 shadow-lg">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h2 className="text-xl font-medium text-gray-200">Your Gateway Rankings</h2>
              <p className="text-sm text-gray-400">Performance across different time periods</p>
            </div>
          </div>

          {/* Gateway Rankings Table */}
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b border-gray-700">
                  <th className="px-4 py-3 text-left text-gray-400">Gateway</th>
                  <th className="px-4 py-3 text-center text-gray-400">
                    <div>Current Month</div>
                    <div className="text-xs text-gray-500">Rank</div>
                  </th>
                  <th className="px-4 py-3 text-right text-gray-400">
                    <div>Current Month</div>
                    <div className="text-xs text-gray-500">Points</div>
                  </th>
                  <th className="px-4 py-3 text-center text-gray-400">
                    <div>Last Week</div>
                    <div className="text-xs text-gray-500">Rank</div>
                  </th>
                  <th className="px-4 py-3 text-right text-gray-400">
                    <div>Last Week</div>
                    <div className="text-xs text-gray-500">Points</div>
                  </th>
                  <th className="px-4 py-3 text-right text-gray-400">
                    <div>30 Day</div>
                    <div className="text-xs text-gray-500">Total Points</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentMonthGateways?.filter(g => userGatewayRewards.some(ug => ug.gwId === g.gwId))
                  .map((gateway) => {
                    const currentMonthRank = userGatewayRanks.find(g => g.gwId === gateway.gwId)?.rank || 0;
                    const lastWeekGateway = lastWeekGateways?.find(g => g.gwId === gateway.gwId);
                    const lastWeekRank = lastWeekGateways ? 
                      lastWeekGateways.findIndex(g => g.gwId === gateway.gwId) + 1 : 
                      0;
                    const dailyTotal = userGatewayRewards.find(g => g.gwId === gateway.gwId)?.amount || 0;
                    
                    return (
                      <tr key={gateway.gwId} className="border-b border-gray-700/50 hover:bg-gray-800/30">
                        <td className="px-4 py-3 text-gray-300">{formatGatewayName(gateway.name, gateway.gwId)}</td>
                        <td className="px-4 py-3 text-center">
                          <span className={`inline-flex items-center justify-center px-2 py-1 rounded-full ${
                            currentMonthRank <= 10 ? 'bg-green-900/50 text-green-400' : 'bg-gray-700 text-gray-300'
                          }`}>
                            #{currentMonthRank || '-'}
                          </span>
                        </td>
                        <td className="px-4 py-3 text-right text-green-400 font-medium">
                          {formatNumber(gateway.amount)}
                        </td>
                        <td className="px-4 py-3 text-center">
                          <span className={`inline-flex items-center justify-center px-2 py-1 rounded-full ${
                            lastWeekRank <= 10 ? 'bg-green-900/50 text-green-400' : 'bg-gray-700 text-gray-300'
                          }`}>
                            #{lastWeekRank || '-'}
                          </span>
                        </td>
                        <td className="px-4 py-3 text-right text-green-400 font-medium">
                          {formatNumber(lastWeekGateway?.amount || 0)}
                        </td>
                        <td className="px-4 py-3 text-right text-green-400 font-medium">
                          {formatNumber(dailyTotal)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Leaderboards Section */}
      <div className="mb-12">
        <div className="bg-glass rounded-xl p-6 shadow-lg">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h2 className="text-2xl font-bold text-white">Leaderboards</h2>
              <p className="text-gray-400">Top performing gateways and wallets</p>
            </div>
            <div className="bg-gray-800/50 rounded-lg p-1">
              <button
                className={`px-4 py-2 rounded-md transition-colors ${
                  timePeriod === RewardsTimePeriod.CURRENT_MONTH
                    ? 'bg-[#0be898] text-black font-medium'
                    : 'text-gray-300 hover:bg-gray-700'
                }`}
                onClick={() => setTimePeriod(RewardsTimePeriod.CURRENT_MONTH)}
              >
                Current Month
              </button>
              <button
                className={`px-4 py-2 rounded-md transition-colors ${
                  timePeriod === RewardsTimePeriod.LAST_FULL_WEEK
                    ? 'bg-[#0be898] text-black font-medium'
                    : 'text-gray-300 hover:bg-gray-700'
                }`}
                onClick={() => setTimePeriod(RewardsTimePeriod.LAST_FULL_WEEK)}
              >
                Last Week
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Top Gateways Table */}
            <div className="space-y-4">
              <h3 className="text-xl font-medium text-gray-200">Gateway Leaderboard</h3>
              {leaderboardState.error ? (
                <p className="text-gray-400">{leaderboardState.error}</p>
              ) : (timePeriod === RewardsTimePeriod.CURRENT_MONTH ? currentMonthGateways : lastWeekGateways)?.length ? (
                <div className="overflow-x-auto">
                  <table className="w-full">
                    <thead>
                      <tr className="border-b border-gray-700">
                        <th className="px-4 py-3 text-left text-gray-400">Rank</th>
                        <th className="px-4 py-3 text-left text-gray-400">Gateway</th>
                        <th className="px-4 py-3 text-right text-gray-400">Rewards</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(timePeriod === RewardsTimePeriod.CURRENT_MONTH ? currentMonthGateways : lastWeekGateways)
                        ?.slice(0, 10)
                        .map((gateway, index) => {
                          const isUserGateway = userGatewayRewards.some(g => g.gwId === gateway.gwId);
                          return (
                            <tr 
                              key={gateway.gwId} 
                              className={`border-b border-gray-700/50 hover:bg-gray-800/30 ${isUserGateway ? 'bg-[#0be898]/10' : ''}`}
                            >
                              <td className="px-4 py-3 text-gray-300">#{index + 1}</td>
                              <td className="px-4 py-3 text-gray-300">
                                {formatGatewayName(gateway.name, gateway.gwId)}
                                {isUserGateway && (
                                  <span className="ml-2 px-2 py-0.5 text-xs rounded-full bg-[#0be898]/20 text-[#0be898]">
                                    Yours
                                  </span>
                                )}
                              </td>
                              <td className="px-4 py-3 text-right text-green-400 font-medium">{formatNumber(gateway.amount)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-gray-400">No gateway rewards data available</p>
              )}
            </div>

            {/* Top Wallets Table */}
            <div className="space-y-4">
              <h3 className="text-xl font-medium text-gray-200">Wallet Leaderboard</h3>
              {leaderboardState.error ? (
                <p className="text-gray-400">{leaderboardState.error}</p>
              ) : (timePeriod === RewardsTimePeriod.CURRENT_MONTH ? currentMonthWallets : lastWeekWallets)?.length ? (
                <div className="overflow-x-auto">
                  <table className="w-full">
                    <thead>
                      <tr className="border-b border-gray-700">
                        <th className="px-4 py-3 text-left text-gray-400">Rank</th>
                        <th className="px-4 py-3 text-left text-gray-400">Wallet</th>
                        <th className="px-4 py-3 text-center text-gray-400">Gateways</th>
                        <th className="px-4 py-3 text-right text-gray-400">Rewards</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(timePeriod === RewardsTimePeriod.CURRENT_MONTH ? currentMonthWallets : lastWeekWallets)
                        ?.slice(0, 10)
                        .map((wallet, index) => {
                          const isUserWallet = publicKey && wallet.wallet === publicKey.toString();
                          return (
                            <tr 
                              key={wallet.wallet} 
                              className={`border-b border-gray-700/50 hover:bg-gray-800/30 ${isUserWallet ? 'bg-[#F75C77]/10' : ''}`}
                            >
                              <td className="px-4 py-3 text-gray-300">#{index + 1}</td>
                              <td className="px-4 py-3 text-gray-300">
                                {formatWalletAddress(wallet.wallet)}
                                {isUserWallet && (
                                  <span className="ml-2 px-2 py-0.5 text-xs rounded-full bg-[#F75C77]/20 text-[#F75C77]">
                                    You
                                  </span>
                                )}
                              </td>
                              <td className="px-4 py-3 text-center text-gray-300">{wallet.numerOfGateways}</td>
                              <td className="px-4 py-3 text-right text-green-400 font-medium">{formatNumber(wallet.amount)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-gray-400">No wallet rewards data available</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Footer note */}
      <div className="mt-12 text-center text-gray-500 text-sm">
        <p>Rewards data is updated daily. The leaderboard shows the top performers for the selected time period.</p>
      </div>
    </div>
  );
};

export default RewardsPage; 