import { createContext, useContext, FC, ReactNode, useEffect, useState, useCallback, useMemo } from 'react';
import { WalletInterface } from '../wallets';
import { setWalletDisconnected } from '../utils/WalletUtils';

// Generic signature for any wallet adapter's sign message function
export type SignMessageFunction = (message: Uint8Array) => Promise<Uint8Array>;

interface WalletContextType {
  isConnected: boolean;
  isConnecting: boolean;
  resetConnection: () => void;
  publicKey: string | null;
  signMessage: SignMessageFunction | null;
  connected: boolean;
  disconnect: () => void;
  setWallet: (wallet: WalletInterface | null) => void;
  wallet: WalletInterface | null;
}

const WalletContext = createContext<WalletContextType | undefined>(undefined);

export const WalletContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // Internal wallet state
  const [wallet, setWalletState] = useState<WalletInterface | null>(null);
  const [publicKey, setPublicKey] = useState<string | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);

  // Update state when wallet changes
  useEffect(() => {
    if (wallet) {
      const newPublicKey = wallet.getPublicKey();
      const newIsConnecting = wallet.isConnecting();
      
      // Only update state if values have changed
      if (publicKey !== newPublicKey) {
        setPublicKey(newPublicKey);
      }
      
      if (isConnecting !== newIsConnecting) {
        setIsConnecting(newIsConnecting);
      }
    } else {
      // Only update state if values have changed
      if (publicKey !== null) {
        setPublicKey(null);
      }
      
      if (isConnecting !== false) {
        setIsConnecting(false);
      }
    }
  }, [wallet, publicKey, isConnecting]);

  // Set a new wallet instance - memoized to prevent recreation
  const setWalletInstance = useCallback((newWallet: WalletInterface | null) => {
    // Store reference to the old wallet before making changes
    setWalletState(prevWallet => {
      // If the wallet is the same, don't trigger a state update
      if (prevWallet === newWallet) {
        return prevWallet;
      }
      
      // Store old wallet reference for cleanup outside state update
      if (prevWallet) {
        // Use setTimeout to disconnect the old wallet in the next event loop
        // This ensures the new wallet is fully set before disconnecting the old one
        setTimeout(() => {
          try {
            prevWallet.disconnect();
          } catch (error) {
            console.error('Error disconnecting previous wallet:', error);
          }
        }, 100);
      }
      
      // Return the new wallet immediately
      return newWallet;
    });
  }, []);

  // Determine if any wallet is connected using the interface method
  const isAnyWalletConnected = useMemo(() => 
    wallet?.isConnected() || false
  , [wallet]);

  // Disconnect the active wallet - memoized to prevent recreation
  const disconnect = useCallback(() => {
    if (wallet) {
      // First call the wallet's disconnect method
      wallet.disconnect();
      
      // Set the manual disconnect flag to prevent auto-reconnection
      setWalletDisconnected();
      
      // Always clear the wallet state, regardless of wallet type
      setWalletState(null);
      setPublicKey(null);
      setIsConnecting(false);
    }
  }, [wallet]);

  // Redirect to login if no wallet is active
  useEffect(() => {
    const pathname = window.location.pathname;
    
    // Skip redirect for certain pages that should be accessible without a wallet
    if (pathname === '/login' || pathname === '/signup' || pathname === '/reset-password') {
      return;
    }
    
    // Add a delay before redirecting to give wallet connections time to complete
    let redirectTimeout: number | null = null;
    
    if (!isAnyWalletConnected && !isConnecting) {
      redirectTimeout = window.setTimeout(() => {
        // Check one more time before redirecting (in case wallet connected during timeout)
        if (!isAnyWalletConnected && !isConnecting && window.location.pathname !== '/login') {
          window.location.href = '/login';
        }
      }, 1500); // Increased from 1000ms to 1500ms to allow for async wallet operations
    }
    
    // Clean up timeout if component unmounts or dependencies change
    return () => {
      if (redirectTimeout !== null) {
        window.clearTimeout(redirectTimeout);
      }
    };
  }, [isAnyWalletConnected, isConnecting]);

  // Bind signMessage to the wallet
  const boundSignMessage = useMemo(() => 
    wallet ? wallet.sign.bind(wallet) : null
  , [wallet]);

  // Memoize the context value to prevent unnecessary renders
  const contextValue = useMemo(() => ({
    isConnected: isAnyWalletConnected,
    isConnecting,
    resetConnection: disconnect,
    publicKey,
    signMessage: boundSignMessage,
    connected: isAnyWalletConnected,
    disconnect,
    setWallet: setWalletInstance,
    wallet
  }), [
    isAnyWalletConnected,
    isConnecting,
    disconnect,
    publicKey,
    boundSignMessage,
    setWalletInstance,
    wallet
  ]);

  return (
    <div data-wallet-context data-is-connected={isAnyWalletConnected}>
      <WalletContext.Provider value={contextValue}>
        {children}
      </WalletContext.Provider>
    </div>
  );
};

export const useWalletContext = () => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    throw new Error('useWalletContext must be used within a WalletContextProvider');
  }
  return context;
}; 