import { FC, useState } from 'react';
import ContentFramework from '../components/ContentFramework';
import { useGateways } from '../context/GatewayContext';
import { useWalletContext } from '../context/WalletContext';
import { BellIcon } from '@heroicons/react/24/outline';
import PageContainer from '../components/layout/PageContainer';
import NotificationItem from '../components/notifications/NotificationItem';
import { toast } from 'react-hot-toast';
import SrcfulMessageProtocolFactory from '../services/backend/SrcfulMessageProtocolFactory';

interface Notification {
  id: number;
  message: string;
  type: string;
  timestamp: number;
}

const NotificationsPage: FC = () => {
  const { isConnected, publicKey, wallet } = useWalletContext();
  const { gateways } = useGateways();
  const [deletingNotifications, setDeletingNotifications] = useState<Record<number, { timeoutId: NodeJS.Timeout }>>({});

  if (!isConnected) {
    return (
      <ContentFramework>
        <PageContainer>
          <div className="text-center py-12">
            <p className="text-gray-400">Please connect your wallet to view notifications</p>
          </div>
        </PageContainer>
      </ContentFramework>
    );
  }

  if (!gateways || gateways.length === 0) {
    return (
      <ContentFramework>
        <PageContainer>
          <div className="text-center py-12">
            <p className="text-gray-400">No gateways found. Add a gateway to receive notifications.</p>
          </div>
        </PageContainer>
      </ContentFramework>
    );
  }

  const handleDelete = async (id: number) => {
    if (!publicKey || !wallet) return;

    const timeoutId = setTimeout(() => {
      if (deletingNotifications[id]) {
        toast.error('Delete notification timed out');
        setDeletingNotifications(prev => {
          const newState = { ...prev };
          delete newState[id];
          return newState;
        });
      }
    }, 30000);

    setDeletingNotifications(prev => ({
      ...prev,
      [id]: { timeoutId }
    }));

    const message = SrcfulMessageProtocolFactory.deleteNotificationMessage({
      walletPublicKey: publicKey.toString(),
      gatewayId: '', // We don't need this for the notifications page
      notificationId: id
    });

    try {
      const messageContext = await wallet.signMessage(message);
      if (messageContext?.signedMessage) {
        toast.success('Notification deleted');
        clearTimeout(timeoutId);
        setDeletingNotifications(prev => {
          const newState = { ...prev };
          delete newState[id];
          return newState;
        });
      } else {
        throw new Error('Failed to sign message');
      }
    } catch (error) {
      toast.error('Failed to delete notification');
      clearTimeout(timeoutId);
      setDeletingNotifications(prev => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });
    }
  };

  // Collect all notifications from all gateways
  const allNotifications = gateways.flatMap(gateway => 
    gateway.state?.status?.messages?.map((message: Notification) => ({
      ...message,
      gatewayId: gateway.id,
      gatewayName: gateway.name
    })) || []
  ).sort((a, b) => b.timestamp - a.timestamp);

  return (
    <ContentFramework>
      <PageContainer>
        <div className="flex items-center mb-6">
          <BellIcon className="h-6 w-6 text-gray-400 mr-2" />
          <h1 className="text-2xl font-bold text-gray-200">Notifications</h1>
        </div>

        <div className="space-y-4">
          {allNotifications.length > 0 ? (
            allNotifications.map((notification) => (
              <NotificationItem
                key={`${notification.gatewayId}-${notification.id}`}
                id={notification.id}
                type={notification.type}
                timestamp={notification.timestamp}
                message={notification.message}
                gatewayName={notification.gatewayName}
                onDelete={handleDelete}
                showDeleteButton={true}
              />
            ))
          ) : (
            <div className="text-center py-8">
              <p className="text-gray-400">No notifications available</p>
            </div>
          )}
        </div>
      </PageContainer>
    </ContentFramework>
  );
};

export default NotificationsPage; 