// @ts-ignore
import { WalletInterface, MessageContext, createSignedMessage } from './types';
import { WalletVisitor } from './WalletVisitor';
import { Wallet, WalletContextState } from '@solana/wallet-adapter-react';

export class SolanaWalletAdapter implements WalletInterface {
  protected walletState: WalletContextState;
  protected adapter: Wallet;

  constructor(walletState: WalletContextState) {
    this.walletState = walletState;
    if (!walletState.wallet) {
      throw new Error('Wallet adapter is required');
    }
    this.adapter = walletState.wallet;
  }

  public getWalletAdapter(): Wallet {
    return this.adapter;
  }

  public async connect(options?: { onlyIfTrusted?: boolean }): Promise<void> {
    // For Solana wallet, just return a resolved promise since the wallet is already connected
    return Promise.resolve();
  }

  public getPublicKey(): string | null {
    return this.walletState.publicKey?.toString() || null;
  }

  public isConnected(): boolean {
    return this.walletState.connected;
  }

  public isConnecting(): boolean {
    return this.walletState.connecting;
  }

  public async sign(message: Uint8Array): Promise<Uint8Array> {
    if (!this.isConnected() || !this.walletState.signMessage) {
      throw new Error('Wallet not connected or signing not available');
    }

    try {
      return await this.walletState.signMessage(message);
    } catch (error) {
      console.error("Error signing message:", error);
      throw new Error(`Error signing message: ${error}`);
    }
  }

  public async signMessage(message: string): Promise<MessageContext | null> {
    const signedMessage = await createSignedMessage(this.sign.bind(this), message);
    if (!signedMessage) {
      return null;
    }
    return { signedMessage };
  }

  public disconnect(): void {
    if (this.walletState.disconnect) {
      this.walletState.disconnect();
    }
  }

  public accept(visitor: WalletVisitor): void {
    visitor.visitSolanaWallet(this);
  }
} 