import { nanoid } from 'nanoid';
import { handleSubmitFlow } from '../components/utils/gatewayUtils';
import SrcfulMessageProtocolFactory from './backend/SrcfulMessageProtocolFactory';
import { WalletInterface } from '../wallets/types';
interface PendingRequest {
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
  timeout: NodeJS.Timeout;
}

interface RawGatewayResponse {
  id: string;
  code: number;
  response: string;
  timestamp: number;
}

export interface GatewayRequestInit {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  path: string;
  body?: any;
  headers?: Record<string, string>;
  query?: Record<string, string>;
}

export interface GatewayRequestConfig {
  gatewayId: string;
  timeout?: number;
}

export class GatewayResponse {
  constructor(private rawResponse: RawGatewayResponse) {}

  async json<T = any>(): Promise<T> {
    return JSON.parse(this.rawResponse.response);
  }

  get ok(): boolean {
    return this.rawResponse.code === 200;
  }

  get status(): number {
    return this.rawResponse.code;
  }

  get requestId(): string {
    return this.rawResponse.id;
  }
}

class GatewayMessageManager {
  private static instance: GatewayMessageManager;
  private pendingRequests: Map<string, PendingRequest> = new Map();
  private readonly DEFAULT_TIMEOUT = 30000; // 30 seconds timeout
  private wallet: WalletInterface | null = null;

  private constructor() {}

  public static getInstance(): GatewayMessageManager {
    if (!GatewayMessageManager.instance) {
      GatewayMessageManager.instance = new GatewayMessageManager();
    }
    return GatewayMessageManager.instance;
  }

  public setWallet(wallet: WalletInterface) {
    this.wallet = wallet;
  }

  public clearWallet() {
    this.wallet = null;
  }

  private generateRequestId(): string {
    return nanoid();
  }

  private createRequest(timeout: number = this.DEFAULT_TIMEOUT): Promise<GatewayResponse> & { requestId: string } {
    const requestId = this.generateRequestId();
    console.log('Creating request with ID:', requestId);
    
    const promise = new Promise<GatewayResponse>((resolve, reject) => {
      const timeoutHandle = setTimeout(() => {
        console.log('Request timed out:', requestId);
        this.pendingRequests.delete(requestId);
        reject(new Error('Request timeout'));
      }, timeout);

      this.pendingRequests.set(requestId, {
        resolve,
        reject,
        timeout: timeoutHandle
      });
      console.log('Stored pending request. Current requests:', Array.from(this.pendingRequests.keys()));
    });

    return Object.assign(promise, { requestId });
  }

  public handleResponse(response: RawGatewayResponse) {
    const responseId = response.id;
    console.log('Handling response for ID:', responseId);
    console.log('Current pending requests:', Array.from(this.pendingRequests.keys()));
    
    const pendingRequest = this.pendingRequests.get(responseId);
    
    if (!pendingRequest) {
      console.warn('Received response for unknown request:', response);
      return;
    }

    clearTimeout(pendingRequest.timeout);
    this.pendingRequests.delete(responseId);

    if (response.code === 200 || response.code === 201) {
      pendingRequest.resolve(new GatewayResponse(response));
    } else {
      pendingRequest.reject(new Error(`Request failed with code ${response.code}`));
    }
  }

  private cancelRequest(requestId: string) {
    const pendingRequest = this.pendingRequests.get(requestId);
    if (pendingRequest) {
      clearTimeout(pendingRequest.timeout);
      this.pendingRequests.delete(requestId);
    }
  }

  /**
   * Generic fetch method for gateway communications
   * @example
   * // Echo message
   * const response = await GatewayMessageManager.getInstance().fetch('/api/echo', {
   *   method: 'POST',
   *   body: 'Hello World'
   * }, {
   *   gatewayId: 'gateway-id'
   * });
   * const data = await response.json();
   */
  public async fetch(
    path: string,
    init: Partial<GatewayRequestInit> = {},
    config: GatewayRequestConfig
  ): Promise<GatewayResponse> {
    if (!this.wallet) {
      throw new Error('Wallet configuration not set. Call setWallet first.');
    }

    const request = this.createRequest(config.timeout);
    const requestData = {
      method: init.method || 'GET',
      path,
      headers: JSON.stringify(init.headers || {}),
      body: init.body || {},
      query: JSON.stringify(init.query || {}),
      timestamp: Date.now(),
      id: request.requestId
    };
    const message = SrcfulMessageProtocolFactory.createGatewayConfigMessage({
      walletPublicKey: this.wallet?.getPublicKey()?.toString() || '',
      gatewayId: config.gatewayId,
      configData: requestData,
      subKey: 'request'
    });

    try {
      await handleSubmitFlow(
        message,
        this.wallet,
        () => request,
        () => {
          this.cancelRequest(request.requestId);
          throw new Error('Failed request submission failed');
        }
      );

      return await request;
    } catch (error) {
      this.cancelRequest(request.requestId);
      throw error;
    }
  }
}

export default GatewayMessageManager; 