import { FC, useState } from 'react';
import ContentFramework from "../../components/ContentFramework";
import PageContainer from '../../components/layout/PageContainer';
import { BatteryIcon } from '../../components/icons/icons';
import { 
  BoltIcon, ClockIcon, CalendarDaysIcon, ChartBarIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline';

// Mock data for the battery charge/discharge history
const hourlyData = Array.from({ length: 24 }, (_, i) => ({
  hour: i,
  charge: Math.sin(i / 3) * 30 + Math.random() * 10 + 50, // Simulated charge pattern
  power: Math.sin(i / 3) * 2.5 + (Math.random() - 0.5) * 1, // Simulated power flow
}));

const weeklyData = Array.from({ length: 7 }, (_, i) => ({
  day: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][i],
  cycles: Math.floor(Math.random() * 3) + 1,
  efficiency: 92 + Math.random() * 6,
}));

const BatteryPage: FC = () => {
  const [selectedTimeframe, setSelectedTimeframe] = useState<'day' | 'week'>('day');
  const [animatedCharge, setAnimatedCharge] = useState(85);

  // Simulate real-time updates
  useState(() => {
    const interval = setInterval(() => {
      setAnimatedCharge(prev => {
        const change = (Math.random() - 0.5) * 2;
        return Math.min(Math.max(prev + change, 0), 100);
      });
    }, 3000);
    return () => clearInterval(interval);
  });

  // Battery level color based on charge
  const getBatteryColor = (charge: number) => {
    if (charge > 80) return 'text-green-500';
    if (charge > 20) return 'text-yellow-500';
    return 'text-red-500';
  };

  // Custom battery level indicator
  const BatteryLevelIndicator = ({ charge }: { charge: number }) => (
    <div className="relative w-32 h-64 border-4 border-gray-600 rounded-2xl p-2 bg-gray-800">
      <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-4 w-8 h-4 bg-gray-600 rounded-t-lg" />
      <div 
        className="absolute bottom-0 left-0 right-0 mx-2 rounded-lg transition-all duration-1000 ease-in-out"
        style={{ 
          height: `${charge}%`,
          background: `linear-gradient(to top, 
            ${charge > 80 ? '#22c55e' : charge > 20 ? '#eab308' : '#ef4444'} 0%,
            ${charge > 80 ? '#15803d' : charge > 20 ? '#854d0e' : '#b91c1c'} 100%)`
        }}
      />
      {Array.from({ length: 10 }).map((_, i) => (
        <div 
          key={i}
          className="absolute left-0 right-0 h-px bg-gray-700"
          style={{ bottom: `${(i + 1) * 10}%` }}
        />
      ))}
    </div>
  );

  return (
    <ContentFramework>
      <PageContainer>
        {/* Disclaimer Banner */}
        <div className="mb-6 p-4 bg-blue-900/30 border border-blue-500/50 rounded-lg">
          <div className="flex items-center gap-2 text-blue-400">
            <InformationCircleIcon className="w-5 h-5" />
            <p>This is a mockup page showcasing potential battery data visualizations. The data shown is simulated and does not reflect real-world values.</p>
          </div>
        </div>

        <div className="flex items-center gap-4 mb-6">
          <BatteryIcon className="w-8 h-8" state="active" />
          <h1 className="text-4xl font-bold">Battery Data</h1>
        </div>

        <div className="space-y-6">
          {/* Main Battery Status */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {/* Battery Visualization */}
            <div className="bg-gray-800/50 rounded-lg p-6 border border-gray-700 flex flex-col items-center">
              <h2 className="text-lg font-semibold mb-4">Current Status</h2>
              <div className="flex items-center justify-center mb-4">
                <BatteryLevelIndicator charge={animatedCharge} />
              </div>
              <div className="text-center">
                <p className={`text-4xl font-bold ${getBatteryColor(animatedCharge)}`}>
                  {animatedCharge.toFixed(1)}%
                </p>
                <p className="text-gray-400 mt-2">State of Charge</p>
              </div>
            </div>

            {/* Power Flow */}
            <div className="bg-gray-800/50 rounded-lg p-6 border border-gray-700">
              <h2 className="text-lg font-semibold mb-4">Power Flow</h2>
              <div className="space-y-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <BoltIcon className="w-6 h-6 text-green-500" />
                    <span className="text-gray-300">Charging Rate</span>
                  </div>
                  <span className="text-2xl font-bold text-green-500">+2.1 kW</span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <BoltIcon className="w-6 h-6 text-red-500 transform rotate-180" />
                    <span className="text-gray-300">Discharging Rate</span>
                  </div>
                  <span className="text-2xl font-bold text-red-500">-0.4 kW</span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <ChartBarIcon className="w-6 h-6 text-blue-500" />
                    <span className="text-gray-300">Net Power</span>
                  </div>
                  <span className="text-2xl font-bold text-blue-500">+1.7 kW</span>
                </div>
              </div>
            </div>

            {/* Quick Stats */}
            <div className="bg-gray-800/50 rounded-lg p-6 border border-gray-700">
              <h2 className="text-lg font-semibold mb-4">System Health</h2>
              <div className="space-y-4">
                <div>
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-gray-400">Temperature</span>
                    <span className="text-green-500 font-medium">23°C</span>
                  </div>
                  <div className="h-2 bg-gray-700 rounded-full">
                    <div className="h-full bg-green-500 rounded-full" style={{ width: '40%' }} />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-gray-400">Cell Balance</span>
                    <span className="text-green-500 font-medium">98%</span>
                  </div>
                  <div className="h-2 bg-gray-700 rounded-full">
                    <div className="h-full bg-green-500 rounded-full" style={{ width: '98%' }} />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-gray-400">Cycle Count</span>
                    <span className="text-yellow-500 font-medium">342</span>
                  </div>
                  <div className="h-2 bg-gray-700 rounded-full">
                    <div className="h-full bg-yellow-500 rounded-full" style={{ width: '34%' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Time-based Analysis */}
          <div className="bg-gray-800/50 rounded-lg p-6 border border-gray-700">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-lg font-semibold">Charge Analysis</h2>
              <div className="flex gap-2">
                <button
                  onClick={() => setSelectedTimeframe('day')}
                  className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                    selectedTimeframe === 'day' 
                      ? 'bg-green-600 text-white' 
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                >
                  <ClockIcon className="w-5 h-5" />
                  24 Hours
                </button>
                <button
                  onClick={() => setSelectedTimeframe('week')}
                  className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                    selectedTimeframe === 'week' 
                      ? 'bg-green-600 text-white' 
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                >
                  <CalendarDaysIcon className="w-5 h-5" />
                  7 days
                </button>
              </div>
            </div>

            {selectedTimeframe === 'day' ? (
              <div className="relative h-64">
                <div className="absolute inset-0 flex items-end">
                  {hourlyData.map((data, i) => (
                    <div 
                      key={i}
                      className="flex-1 flex flex-col items-center"
                      style={{ height: '100%' }}
                    >
                      <div className="relative flex-1 w-full px-1">
                        <div 
                          className={`absolute bottom-0 left-0 right-0 mx-1 rounded-sm transition-all duration-500 ${
                            data.power > 0 ? 'bg-green-500/50' : 'bg-red-500/50'
                          }`}
                          style={{ 
                            height: `${Math.abs(data.charge)}%`,
                            transform: `scaleY(${Math.abs(data.power / 3)})`
                          }}
                        />
                      </div>
                      <div className="text-xs text-gray-500 mt-2">{data.hour}:00</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-7 gap-4">
                {weeklyData.map((data, i) => (
                  <div key={i} className="text-center">
                    <div className="bg-gray-700/50 rounded-lg p-4">
                      <div className="text-lg font-bold">{data.cycles}</div>
                      <div className="text-sm text-gray-400">cycles</div>
                      <div className="mt-2 text-xs text-green-500">{data.efficiency.toFixed(1)}%</div>
                    </div>
                    <div className="mt-2 text-sm text-gray-400">{data.day}</div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Recommendations */}
          <div className="bg-gray-800/50 rounded-lg p-6 border border-gray-700">
            <h2 className="text-lg font-semibold mb-4">Smart Recommendations</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="bg-green-900/20 rounded-lg p-4 border border-green-700">
                <h3 className="font-medium text-green-400">Optimal Charging Window</h3>
                <p className="text-sm text-gray-300 mt-2">
                  Based on your solar production forecast, optimal charging is recommended between 10:00 - 14:00
                </p>
              </div>
              <div className="bg-blue-900/20 rounded-lg p-4 border border-blue-700">
                <h3 className="font-medium text-blue-400">Maintenance Tip</h3>
                <p className="text-sm text-gray-300 mt-2">
                  Battery health is excellent. Next cell balancing scheduled in 14 days.
                </p>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </ContentFramework>
  );
};

export default BatteryPage; 