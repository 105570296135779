// GQL API for public endpoints

import { MessageContext } from '../../wallets/types';

interface GatewayResponse {
  name: string;
  id: string;
  h3Index: string;
  publicKey: string;
  typeOf: string;
  timeZone?: string;
  ders?: DerInfo[];
}

interface GatewayStatusResponse {
  exists: boolean;
  wallet: boolean;
  location: boolean;
}

// Helper to check if a gateway is hardware type
export const isHardwareGateway = (gateway: GatewayResponse): boolean => {
  return gateway.typeOf === "Hardware";
};

const API_URL = 'https://api.srcful.dev/';
const SOLAR_EDGE_CREATE = "happy-happy-energy";

// Add new interfaces for DER data
export interface DerInfo {
  type: string;
  lastSeen: string;
  sn: string;
}

// Meter data interfaces
export interface MeterData {
  latest: {
    ts: string;
    consumption: number;  // kWh - cumulative energy consumption
    delivery: number;     // kWh - cumulative energy delivered back to grid
    consumptionL1: number;
    consumptionL2: number;
    consumptionL3: number;
  };
  dailyPowerConsumption: Array<{
    ts: string;         // Timestamp for the daily power consumption
    power: number;       // kWh - total energy consumption for the day
  }>;
  powerConsumption: Array<{
    ts: string;
    power: number;       // kW - total current power consumption
    delivery?: number;   // kW - total current power delivery back to grid
    l1: number;          // kW - power on phase L1
    l2: number;          // kW - power on phase L2
    l3: number;          // kW - power on phase L3
  }>;
}

/**
 * Fetches all gateways associated with a wallet address
 * @param walletAddress - The public key of the wallet as string
 * @returns Promise resolving to array of gateway data
 */
export const fetchWalletGateways = async (walletAddress: string): Promise<GatewayResponse[]> => {
  const query = JSON.stringify({
    query: `
      query get_gateways {
        gateway {
          list(wallet:"${walletAddress}", take: 100, showTestMode: true) {
            h3Index
            id
            publicKey
            name
            typeOf
            timeZone
          }
        }
      }
    `,
  });

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('Content-Length', query.length.toString());

  const response = await fetch(API_URL, {
    method: 'POST',
    body: query,
    headers: requestHeaders,
  });

  const json = await response.json();
  if (json.errors) {
    throw new Error(json.errors[0].message);
  }

  return json.data.gateway.list;
};

/**
 * Creates a WebSocket subscription for gateway configuration changes
 * @param gatewayId - The ID of the gateway to subscribe to
 * @param signedMessage - The MessageContext containing the signed message and optional delegation token
 * @returns The subscription query string
 */
export const createGatewaySubscriptionQuery = (
  gatewayId: string,
  signedMessage: MessageContext
): string => {
  const authParams = {
    message: signedMessage.signedMessage.message,
    signature: signedMessage.signedMessage.signature,
    serial: gatewayId,
    subKey: "state",
    ...(signedMessage.authToken && { delegationToken: signedMessage.authToken })
  };

  return `
    subscription {
      configurationDataChanges(walletAuth: {
        message: "${authParams.message}"
        signature: "${authParams.signature}"
        serial: "${authParams.serial}"
        subKey: "${authParams.subKey}"
        ${signedMessage.authToken ? `delegationToken: "${authParams.delegationToken}"` : ''}
      }) {
        data
        subKey
      }
    }
  `;
};

/**
 * Fetches DER information for a specific gateway
 * @param gatewayId - The ID of the gateway to fetch DER info for
 * @returns Promise resolving to array of DER information
 */
export const fetchGatewayDers = async (gatewayId: string): Promise<DerInfo[]> => {
  const query = JSON.stringify({
    query: `
      query ders {
        gateway {
          gateway(id:"${gatewayId}") {
            ders {
              type
              lastSeen
              sn
            }
          }
        }
      }
    `,
  });

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('Content-Length', query.length.toString());

  const response = await fetch(API_URL, {
    method: 'POST',
    body: query,
    headers: requestHeaders,
  });

  const json = await response.json();
  if (json.errors) {
    throw new Error(json.errors[0].message);
  }

  return json.data.gateway.gateway.ders;
};

/**
 * Creates a new cloud gateway with SolarEdge credentials
 * @param walletAddress - The public key of the wallet as string
 * @param siteId - The SolarEdge site ID
 * @param apiKey - The user's SolarEdge API key
 * @param latitude - The gateway's latitude
 * @param longitude - The gateway's longitude
 * @returns Promise resolving to the created gateway's ID
 */
export const createSolarEdgeCloudGateway = async (
  walletAddress: string,
  siteId: string,
  apiKey: string,
  latitude: string,
  longitude: string
): Promise<string> => {
  const query = JSON.stringify({
    query: `
      mutation {
        gatewayInception {
          registerSolarEdgeApiGateway(
            ownerWallet: "${walletAddress}"
            auth: { apiKey: "${SOLAR_EDGE_CREATE}" }
            longitude: ${parseFloat(longitude)}
            latitude: ${parseFloat(latitude)}
            siteId: "${siteId}"
            solarEdgeApiKey: "${apiKey}"
          ) {
            id
          }
        }
      }
    `
  });

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('Content-Length', query.length.toString());

  const response = await fetch(API_URL, {
    method: 'POST',
    body: query,
    headers: requestHeaders,
  });

  const json = await response.json();
  if (json.errors) {
    throw new Error(json.errors[0].message);
  }

  return json.data.gatewayInception.registerSolarEdgeApiGateway.id;
};

/**
 * Fetches status information for a specific gateway
 * @param gatewayId - The ID of the gateway to fetch status for
 * @returns Promise resolving to gateway status information
 */
export const fetchGatewayStatus = async (gatewayId: string): Promise<GatewayStatusResponse> => {
  const query = JSON.stringify({
    query: `
      query gw_status {
        gateway {
          status(gwId:"${gatewayId}") {
            exists
            wallet
            location
          }
        }
      }
    `,
  });

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('Content-Length', query.length.toString());

  const response = await fetch(API_URL, {
    method: 'POST',
    body: query,
    headers: requestHeaders,
  });

  const json = await response.json();
  if (json.errors) {
    throw new Error(json.errors[0].message);
  }

  return json.data.gateway.status;
};

/**
 * Fetches real-time meter data for a specific meter
 * @param serialNumber - The serial number of the meter
 * @returns Promise resolving to meter data including current consumption and phase information
 */
export const fetchMeterData = async (serialNumber: string): Promise<MeterData> => {
  const query = JSON.stringify({
    query: `
      query energy_meter_data {
        derData {
          energyMeter(sn: "${serialNumber}") {
            latest {
              ts
              consumption
              delivery
              consumptionL1
              consumptionL2
              consumptionL3
            }
            dailyPowerConsumption {
              ts
              power
            }
            powerConsumption {
              ts
              power
              l1
              l2
              l3
            }
          }
        }
      }
    `,
  });

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('Content-Length', query.length.toString());

  const response = await fetch(API_URL, {
    method: 'POST',
    body: query,
    headers: requestHeaders,
  });

  const json = await response.json();
  if (json.errors) {
    throw new Error(json.errors[0].message);
  }

  return json.data.derData.energyMeter;
};
