import { FC } from 'react';
import InfoRow from "../InfoRow";
import { FaSpinner } from "react-icons/fa";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";

export interface DeviceInfo {
  serialNumber: string;
  publicKey: string;
}

interface DeviceInfoStepProps {
  deviceInfo: DeviceInfo;
  isLoading: boolean;
}

const DeviceInfoStep: FC<DeviceInfoStepProps> = ({ 
  deviceInfo, 
  isLoading
}) => {
  const handleCopyInfo = () => {
    const infoText = `Gateway ID: ${deviceInfo.serialNumber}\nGateway Public Key: ${deviceInfo.publicKey}`;
    navigator.clipboard.writeText(infoText)
      .then(() => toast.success('Copied gateway information to clipboard'))
      .catch(() => toast.error('Failed to copy to clipboard'));
  };

  if (isLoading) {
    return (
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-white">Gateway Information</h2>
        <div className="flex justify-center items-center py-8">
          <FaSpinner className="animate-spin text-4xl text-blue-500" />
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white">Gateway Information</h2>
      
      <div className="space-y-4 text-gray-300">
        <p>
          Please verify that this is the correct gateway device you want to use for wallet recovery.
          The information below should match your gateway.
        </p>
        
        <div className="bg-blue-500/10 p-4 rounded-lg border border-blue-500/20">
          <div className="break-all">
            <InfoRow
              label="Gateway ID"
              tooltip="Unique identifier for your gateway"
              value={deviceInfo.serialNumber}
              isCopyable
            />
            <InfoRow
              label="Public Key"
              tooltip="Public key associated with this gateway"
              value={deviceInfo.publicKey}
              displayValue={`${deviceInfo.publicKey.slice(0, 8)}...${deviceInfo.publicKey.slice(-8)}`}
              isCopyable
            />
          </div>
          
          <div className="mt-4">
            <button
              onClick={handleCopyInfo}
              className="flex items-center gap-2 px-3 py-2 text-sm rounded-lg bg-blue-800/50 hover:bg-blue-800/70 border border-blue-700/50 transition-colors"
            >
              <DocumentDuplicateIcon className="h-4 w-4" />
              Copy Gateway Information
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceInfoStep; 