import { FC, useState, useEffect, useMemo } from 'react';
import { useRouter } from "@tanstack/react-router";
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { MeterIcon, GatewayIcon, CloudGatewayIcon } from '../../components/icons/icons';
import { useGateways } from '../../context/GatewayContext';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { PowerMeter } from '../../components/PowerMeter';
import { Tooltip } from '../../components/Tooltip';
import { isHardwareGateway } from '../../context/GatewayContext';

interface MeterSettings {
  voltage: 110 | 230;
  fuseSize: number; // in Amperes
}

const FUSE_SIZE_OPTIONS = [6, 10, 13, 16, 20, 25, 32, 40, 50, 63] as const;
const DEFAULT_SETTINGS: MeterSettings = {
  voltage: 230,
  fuseSize: 25
};

const MeterPage: FC = () => {
  const { gateways } = useGateways();
  const router = useRouter();
  const [settings, setSettings] = useState<MeterSettings>(() => {
    const savedSettings = localStorage.getItem('meterSettings');
    return savedSettings ? JSON.parse(savedSettings) : DEFAULT_SETTINGS;
  });

  // Calculate maximum power based on settings (using line-to-neutral voltage)
  const maxPowerKw = (3 * settings.voltage * settings.fuseSize) / 1000;

  // Save settings to localStorage when they change
  useEffect(() => {
    localStorage.setItem('meterSettings', JSON.stringify(settings));
  }, [settings]);

  // Filter and process meters from all gateways
  const meters = useMemo(() => 
    gateways?.flatMap(gateway => 
      (gateway.ders || [])
        .filter(der => der.type.toLowerCase().includes('meter'))
        .map(der => ({
          ...der,
          gatewayName: gateway.name,
          gatewayId: gateway.id
        }))
    ) || [], [gateways]
  );

  // Helper function to safely format numbers
  const formatNumber = (value: number | undefined | null, decimals: number = 2) => {
    if (value === undefined || value === null) return '0.000';
    return value.toFixed(decimals);
  };

  // Add function to get gateway state
  const getGatewayState = (gatewayId: string) => {
    const gateway = gateways?.find(g => g.id === gatewayId);
    if (!gateway || !gateway.state?.timestamp) return 'unknown';
    
    const now = Date.now();
    const diffMinutes = (now - gateway.state.timestamp) / 1000 / 60;
    
    if (diffMinutes < 10) return 'active';
    if (diffMinutes > 60) return 'inactive';
    return 'disabled';
  };

  return (
    <div className="container mx-auto px-1 sm:px-4 py-4 sm:py-8">
      <div className="mb-4 sm:mb-8">
        <div className="flex items-center gap-4 mb-2 sm:mb-4">
          <button
            onClick={() => router.navigate({ to: '/' })}
            className="p-2 rounded-full hover:bg-gray-800 transition-colors"
          >
            <ArrowLeftIcon className="w-6 h-6 text-gray-400" />
          </button>
          <h1 className="text-3xl font-bold text-white">Meter Data</h1>
        </div>
      </div>

      {/* Settings Section */}
      <div className="bg-glass rounded-xl p-2 sm:p-6 mb-4 sm:mb-8">
        <h2 className="text-xl font-medium text-white mb-6">Installation Settings</h2>
        <div className="flex flex-col sm:flex-row gap-6 sm:items-center">
          <div className="flex items-center gap-3">
            <label className="text-gray-400 min-w-[60px]">Voltage:</label>
            <select
              className="bg-gray-800/50 border border-gray-700 rounded-lg px-4 py-2 text-gray-200"
              value={settings.voltage}
              onChange={(e) => setSettings(prev => ({ ...prev, voltage: parseInt(e.target.value) as 110 | 230 }))}
            >
              <option value="110">110V</option>
              <option value="230">230V</option>
            </select>
            <Tooltip text="Grid voltage:\n110V - US/Japan\n230V - EU/UK">
              <InformationCircleIcon className="w-5 h-5 text-gray-400 cursor-help" />
            </Tooltip>
          </div>
          <div className="flex items-center gap-3">
            <label className="text-gray-400 min-w-[60px]">Fuse Size:</label>
            <select
              className="bg-gray-800/50 border border-gray-700 rounded-lg px-4 py-2 text-gray-200"
              value={settings.fuseSize}
              onChange={(e) => setSettings(prev => ({ ...prev, fuseSize: parseInt(e.target.value) }))}
            >
              {FUSE_SIZE_OPTIONS.map(size => (
                <option key={size} value={size}>{size}A</option>
              ))}
            </select>
            <Tooltip text="Main circuit breaker/fuse size\nof your electrical installation">
              <InformationCircleIcon className="w-5 h-5 text-gray-400 cursor-help" />
            </Tooltip>
          </div>
          <div className="flex items-center gap-3">
            <span className="text-gray-400 min-w-[60px]">Max Power:</span>
            <span className="font-medium text-[#0BE898]">{formatNumber(maxPowerKw, 1)} kW</span>
            <Tooltip text="Maximum power capacity based on\nyour voltage and fuse settings">
              <InformationCircleIcon className="w-5 h-5 text-gray-400 cursor-help" />
            </Tooltip>
          </div>
        </div>
      </div>

      {/* Connected Meters List */}
      <div className="space-y-4 sm:space-y-8">
        {meters.length > 0 ? (
          meters
            .sort((a, b) => new Date(b.lastSeen).getTime() - new Date(a.lastSeen).getTime())
            .map((meter) => {
              const gateway = gateways?.find(g => g.id === meter.gatewayId);
              return (
                <div key={meter.sn} className="space-y-4">
                  <div className="flex items-center gap-4">
                    {gateway && (isHardwareGateway(gateway) ? (
                      <GatewayIcon state={getGatewayState(meter.gatewayId)} className="w-6 h-6" />
                    ) : (
                      <CloudGatewayIcon state={getGatewayState(meter.gatewayId)} className="w-6 h-6" />
                    ))}
                    <h2 className="text-2xl font-medium text-white">{meter.gatewayName}</h2>
                  </div>
                  <PowerMeter
                    lastSeen={meter.lastSeen}
                    sn={meter.sn}
                    gatewayName={meter.gatewayName}
                    gatewayId={meter.gatewayId}
                    maxPowerKw={maxPowerKw}
                  />
                </div>
              );
            })
        ) : (
          <div className="bg-glass rounded-xl p-12 text-center">
            <MeterIcon className="w-12 h-12 mx-auto text-gray-400" state="none" />
            <h3 className="mt-4 text-lg font-medium text-gray-300">No Energy Meters Found</h3>
            <p className="mt-2 text-gray-400">No energy meters are currently connected to any of your gateways.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MeterPage; 