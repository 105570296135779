import { FC, useState, useRef, KeyboardEvent } from 'react';
import { ActionButton } from '../ui/ActionButton';
import { toast } from 'react-hot-toast';
import * as ble from "../../services/ble/";

export interface RecoveryInfo {
  recoveredPrivateKey: string;
  signedMessage?: {
    message: string;
    sign: string;
  };
}

interface AccessKeyStepProps {
  onSubmit: (accessKey: string, deviceMessage: string, signature: string) => Promise<void>;
  isSubmitting: boolean;
  bleApi: any;
}

const AccessKeyStep: FC<AccessKeyStepProps> = ({ onSubmit, isSubmitting, bleApi }) => {
  const [keySections, setKeySections] = useState<string[]>(['', '', '', '']);
  const [signedData, setSignedData] = useState<{ message: string; sign: string } | null>(null);
  const [isSigning, setIsSigning] = useState(false);
  
  const sectionRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null)
  ];

  const handleSectionChange = (index: number, value: string) => {
    // Convert to uppercase and filter non-alphanumeric characters
    value = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    
    // Limit to 4 characters
    value = value.slice(0, 4);
    
    // Update state
    const newKeySections = [...keySections];
    newKeySections[index] = value;
    setKeySections(newKeySections);
    
    // Auto-advance to next field if this one is full
    if (value.length === 4 && index < 3) {
      sectionRefs[index + 1].current?.focus();
    }
  };

  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    // Handle backspace when field is empty to go to previous field
    if (e.key === 'Backspace' && keySections[index] === '' && index > 0) {
      sectionRefs[index - 1].current?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    
    // Get pasted content and clean it
    const pastedContent = e.clipboardData.getData('text');
    const cleaned = pastedContent.replace(/[^A-Z0-9-]/gi, '');
    
    // Try to parse as sections separated by dashes
    const sections = cleaned.split('-').map(section => section.toUpperCase());
    
    if (sections.length === 4) {
      // Fill in all sections if we got the right number
      setKeySections(sections.map(s => s.slice(0, 4)));
    } else {
      // If not clearly formatted, just fill in as much as we can sequentially
      const chars = cleaned.toUpperCase();
      const newSections = ['', '', '', ''];
      
      for (let i = 0; i < 4; i++) {
        newSections[i] = chars.slice(i * 4, i * 4 + 4);
      }
      
      setKeySections(newSections);
    }
  };

  const handleSubmit = async () => {
    const fullKey = keySections.join('-');
    
    // Validate input (should be 4 sections of 4 characters each)
    if (keySections.some(section => section.length !== 4)) {
      toast.error('Please enter all 4 sections with 4 characters each');
      return;
    }

    // Validate that signed message data exists
    if (!signedData) {
      toast.error('Please sign a message with the gateway first');
      return;
    }
    
    // Submit the access key with signed message data
    try {
      await onSubmit(fullKey, signedData.message, signedData.sign);
    } catch (error) {
      console.error('Error recovering wallet:', error);
      toast.error('Failed to recover wallet. Please check your access key and try again.');
    }
  };

  const handleSignMessage = async () => {
    if (!bleApi) {
      toast.error('Bluetooth connection not available');
      return;
    }

    setIsSigning(true);
    try {
      // Generate timestamp in UTC format: Y-m-dTH:M:SZ
      const now = new Date();
      const timestamp = now.toISOString().replace(/\.\d+Z$/, 'Z'); // Remove milliseconds
      
      // Call the api/crypto/sign endpoint with a timestamp parameter
      const response = await bleApi.fetch(
        `${ble.API_CRYPTO}/sign`,
        ble.Method.POST,
        {
          timestamp: timestamp
        }
      );

      if (response.payload && response.payload.message && response.payload.sign) {
        setSignedData({
          message: response.payload.message,
          sign: response.payload.sign
        });
        toast.success('Message signed successfully');
      } else {
        throw new Error('Invalid response from signing endpoint');
      }
    } catch (error) {
      console.error('Error signing message:', error);
      toast.error('Failed to sign message with gateway');
    } finally {
      setIsSigning(false);
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white">Enter Recovery Access Key</h2>
      
      <div className="space-y-4 text-gray-300">
        <p>
          Please enter your wallet recovery access key. This key consists of 4 sets of 4 uppercase
          characters and numbers, separated by dashes (e.g., LTQW-JL9B-XJHL-WK5D).
        </p>
        
        <div className="bg-blue-500/10 p-4 rounded-lg border border-blue-500/20">
          <p className="text-sm mb-3">
            Your recovery access key was provided when you created your walletless account.
            If you don't have your recovery access key, please contact support.
          </p>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">Sign Message with Gateway</h3>
          <p className="text-sm mb-3">
            You can verify gateway ownership by requesting a signed message from the gateway.
            This proves the gateway has valid cryptographic credentials.
          </p>
          <ActionButton
            onClick={handleSignMessage}
            disabled={isSigning}
            isLoading={isSigning}
            loadingText="Signing Message..."
            variant="secondary"
            className="w-full py-3 mb-4"
          >
            Sign Message with Gateway
          </ActionButton>

          {signedData && (
            <div className="bg-gray-800 border border-gray-700 rounded-lg p-4 mt-3">
              <h4 className="text-md font-medium text-gray-200 mb-2">Signed Message Result:</h4>
              <div className="space-y-3">
                <div>
                  <p className="text-sm text-gray-400 mb-1">Message:</p>
                  <p className="text-sm font-mono break-all bg-gray-900 p-2 rounded">
                    {signedData.message}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-400 mb-1">Signature:</p>
                  <p className="text-sm font-mono break-all bg-gray-900 p-2 rounded">
                    {signedData.sign}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-400 mb-2">
            Recovery Access Key
          </label>
          <div className="flex space-x-2" onPaste={handlePaste}>
            {keySections.map((section, index) => (
              <div key={index} className="flex-1">
                <input
                  ref={sectionRefs[index]}
                  type="text"
                  value={section}
                  onChange={(e) => handleSectionChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  className="w-full bg-gray-800 border border-gray-600 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-center uppercase"
                  placeholder="XXXX"
                  maxLength={4}
                  autoComplete="off"
                />
                {index < 3 && (
                  <div className="flex justify-center mt-2 text-gray-500">-</div>
                )}
              </div>
            ))}
          </div>
        </div>
        
        <div className="mt-6">
          <ActionButton
            onClick={handleSubmit}
            disabled={!signedData || keySections.some(section => section.length !== 4) || isSubmitting}
            isLoading={isSubmitting}
            loadingText="Recovering Wallet..."
            variant="primary"
            className="w-full py-3"
          >
            Recover Wallet
          </ActionButton>
        </div>
      </div>
    </div>
  );
};

export default AccessKeyStep; 