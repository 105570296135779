import { useGateways } from "../context/GatewayContext";
import ContentFramework from "../components/ContentFramework";
import { useWalletContext } from "../context/WalletContext";
import { useParams } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon, TrashIcon, ArrowPathIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import SrcfulMessageProtocolFactory from '../services/backend/SrcfulMessageProtocolFactory';
import { motion, AnimatePresence } from 'framer-motion';
import FormGatewayInverter from '../components/form/FormGatewayInverter';
import GatewayPageHeader from '../components/GatewayPageHeader';
import { handleSubmitFlow } from "../components/utils/gatewayUtils";
import { ActionButton } from "../components/ui/ActionButton";
import PageContainer from "../components/layout/PageContainer";
import { MeterIcon, SolarIcon } from '../components/icons/icons';

interface ConfiguredDevice {
  is_open: boolean;
  id: string;
  name: string;
  client_name: string;
  connection: {
    ip: string;
    port: number;
    connection: string;
    meter_serial_number?: string;
    mac?: string;
    username?: string;
    password?: string;
    iq_gw_serial?: string;
  };
}

interface ExpandableBoxProps {
  title: string;
  children: React.ReactNode;
}

const ExpandableBox: React.FC<ExpandableBoxProps> = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-glass rounded-xl p-4 mt-6">
      <button
        className="w-full flex justify-between items-center"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h2 className="text-base font-bold leading-7">{title}</h2>
        {isExpanded ? (
          <ChevronUpIcon className="h-5 w-5" />
        ) : (
          <ChevronDownIcon className="h-5 w-5" />
        )}
      </button>
      {isExpanded && <div className="mt-4">{children}</div>}
    </div>
  );
};

interface P1MeterFormData {
  type: 'jemac' | 'telnet';
  ipAddress: string;
  port: string;
}

const P1MeterForm = () => {
  const { publicKey, wallet } = useWalletContext();
  const { activeGateway } = useGateways();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pendingDeviceConfig, setPendingDeviceConfig] = useState<null | { ip: string; connection: string }>(null);
  const [formData, setFormData] = useState<P1MeterFormData>({
    type: 'jemac',
    ipAddress: '',
    port: '23'
  });

  // Watch for the device to appear in the gateway state
  useEffect(() => {
    if (!pendingDeviceConfig || !activeGateway?.state?.devices?.configured) return;

    const deviceExists = activeGateway.state.devices.configured.some(
      (device: ConfiguredDevice) => 
        device.connection.ip === pendingDeviceConfig.ip && 
        device.connection.connection === pendingDeviceConfig.connection
    );

    if (deviceExists) {
      setIsSubmitting(false);
      setPendingDeviceConfig(null);
      toast.success('P1 Meter successfully configured and connected');
      // Reset form
      setFormData({
        type: 'jemac',
        ipAddress: '',
        port: '23'
      });
    }
  }, [activeGateway?.state?.devices?.configured, pendingDeviceConfig]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!publicKey || !activeGateway || !wallet) return;
    setIsSubmitting(true);

    const deviceConfig = {
      ip: formData.ipAddress,
      port: formData.type === 'jemac' ? 80 : parseInt(formData.port),
      connection: formData.type === 'jemac' ? 'P1Jemac' : 'P1Telnet'
    };

    const message = SrcfulMessageProtocolFactory.createDeviceConfigMessage({
      walletPublicKey: publicKey.toString(),
      gatewayId: activeGateway.id,
      deviceConfig,
      deviceDisplayName: `P1 Meter (${formData.type})`
    });


    await handleSubmitFlow(message, wallet, () => {
      // Store the pending device config to watch for it in the gateway state
      setPendingDeviceConfig({
        ip: deviceConfig.ip,
        connection: deviceConfig.connection
      });
    }, () => {
      toast.error('Failed to connect P1 Meter');
      setIsSubmitting(false);
    });

  };

  // Add a timeout to reset the form if device doesn't appear after 30 seconds
  useEffect(() => {
    if (!pendingDeviceConfig) return;

    const timeout = setTimeout(() => {
      if (isSubmitting) {
        setIsSubmitting(false);
        setPendingDeviceConfig(null);
        toast.error('Device configuration timed out. Please try again.');
      }
    }, 30000);

    return () => clearTimeout(timeout);
  }, [pendingDeviceConfig, isSubmitting]);

  const isFormValid = () => {
    // Check if IP address is not empty
    if (!formData.ipAddress.trim()) return false;
    
    // For telnet type, check if port is not empty and is a valid number
    if (formData.type === 'telnet') {
      const portNum = parseInt(formData.port);
      if (!formData.port || isNaN(portNum) || portNum < 1 || portNum > 65535) {
        return false;
      }
    }
    
    return true;
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium mb-2">Meter Type</label>
        <select
          value={formData.type}
          onChange={(e) => setFormData(prev => ({ 
            ...prev, 
            type: e.target.value as 'jemac' | 'telnet',
            // Reset port to default when switching types
            port: e.target.value === 'telnet' ? '23' : '80'
          }))}
          className="w-full bg-gray-700 rounded-md border border-gray-600 px-3 py-2 text-sm"
          disabled={isSubmitting}
        >
          <option value="jemac">Jemac P1 Dongle</option>
          <option value="telnet">Telnet Compatible Dongle</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium mb-2">IP Address</label>
        <input
          type="text"
          value={formData.ipAddress}
          onChange={(e) => setFormData(prev => ({ ...prev, ipAddress: e.target.value }))}
          placeholder="192.168.1.100"
          className="w-full bg-gray-700 rounded-md border border-gray-600 px-3 py-2 text-sm"
          disabled={isSubmitting}
        />
      </div>

      {formData.type === 'telnet' && (
        <div>
          <label className="block text-sm font-medium mb-2">
            Port (default port for telnet is 23)
          </label>
          <input
            type="text"
            value={formData.port}
            onChange={(e) => setFormData(prev => ({ ...prev, port: e.target.value }))}
            placeholder="23"
            className="w-full bg-gray-700 rounded-md border border-gray-600 px-3 py-2 text-sm"
            disabled={isSubmitting}
          />
        </div>
      )}

      <div className="flex justify-end">
        <ActionButton
          type="submit"
          isLoading={isSubmitting}
          loadingText="Configuring..."
          disabled={isSubmitting || !isFormValid()}
        >
          Add P1 Meter
        </ActionButton>
      </div>
    </form>
  );
};

interface DeletingDevices {
  [key: string]: {
    timeoutId: NodeJS.Timeout;
  };
}

interface ConnectingDevices {
  [key: string]: {
    timeoutId: NodeJS.Timeout;
    startTime: number;
  };
}

const AdvancedSection = ({ state }: { state: Record<string, any> }) => {
  return (
    <div className="space-y-4">
      <p className="text-sm text-gray-400 mb-4">
        ⚠️ These are advanced configuration options. Most users won't need these settings.
      </p>
      
      <ExpandableBox title="Add P1 Meter">
        <P1MeterForm />
      </ExpandableBox>

      <ExpandableBox title="Add Inverter">
        <FormGatewayInverter />
      </ExpandableBox>
    </div>
  );
};

const formatClientName = (clientName: string) => {
  if (!clientName) return 'Unknown Device';
  
  // Split the name into parts
  const parts = clientName.split('.');
  
  // Capitalize each part
  const formattedParts = parts.map(part => 
    part.charAt(0).toUpperCase() + part.slice(1)
  );
  
  // skip the first part and concatenate the parts with a space
  return formattedParts.slice(1).join(' ');
};

const DeviceCard = ({ 
  device, 
  onDelete,
  isDeleting 
}: { 
  device: ConfiguredDevice;
  onDelete: (device: ConfiguredDevice) => void;
  isDeleting: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <motion.div 
      key={device.id}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.2 }}
      className={`border border-gray-700 rounded-lg bg-gray-800/50 relative overflow-hidden
        ${isDeleting ? 'opacity-50 pointer-events-none' : ''}`}
    >
      {/* Header - Always Visible */}
      <div 
        className="flex justify-between items-center cursor-pointer p-4 hover:bg-gray-700/30 transition-colors"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-4">
          <motion.div 
            className="flex items-center"
            initial={false}
            animate={{ scale: device.is_open ? 1 : 1 }}
          >
            {device.client_name.toLowerCase().includes('meter') ? (
              <MeterIcon state={device.is_open ? 'active' : 'inactive'} className="w-6 h-6" />
            ) : (
              <SolarIcon state={device.is_open ? 'active' : 'inactive'} className="w-7 h-7" />
            )}
            <span className="ml-2 text-base font-medium">
              {formatClientName(device.client_name) || device.name}
            </span>
          </motion.div>
        </div>
        <div className="flex items-center space-x-2">
          {isExpanded ? (
            <ChevronUpIcon className="h-5 w-5 text-gray-400" />
          ) : (
            <ChevronDownIcon className="h-5 w-5 text-gray-400" />
          )}
        </div>
      </div>

      {/* Expanded Content */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="overflow-hidden"
          >
            <div className="px-4 pb-4 border-t border-gray-700 bg-gray-800/30">
              {/* Connection Details */}
              <div className="space-y-2 pt-4">
                {Object.entries(device.connection).map(([key, value]) => (
                  <p key={key} className="text-sm text-gray-400">
                    <span className="font-medium">{key}:</span> {value}
                  </p>
                ))}
              </div>

              {/* Actions */}
              <div className="mt-4 flex justify-end">
                <ActionButton
                  variant="destructive"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(device);
                  }}
                  isLoading={isDeleting}
                  loadingText="Disconnecting..."
                  icon={<TrashIcon className="h-4 w-4" />}
                >
                  Disconnect
                </ActionButton>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Loading Overlay */}
      {isDeleting && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute inset-0 flex items-center justify-center bg-gray-900/20 rounded-lg"
        >
          <ArrowPathIcon className="h-8 w-8 animate-spin text-blue-500" />
        </motion.div>
      )}
    </motion.div>
  );
};

interface DeviceFormData {
  [deviceId: string]: {
    [key: string]: string;
  };
}

interface AvailableDeviceCardProps {
  device: ConfiguredDevice;
  onConnect: (device: ConfiguredDevice) => void;
  isConnecting: boolean;
  formData?: { [key: string]: string };
  onUpdateFormData: (data: { [key: string]: string }) => void;
}

const AvailableDeviceCard: React.FC<AvailableDeviceCardProps> = ({ 
  device, 
  onConnect,
  isConnecting,
  formData,
  onUpdateFormData
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [visiblePasswords, setVisiblePasswords] = useState<{ [key: string]: boolean }>({});
  
  // Helper function to determine field order
  const getFieldOrder = (fieldName: string): number => {
    const orderPriority: { [key: string]: number } = {
      'username': 1,
      'user': 2,
      'password': 10,
      'serial': 20,
      'iq_gw_serial': 21,
    };

    const matchingKey = Object.keys(orderPriority).find(key => 
      fieldName.toLowerCase().includes(key.toLowerCase())
    );

    return matchingKey ? orderPriority[matchingKey] : 100;
  };

  // Sort fields by priority with correct typing
  const sortFields = (fields: [string, string | number][]): [string, string | number][] => {
    return [...fields].sort((a, b) => {
      const orderA = getFieldOrder(a[0]);
      const orderB = getFieldOrder(b[0]);
      return orderA - orderB;
    });
  };

  useEffect(() => {
    if (!formData) {
      const emptyFields = Object.entries(device.connection)
        .filter(([_, value]) => value === '')
        .reduce((acc, [key]) => ({
          ...acc,
          [key]: ''
        }), {});
      
      onUpdateFormData(emptyFields);
    }
  }, [device.id, device.connection, formData, onUpdateFormData]);

  const needsConfiguration = Object.values(device.connection).some(value => value === '');

  const handleConnect = (e: React.MouseEvent) => {
    e.stopPropagation();
    const updatedDevice = {
      ...device,
      connection: {
        ...device.connection,
        ...(formData || {})
      }
    };
    onConnect(updatedDevice);
  };

  if (!formData) return null;

  return (
    <motion.div 
      key={device.id}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.2 }}
      className={`border border-gray-700 rounded-lg bg-gray-800/50 relative overflow-hidden
        ${isConnecting ? 'opacity-50 pointer-events-none' : ''}`}
    >
      {/* Header - Always Visible */}
      <div 
        className="flex justify-between items-center cursor-pointer p-4 hover:bg-gray-700/30 transition-colors"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-4">
          {device.client_name && (
            <span className="mr-2">
              {device.client_name.toLowerCase().includes('meter') ? (
                <MeterIcon state={device.is_open ? 'active' : 'inactive'} className="w-6 h-6" />
              ) : (
                <SolarIcon state={device.is_open ? 'active' : 'inactive'} className="w-6 h-6" />
              )}
            </span>
          )}
          <span className="text-base font-medium">
            {formatClientName(device.client_name) || device.name || 'Available Device'}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          {isExpanded ? (
            <ChevronUpIcon className="h-5 w-5 text-gray-400" />
          ) : (
            <ChevronDownIcon className="h-5 w-5 text-gray-400" />
          )}
        </div>
      </div>

      {/* Expanded Content */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="overflow-hidden"
          >
            <div className="px-4 pb-4 border-t border-gray-700 bg-gray-800/30">
              {/* Connection Details */}
              <div className="space-y-2 pt-4">
                {Object.entries(device.connection)
                  .filter(([_, value]) => value !== '')
                  .map(([key, value]) => (
                    <p key={key} className="text-sm text-gray-400">
                      <span className="font-medium">{key}:</span> {value}
                    </p>
                  ))}
              </div>

              {/* Configuration Form - Dynamic Fields */}
              {needsConfiguration && (
                <div className="mt-4 space-y-3">
                  {sortFields(
                    Object.entries(device.connection)
                      .filter(([_, value]) => value === '')
                  ).map(([key]) => {
                    const isPassword = key.toLowerCase().includes('password');
                    return (
                      <div key={key} className="relative">
                        <input
                          type={isPassword && !visiblePasswords[key] ? 'password' : 'text'}
                          value={formData[key] || ''}
                          onChange={(e) => onUpdateFormData({ ...formData, [key]: e.target.value })}
                          placeholder={key.split('_').map(word => 
                            word.charAt(0).toUpperCase() + word.slice(1)
                          ).join(' ')}
                          className={`w-full bg-gray-700 rounded-md border border-gray-600 px-3 py-2 text-sm
                            ${isPassword ? 'pr-10' : ''}`}
                          disabled={isConnecting}
                        />
                        {isPassword && (
                          <button
                            type="button"
                            onClick={() => setVisiblePasswords(prev => ({
                              ...prev,
                              [key]: !prev[key]
                            }))}
                            className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-400 hover:text-gray-300"
                          >
                            {visiblePasswords[key] ? (
                              <EyeSlashIcon className="h-4 w-4" />
                            ) : (
                              <EyeIcon className="h-4 w-4" />
                            )}
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}

              {/* Actions */}
              <div className="mt-4 flex justify-end">
                <ActionButton
                  isLoading={isConnecting}
                  loadingText="Connecting..."
                  variant="primary"
                  onClick={handleConnect}
                  disabled={isConnecting || (needsConfiguration && Object.values(formData).some(value => !value))}
                >
                  Connect
                </ActionButton>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const GatewayDevices = () => {
  const { isConnected, publicKey, wallet } = useWalletContext();
  const { gateways, activeGateway, setActiveGateway } = useGateways();
  const { gatewayId } = useParams({ from: '/gateway/$gatewayId/devices' });
  const [deletingDevices, setDeletingDevices] = useState<DeletingDevices>({});
  const [connectingDevices, setConnectingDevices] = useState<ConnectingDevices>({});
  const [isScanning, setIsScanning] = useState(false);
  const [deviceFormData, setDeviceFormData] = useState<DeviceFormData>({});

  useEffect(() => {
    if (gatewayId && gateways) {
      const gateway = gateways.find(g => g.id === gatewayId);
      if (gateway) {
        setActiveGateway(gateway);
      }
    }
  }, [gatewayId, gateways, setActiveGateway]);

  // Watch for device removal from state
  useEffect(() => {
    if (!activeGateway?.state?.devices?.configured) return;
    
    // Check each deleting device to see if it's been removed
    Object.entries(deletingDevices).forEach(([deviceId, { timeoutId }]) => {
      const configuredDevices = activeGateway?.state?.devices?.configured || [];
      const deviceStillExists = configuredDevices.some(
        (device: ConfiguredDevice) => device.id === deviceId
      );
      
      if (!deviceStillExists) {
        // Device was successfully removed
        clearTimeout(timeoutId);
        setDeletingDevices(prev => {
          const newState = { ...prev };
          delete newState[deviceId];
          return newState;
        });
        toast.success('Device successfully disconnected');
      }
    });
  }, [activeGateway?.state?.devices?.configured, deletingDevices]);

  const handleDeleteDevice = async (device: ConfiguredDevice) => {
    if (!publicKey || !activeGateway || !wallet) return;

    const deviceId = device.id;
    if (!deviceId) {
      toast.error('Device ID not found');
      return;
    }

    const timeoutId = setTimeout(() => {
      // Only show timeout error if device is still in deleting state
      if (deletingDevices[deviceId]) {
        toast.error('Device disconnect timed out');
        setDeletingDevices(prev => {
          const newState = { ...prev };
          delete newState[deviceId];
          return newState;
        });
      }
    }, 30000);

    setDeletingDevices(prev => ({
      ...prev,
      [deviceId]: { timeoutId }
    }));

    const message = SrcfulMessageProtocolFactory.deleteDeviceConfigMessage({
      walletPublicKey: publicKey.toString(),
      gatewayId: activeGateway.id,
      deviceId,
      deviceDisplayName: device.connection.connection
    });

    await handleSubmitFlow(message, wallet, () => {
      toast.success('Device disconnecting...');
    }, () => {
      toast.error('Failed to disconnect device');
      clearTimeout(deletingDevices[deviceId]?.timeoutId);
      setDeletingDevices(prev => {
        const newState = { ...prev };
        delete newState[deviceId];
        return newState;
      });
    });

  };

  useEffect(() => {
    return () => {
      Object.values(deletingDevices).forEach(({ timeoutId }) => {
        clearTimeout(timeoutId);
      });
    };
  }, [deletingDevices]);

  // Check for timeouts every second
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      Object.entries(connectingDevices).forEach(([deviceId, { startTime }]) => {
        if (now - startTime > 60000) { // 60 seconds
          // Connection has timed out
          setConnectingDevices(prev => {
            const newState = { ...prev };
            delete newState[deviceId];
            return newState;
          });
          toast.error('Connection timed out after 60 seconds. Please try again.');
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [connectingDevices]);

  const handleConnectDevice = async (device: ConfiguredDevice) => {
    if (!publicKey || !activeGateway || !wallet) return;

    const deviceId = device.id;
    if (!deviceId) {
      toast.error('Device ID not found');
      return;
    }

    // Set connecting state with current timestamp
    setConnectingDevices(prev => ({
      ...prev,
      [deviceId]: { 
        timeoutId: setTimeout(() => {}, 0), // Dummy timeout for type compatibility
        startTime: Date.now() 
      }
    }));

    const deviceConfig = {
      ...device.connection
    };

    const message = SrcfulMessageProtocolFactory.createDeviceConfigMessage({
      walletPublicKey: publicKey.toString(),
      gatewayId: activeGateway.id,
      deviceConfig,
      deviceDisplayName: device.name
    });

    await handleSubmitFlow(message, wallet, () => {
      toast.success('Device connection initiated');
    }, () => {
      toast.error('Failed to connect device');
      setConnectingDevices(prev => {
        const newState = { ...prev };
        delete newState[deviceId];
        return newState;
      });
    });
  };

  // Watch for device connection in state
  useEffect(() => {
    if (!activeGateway?.state?.devices?.configured) return;
    
    // Check each connecting device to see if it's connected
    Object.entries(connectingDevices).forEach(([deviceId, { timeoutId }]) => {
      const device = activeGateway?.state?.devices?.configured.find(
        (d: ConfiguredDevice) => d.id === deviceId
      );
      
      if (device?.is_open) {
        // Device is now connected
        clearTimeout(timeoutId);
        setConnectingDevices(prev => {
          const newState = { ...prev };
          delete newState[deviceId];
          return newState;
        });
        toast.success('Device successfully connected');
      }
    });
  }, [activeGateway?.state?.devices?.configured, connectingDevices]);

  // Add cleanup effect for connecting devices
  useEffect(() => {
    return () => {
      // Clear all timeouts when component unmounts
      Object.values(connectingDevices).forEach(({ timeoutId }) => {
        clearTimeout(timeoutId);
      });
      Object.values(deletingDevices).forEach(({ timeoutId }) => {
        clearTimeout(timeoutId);
      });
    };
  }, [connectingDevices, deletingDevices]);

  const handleScan = async () => {
    if (!publicKey || !activeGateway || !wallet) return;
    setIsScanning(true);
    
    const message = SrcfulMessageProtocolFactory.scanDevicesMessage({
      walletPublicKey: publicKey.toString(),
      gatewayId: activeGateway.id
    });

    await handleSubmitFlow(message, wallet, () => {
      toast.success('Device scan initiated');
      const timeout = setTimeout(() => {
        setIsScanning(false);
      }, 60000);

      // Return early if we get new available devices
      const checkForDevices = setInterval(() => {
        if (activeGateway?.state?.devices?.available?.length > 0) {
          clearTimeout(timeout);
          clearInterval(checkForDevices);
          setIsScanning(false);
        }
      }, 1000);

      // Cleanup after 60 seconds
      setTimeout(() => {
        clearInterval(checkForDevices);
      }, 60000);
    }, () => {
      toast.error('Failed to initiate device scan');
      setIsScanning(false);
    });
  };

  const updateDeviceFormData = (deviceId: string, data: { [key: string]: string }) => {
    setDeviceFormData(prev => ({
      ...prev,
      [deviceId]: {
        ...(prev[deviceId] || {}),
        ...data
      }
    }));
  };

  if (!isConnected || !activeGateway) return null;

  const savedDevices = activeGateway.state?.devices?.saved || [];
  const configuredDevices = activeGateway.state?.devices?.configured || [];

  // Combine and deduplicate devices, preferring configured devices over saved ones
  const allDevices = [...configuredDevices];
  savedDevices.forEach((savedDevice: ConfiguredDevice) => {
    if (!allDevices.some(device => device.id === savedDevice.id)) {
      allDevices.push(savedDevice);
    }
  });

  // Add this section before the P1 Meter Configuration ExpandableBox
  const availableDevices = activeGateway.state?.devices?.available || [];
  const configuredDeviceIds = new Set(configuredDevices.map((d: ConfiguredDevice) => d.id));

  return (
    <ContentFramework>
      <PageContainer>
        <GatewayPageHeader 
          gatewayName={activeGateway.name}
        />
        <h1 className="text-4xl font-bold mb-6">Devices</h1>
        
        {allDevices.length > 0 ? (
          <div className="space-y-4">
            <AnimatePresence mode="popLayout">
              {allDevices.map((device: ConfiguredDevice) => (
                <DeviceCard
                  key={device.id}
                  device={device}
                  onDelete={handleDeleteDevice}
                  isDeleting={Boolean(deletingDevices[device.id])}
                />
              ))}
            </AnimatePresence>
          </div>
        ) : (
          <motion.p className="text-gray-400">No devices available</motion.p>
        )}
        
        {/* Device Scan Section */}
        <ExpandableBox title="Device Scan">
          <div className="space-y-4">
            <p className="mb-4">
              Your gateway can scan the network for compatible devices such as inverters and meters.
            </p>
            <div className="space-y-2 mb-4">
              <h3 className="font-semibold">Before scanning:</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>Make sure devices are on the same network as the gateway</li>
                <li>Scanning involves connecting to devices on your network, this may cause disruptions</li>
                <li>Advanced users that run e.g. a proxy are NOT recommended to scan their network</li>
              </ul>
            </div>
            <div className="bg-yellow-500/10 p-4 rounded-lg border border-yellow-500/20 mb-4">
              <p className="text-yellow-200">
                Note: Enphase, Deye and Sofar inverters cannot be discovered through scanning and require manual configuration in the Advanced Configuration section.
              </p>
            </div>
            <div className="flex justify-end">
              <ActionButton
                onClick={handleScan}
                isLoading={isScanning}
                loadingText="Scanning..."
              >
                Start Device Scan
              </ActionButton>
            </div>
          </div>
        </ExpandableBox>

        {/* Available Devices Section */}
        <div className="bg-glass rounded-xl p-4 mt-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-base font-bold leading-7">
              Found Devices
            </h2>
          </div>

          <div className="space-y-4">
            <AnimatePresence mode="popLayout">
              {availableDevices
                .filter((device: ConfiguredDevice) => !configuredDeviceIds.has(device.id))
                .map((device: ConfiguredDevice) => (
                  <AvailableDeviceCard
                    key={device.id}
                    device={device}
                    onConnect={handleConnectDevice}
                    isConnecting={Boolean(connectingDevices[device.id])}
                    formData={deviceFormData[device.id]}
                    onUpdateFormData={(data: { [key: string]: string }) => 
                      updateDeviceFormData(device.id, data)
                    }
                  />
                ))}
            </AnimatePresence>
          </div>
        </div>

        {/* Advanced Configuration */}
        <ExpandableBox title="Advanced Configuration">
          <AdvancedSection state={activeGateway.state || {}} />
        </ExpandableBox>
      </PageContainer>
    </ContentFramework>
  );
};

export default GatewayDevices; 