import { FC } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';

interface NotificationItemProps {
  id: number;
  type: string;
  timestamp: number;
  message: string;
  gatewayName: string;
  onDelete?: (id: number) => void;
  showDeleteButton?: boolean;
}

const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const userLocale = navigator.language;
  return date.toLocaleString(userLocale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const NotificationItem: FC<NotificationItemProps> = ({
  id,
  type,
  timestamp,
  message,
  gatewayName,
  onDelete,
  showDeleteButton = false
}) => {
  return (
    <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700/50">
      <div className="flex justify-between items-start">
        <div className="flex-grow">
          <div className="flex items-center space-x-2 mb-2">
            <span className={`
              inline-block px-2 py-1 text-xs rounded-full
              ${type === 'info' ? 'bg-blue-900/50 text-blue-200' : ''}
              ${type === 'error' ? 'bg-red-900/50 text-red-200' : ''}
              ${type === 'warning' ? 'bg-yellow-900/50 text-yellow-200' : ''}
            `}>
              {type}
            </span>
            <span className="text-xs text-gray-400">
              {formatTimestamp(timestamp)}
            </span>
          </div>
          <div className="text-sm text-gray-300 mb-1">
            From: {gatewayName}
          </div>
          <p className="text-sm text-gray-200">{message}</p>
        </div>
        {showDeleteButton && onDelete && (
          <button
            onClick={() => onDelete(id)}
            className="ml-4 p-1 rounded hover:bg-gray-700/50 transition-colors"
            title="Delete notification"
          >
            <TrashIcon className="h-4 w-4 text-gray-400 hover:text-red-400" />
          </button>
        )}
      </div>
    </div>
  );
};

export default NotificationItem; 