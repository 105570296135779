import { WalletInterface, MessageContext, createSignedMessage } from './types';
import { WalletVisitor } from './WalletVisitor';

export class GuestWallet implements WalletInterface {
  private guestPublicKey: string | null = null;

  constructor(guestPublicKey: string | null) {
    this.guestPublicKey = guestPublicKey;
  }

  public isConnected(): boolean {
    // A guest wallet is connected if it has a public key
    return this.guestPublicKey !== null;
  }

  public isConnecting(): boolean {
    // Guest wallet connection is immediate, never in connecting state
    return false;
  }

  public getPublicKey(): string | null {
    return this.guestPublicKey;
  }

  public async sign(message: Uint8Array): Promise<Uint8Array> {
    if (!this.isConnected()) {
      throw new Error('Guest wallet not connected');
    }
    
    // Generate a consistent dummy signature for the guest wallet
    // Using a 64-byte signature which is the standard for ed25519
    const signature = new Uint8Array(64);
    
    // Fill with a predictable pattern based on the message
    // This ensures different messages get different signatures
    // while keeping them deterministic for the same guest key
    for (let i = 0; i < 64; i++) {
      // XOR the message bytes with a repeating pattern
      // This creates a deterministic but unique signature
      signature[i] = (i < message.length ? message[i] : 0) ^ 0xde;
    }
    
    return signature;
  }

  // Legacy method - now calls disconnect
  public resetConnection(): void {
    this.disconnect();
  }

  public disconnect(): void {
    this.guestPublicKey = null;
  }

  public async connect(guestPublicKey: string): Promise<void> {
    this.guestPublicKey = guestPublicKey;
  }

  public verifySignature(message: Uint8Array, signature: Uint8Array): boolean {
    // For guest wallets, we can't verify signatures since they don't have real keys
    if (!this.isConnected()) {
      return false;
    }
    
    // Check signature length - expected to be 64 bytes for ed25519
    if (signature.length !== 64) {
      return false;
    }
    
    // Verify the signature matches our algorithm
    for (let i = 0; i < 64; i++) {
      const expected = (i < message.length ? message[i] : 0) ^ 0xde;
      if (signature[i] !== expected) {
        return false;
      }
    }
    
    return true;
  }

  public async signMessage(message: string): Promise<MessageContext | null> {
    if (!this.isConnected()) {
      return null;
    }
    const signedMessage = await createSignedMessage(this.sign.bind(this), message);
    if (!signedMessage) {
      return null;
    }
    return {
      signedMessage
    }
  }

  public accept(visitor: WalletVisitor): void {
    visitor.visitGuestWallet(this);
  }
} 