import { FC } from 'react';
import { ActionButton } from '../../../ui/ActionButton';
import { StepProps, DimoAuthData } from '../../types';
import { DimoAuthProvider } from "@dimo-network/login-with-dimo";
import DimoLoginButton from '../../../DimoLoginButton';


interface DimoCredentialsProps extends StepProps {
  setDimoAuthData: (data: DimoAuthData | null) => void;
  setCurrentStep: (value: number | ((prev: number) => number)) => void;
  dimoAuthData: DimoAuthData | null;
}

const DimoCredentialsStep: FC<DimoCredentialsProps> = ({
  onBack,
  onNext,
  setDimoAuthData,
  setCurrentStep,
  dimoAuthData
}) => {
  return (
    <div className="space-y-8">
      <div className="space-y-6">
        <div className="flex items-center gap-4">
          <h2 className="text-xl font-semibold">DIMO Authentication</h2>
        </div>
        
        <div className="space-y-4 bg-gray-800/50 p-6 rounded-lg border border-gray-700">
          <div className="flex items-center gap-3">
            <div className="text-2xl font-bold text-white">DIMO</div>
            <h3 className="text-lg font-medium">Connect Your DIMO Account</h3>
          </div>
          <p className="text-gray-300">
            Connect your DIMO account and select vehicles for energy monitoring.
          </p>
          <div className="space-y-2 text-sm text-gray-400">
            <p>The process will:</p>
            <ul className="list-disc ml-4 space-y-1">
              <li>Connect your DIMO account</li>
              <li>Allow you to select vehicles</li>
              <li>Create your gateway automatically after selection</li>
            </ul>
          </div>

          <div className="mt-6">
            <DimoAuthProvider>
              <DimoLoginButton
                onSuccess={setDimoAuthData}
                className="w-full"
              />
            </DimoAuthProvider>
          </div>
        </div>

        {/* Shared Vehicles Section */}
        {dimoAuthData?.vehicles && dimoAuthData.vehicles.length > 0 && (
          <div className="mt-8">
            <div className="flex items-center gap-4 mb-4">
              <h2 className="text-xl font-semibold">Shared Vehicles</h2>
              <div className="text-sm text-gray-400">
                {dimoAuthData.vehicles.length} vehicle{dimoAuthData.vehicles.length !== 1 ? 's' : ''} available
              </div>
            </div>
            <div className="bg-gray-800/50 p-6 rounded-lg border border-gray-700 space-y-3">
              {dimoAuthData.vehicles.map((vehicle) => (
                <div key={vehicle.tokenId} className="bg-gray-800/80 p-3 rounded">
                  <div className="space-y-1">
                    <span className="font-mono text-sm text-gray-300">Vehicle ID: {vehicle.tokenId}</span>
                    <div className="text-sm text-gray-400">
                      {vehicle.definition.make} {vehicle.definition.model} ({vehicle.definition.year})
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-between">
        <ActionButton
          onClick={onBack}
          variant="secondary"
        >
          Back
        </ActionButton>
        {dimoAuthData?.vehicles && dimoAuthData.vehicles.length > 0 && (
          <ActionButton
            onClick={onNext}
            variant="primary"
          >
            Create Gateway
          </ActionButton>
        )}
      </div>
    </div>
  );
}

export default DimoCredentialsStep; 