import { FC, useEffect, useRef } from "react";
import { useWalletContext } from '../context/WalletContext';
import { Gateway, useGateways, isHardwareGateway } from "../context/GatewayContext";
import NavItem from "./layout/NavItem";
import { useMatchRoute, useRouter } from "@tanstack/react-router";
import { GatewayIcon, CloudGatewayIcon } from './icons/icons';



interface GatewayListProps {
  onNavigate: () => void;
  expandedGatewayIds: Set<string>;
  setExpandedGatewayIds: (ids: Set<string>) => void;
}

const GatewayList: FC<GatewayListProps> = ({
  onNavigate,
  expandedGatewayIds,
  setExpandedGatewayIds,
}) => {
  const { isConnected } = useWalletContext();
  const { gateways, activeGateway } = useGateways();
  const matchRoute = useMatchRoute();
  const router = useRouter();
  const viewTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const activeGatewayRef = useRef<string | null>(null);
  const currentPathRef = useRef<string | null>(null);

  const getGatewayState = (gateway: Gateway) => {
    // For cloud gateways, return unknown
    
    if (!gateway.state?.timestamp) return 'unknown';
    
    const now = Date.now();
    const diffMinutes = (now - gateway.state.timestamp) / 1000 / 60;
    
    if (diffMinutes < 10) return 'active';
    if (diffMinutes > 60) return 'inactive';
    return 'disabled';
  };

  useEffect(() => {
    if (activeGateway) {
      setExpandedGatewayIds(new Set([activeGateway.id]));
    }
  }, [activeGateway, setExpandedGatewayIds]);

    // Check if we're on a notifications page and which gateway
  useEffect(() => {
    const currentPath = router.state.location.pathname;

    // If we're already handling this path, skip
    if (currentPath === currentPathRef.current) {
      return;
    }

    // Update current path
    currentPathRef.current = currentPath;

    // Clear any existing timer
    if (viewTimeoutRef.current) {
      // console.log("Clearing existing notification timer");
      clearTimeout(viewTimeoutRef.current);
      viewTimeoutRef.current = null;
      activeGatewayRef.current = null;
    }

    return () => {
      const previousPath = currentPathRef.current;
      if (!previousPath) return;

      // Check if we're navigating away from a notifications page
      const wasOnNotifications = previousPath.endsWith('/notifications');
      const isStillOnNotifications = currentPath.endsWith('/notifications');

      if (wasOnNotifications && !isStillOnNotifications && viewTimeoutRef.current) {
        // console.log("Cancelled notification view timer - navigated away");
        clearTimeout(viewTimeoutRef.current);
        viewTimeoutRef.current = null;
        activeGatewayRef.current = null;
      }
    };
  }, [router.state.location.pathname, gateways]);


  if (!isConnected || !gateways || gateways.length === 0) {
    return null;
  }

  const handleExpand = (gateway: Gateway) => {
    const newIds = new Set(expandedGatewayIds);
    
    // Check if any of the gateway's routes are active
    const isOverviewActive = matchRoute({ to: `/gateway/${gateway.id}`, fuzzy: false });
    const isLocationActive = matchRoute({ to: `/gateway/${gateway.id}/location`, fuzzy: false });
    const isDevicesActive = matchRoute({ to: `/gateway/${gateway.id}/devices`, fuzzy: false });
    const isNetworkActive = matchRoute({ to: `/gateway/${gateway.id}/network`, fuzzy: false });
    const hasActiveRoute = isOverviewActive || isLocationActive || isDevicesActive || isNetworkActive;
    
    // If a sub-route is active, don't allow collapsing
    if (hasActiveRoute) {
      newIds.add(gateway.id);
    } else {
      if (newIds.has(gateway.id)) {
        newIds.delete(gateway.id);
      } else {
        newIds.add(gateway.id);
      }
    }
    
    setExpandedGatewayIds(newIds);
  };

  return (
    <div>
      <h2 className="mb-2 px-2 text-xs font-semibold uppercase tracking-wider text-gray-400">
        Your Gateways
      </h2>
      <ul className="space-y-1">
        {gateways.map((gateway) => {
          
          return (
            <li key={gateway.id}>
              <NavItem
                title={gateway.name}
                isExpanded={expandedGatewayIds.has(gateway.id)}
                onExpand={() => handleExpand(gateway)}
                onClick={() => {}}
                onItemClick={() => {
                  onNavigate();
                }}
                icon={
                  isHardwareGateway(gateway) ? (
                    <GatewayIcon state={getGatewayState(gateway)} className="w-5 h-5 mr-3" />
                  ) : (
                    <CloudGatewayIcon state={getGatewayState(gateway)} className="w-5 h-5 mr-3" />
                  )
                }
                children={
                  isHardwareGateway(gateway) ? [
                    {
                      title: "Overview",
                      path: `/gateway/${gateway.id}`,
                    },
                    {
                      title: "Devices",
                      path: `/gateway/${gateway.id}/devices`,
                    },
                    {
                      title: "Network",
                      path: `/gateway/${gateway.id}/network`,
                    },
                    {
                      title: "Location",
                      path: `/gateway/${gateway.id}/location`,
                    },
                  ] : [
                    {
                      title: "Overview",
                      path: `/gateway/${gateway.id}`,
                    },
                    {
                      title: "Location",
                      path: `/gateway/${gateway.id}/location`,
                    },
                  ]
                }
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default GatewayList;
