import bs58 from "bs58";
import { WalletVisitor } from './WalletVisitor';

export interface SignedMessage {
  message: string;
  signature: string;
}

export interface MessageContext {
  signedMessage: SignedMessage;
  authToken?: string;
}

export interface WalletInterface {
  // Using function signatures that will work with all implementations
  connect: (options?: any) => Promise<void> | void;
  disconnect: () => void;
  isConnected: () => boolean;
  isConnecting: () => boolean;
  getPublicKey: () => string | null;
  sign: (message: Uint8Array) => Promise<Uint8Array>;
  verifySignature?: (message: Uint8Array, signature: Uint8Array) => boolean;
  signMessage: (message: string) => Promise<MessageContext | null>;
  
  // Legacy method - implementations should call disconnect() internally
  resetConnection?: () => void;

  // Visitor pattern method
  accept(visitor: WalletVisitor): void;
}

// Helper function to sign Srcful messages basically only used by concrete implementations of the interface.
export const createSignedMessage = async (
  signMessage: (message: Uint8Array) => Promise<Uint8Array>,
  message: string,
): Promise<SignedMessage | null> => {
  try {
    const messageBytes = new TextEncoder().encode(message);
    const signature = await signMessage(messageBytes);
    const signatureb58 = bs58.encode(signature);
    const message58 = bs58.encode(messageBytes);
    return {
      message: message58,
      signature: signatureb58,
    };
  } catch (e) {
    console.log(e);
    return null;
  }
}; 