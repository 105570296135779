import { createContext, useContext, useState, ReactNode, useEffect, useRef } from "react";
import { useWalletContext } from './WalletContext';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import SrcfulMessageProtocolFactory from "../services/backend/SrcfulMessageProtocolFactory";
import { MessageContext } from '../wallets/types';
import { fetchGatewayState } from "../services/backend/SrcfulApiRequests";
import { toast } from "react-hot-toast";
import { createClient } from 'graphql-ws';
import { fetchWalletGateways, createGatewaySubscriptionQuery, fetchGatewayDers, DerInfo } from "../services/backend/SrcfulPublicApi";
import GatewayMessageManager from '../services/GatewayMessageManager';
import * as SrcfulApiRequests from "../services/backend/SrcfulApiRequests";
// Simple deep equality check function
const isEqual = (obj1: any, obj2: any): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

// Storage helper functions
const getStoredClaims = (walletPubkey: string) => {
  // Get claims for current wallet
  const stored = localStorage.getItem(`gateway-claims-${walletPubkey}`);
  return stored ? JSON.parse(stored) : {};
};

const storeGatewayClaims = (walletPubkey: string, claims: Record<string, MessageContext>) => {
  localStorage.setItem(`gateway-claims-${walletPubkey}`, JSON.stringify(claims));
};

// Ensure only claims for the current wallet are kept in storage
const cleanupGatewayClaims = (currentWalletPubkey: string | null) => {
  // Clear any stored claims from other wallets
  const allKeys = Object.keys(localStorage);
  const gatewayClaimKeys = allKeys.filter(key => key.startsWith('gateway-claims-'));
  
  gatewayClaimKeys.forEach(key => {
    // Keep current wallet's claims, clear all others
    if (!currentWalletPubkey || key !== `gateway-claims-${currentWalletPubkey}`) {
      localStorage.removeItem(key);
    }
  });
};

// Add this interface to define the subscription response type
interface ConfigurationDataChangesResponse {
  data?: {
    configurationDataChanges?: {
      data: Record<string, any>;
      subKey: string;
    };
  };
}

export interface Gateway {
  id: string;
  name: string;
  h3Index: string;
  publicKey: string;
  typeOf: string;
  timeZone?: string;
  ownershipClaim?: MessageContext;
  state?: Record<string, any>;
  ders?: DerInfo[];
}

// Helper to check if a gateway is hardware type
export const isHardwareGateway = (gateway: Gateway): boolean => {
  return gateway.typeOf === "Hardware";
};

interface GatewayContextType {
  gateways: Gateway[];
  activeGateway: Gateway | null;
  setActiveGateway: (gateway: Gateway | null) => void;
  selectedGateway: Gateway | null;
  setSelectedGateway: (gateway: Gateway | null) => void;
  isLoading: boolean;
  error: Error | null;
  fetchGatewayStates: () => void;
  isGatewaysSigned: boolean;
  refreshGateways: () => Promise<void>;
  claimNewGateway: (gatewayId: string) => Promise<void>;
  suppressSigningStep: boolean;
  setSuppressSigningStep: (suppress: boolean) => void;
}

const GatewayContext = createContext<GatewayContextType>({
  gateways: [],
  activeGateway: null,
  setActiveGateway: () => {},
  selectedGateway: null,
  setSelectedGateway: () => {},
  isLoading: false,
  error: null,
  fetchGatewayStates: async () => {},
  isGatewaysSigned: false,
  refreshGateways: async () => {},
  claimNewGateway: async () => {},
  suppressSigningStep: false,
  setSuppressSigningStep: () => {},
});

// Create a WebSocket client
const wsClient = createClient({
  url: 'wss://api.srcful.dev/',
});

export const GatewayProvider = ({ children }: { children: ReactNode }) => {
  const { connected, publicKey, wallet } = useWalletContext();
  const [activeGateway, setActiveGateway] = useState<Gateway | null>(null);
  const [selectedGateway, setSelectedGateway] = useState<Gateway | null>(null);
  const queryClient = useQueryClient();
  const [isGatewaysSigned, setIsGatewaysSigned] = useState(false);
  const [suppressSigningStep, setSuppressSigningStep] = useState(false);
  const lastWalletPublicKeyRef = useRef<string | null>(null);
  
  // Keep track of active subscriptions to properly clean them up
  const activeSubscriptionsRef = useRef<Array<() => void>>([]);
  
  // Add a ref to track the current subscription state
  const subscriptionStateRef = useRef<{
    walletKey: string | null;
    gatewayIds: string[];
    isSetup: boolean;
  }>({
    walletKey: null,
    gatewayIds: [],
    isSetup: false
  });

  // Track wallet changes and clean up claims when wallet changes
  useEffect(() => {
    // Check if this is a new wallet connection
    if (publicKey && lastWalletPublicKeyRef.current !== publicKey) {
      // If we're switching wallets, clean up claims storage
      cleanupGatewayClaims(publicKey);
      
      // Reset active gateway when switching wallets
      setActiveGateway(null);
      
      // Update the last wallet reference
      lastWalletPublicKeyRef.current = publicKey;
      
      // Clean up any existing subscriptions when wallet changes
      cleanupSubscriptions();
      
      // Reset subscription state
      subscriptionStateRef.current = {
        walletKey: publicKey,
        gatewayIds: [],
        isSetup: false
      };
    } else if (!publicKey) {
      // If we're disconnecting, clean up all claims
      cleanupGatewayClaims(null);
      lastWalletPublicKeyRef.current = null;
      
      // Reset active gateway when disconnecting
      setActiveGateway(null);
      
      // Clean up subscriptions when disconnecting
      cleanupSubscriptions();
      
      // Reset subscription state
      subscriptionStateRef.current = {
        walletKey: null,
        gatewayIds: [],
        isSetup: false
      };
    }
  }, [publicKey]);

  // Helper to clean up subscriptions
  const cleanupSubscriptions = () => {
    console.log('Cleaning up subscriptions');
    activeSubscriptionsRef.current.forEach(unsubscribe => {
      try {
        unsubscribe();
      } catch (error) {
        console.error('Error unsubscribing:', error);
      }
    });
    activeSubscriptionsRef.current = [];
  };

  useEffect(() => {
    if (!connected || !publicKey) {
      // If we're not connected or don't have a public key, return early
      if (!connected) {
        setIsGatewaysSigned(false);
        setActiveGateway(null);
        GatewayMessageManager.getInstance().clearWallet();
      }
      return;
    }

    // Only set wallet config when we have all required values
    if (connected && publicKey && wallet) {
      GatewayMessageManager.getInstance().setWallet(wallet);
    }
  }, [connected, publicKey, wallet]);

  // Separate query for gateway list
  const { data: gateways, isLoading, error } = useQuery({
    queryKey: ["gateways", publicKey],
    queryFn: async () => {
      if (!publicKey) {
        return [];
      }
      const gatewayResponses = await fetchWalletGateways(publicKey);
      
      // Check if we have any removed gateways by comparing with stored claims
      const storedClaims = getStoredClaims(publicKey);
      const storedGatewayIds = new Set(Object.keys(storedClaims));
      const currentGatewayIds = new Set(gatewayResponses.map(g => g.id));

      // If we have any gateways in stored claims that are no longer in the current list
      const hasRemovedGateways = Array.from(storedGatewayIds).some(id => !currentGatewayIds.has(id));
      
      if (hasRemovedGateways) {
        // Clear all stored claims as they're no longer valid
        storeGatewayClaims(publicKey, {});
        setIsGatewaysSigned(false);
      }
      
      // Fetch DER info for each gateway
      const gatewaysWithDers = await Promise.all(
        gatewayResponses.map(async (gateway) => {
          try {
            const ders = await fetchGatewayDers(gateway.id);
            return { ...gateway, ders, ownershipClaim: undefined };
          } catch (error) {
            console.error(`Failed to fetch DERs for gateway ${gateway.id}:`, error);
            // Return gateway without DER info if fetch fails
            return {...gateway, ownershipClaim: undefined};
          }
        })
      );

      return gatewaysWithDers;
    },
    enabled: connected && !!publicKey,
  }) as { data: Gateway[] | undefined, isLoading: boolean, error: Error | null };


  // Check if we need to set up new subscriptions
  const maybeSetupSubscriptions = (gatewaysToSubscribe: Gateway[]) => {
    // Return early if no gateways or wallet is not available
    if (!publicKey || !gatewaysToSubscribe || gatewaysToSubscribe.length === 0) {
      return;
    }
    
    // Get gateway IDs to compare
    const gatewayIds = gatewaysToSubscribe
      .filter(g => g.ownershipClaim)
      .map(g => g.id)
      .sort();
      
    // Get current subscription state
    const currentState = subscriptionStateRef.current;
    
    // Check if we need to recreate subscriptions
    const needsUpdate = 
      // Different wallet
      currentState.walletKey !== publicKey ||
      // Different number of gateways
      currentState.gatewayIds.length !== gatewayIds.length ||
      // Not already set up
      !currentState.isSetup ||
      // Different gateway IDs
      JSON.stringify(currentState.gatewayIds.sort()) !== JSON.stringify(gatewayIds);
      
    if (needsUpdate) {
      console.log('Subscription state changed, setting up new subscriptions');
      setupGatewaySubscriptions(gatewaysToSubscribe);
      
      // Update subscription state
      subscriptionStateRef.current = {
        walletKey: publicKey,
        gatewayIds,
        isSetup: true
      };
    }
  };

  // Load stored claims when wallet connects
  useEffect(() => {
    if (connected && publicKey && gateways) {
      const storedClaims = getStoredClaims(publicKey);
      
      // Apply claims to gateways
      const updatedGateways = gateways.map(gateway => ({
        ...gateway,
        ownershipClaim: storedClaims[gateway.id]
      }));
      
      // Check if all gateways have claims
      const allSigned = updatedGateways.every(gateway => gateway.ownershipClaim);
      
      // Update gateways with any existing claims
      queryClient.setQueryData(["gateways", publicKey], updatedGateways);
      
      if (allSigned) {
        // If all are signed, set as signed and fetch states
        setIsGatewaysSigned(true);

        // Then fetch states for each gateway without triggering signing
        Promise.all(
          updatedGateways.map(async (gateway) => {
            try {
              const state = await fetchGatewayState(gateway.id, gateway.ownershipClaim!);
              return {
                ...gateway,
                state
              };
            } catch (error) {
              console.error(`Failed to fetch state for gateway ${gateway.id}:`, error);
              return gateway;
            }
          })
        ).then(gatewaysWithStates => {
          queryClient.setQueryData(["gateways", publicKey], gatewaysWithStates);
          
          // Check if we need to set up subscriptions
          maybeSetupSubscriptions(gatewaysWithStates);
        });
      } else {
        // If not all gateways are signed, set as not signed and user will see signing step
        setIsGatewaysSigned(false);
      }
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, publicKey, gateways, queryClient]);

  

  // Set up subscriptions for all gateways
  const setupGatewaySubscriptions = (gatewaysToSubscribe: Gateway[]) => {
    // Clean up any existing subscriptions first
    cleanupSubscriptions();
    
    console.log(`Setting up subscriptions for ${gatewaysToSubscribe.length} gateways`);
    
    // Filter out gateways without ownership claims
    const signedGateways = gatewaysToSubscribe.filter(g => g.ownershipClaim);
    
    if (signedGateways.length === 0) {
      console.log('No signed gateways to subscribe to');
      return;
    }
    
    // Create new subscriptions
    const subscriptions = signedGateways.map(gateway => {
      try {
        const claim = gateway.ownershipClaim!;
        console.log(`Creating subscription for gateway ${gateway.id}`);
        
        const unsubscribe = wsClient.subscribe(
          {
            query: createGatewaySubscriptionQuery(gateway.id, claim),
          },
          {
            next: (response: ConfigurationDataChangesResponse) => {
              const newData = response?.data?.configurationDataChanges?.data;
              const subKey = response?.data?.configurationDataChanges?.subKey;
              
              if (newData && subKey === "state") {
                queryClient.setQueryData(
                  ["gateways", publicKey],
                  (oldData: Gateway[] | undefined) => {
                    if (!oldData) return oldData;
                    return oldData.map(g => {
                      if (g.id === gateway.id) {
                        // Only update if data actually changed
                        if (isEqual(g.state, newData)) {
                          return g;
                        }
                        return {
                          ...g,
                          state: newData
                        };
                      }
                      return g;
                    });
                  }
                );
                
                // Force React Query to notify subscribers without refetching
                queryClient.invalidateQueries({
                  queryKey: ["gateways", publicKey],
                  refetchType: "none"
                });
              }
              
              // Handle responses for echo messages
              if (subKey === "response" && newData) {
                const responseData = typeof newData === 'string' ? JSON.parse(newData) : newData;
                GatewayMessageManager.getInstance().handleResponse(responseData);
              }
            },
            error: (error) => {
              console.error(`Subscription error for gateway ${gateway.id}:`, error);
            },
            complete: () => {
              console.log(`Subscription completed for gateway ${gateway.id}`);
            },
          }
        );
        
        return unsubscribe;
      } catch (error) {
        console.error(`Error creating subscription for gateway ${gateway.id}:`, error);
        return () => {}; // Return empty cleanup function
      }
    });
    
    // Store references to the unsubscribe functions
    activeSubscriptionsRef.current = subscriptions;
  };

  // Fetch gateway states (with signing if needed)
  const { mutate: fetchGatewayStates } = useMutation({
    mutationFn: async () => {
      if (!publicKey || !wallet || !gateways || gateways.length === 0) {
        return;
      }

      try {
        const storedClaims = getStoredClaims(publicKey);
        const needsNewSignature = gateways.some(gateway => 
          !gateway.ownershipClaim ||
          !storedClaims[gateway.id]
        );

        let signedMessage: MessageContext | undefined;
        if (needsNewSignature) {
          const gatewayIds = gateways.map(gateway => gateway.id);
          const message = SrcfulMessageProtocolFactory.createClaimOwnershipMessage({
            walletPublicKey: publicKey,
            gatewayIds,
            expirationDays: 365
          });

          const signResult = await wallet.signMessage(message);
          if (!signResult) {
            throw new Error("Failed to sign message");
          }
          signedMessage = signResult;

          // test gateway ownership
          try {
            const isOwner = await SrcfulApiRequests.isGatewayOwner(gatewayIds, signedMessage);
            toast.success(`Gateway ownership is ${isOwner}`);
            console.log('isOwner', isOwner);
          } catch (error) {
            toast.error('Gateway ownership validation failed');
            console.error('Error validating ownership:', error);
            throw error;
          }

          // Store new signature
          const newClaims = { ...storedClaims };
          gateways.forEach(gateway => {
            newClaims[gateway.id] = signedMessage;
            console.log('new claims for gw', gateway.id);
          });
          storeGatewayClaims(publicKey, newClaims);
          console.log('new claims stored', newClaims);

          // Update gateways with new claims immediately
          const gatewaysWithClaims = gateways.map(gateway => ({
            ...gateway,
            ownershipClaim: signedMessage
          }));
          queryClient.setQueryData(["gateways", publicKey], gatewaysWithClaims);
        }

        // Get the latest gateways data with claims
        const currentGateways = queryClient.getQueryData(["gateways", publicKey]) as Gateway[];

        // Fetch states and update gateways
        const updatedGateways = await Promise.all(
          currentGateways.map(async (gateway) => {
            try {
              const claimToUse = gateway.ownershipClaim || signedMessage;
              if (!claimToUse) {
                throw new Error("No ownership claim available");
              }

              const state = await fetchGatewayState(gateway.id, claimToUse);
              return {
                ...gateway,
                state,
                ownershipClaim: claimToUse
              };
            } catch (error) {
              console.error(`Failed to fetch state for gateway ${gateway.id}:`, error);
              return gateway;
            }
          })
        );

        // Update the gateways query data
        queryClient.setQueryData(["gateways", publicKey], updatedGateways);
        setIsGatewaysSigned(true);
        
        // Check if we need to set up subscriptions
        maybeSetupSubscriptions(updatedGateways);
      } catch (error) {
        toast.error("Failed to update gateway states");
        throw error;
      }
    }
  });

  // Cleanup subscriptions when component unmounts
  useEffect(() => {
    return () => {
      cleanupSubscriptions();
    };
  }, []);

  const refreshGateways = async () => {
    if (!publicKey || !wallet) {
      throw new Error("Missing publicKey or wallet");
    }

    try {
      // First get the new list of gateways
      const gatewayResponses = await fetchWalletGateways(publicKey);
      
      // Compare with stored claims to check for removed gateways
      const storedClaims = getStoredClaims(publicKey);
      const storedGatewayIds = new Set(Object.keys(storedClaims));
      const currentGatewayIds = new Set(gatewayResponses.map(g => g.id));
      
      const hasRemovedGateways = Array.from(storedGatewayIds).some(id => !currentGatewayIds.has(id));
      
      // If we have removed gateways, clear claims and set unsigned
      if (hasRemovedGateways) {
        storeGatewayClaims(publicKey, {});
        setIsGatewaysSigned(false);
        
        // Reset subscription state
        subscriptionStateRef.current.isSetup = false;
      }

      // Map gateways with DER info and explicitly unset ownershipClaim
      const newGateways = await Promise.all(
        gatewayResponses.map(async (f): Promise<Gateway> => {
          try {
            const ders = await fetchGatewayDers(f.id);
            return {...f,
              ders,
              ownershipClaim: storedClaims[f.id] // Keep existing claim if available
            };
          } catch (error) {
            console.error(`Failed to fetch DERs for gateway ${f.id}:`, error);
            return {
              ...f,
              ders: [],
              ownershipClaim: storedClaims[f.id] // Keep existing claim if available
            };
          }
        })
      );
      // Update the gateways in the query cache
      await queryClient.setQueryData(["gateways", publicKey], newGateways);

      // Don't automatically trigger fetchGatewayStates - let the UI handle that
    } catch (error) {
      console.error("Error refreshing gateways:", error);
      toast.error("Failed to refresh gateways");
      throw error;
    }
  };

  const claimNewGateway = async (gatewayId: string) => {
    if (!publicKey || !wallet) {
      throw new Error("Wallet not connected");
    }

    const toastId = toast.loading("Claiming gateway...");
    try {
      // Create a new signature for this gateway
      const message = SrcfulMessageProtocolFactory.createClaimOwnershipMessage({
        walletPublicKey: publicKey,
        gatewayIds: [gatewayId],
        expirationDays: 365
      });

      const signResult = await wallet.signMessage(message);
      if (!signResult) {
        throw new Error("Failed to sign message");
      }
      const signedMessage = signResult;

      // Store the new claim
      const storedClaims = getStoredClaims(publicKey);
      storedClaims[gatewayId] = signedMessage;
      storeGatewayClaims(publicKey, storedClaims);

      // Fetch the gateway state
      const state = await fetchGatewayState(gatewayId, signedMessage);

      // Update the gateways in the query cache
      queryClient.setQueryData(
        ["gateways", publicKey],
        (oldData: Gateway[] | undefined) => {
          if (!oldData) return oldData;
          return oldData.map(gateway => {
            if (gateway.id === gatewayId) {
              return {
                ...gateway,
                ownershipClaim: signedMessage,
                state
              };
            }
            return gateway;
          });
        }
      );

      // Get the updated gateways and check if we need to update subscriptions
      const updatedGateways = queryClient.getQueryData(["gateways", publicKey]) as Gateway[] || [];
      maybeSetupSubscriptions(updatedGateways);

      toast.success("Gateway claimed successfully");
    } catch (error) {
      console.error("Failed to claim gateway:", error);
      toast.error("Failed to claim gateway");
      throw error;
    } finally {
      toast.dismiss(toastId);
    }
  };

  return (
    <GatewayContext.Provider
      value={{
        gateways: gateways || [],
        activeGateway,
        setActiveGateway,
        selectedGateway,
        setSelectedGateway,
        isLoading,
        error,
        fetchGatewayStates,
        isGatewaysSigned,
        refreshGateways,
        claimNewGateway,
        suppressSigningStep,
        setSuppressSigningStep,
      }}
    >
      {children}
    </GatewayContext.Provider>
  );
};

export const useGateways = () => {
  const context = useContext(GatewayContext);
  if (context === undefined) {
    throw new Error("useGateways must be used within a GatewayProvider");
  }
  return context;
};
