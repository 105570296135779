import { FC, useState, useEffect } from 'react';
import InfoRow from "../../components/InfoRow";
import { FaSpinner } from "react-icons/fa";
import { gatewayInfo } from '../../constants/infoTexts';

interface CompletionStepProps {
  gatewayId: string;
  cryptoInfo: {
    serialNumber: string;
    publicKey: string;
  };
}

const CompletionStep: FC<CompletionStepProps> = ({ gatewayId, cryptoInfo }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [gatewayName, setGatewayName] = useState<string | null>(null);

  useEffect(() => {
    const fetchGatewayName = async () => {
      try {
        const query = JSON.stringify({
          query: `{
            gateway {
              gateway(id: "${gatewayId}") {
                name
              }
            }
          }`
        });

        const response = await fetch('https://api.srcful.dev/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: query
        });

        const data = await response.json();
        const name = data.data?.gateway?.gateway?.name;
        setGatewayName(name || `Gateway ${gatewayId.slice(-6)}`);
      } catch (error) {
        console.error('Failed to fetch gateway name:', error);
        setGatewayName(`Gateway ${gatewayId.slice(-6)}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGatewayName();
  }, [gatewayId]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <FaSpinner className="animate-spin text-4xl text-blue-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white">Gateway Successfully Onboarded</h2>
      <div className="bg-green-500/10 p-4 rounded-lg border border-green-500/20">
        <InfoRow
          label="Gateway Name"
          value={gatewayName || ''}
          isCopyable
        />
        <InfoRow
          label={gatewayInfo.serial.label}
          tooltip={gatewayInfo.serial.tooltip}
          value={cryptoInfo.serialNumber}
          isCopyable
        />
        <InfoRow
          label={gatewayInfo.publicKey.label}
          tooltip={gatewayInfo.publicKey.tooltip}
          value={cryptoInfo.publicKey}
          displayValue={`${cryptoInfo.publicKey.slice(0, 8)}...${cryptoInfo.publicKey.slice(-8)}`}
          isCopyable
        />
        <p className="mt-4 text-sm text-green-300">
          Your gateway has been successfully onboarded and is ready to use.
        </p>
      </div>
    </div>
  );
};

export default CompletionStep; 