import { FC } from 'react';
import { useWalletContext } from '../../context/WalletContext';
import WalletItem from './WalletItem';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

interface WalletConnectProps {
  variant?: 'sidebar' | 'compact' | 'header';
}

/**
 * Wallet connection component that shows either:
 * 1. The connected wallet information with copy/disconnect options
 * 2. The Solana wallet adapter connection button when not connected
 */
const WalletConnect: FC<WalletConnectProps> = ({ variant = 'sidebar' }) => {
  const { isConnected } = useWalletContext();
  
  if (variant === 'compact') {
    return (
      <div className="flex items-center">
        {isConnected ? (
          <WalletItem variant={variant} />
        ) : (
          <WalletMultiButton />
        )}
      </div>
    );
  }
  
  if (variant === 'header') {
    return (
      <div className="flex items-center">
        {isConnected ? (
          <WalletItem variant={variant} />
        ) : (
          <WalletMultiButton />
        )}
      </div>
    );
  }

  // Default sidebar variant
  return (
    <div className="flex h-10 items-center">
      <div className="flex w-full items-center rounded-lg p-2 text-gray-300">
        {isConnected ? (
          <WalletItem variant={variant} />
        ) : (
          <WalletMultiButton />
        )}
      </div>
    </div>
  );
};

export default WalletConnect; 