import { initializeDimoSDK } from "@dimo-network/login-with-dimo";

// DIMO SDK Configuration
const DIMO_CONFIG = {
  clientId: process.env.REACT_APP_DIMO_CLIENT_ID || '0x624a81ceaDbAFd51ACA8CbFE9826d3dFa5F5bb10',
  redirectUri: process.env.REACT_APP_DIMO_REDIRECT_URI || 'https://daab-79-136-106-88.ngrok-free.app/redirect'
};

// Initialize DIMO SDK
initializeDimoSDK(DIMO_CONFIG);

export interface CreateGatewayRequest {
  dimo_wallet: string;
  longitude: number;
  latitude: number;
  wallet: string;
}

export interface CreateGatewayResponse {
  success: boolean;
  message?: string;
  error?: string;
  config?: {
    dimo_wallet: string;
    location: {
      longitude: number;
      latitude: number;
    }
  };
  shared_vehicles?: number[];
  gateway_id?: string;
}

export interface DimoVehicle {
  tokenId: number;
  definition: {
    make: string;
    model: string;
    year: number;
  };
}

export interface DimoVehiclesResponse {
  success: boolean;
  vehicles: DimoVehicle[];
}

class DimoService {
  private baseUrl: string;
  private static instance: DimoService;

  private constructor() {
    this.baseUrl = 'https://dimo.srcful.dev';
  }

  public static getInstance(): DimoService {
    if (!DimoService.instance) {
      DimoService.instance = new DimoService();
    }
    return DimoService.instance;
  }

  extractEthereumAddress(token: string): string {
    try {
      const tokenParts = token.split('.');
      const payload = JSON.parse(atob(tokenParts[1]));
      return payload.ethereum_address;
    } catch (error) {
      throw new Error('Failed to extract ethereum address from token');
    }
  }

  async createGateway(request: CreateGatewayRequest): Promise<CreateGatewayResponse> {
    try {
      const response = await fetch(`${this.baseUrl}/api/gateway`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      });

      const data = await response.json();
      
      if (!response.ok) {
        return {
          success: false,
          error: data.error || data.message || 'Failed to create DIMO gateway'
        };
      }

      return data;
    } catch (error: any) {
      return {
        success: false,
        error: error.message || 'Failed to create DIMO gateway'
      };
    }
  }

  async getVehicles(ownerAddress: string): Promise<DimoVehiclesResponse> {
    try {
      const response = await fetch(`${this.baseUrl}/api/vehicle?owner=${ownerAddress}`);
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to fetch DIMO vehicles');
      }

      return response.json();
    } catch (error: any) {
      throw new Error(error.message || 'Failed to fetch DIMO vehicles');
    }
  }
}

export { DimoService }; 