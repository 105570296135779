import { FC, useState } from 'react';
import { toast } from 'react-hot-toast';
import { LocalWallet } from '../../wallets';
import OTPInput from './OTPInput';

interface PrivateKeyLoginProps {
  onSuccess: (wallet: LocalWallet) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}

// Storage keys with names completely unrelated to wallets or keys
const PRIVATE_KEY_STORAGE_KEY = 'srcful_user_preferences';
const HAS_ENCRYPTED_KEY = 'srcful_dark_mode_enabled';
const SESSION_KEY = 'srcful_session_preference';
const SESSION_FLAG = 'srcful_session_theme';

const PrivateKeyLogin: FC<PrivateKeyLoginProps> = ({ onSuccess, isLoading, setIsLoading }) => {
  const [privateKey, setPrivateKey] = useState('');
  const [code, setCode] = useState('');

  const handlePrivateKeyLogin = async () => {
    if (!privateKey.trim()) {
      toast.error('Please enter a private key');
      return;
    }

    if (!code.trim() || code.length !== 6 || !/^\d+$/.test(code)) {
      toast.error('Please enter a valid 6-digit code');
      return;
    }

    setIsLoading(true);
    try {
      const localWallet = new LocalWallet();
      await localWallet.connect(privateKey);
      
      if (localWallet.isConnected()) {
        // Encrypt the private key with the 6-digit code before storing
        const encryptedKey = await encryptDecrypt(privateKey, code);
        
        // Store the encrypted key and set the flag in both localStorage and sessionStorage
        console.log('[Private Key Login] Storing encrypted key in secure storage');
        localStorage.setItem(PRIVATE_KEY_STORAGE_KEY, encryptedKey);
        localStorage.setItem(HAS_ENCRYPTED_KEY, 'true');
        
        // Also store in sessionStorage as backup
        sessionStorage.setItem(SESSION_KEY, encryptedKey);
        sessionStorage.setItem(SESSION_FLAG, 'true');
        
        onSuccess(localWallet);
      } else {
        toast.error('Failed to connect wallet');
      }
    } catch (error) {
      console.error('Error connecting wallet:', error);
      toast.error('Failed to connect: ' + (error instanceof Error ? error.message : String(error)));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full max-w-md">
      <div className="rounded-lg bg-gray-800/50 p-4 space-y-3">
        <input
          type="password"
          value={privateKey}
          onChange={(e) => setPrivateKey(e.target.value)}
          placeholder="Enter your private key"
          className="w-full p-2 bg-gray-700 text-gray-200 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        
        <div>
          <div className="mb-2 text-gray-400 text-sm">Enter a 6-digit security code:</div>
          <div className="flex justify-center py-1">
            <OTPInput
              length={6}
              value={code}
              onChange={setCode}
              onComplete={privateKey.trim() ? handlePrivateKeyLogin : undefined}
              maskDelay={1200}
            />
          </div>
        </div>
        
        <div className="text-xs text-gray-400">
          This code will be used to encrypt your private key. You'll need it to reconnect.
        </div>
        <button
          onClick={handlePrivateKeyLogin}
          disabled={isLoading}
          className="w-full p-2 bg-blue-600 hover:bg-blue-700 text-white rounded transition-colors"
        >
          {isLoading ? 'Connecting...' : 'Login with Private Key'}
        </button>
      </div>
    </div>
  );
};

// Enhanced encryption/decryption function using SHA-256
const encryptDecrypt = async (text: string, code: string): Promise<string> => {
  // Get SHA-256 hash of the code
  const hashedCode = await hashPasscode(code);
  
  // Pad the hashed code to match the text length by repeating it
  const paddedHashedCode = hashedCode.repeat(Math.ceil(text.length / hashedCode.length)).slice(0, text.length);
  
  // XOR each character with the corresponding character in the hashed code
  return Array.from(text)
    .map((char, i) => {
      // XOR the char code with the digit from the hashed code
      const xorValue = char.charCodeAt(0) ^ paddedHashedCode.charCodeAt(i);
      return String.fromCharCode(xorValue);
    })
    .join('');
};

// SHA-256 hash function that returns a hex string
const hashPasscode = async (code: string): Promise<string> => {
  // Create a SHA-256 hash of the passcode
  const msgUint8 = stringToBytes(code);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  
  return hashHex;
};

// Convert a string to a Uint8Array for crypto operations
const stringToBytes = (str: string): Uint8Array => {
  return new TextEncoder().encode(str);
};

export default PrivateKeyLogin; 