import { ReactNode } from 'react';

interface PageContainerProps {
  children: ReactNode;
}

const PageContainer = ({ children }: PageContainerProps) => {
  return (
    <div className="flex flex-col items-center w-full max-w-3xl mx-auto px-1 sm:px-0">
      <div className="w-full p-2 sm:p-6 bg-glass rounded-xl text-gray-200">
        {children}
      </div>
    </div>
  );
};

export default PageContainer; 