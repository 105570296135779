import { FC, useState, useRef, useEffect } from 'react';
import { useWalletContext } from '../../context/WalletContext';
import { toast } from 'react-hot-toast';
import { 
  ArrowLeftOnRectangleIcon,
  CheckIcon,
  ChevronDownIcon,
  ClipboardIcon,
  WalletIcon
} from '@heroicons/react/24/outline';
import { forceWalletDisconnect } from '../../utils/WalletUtils';
import { WalletIcon as SolanaWalletIcon } from '@solana/wallet-adapter-react-ui';
import { WalletVisitor } from '../../wallets/WalletVisitor';
import { SolanaWalletAdapter } from '../../wallets/SolanaWalletAdapter';
import { LocalWallet } from '../../wallets/LocalWallet';
import { GuestWallet } from '../../wallets/GuestWallet';
import { DelegatedWallet } from '../../wallets/DelegatedWallet';

// Rainbow Bridge Icon component for Bifrost
const BifrostIcon: FC<{ className?: string }> = ({ className = "h-5 w-5" }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round" 
    className={className}
  >
    <rect width="14" height="20" x="5" y="2" rx="2" ry="2"/>
    <path d="M12.667 8 10 12h4l-2.667 4"/>
  </svg>
);

// Key Icon component for private key wallets
const KeyIcon: FC<{ className?: string }> = ({ className = "h-5 w-5" }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round" 
    className={className}
  >
    <path d="M2.586 17.414A2 2 0 0 0 2 18.828V21a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1h1a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1h.172a2 2 0 0 0 1.414-.586l.814-.814a6.5 6.5 0 1 0-4-4z"/>
    <circle cx="16.5" cy="7.5" r=".5" fill="currentColor"/>
  </svg>
);

// Drama Icon component for guest wallets
const DramaIcon: FC<{ className?: string }> = ({ className = "h-5 w-5" }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round" 
    className={className}
  >
    <path d="M10 11h.01"/>
    <path d="M14 6h.01"/>
    <path d="M18 6h.01"/>
    <path d="M6.5 13.1h.01"/>
    <path d="M22 5c0 9-4 12-6 12s-6-3-6-12c0-2 2-3 6-3s6 1 6 3"/>
    <path d="M17.4 9.9c-.8.8-2 .8-2.8 0"/>
    <path d="M10.1 7.1C9 7.2 7.7 7.7 6 8.6c-3.5 2-4.7 3.9-3.7 5.6 4.5 7.8 9.5 8.4 11.2 7.4.9-.5 1.9-2.1 1.9-4.7"/>
    <path d="M9.1 16.5c.3-1.1 1.4-1.7 2.4-1.4"/>
  </svg>
);

interface WalletItemProps {
  variant?: 'sidebar' | 'header' | 'compact';
}

class WalletIconVisitor implements WalletVisitor {
  public icon: React.ReactNode = null;

  visitSolanaWallet(wallet: SolanaWalletAdapter): void {
    const adapter = wallet.getWalletAdapter();
    this.icon = <SolanaWalletIcon wallet={adapter} className="h-6 w-6" />;
  }

  visitLocalWallet(wallet: LocalWallet): void {
    this.icon = <KeyIcon className="h-6 w-6 text-gray-400" />;
  }

  visitGuestWallet(wallet: GuestWallet): void {
    this.icon = <DramaIcon className="h-6 w-6 text-yellow-400" />;
  }

  visitDelegatedWallet(wallet: DelegatedWallet): void {
    this.icon = <BifrostIcon className="h-6 w-6 text-emerald-500" />;
  }
}

const WalletItem: FC<WalletItemProps> = ({ variant = 'sidebar' }) => {
  const { publicKey, disconnect, isConnected, wallet } = useWalletContext();
  const [copied, setCopied] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Truncate the public key for display
  const truncatedKey = publicKey && publicKey.length > 12
    ? `${publicKey.substring(0, 6)}...${publicKey.substring(publicKey.length - 4)}`
    : publicKey;

  // Get the wallet icon using the visitor pattern
  const getWalletIcon = () => {
    if (!wallet) {
      return <WalletIcon className="h-6 w-6 text-gray-400" />;
    }

    const visitor = new WalletIconVisitor();
    wallet.accept(visitor);
    return visitor.icon;
  };

  // Handle copy to clipboard
  const handleCopy = async () => {
    if (!publicKey) return;
    
    try {
      await navigator.clipboard.writeText(publicKey);
      setCopied(true);
      toast.success('Address copied to clipboard');
      
      // Reset copied status after 2 seconds
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (error) {
      console.error('Failed to copy address:', error);
      toast.error('Failed to copy address');
    }
  };

  // Handle wallet disconnect with improved disconnection process
  const handleDisconnect = () => {
    // First disconnect via the context
    disconnect();
    
    // Then force a complete disconnect of all wallet storage
    forceWalletDisconnect();
    
    toast.success('Wallet disconnected');
    
    // Redirect to login page after a short delay to allow the toast to be seen
    setTimeout(() => {
      window.location.href = '/login';
    }, 1000);
  };

  if (variant === 'sidebar') {
    return (
      <div className="flex flex-grow items-center justify-between rounded-lg py-2 px-3 bg-gray-700/30 text-gray-200 hover:bg-gray-700/50 transition-colors">
        {isConnected && publicKey ? (
          <>
            <div className="flex items-center">
              {getWalletIcon()}
              <span className="font-mono text-base ml-2">{truncatedKey}</span>
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={handleCopy}
                className="p-1 rounded hover:bg-gray-600/50 transition-colors"
                title="Copy address"
              >
                {copied ? (
                  <CheckIcon className="h-4 w-4 text-green-400" />
                ) : (
                  <ClipboardIcon className="h-4 w-4 text-gray-400" />
                )}
              </button>
              <button
                onClick={handleDisconnect}
                className="p-1 rounded hover:bg-gray-600/50 transition-colors"
                title="Disconnect"
              >
                <ArrowLeftOnRectangleIcon className="h-4 w-4 text-gray-400" />
              </button>
            </div>
          </>
        ) : (
          <div className="flex items-center text-sm text-yellow-400">
            <div className="h-2 w-2 rounded-full bg-yellow-500 mr-2 animate-pulse" title="Not Connected"></div>
            <span>Wallet not connected</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-grow items-center justify-between rounded-lg py-2 px-3 bg-gray-700/30 text-gray-200 hover:bg-gray-700/50 transition-colors">
      {isConnected && publicKey ? (
        <>
          <div className="flex items-center relative" ref={dropdownRef}>
            {getWalletIcon()}
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="font-mono text-base hover:text-gray-300 transition-colors duration-200 flex items-center ml-2"
              title="Click to open menu"
            >
              <span>{truncatedKey}</span>
              <ChevronDownIcon className="h-5 w-5 ml-1" />
            </button>
            
            {isDropdownOpen && (
              <div className="absolute right-0 top-full mt-2 w-48 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu">
                  <button
                    onClick={() => {
                      handleCopy();
                      setIsDropdownOpen(false);
                    }}
                    className="w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white flex items-center"
                    role="menuitem"
                  >
                    {copied ? (
                      <>
                        <CheckIcon className="h-4 w-4 text-green-400 mr-2" />
                        <span className="text-green-400">Copied!</span>
                      </>
                    ) : (
                      <>
                        <CheckIcon className="h-4 w-4 text-gray-400 mr-2" />
                        <span>Copy Address</span>
                      </>
                    )}
                  </button>
                  <button
                    onClick={() => {
                      handleDisconnect();
                      setIsDropdownOpen(false);
                    }}
                    className="w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-red-400 flex items-center"
                    role="menuitem"
                  >
                    <ArrowLeftOnRectangleIcon className="h-4 w-4 mr-2" />
                    <span>Disconnect</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="flex items-center text-sm text-yellow-400">
          <div className="h-2 w-2 rounded-full bg-yellow-500 mr-2 animate-pulse" title="Not Connected"></div>
          <span>Wallet not connected</span>
        </div>
      )}
    </div>
  );
};

export default WalletItem; 