import { Fragment } from 'react';
import { FC, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Gateway, isHardwareGateway } from '../context/GatewayContext';
import { GatewayIcon, CloudGatewayIcon } from './icons/icons';
import { 
  SunIcon,
  InformationCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CalendarIcon
} from '@heroicons/react/24/outline';
import { XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, 
  Legend, ResponsiveContainer, BarChart, Bar, AreaChart, Area, Cell, ReferenceArea } from 'recharts';
import SolarDataService, { 
  SolarDailyData, 
  TimeframeOption, 
  ResolutionOption 
} from '../services/SolarDataService';
import { Popover, Transition } from '@headlessui/react';
import { DerSerialNumber } from './DerSerialNumber';

// Interface definitions
interface SolarCurrentData {
  latest: {
    ts: string;
    power_W: number;
  } | null;
  today_kWh: number;
}

interface SolarGatewayState {
  currentData: SolarCurrentData;
  histogramData: Array<{
    ts: string;
    power_W: number;
  }>;
  dailyData: SolarDailyData[];
  isCurrentDataLoading: boolean;
  isHistogramLoading: boolean;
  isDailyDataLoading: boolean;
  error: string | null;
}

interface SolarGatewayDataProps {
  gateway: Gateway;
}

// Helper functions
const formatNumber = (value: number | undefined | null, decimals: number = 2) => {
  if (value === undefined || value === null) return '0.00';
  return value.toFixed(decimals);
};

// Helper for formatting time
const formatTime = (timestamp: string, includeDate: boolean = false) => {
  const date = new Date(timestamp);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return includeDate ? `${date.toLocaleDateString()} ${hours}:${minutes}` : `${hours}:${minutes}`;
};

// Convert watts to kilowatts
const wattsToKilowatts = (watts: number): number => {
  return watts / 1000;
};

// Helper function to get UTC timestamps for a gateway-local day
const getDayBoundariesInUtc = (dateStr: string, gatewayTimezone?: string): { startUtc: Date, endUtc: Date } => {
  if (!gatewayTimezone) {
    // No timezone provided, use browser local time
    const date = new Date(dateStr);
    const startOfDay = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    const endOfDay = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999));
    return { startUtc: startOfDay, endUtc: endOfDay };
  }

  try {
    // Parse the date string to get year, month, day
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    // Special case for Phoenix timezone (UTC-7)
    if (gatewayTimezone === 'America/Phoenix') {
      // We know Phoenix is UTC-7, so:
      // Midnight in Phoenix = 7:00 UTC
      const phoenixStartUtc = new Date(Date.UTC(year, month, day, 7, 0, 0)); // 7 AM UTC = midnight Phoenix
      const phoenixEndUtc = new Date(Date.UTC(year, month, day, 7 + 24, 0, 0) - 1); // Next day 7 AM UTC - 1ms
      return { startUtc: phoenixStartUtc, endUtc: phoenixEndUtc };
    }
    
    // More general approach for other timezones
    // To find when it's midnight in target timezone:
    
    // 1. Create a date at midnight in the reference timezone
    const targetDate = new Date(year, month, day);
    targetDate.setHours(0, 0, 0, 0);
    
    // 2. Convert it to UTC (will account for the timezone offset automatically)
    const targetDateUtcMs = targetDate.getTime() - (targetDate.getTimezoneOffset() * 60000);
    
    // 3. Create date objects from the UTC time
    const startUtc = new Date(targetDateUtcMs);
    const endUtc = new Date(targetDateUtcMs + 24 * 60 * 60 * 1000 - 1);
    
    return { startUtc, endUtc };
  } catch (error) {
    console.error('Error calculating day boundaries in UTC:', error);
    
    // Fallback to simple UTC approach
    const date = new Date(dateStr);
    const startOfDay = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    const endOfDay = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999));
    return { startUtc: startOfDay, endUtc: endOfDay };
  }
};

// Get timezone abbreviation
const getTimezoneAbbreviation = (date: Date): string => {
  const tzPart = new Intl.DateTimeFormat('en-US', {
    timeZoneName: 'short',
  }).formatToParts(date).find(part => part.type === 'timeZoneName');
  return tzPart ? tzPart.value : '';
};

// Convert a date to the specified timezone
const convertToTimezone = (date: Date, timezone: string): Date => {
  // Create a formatter that will format dates in the target timezone
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false
  });
  
  // Get the parts of the formatted date
  const parts = formatter.formatToParts(date);
  
  // Extract values from parts
  const year = parseInt(parts.find(part => part.type === 'year')?.value || '0');
  const month = parseInt(parts.find(part => part.type === 'month')?.value || '0') - 1; // Month is 0-based
  const day = parseInt(parts.find(part => part.type === 'day')?.value || '0');
  const hour = parseInt(parts.find(part => part.type === 'hour')?.value || '0');
  const minute = parseInt(parts.find(part => part.type === 'minute')?.value || '0');
  const second = parseInt(parts.find(part => part.type === 'second')?.value || '0');
  
  // Create a new date with the values in the target timezone
  return new Date(year, month, day, hour, minute, second);
};

// Custom Date Picker Component that adapts to the selected timeframe
interface AdaptiveDatePickerProps {
  timeframe: TimeframeOption;
  referenceDate: Date;
  isViewingCurrent: boolean;
  onDateChange: (newDate: Date) => void;
  onSetCurrent: () => void;
  gatewayTimezone?: string;
}

const AdaptiveDatePicker: FC<AdaptiveDatePickerProps> = ({
  timeframe,
  referenceDate,
  isViewingCurrent,
  onDateChange,
  onSetCurrent,
  gatewayTimezone
}) => {
  // Get the current date for today highlighting
  const today = new Date();
  
  // State for the calendar view
  const [calendarView, setCalendarView] = useState<'days' | 'months' | 'years'>('days');
  const [viewDate, setViewDate] = useState(() => new Date(referenceDate));
  
  // Add state for tracking hovered date to show range preview
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  
  // Update viewDate when referenceDate changes
  useEffect(() => {
    setViewDate(new Date(referenceDate));
  }, [referenceDate]);
  
  // Get month name
  const getMonthName = (month: number): string => {
    return new Date(2000, month, 1).toLocaleString('default', { month: 'long' });
  };
  
  // Get days in month
  const getDaysInMonth = (year: number, month: number): number => {
    return new Date(year, month + 1, 0).getDate();
  };
  
  // Get first day of month (0 = Sunday, 1 = Monday, etc.)
  const getFirstDayOfMonth = (year: number, month: number): number => {
    return new Date(year, month, 1).getDay();
  };
  
  // Generate array of days for the current month view
  const getDaysArray = (): Array<{ 
    date: Date | null; 
    isCurrentMonth: boolean; 
    isToday: boolean; 
    isSelected: boolean;
    isInHoverRange: boolean;
  }> => {
    const year = viewDate.getFullYear();
    const month = viewDate.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = getFirstDayOfMonth(year, month);
    
    // Calculate previous month days needed for calendar
    const prevMonth = month === 0 ? 11 : month - 1;
    const prevMonthYear = month === 0 ? year - 1 : year;
    const daysInPrevMonth = getDaysInMonth(prevMonthYear, prevMonth);
    
    // Calculate the range start date for visual highlighting based on timeframe
    // This is used to show the selected range in the calendar
    let rangeStartDate: Date;
    if (timeframe === 'month') {
      // For month view, start is 30 days before the reference date
      rangeStartDate = new Date(referenceDate);
      rangeStartDate.setDate(referenceDate.getDate() - 30);
    } else if (timeframe === 'week') {
      // For week view, start is 7 days before the reference date
      rangeStartDate = new Date(referenceDate);
      rangeStartDate.setDate(referenceDate.getDate() - 6);
    } else if (timeframe === 'day') {
      // For day view, start is the same day as the reference date (midnight)
      rangeStartDate = new Date(referenceDate);
      rangeStartDate.setHours(0, 0, 0, 0);
    } else if (timeframe === '12h') {
      // For 12h view, start is the same day as the reference date (midnight)
      // This ensures we only highlight one day in the calendar
      rangeStartDate = new Date(referenceDate);
      rangeStartDate.setHours(0, 0, 0, 0);
    } else {
      // Default fallback (should not happen)
      rangeStartDate = new Date(referenceDate);
    }
    
    // Calculate hover range if a date is being hovered
    let hoverRangeStart: Date | null = null;
    let hoverRangeEnd: Date | null = null;
    
    if (hoveredDate) {
      hoverRangeEnd = new Date(hoveredDate);
      hoverRangeEnd.setHours(23, 59, 59, 999);
      
      if (timeframe === 'month') {
        // For month view, hover range is 30 days ending on hovered date
        hoverRangeStart = new Date(hoveredDate);
        hoverRangeStart.setDate(hoveredDate.getDate() - 30);
      } else if (timeframe === 'week') {
        // For week view, hover range is 7 days ending on hovered date
        hoverRangeStart = new Date(hoveredDate);
        hoverRangeStart.setDate(hoveredDate.getDate() - 6);
      } else if (timeframe === 'day') {
        // For day view, hover range is just the hovered day
        hoverRangeStart = new Date(hoveredDate);
        hoverRangeStart.setHours(0, 0, 0, 0);
      } else if (timeframe === '12h') {
        // For 12h view, hover range is 12 hours ending on the hovered date
        hoverRangeStart = new Date(hoveredDate);
        hoverRangeStart.setHours(0, 0, 0, 0);
      }
    }
    
    // Normalize dates to midnight for proper date comparison in calendar
    const rangeStartMidnight = new Date(rangeStartDate);
    rangeStartMidnight.setHours(0, 0, 0, 0);
    const rangeEndMidnight = new Date(referenceDate);
    rangeEndMidnight.setHours(0, 0, 0, 0);
    
    // Normalize hover dates to midnight for comparison
    const hoverStartMidnight = hoverRangeStart ? new Date(hoverRangeStart) : null;
    if (hoverStartMidnight) hoverStartMidnight.setHours(0, 0, 0, 0);
    const hoverEndMidnight = hoverRangeEnd ? new Date(hoverRangeEnd) : null;
    if (hoverEndMidnight) hoverEndMidnight.setHours(0, 0, 0, 0);
    
    const days: Array<{ 
      date: Date | null; 
      isCurrentMonth: boolean; 
      isToday: boolean; 
      isSelected: boolean;
      isInHoverRange: boolean;
    }> = [];
    
    // Add previous month days
    for (let i = 0; i < firstDayOfMonth; i++) {
      const day = daysInPrevMonth - firstDayOfMonth + i + 1;
      const date = new Date(prevMonthYear, prevMonth, day);
      const dateMidnight = new Date(date);
      dateMidnight.setHours(0, 0, 0, 0);
      
      days.push({
        date,
        isCurrentMonth: false,
        isToday: date.toDateString() === today.toDateString(),
        isSelected: dateMidnight >= rangeStartMidnight && dateMidnight <= rangeEndMidnight,
        isInHoverRange: Boolean(
          hoverStartMidnight && 
          hoverEndMidnight && 
          dateMidnight >= hoverStartMidnight && 
          dateMidnight <= hoverEndMidnight &&
          !(dateMidnight >= rangeStartMidnight && dateMidnight <= rangeEndMidnight)
        )
      });
    }
    
    // Add current month days
    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(year, month, i);
      const dateMidnight = new Date(date);
      dateMidnight.setHours(0, 0, 0, 0);
      
      days.push({
        date,
        isCurrentMonth: true,
        isToday: date.toDateString() === today.toDateString(),
        isSelected: dateMidnight >= rangeStartMidnight && dateMidnight <= rangeEndMidnight,
        isInHoverRange: Boolean(
          hoverStartMidnight && 
          hoverEndMidnight && 
          dateMidnight >= hoverStartMidnight && 
          dateMidnight <= hoverEndMidnight &&
          !(dateMidnight >= rangeStartMidnight && dateMidnight <= rangeEndMidnight)
        )
      });
    }
    
    // Add next month days to fill 6 rows (42 days)
    const daysNeeded = 42 - days.length;
    for (let i = 1; i <= daysNeeded; i++) {
      const date = new Date(year, month + 1, i);
      const dateMidnight = new Date(date);
      dateMidnight.setHours(0, 0, 0, 0);
      
      days.push({
        date,
        isCurrentMonth: false,
        isToday: date.toDateString() === today.toDateString(),
        isSelected: dateMidnight >= rangeStartMidnight && dateMidnight <= rangeEndMidnight,
        isInHoverRange: Boolean(
          hoverStartMidnight && 
          hoverEndMidnight && 
          dateMidnight >= hoverStartMidnight && 
          dateMidnight <= hoverEndMidnight &&
          !(dateMidnight >= rangeStartMidnight && dateMidnight <= rangeEndMidnight)
        )
      });
    }
    
    return days;
  };
  
  // Generate array of months for year view
  const getMonthsArray = (): Array<{ month: number; name: string; isCurrentMonth: boolean; isSelected: boolean }> => {
    const months = [];
    const currentMonth = today.getMonth();
    const selectedMonth = referenceDate.getMonth();
    const selectedYear = referenceDate.getFullYear();
    const viewYear = viewDate.getFullYear();
    
    for (let i = 0; i < 12; i++) {
      months.push({
        month: i,
        name: getMonthName(i),
        isCurrentMonth: i === currentMonth && viewYear === today.getFullYear(),
        isSelected: i === selectedMonth && viewYear === selectedYear
      });
    }
    
    return months;
  };
  
  // Generate array of years for decade view
  const getYearsArray = (): Array<{ year: number; isCurrentYear: boolean; isSelected: boolean }> => {
    const years = [];
    const currentYear = today.getFullYear();
    const selectedYear = referenceDate.getFullYear();
    const startYear = Math.floor(viewDate.getFullYear() / 10) * 10;
    
    for (let i = startYear - 1; i <= startYear + 10; i++) {
      years.push({
        year: i,
        isCurrentYear: i === currentYear,
        isSelected: i === selectedYear
      });
    }
    
    return years;
  };
  
  // Handle day selection
  const handleDaySelect = (date: Date | null, closePopover?: () => void) => {
    if (!date) return;
    
    // For all views, set the reference date to the end of the selected day
    // This is more intuitive as we show data ending on the selected date
    const newDate = new Date(date);
    
    if (timeframe === 'day') {
      // Set to end of day (23:59:59)
      newDate.setHours(23, 59, 59, 999);
    } else if (timeframe === '12h') {
      // For 12h view, set to 18:00 of the selected day
      // This will show data from 06:00 to 18:00
      newDate.setHours(18, 0, 0, 0);
    } else if (timeframe === 'week') {
      // The selected day will be the LAST day of the 7-day period
      newDate.setHours(23, 59, 59, 999);
    } else if (timeframe === 'month') {
      // The selected day will be the LAST day of the month period
      newDate.setHours(23, 59, 59, 999);
    }
    
    // Check if future date
    if (newDate > today) {
      onSetCurrent();
    } else {
      onDateChange(newDate);
    }
    
    // Close the popover after selecting a date
    if (closePopover) closePopover();
  };
  
  // Handle month selection
  const handleMonthSelect = (month: number, closePopover?: () => void) => {
    const newViewDate = new Date(viewDate);
    newViewDate.setMonth(month);
    
    // If timeframe is month, set reference date to end of the selected month
    if (timeframe === 'month') {
      // Calculate the last day of the selected month
      const endOfMonth = new Date(newViewDate.getFullYear(), month + 1, 0);
      endOfMonth.setHours(23, 59, 59, 999);
      
      // Check if future date
      if (endOfMonth > today) {
        onSetCurrent();
      } else {
        onDateChange(endOfMonth);
      }
      
      // Close the popover after selecting a month
      if (closePopover) closePopover();
    } else {
      // For other timeframes, update view date and switch to days view
      // This allows selecting a specific day within the month
      setViewDate(newViewDate);
      setCalendarView('days');
    }
  };
  
  // Handle year selection
  const handleYearSelect = (year: number) => {
    const newViewDate = new Date(viewDate);
    newViewDate.setFullYear(year);
    setViewDate(newViewDate);
    setCalendarView('months');
  };
  
  // Navigate to previous month/year
  const navigatePrevious = () => {
    const newViewDate = new Date(viewDate);
    if (calendarView === 'days') {
      newViewDate.setMonth(newViewDate.getMonth() - 1);
    } else if (calendarView === 'months') {
      newViewDate.setFullYear(newViewDate.getFullYear() - 1);
    } else if (calendarView === 'years') {
      newViewDate.setFullYear(newViewDate.getFullYear() - 10);
    }
    setViewDate(newViewDate);
  };
  
  // Navigate to next month/year
  const navigateNext = () => {
    const newViewDate = new Date(viewDate);
    if (calendarView === 'days') {
      newViewDate.setMonth(newViewDate.getMonth() + 1);
    } else if (calendarView === 'months') {
      newViewDate.setFullYear(newViewDate.getFullYear() + 1);
    } else if (calendarView === 'years') {
      newViewDate.setFullYear(newViewDate.getFullYear() + 10);
    }
    setViewDate(newViewDate);
  };
  
  // Navigate to current month/year
  const navigateToCurrent = () => {
    setViewDate(new Date());
    setCalendarView('days');
  };
  
  // Format the date range for display in the button based on timeframe
  const formatDateRange = (): string => {
    const dateInGatewayTimezone = convertToTimezone(referenceDate, gatewayTimezone || 'UTC');
    
    if (timeframe === 'month') {
      // For month view, show the 30 days ending on the reference date
      const startDate = new Date(dateInGatewayTimezone);
      startDate.setDate(startDate.getDate() - 30); // Go back 30 days
      
      return `${startDate.toLocaleDateString(undefined, { month: 'short', day: 'numeric' })} - ${dateInGatewayTimezone.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}`;
    } else if (timeframe === 'week') {
      // For week view, show the 7 days ending on the reference date
      const startDate = new Date(dateInGatewayTimezone);
      startDate.setDate(startDate.getDate() - 6); // Go back 6 days
      
      return `${startDate.toLocaleDateString(undefined, { month: 'short', day: 'numeric' })} - ${dateInGatewayTimezone.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}`;
    } else if (timeframe === 'day') {
      // For day view, show the 24 hours ending on the reference date
      const startDate = new Date(dateInGatewayTimezone);
      startDate.setHours(startDate.getHours() - 24); // Go back 24 hours
      
      // Check if the start and end dates are different
      const startDay = startDate.getDate();
      const endDay = dateInGatewayTimezone.getDate();
      
      if (startDay !== endDay) {
        // If spanning multiple days, show the date range
        return `${startDate.toLocaleDateString(undefined, { month: 'short', day: 'numeric' })} - ${dateInGatewayTimezone.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}`;
      }
      
      // If same day, show just the date
      return `${dateInGatewayTimezone.toLocaleDateString(undefined, { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' })}`;
    } else if (timeframe === '12h') {
      // For 12h view, show the date range (12 hours ending at reference date)
      const startDate = new Date(dateInGatewayTimezone);
      startDate.setHours(startDate.getHours() - 12); // Go back 12 hours
      
      // Check if the start and end dates are different
      const startDay = startDate.getDate();
      const endDay = dateInGatewayTimezone.getDate();
      
      // Format with dates only
      if (startDay !== endDay) {
        // If spanning multiple days, show the date range
        return `${startDate.toLocaleDateString(undefined, { month: 'short', day: 'numeric' })} - ${dateInGatewayTimezone.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}`;
      }
      
      // If same day, show just one date
      return `${dateInGatewayTimezone.toLocaleDateString(undefined, { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' })}`;
    }
    
    // Default format
    return dateInGatewayTimezone.toLocaleDateString();
  };
  
  // Render appropriate calendar view
  const renderCalendarView = (closePopover: () => void) => {
    if (calendarView === 'years') {
      const years = getYearsArray();
      const decadeStart = Math.floor(viewDate.getFullYear() / 10) * 10;
      
      return (
        <div>
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={navigatePrevious}
              className="p-1 rounded-md hover:bg-gray-700 text-gray-300"
            >
              <ChevronLeftIcon className="w-5 h-5" />
            </button>
            <span className="text-gray-300 text-sm">
              {decadeStart} - {decadeStart + 9}
            </span>
            <button
              onClick={navigateNext}
              className="p-1 rounded-md hover:bg-gray-700 text-gray-300"
            >
              <ChevronRightIcon className="w-5 h-5" />
            </button>
          </div>
          
          <div className="grid grid-cols-3 gap-2">
            {years.map((yearData) => (
              <button
                key={yearData.year}
                onClick={() => handleYearSelect(yearData.year)}
                className={`
                  p-1 rounded-md text-sm 
                  ${yearData.isSelected ? 'bg-blue-600 text-white' : 
                    yearData.isCurrentYear ? 'text-blue-400 font-bold' : 'text-gray-300 hover:bg-gray-700'}
                `}
              >
                {yearData.year}
              </button>
            ))}
          </div>
        </div>
      );
    } else if (calendarView === 'months') {
      const months = getMonthsArray();
      
      return (
        <div>
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={navigatePrevious}
              className="p-1 rounded-md hover:bg-gray-700 text-gray-300"
            >
              <ChevronLeftIcon className="w-5 h-5" />
            </button>
            <button
              onClick={() => setCalendarView('years')}
              className="text-gray-300 text-sm hover:bg-gray-700 rounded-md px-2 py-1"
            >
              {viewDate.getFullYear()}
            </button>
            <button
              onClick={navigateNext}
              className="p-1 rounded-md hover:bg-gray-700 text-gray-300"
            >
              <ChevronRightIcon className="w-5 h-5" />
            </button>
          </div>
          
          <div className="grid grid-cols-3 gap-2">
            {months.map((monthData) => (
              <button
                key={monthData.month}
                onClick={() => {
                  if (timeframe === 'month') {
                    handleMonthSelect(monthData.month, closePopover);
                  } else {
                    handleMonthSelect(monthData.month);
                  }
                }}
                className={`
                  p-1 rounded-md text-sm 
                  ${monthData.isSelected ? 'bg-blue-600 text-white' : 
                    monthData.isCurrentMonth ? 'text-blue-400 font-bold' : 'text-gray-300 hover:bg-gray-700'}
                `}
              >
                {monthData.name}
              </button>
            ))}
          </div>
        </div>
      );
    } else {
      // Default is days view
      const days = getDaysArray();
      const dayLabels = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
      
      return (
        <div>
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={navigatePrevious}
              className="p-1 rounded-md hover:bg-gray-700 text-gray-300"
            >
              <ChevronLeftIcon className="w-5 h-5" />
            </button>
            <button
              onClick={() => setCalendarView('months')}
              className="text-gray-300 text-sm hover:bg-gray-700 rounded-md px-2 py-1"
            >
              {getMonthName(viewDate.getMonth())} {viewDate.getFullYear()}
            </button>
            <button
              onClick={navigateNext}
              className="p-1 rounded-md hover:bg-gray-700 text-gray-300"
            >
              <ChevronRightIcon className="w-5 h-5" />
            </button>
          </div>
          
          <div className="grid grid-cols-7 gap-1 mb-1">
            {dayLabels.map((day) => (
              <div key={day} className="text-center text-xs text-gray-500">
                {day}
              </div>
            ))}
          </div>
          
          <div className="grid grid-cols-7 gap-1">
            {days.map((dayData, index) => (
              <button
                key={index}
                onClick={() => dayData.date && handleDaySelect(dayData.date, closePopover)}
                onMouseEnter={() => dayData.date && setHoveredDate(dayData.date)}
                onMouseLeave={() => setHoveredDate(null)}
                disabled={dayData.date ? dayData.date > today : false}
                className={`
                  p-1 rounded-md text-xs h-7 flex items-center justify-center
                  ${dayData.isSelected ? 'bg-blue-600 text-white' : 
                    dayData.isInHoverRange ? 'bg-blue-500/25 text-gray-300' :
                    dayData.isToday ? 'text-blue-400 font-bold' : 
                    !dayData.isCurrentMonth ? 'text-gray-500' : 
                    dayData.date && dayData.date > today ? 'text-gray-600' : 'text-gray-300 hover:bg-gray-700'}
                `}
              >
                {dayData.date?.getDate()}
              </button>
            ))}
          </div>
        </div>
      );
    }
  };
  
  
  return (
    <div className="relative">
      <Popover>
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                flex items-center gap-1 bg-gray-700 hover:bg-gray-600 
                text-gray-200 text-sm px-3 py-1.5 rounded-lg
                ${open ? 'bg-gray-600' : ''}
              `}
            >
              <CalendarIcon className="w-4 h-4 mr-1" />
              <span>{formatDateRange()}</span>
              {isViewingCurrent && <span className="text-xs ml-1 text-blue-400">(Current)</span>}
            </Popover.Button>
            
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 mt-2 w-64 max-w-xs transform sm:px-0">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative bg-gray-800 border border-gray-700 p-4">
                    {renderCalendarView(close)}
                    
                    <div className="mt-4 flex justify-between">
                      <button
                        onClick={navigateToCurrent}
                        className="text-xs text-blue-400 hover:text-blue-300"
                      >
                        Today
                      </button>
                      
                      <button
                        onClick={onSetCurrent}
                        className="text-xs text-blue-400 hover:text-blue-300"
                      >
                        Go to Current
                      </button>
                    </div>
                    
                    {gatewayTimezone && (
                      <div className="mt-2 text-xs text-gray-500 text-center">
                        Gateway timezone: {gatewayTimezone}
                      </div>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

const PvDerInfo: FC<{ gateway: Gateway }> = ({ gateway }) => {
  const solarDers = gateway.ders?.filter(der => 
    der.type === 'Solar' || der.sn?.toLowerCase().startsWith('pv-')
  ) || [];

  if (solarDers.length === 0) {
    return (
      <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700">
        <p className="text-center text-gray-400">No Solar DERs found</p>
      </div>
    );
  }

  return (
    <div className="flex flex-wrap gap-4">
      {solarDers.map((der, index) => (
        <DerSerialNumber
          key={der.sn || index}
          sn={der.sn || 'Unknown'}
          lastSeen={der.lastSeen || new Date().toISOString()}
        />
      ))}
    </div>
  );
};

export const SolarGatewayData: FC<SolarGatewayDataProps> = ({ gateway }) => {
  const [state, setState] = useState<SolarGatewayState>({
    currentData: {
      latest: null,
      today_kWh: 0
    },
    histogramData: [],
    dailyData: [],
    isCurrentDataLoading: true,
    isHistogramLoading: true,
    isDailyDataLoading: true,
    error: null
  });
  
  // Add internal state for timeframe and resolution
  const [timeframe, setTimeframe] = useState<TimeframeOption>('day');
  const [histogramResolution, setHistogramResolution] = useState<ResolutionOption>('15m');
  
  // Add state to track pending timeframe/resolution changes
  const [pendingTimeframe, setPendingTimeframe] = useState<TimeframeOption | null>(null);
  const [pendingResolution, setPendingResolution] = useState<ResolutionOption | null>(null);
  
  // Add reference date state for time navigation
  const [referenceDate, setReferenceDate] = useState<Date>(new Date());
  // Track if we're viewing current time or a historical period
  const [isViewingCurrent, setIsViewingCurrent] = useState<boolean>(true);
  // Add state to track hovered day for cross-chart highlighting
  const [hoveredDay, setHoveredDay] = useState<string | null>(null);

  // Use refs to prevent unnecessary re-renders
  const isFetchingCurrentRef = useRef(false);
  const isFetchingDailyRef = useRef(false);
  const timeframeRef = useRef(timeframe);
  const histogramResolutionRef = useRef(histogramResolution);
  // Add ref for tracking last fetch time to prevent too frequent updates
  const lastCurrentDataFetchTimeRef = useRef<number>(0);
  const lastDailyDataFetchTimeRef = useRef<number>(0);
  
  // Update refs when values change
  useEffect(() => {
    timeframeRef.current = timeframe;
  }, [timeframe]);
  
  useEffect(() => {
    histogramResolutionRef.current = histogramResolution;
  }, [histogramResolution]);

  // Fetch current data and today's total
  const updateCurrentData = useCallback(async () => {
    // Check if minimum time since last fetch has passed (45 seconds)
    const now = Date.now();
    const minTimeBetweenFetches = 45 * 1000; // 45 seconds minimum between fetches
    
    if (now - lastCurrentDataFetchTimeRef.current < minTimeBetweenFetches) {
      console.log('Skipping current data fetch - too soon since last fetch');
      return;
    }
    
    // Prevent multiple parallel fetches
    if (isFetchingCurrentRef.current) return;
    isFetchingCurrentRef.current = true;
    
    try {
      // Only show loading on initial load when we have no data
      const isInitialLoad = !state.currentData.latest;
      
      // Update last fetch time
      lastCurrentDataFetchTimeRef.current = now;
      
      // Just fetch current data without histogram
      const currentTimeframe = timeframeRef.current;
      const currentResolution = histogramResolutionRef.current;
      
      // If initial load, set loading state
      if (isInitialLoad) {
        setState(prev => ({ ...prev, isCurrentDataLoading: true }));
      }
      
      const solarData = await SolarDataService.getSolarData(
        gateway.id, 
        currentTimeframe, 
        currentResolution
      );
      
      if (solarData) {
        setState(prev => ({
          ...prev,
          currentData: {
            latest: solarData.latest,
            today_kWh: solarData.today_kWh
          },
          isCurrentDataLoading: false
        }));
      }
    } catch (err) {
      console.error(`Error updating current solar data for gateway ${gateway.id}:`, err);
      setState(prev => ({
        ...prev,
        isCurrentDataLoading: false,
        error: `Failed to fetch current solar data for gateway ${gateway.id}`
      }));
    } finally {
      isFetchingCurrentRef.current = false;
    }
  }, [gateway.id, state.currentData.latest]);
  
  // Fetch histogram data based on timeframe and resolution
  const updateHistogramData = useCallback(async () => {
    // Simple approach - when user changes parameters, fetch immediately
    try {
      // Set loading state
      setState(prev => ({ ...prev, isHistogramLoading: true }));
      
      // Calculate date range based on reference date and timeframe
      const endDate = isViewingCurrent ? new Date() : new Date(referenceDate);
      let startDate = new Date(endDate);
      
      if (timeframe === '12h') {
        startDate.setHours(endDate.getHours() - 12);
      } else if (timeframe === 'day') {
        startDate.setDate(endDate.getDate() - 1);
      } else if (timeframe === 'week') {
        startDate.setDate(endDate.getDate() - 7);
      } else if (timeframe === 'month') {
        startDate.setMonth(endDate.getMonth() - 1);
      }
      
      // Fetch the data with current parameters and custom date range
      const solarData = await SolarDataService.getSolarData(
        gateway.id, 
        timeframe, 
        histogramResolution,
        startDate,
        endDate
      );
      
      if (solarData) {
        setState(prev => ({
          ...prev,
          histogramData: solarData.histogram || [],
          isHistogramLoading: false
        }));
        
        // Clear any pending changes now that we've loaded the data
        setPendingTimeframe(null);
        setPendingResolution(null);
      }
    } catch (err) {
      console.error(`Error updating histogram data for gateway ${gateway.id}:`, err);
      setState(prev => ({
        ...prev,
        isHistogramLoading: false,
        error: `Failed to fetch histogram data for gateway ${gateway.id}`
      }));
      
      // Clear pending states on error too
      setPendingTimeframe(null);
      setPendingResolution(null);
    }
  }, [gateway.id, timeframe, histogramResolution, isViewingCurrent, referenceDate]);
  
  // Handle timeframe change - track pending state until data loads
  const handleTimeframeChange = (newTimeframe: TimeframeOption) => {
    // Only proceed if this is actually a change
    if (newTimeframe === timeframe) return;
    
    // Set pending timeframe state to show loading indicator
    setPendingTimeframe(newTimeframe);
    
    // Auto-adjust resolution when changing timeframes
    // When going to a longer timeframe, reduce resolution
    
    // If switching to month view, ensure appropriate resolution
    if (newTimeframe === 'month') {
      if (histogramResolution === '15m' || histogramResolution === '2m') {
        setPendingResolution('1h');
        setHistogramResolution('1h');
      }
    }
    // If switching to week view from day or 12h, adjust resolution if too detailed
    else if (newTimeframe === 'week') {
      if (histogramResolution === '2m') {
        setPendingResolution('15m');
        setHistogramResolution('15m');
      } else if (timeframe === 'day' && histogramResolution === '15m') {
        setPendingResolution('1h');
        setHistogramResolution('1h');
      }
    }
    // If switching to day view from 12h, adjust from 2m to 15m
    else if (newTimeframe === 'day' && timeframe === '12h' && histogramResolution === '2m') {
      setPendingResolution('15m');
      setHistogramResolution('15m');
    }
    // If switching to 12h view, default to 2m resolution if not using it
    else if (newTimeframe === '12h' && histogramResolution !== '2m') {
      setPendingResolution('2m');
      setHistogramResolution('2m');
    }
    
    // Calculate the middle point of the current view period
    const currentRefDate = referenceDate;
    
    // Find the middle point of the current view
    const middlePoint = new Date();
    
    if (timeframe === 'month') {
      // Middle of month view is 15 days before end
      middlePoint.setTime(currentRefDate.getTime() - 15 * 24 * 60 * 60 * 1000);
    } else if (timeframe === 'week') {
      // Middle of week view is 3.5 days before end
      middlePoint.setTime(currentRefDate.getTime() - 3.5 * 24 * 60 * 60 * 1000);
    } else if (timeframe === 'day') {
      // Middle of day view is 12 hours before end
      middlePoint.setTime(currentRefDate.getTime() - 12 * 60 * 60 * 1000);
    } else {
      // Middle of 12h view is 6 hours before end
      middlePoint.setTime(currentRefDate.getTime() - 6 * 60 * 60 * 1000);
    }
    
    // Calculate new reference date based on the middle point
    let newReferenceDate = new Date();
    
    if (newTimeframe === 'month') {
      // End of month view is 15 days after middle
      newReferenceDate.setTime(middlePoint.getTime() + 15 * 24 * 60 * 60 * 1000);
    } else if (newTimeframe === 'week') {
      // End of week view is 3.5 days after middle
      newReferenceDate.setTime(middlePoint.getTime() + 3.5 * 24 * 60 * 60 * 1000);
    } else if (newTimeframe === 'day') {
      // End of day view is 12 hours after middle
      newReferenceDate.setTime(middlePoint.getTime() + 12 * 60 * 60 * 1000);
    } else {
      // End of 12h view is 6 hours after middle
      newReferenceDate.setTime(middlePoint.getTime() + 6 * 60 * 60 * 1000);
    }
    
    // Check if new reference date is in the future
    const now = new Date();
    if (newReferenceDate > now) {
      // If we'd go into the future, adjust to current time
      setReferenceDate(now);
      setIsViewingCurrent(true);
    } else {
      // Otherwise use our calculated reference date
      setReferenceDate(newReferenceDate);
      // We're not viewing current time if we've adjusted the reference date
      setIsViewingCurrent(false);
    }
    
    // Update actual timeframe state to trigger data fetch
    setTimeframe(newTimeframe);
  };

  // Handle resolution change - track pending state until data loads
  const handleResolutionChange = (resolution: ResolutionOption) => {
    // Only proceed if this is actually a change
    if (resolution === histogramResolution) return;
    
    // If switching to month view with 15m or 2m resolution, 
    // block the change
    if (timeframe === 'month' && (resolution === '15m' || resolution === '2m')) return;
    
    // If switching to timeframes other than 12h or day with 2m resolution,
    // block the change
    if ((timeframe !== '12h' && timeframe !== 'day') && resolution === '2m') return;
    
    // Set pending resolution state to show loading indicator
    setPendingResolution(resolution);
    
    // Update actual resolution state to trigger data fetch
    setHistogramResolution(resolution);
  };

  // Update histogram data when timeframe or resolution changes
  useEffect(() => {
    updateHistogramData();
  }, [timeframe, histogramResolution, updateHistogramData]);

  // Fetch daily solar data 
  const updateDailyData = useCallback(async () => {
    // Check if minimum time since last fetch has passed (1 hour)
    const now = Date.now();
    const minTimeBetweenFetches = 60 * 60 * 1000; // 1 hour minimum between fetches
    
    if (now - lastDailyDataFetchTimeRef.current < minTimeBetweenFetches) {
      console.log('Skipping daily data fetch - too soon since last fetch');
      return;
    }
    
    // Prevent multiple parallel fetches
    if (isFetchingDailyRef.current) return;
    isFetchingDailyRef.current = true;
    
    try {
      // Only show loading on initial load when we have no data
      const isInitialLoad = state.dailyData.length === 0;
      
      // Update last fetch time
      lastDailyDataFetchTimeRef.current = now;
      
      // If initial load, set loading state
      if (isInitialLoad) {
        setState(prev => ({ ...prev, isDailyDataLoading: true }));
      }
      
      const dailyDataResponse = await SolarDataService.getDailySolarData(gateway.id);
      
      setState(prev => ({
        ...prev,
        dailyData: dailyDataResponse,
        isDailyDataLoading: false
      }));
    } catch (err) {
      console.error(`Error updating daily solar data for gateway ${gateway.id}:`, err);
      setState(prev => ({
        ...prev,
        isDailyDataLoading: false,
        error: `Failed to fetch daily solar data for gateway ${gateway.id}`
      }));
    } finally {
      isFetchingDailyRef.current = false;
    }
  }, [gateway.id, state.dailyData.length]);

  // Initial data fetch on component mount
  useEffect(() => {
    // Fetch all data types on mount
    updateCurrentData();
    updateHistogramData();
    updateDailyData();
  }, [updateCurrentData, updateHistogramData, updateDailyData]);

  // Set up polling intervals for different data types
  useEffect(() => {
    // Current data + today's production - refresh every minute
    const currentDataInterval = setInterval(() => {
      updateCurrentData();
    }, 60000); // 1 minute
    
    // Cleanup interval on unmount
    return () => {
      clearInterval(currentDataInterval);
    };
  }, [updateCurrentData]); // This ensures a single interval is created
  
  // Set up dynamic interval for histogram data based on resolution
  useEffect(() => {
    // Determine refresh interval based on resolution
    let refreshInterval: number;
    
    switch (histogramResolution) {
      case '2m':
        refreshInterval = 2 * 60 * 1000; // 2 minutes
        break;
      case '15m':
        refreshInterval = 15 * 60 * 1000; // 15 minutes
        break;
      case '1h':
        refreshInterval = 60 * 60 * 1000; // 1 hour
        break;
      case '4h':
        refreshInterval = 4 * 60 * 60 * 1000; // 4 hours
        break;
      default:
        refreshInterval = 15 * 60 * 1000; // Default to 15 minutes
    }
    
    const histogramInterval = setInterval(() => {
      updateHistogramData();
    }, refreshInterval);
    
    // Cleanup interval on unmount or when resolution changes
    return () => {
      clearInterval(histogramInterval);
    };
  }, [histogramResolution, updateHistogramData]);
  
  // Update daily data once per day (every 24 hours)
  useEffect(() => {
    const dailyDataInterval = setInterval(() => {
      updateDailyData();
    }, 24 * 60 * 60 * 1000); // 24 hours
    
    return () => {
      clearInterval(dailyDataInterval);
    };
  }, [updateDailyData]);

  const { currentData, histogramData, dailyData, isCurrentDataLoading, isHistogramLoading, isDailyDataLoading, error } = state;

  // Calculate stats for display with proper unit conversions
  const currentPower = currentData?.latest ? wattsToKilowatts(currentData.latest.power_W) : 0;
  const todayTotal = currentData?.today_kWh || 0; // Already in kWh from the API
  
  // Memoize histogram data conversion to prevent unnecessary recalculations
  const convertedHistogramData = useCallback(() => {
    return histogramData.map(item => ({
      ts: item.ts,
      power: wattsToKilowatts(item.power_W),
      // Add a date string field for easier comparison with hoveredDay
      dateStr: new Date(item.ts).toISOString().split('T')[0]
    }));
  }, [histogramData]);
  
  // Memoize the result of the histogram data conversion
  const memoizedHistogramData = useMemo(() => convertedHistogramData(), [convertedHistogramData]);
  
  // Extract data points for the hovered day
  const hoveredDayData = useMemo(() => {
    if (!hoveredDay) return [];
    
    // Calculate the UTC boundaries for the day in gateway timezone
    const { startUtc, endUtc } = getDayBoundariesInUtc(hoveredDay, gateway.timeZone);
    
    // Filter data points that fall within the UTC day boundaries
    const filteredData = memoizedHistogramData.filter(item => {
      const itemDate = new Date(item.ts);
      return itemDate >= startUtc && itemDate <= endUtc;
    });
    
    return filteredData;
  }, [hoveredDay, memoizedHistogramData, gateway.timeZone]);
  
  // Daily data is already in kWh
  const convertedDailyData = dailyData;
  
  // Memoize average calculation to prevent recalculations on every render
  const avgDailyPower = useMemo(() => {
    return convertedDailyData.length > 0 
      ? convertedDailyData.reduce((sum, item) => sum + item.power_kWh, 0) / convertedDailyData.length
      : 0;
  }, [convertedDailyData]);

  // Memoize the daily chart data transformation
  const dailyChartData = useMemo(() => {
    return convertedDailyData.map(day => {
      const date = new Date(day.ts);
      // We need to keep the original timestamp from API, which is in gateway's timezone
      // but convert it to display correctly in browser's timezone
      const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return {
        date: localDate.toISOString(),
        originalTs: day.ts, // Keep the original timestamp
        power: day.power_kWh,
        originalEntry: day
      };
    });
  }, [convertedDailyData]);

  // Memoize the statistics calculation for bar coloring
  const dailyDataStats = useMemo(() => {
    if (convertedDailyData.length === 0) return { avg: 0, stdDev: 0 };
    
    const values = convertedDailyData.map(item => item.power_kWh);
    const avg = values.reduce((a, b) => a + b, 0) / values.length;
    
    // Calculate standard deviation
    const squaredDiffs = values.map(value => Math.pow(value - avg, 2));
    const avgSquaredDiff = squaredDiffs.reduce((a, b) => a + b, 0) / squaredDiffs.length;
    const stdDev = Math.sqrt(avgSquaredDiff);
    
    return { avg, stdDev };
  }, [convertedDailyData]);

  // If all data is loading, show a loading state
  const isLoading = isCurrentDataLoading && isHistogramLoading && isDailyDataLoading;
  
  // Add a new handler for chart click to implement drill-down
  const handleChartClick = useCallback((data: any) => {
    // If no active data point, return
    if (!data || !data.activePayload || data.activePayload.length === 0) {
      return;
    }
    
    const clickedPoint = data.activePayload[0].payload;
    const clickedDate = new Date(clickedPoint.ts);
    const now = new Date();
    
    // Implement drill-down based on current timeframe
    if (timeframe === 'month') {
      // Drill down from month to week - centered on the clicked date
      // For a centered view, we need the reference date to be 3.5 days ahead of the clicked date
      const newEndDate = new Date(clickedDate);
      newEndDate.setDate(clickedDate.getDate() + 3.5);
      
      // If the calculated end date is in the future, adjust it
      if (newEndDate > now) {
        setReferenceDate(now);
        setIsViewingCurrent(true);
      } else {
        setReferenceDate(newEndDate);
        setIsViewingCurrent(false);
      }
      
      // Increase resolution one step if possible
      if (histogramResolution === '4h') {
        setPendingResolution('1h');
        setHistogramResolution('1h');
      } else if (histogramResolution === '1h' && timeframe === 'month') {
        // Keep resolution at 1h when moving from month to week
        // as '15m' might be too granular for a full week view
      }
      
      setPendingTimeframe('week');
      setTimeframe('week');
    } else if (timeframe === 'week') {
      // Drill down from week to day - centered on the clicked time
      // For a centered view, we need the reference date to be 12 hours ahead of the clicked time
      const newEndDate = new Date(clickedDate);
      newEndDate.setHours(clickedDate.getHours() + 12);
      
      // If the calculated end date is in the future, adjust it
      if (newEndDate > now) {
        setReferenceDate(now);
        setIsViewingCurrent(true);
      } else {
        setReferenceDate(newEndDate);
        setIsViewingCurrent(false);
      }
      
      // Increase resolution one step if possible
      if (histogramResolution === '4h') {
        setPendingResolution('1h');
        setHistogramResolution('1h');
      } else if (histogramResolution === '1h') {
        setPendingResolution('15m');
        setHistogramResolution('15m');
      }
      
      setPendingTimeframe('day');
      setTimeframe('day');
    } else if (timeframe === 'day') {
      // Drill down from day to 12h - centered on the clicked time
      // For a centered view, we need the reference date to be 6 hours ahead of the clicked time
      const newEndDate = new Date(clickedDate);
      newEndDate.setHours(clickedDate.getHours() + 6);
      
      // If the calculated end date is in the future, adjust it
      if (newEndDate > now) {
        setReferenceDate(now);
        setIsViewingCurrent(true);
      } else {
        setReferenceDate(newEndDate);
        setIsViewingCurrent(false);
      }
      
      // Set to 2m resolution for highest detail
      setPendingResolution('2m');
      setHistogramResolution('2m');
      
      setPendingTimeframe('12h');
      setTimeframe('12h');
    }
    
    // Trigger data fetch with new settings
    updateHistogramData();
  }, [timeframe, histogramResolution, updateHistogramData]);

  // Handle click on the Daily Production chart
  const handleDailyChartClick = useCallback((data: any) => {
    // If no active data point, return
    if (!data || !data.activePayload || data.activePayload.length === 0) {
      return;
    }
    
    const payload = data.activePayload[0].payload;
    if (payload && payload.date) {
      // Use original timestamp for more accurate date in gateway timezone
      const originalTimestamp = payload.originalTs || payload.date;
      
      // Get the current time for comparison
      const now = new Date();
      
      // Calculate the UTC boundaries for the day in gateway timezone
      const { startUtc, endUtc } = getDayBoundariesInUtc(originalTimestamp, gateway.timeZone);
      
      // If the clicked day's end is in the future, adjust to now
      if (endUtc > now) {
        setReferenceDate(now);
        setIsViewingCurrent(true);
      } else {
        // Set the reference date to the end of the day in UTC
        setReferenceDate(endUtc);
        setIsViewingCurrent(false);
      }
      
      // Set to day timeframe and 15m resolution
      setPendingTimeframe('day');
      setPendingResolution('15m');
      setTimeframe('day');
      setHistogramResolution('15m');
      
      // Trigger data fetch with new settings using the UTC time range
      // This ensures we get exactly the full day in the gateway's local timezone
      (async () => {
        try {
          setState(prev => ({ ...prev, isHistogramLoading: true }));
          
          const solarData = await SolarDataService.getSolarData(
            gateway.id, 
            'day', // Always use day timeframe for a specific day view
            '15m',  // Use 15m resolution for detailed view
            startUtc,  // Start of day in gateway timezone, as UTC
            endUtc     // End of day in gateway timezone, as UTC
          );
          
          if (solarData) {
            setState(prev => ({
              ...prev,
              histogramData: solarData.histogram || [],
              isHistogramLoading: false
            }));
            
            // Clear any pending changes
            setPendingTimeframe(null);
            setPendingResolution(null);
          }
        } catch (err) {
          console.error(`Error updating histogram data for gateway ${gateway.id}:`, err);
          setState(prev => ({
            ...prev,
            isHistogramLoading: false,
            error: `Failed to fetch histogram data for gateway ${gateway.id}`
          }));
          
          // Clear pending states on error
          setPendingTimeframe(null);
          setPendingResolution(null);
        }
      })();
    }
  }, [gateway.id, gateway.timeZone]);

  // Add state for timezone display
  const [browserTimezone, setBrowserTimezone] = useState<string>('');
  
  // Get browser timezone on mount
  useEffect(() => {
    const tz = getTimezoneAbbreviation(new Date());
    setBrowserTimezone(tz);
  }, []);

  // Add function to get gateway state
  const getGatewayState = () => {
    if (!gateway.state?.timestamp) return 'unknown';
    
    const now = Date.now();
    const diffMinutes = (now - gateway.state.timestamp) / 1000 / 60;
    
    if (diffMinutes < 10) return 'active';
    if (diffMinutes > 60) return 'inactive';
    return 'disabled';
  };

  if (isLoading) {
    return (
      <div className="bg-gray-800/50 p-6 rounded-lg border border-gray-700">
        <div className="flex items-center gap-2 mb-4">
          {isHardwareGateway(gateway) ? (
            <GatewayIcon state={getGatewayState()} className="w-6 h-6" />
          ) : (
            <CloudGatewayIcon state={getGatewayState()} className="w-6 h-6" />
          )}
          <h2 className="text-2xl font-bold text-white">{gateway.name || `Gateway ${gateway.id.substring(0, 8)}`}</h2>
        </div>
        <PvDerInfo gateway={gateway} />
        <div className="flex items-center justify-center h-48 mt-4">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-500"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-gray-800/50 p-6 rounded-lg border border-gray-700">
        <div className="flex items-center gap-2 mb-4">
          {isHardwareGateway(gateway) ? (
            <GatewayIcon state={getGatewayState()} className="w-6 h-6" />
          ) : (
            <CloudGatewayIcon state={getGatewayState()} className="w-6 h-6" />
          )}
          <h2 className="text-2xl font-bold text-white">{gateway.name || `Gateway ${gateway.id.substring(0, 8)}`}</h2>
        </div>
        <PvDerInfo gateway={gateway} />
        <div className="bg-red-900/30 border border-red-500/50 rounded-lg p-4 mt-4">
          <p className="text-red-400">{error}</p>
        </div>
      </div>
    );
  }

  // Check if we have any actual data to display
  const hasData = 
    (histogramData.length > 0) || 
    (dailyData.length > 0) || 
    (currentData?.latest !== null);
  
  // If no data, show a simplified message instead of empty charts
  if (!hasData && !isLoading) {
    return (
      <div className="bg-gray-800/50 p-6 rounded-lg border border-gray-700">
        <div className="flex items-center gap-2 mb-4">
          {isHardwareGateway(gateway) ? (
            <GatewayIcon state={getGatewayState()} className="w-6 h-6" />
          ) : (
            <CloudGatewayIcon state={getGatewayState()} className="w-6 h-6" />
          )}
          <h2 className="text-2xl font-bold text-white">{gateway.name || `Gateway ${gateway.id.substring(0, 8)}`}</h2>
        </div>
        <PvDerInfo gateway={gateway} />
        <div className="py-8 text-center mt-4">
          <SunIcon className="w-12 h-12 text-gray-600 mx-auto mb-4" />
          <p className="text-gray-400">No solar data available for this gateway.</p>
          <p className="text-gray-500 text-sm mt-2">
            The Solar API couldn't find any data for this gateway. It may not be connected to solar panels
            or there could be an issue with data reporting.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <div className="flex items-center gap-2 mb-4">
          {isHardwareGateway(gateway) ? (
            <GatewayIcon state={getGatewayState()} className="w-6 h-6" />
          ) : (
            <CloudGatewayIcon state={getGatewayState()} className="w-6 h-6" />
          )}
          <h2 className="text-2xl font-medium text-white">{gateway.name || `Gateway ${gateway.id.substring(0, 8)}`}</h2>
        </div>
        <PvDerInfo gateway={gateway} />
      </div>
      
      {/* Current Solar Production Overview */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Current Power Card */}
        <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700">
          <div className="flex items-center gap-2 mb-2">
            <h3 className="text-base font-semibold text-white">Current Output</h3>
            <Tooltip text="Current power output in kilowatts (kW) - Last updated in real-time">
              <InformationCircleIcon className="w-4 h-4 text-gray-400 cursor-help" />
            </Tooltip>
          </div>
          <div className="mt-4 text-center">
            {isCurrentDataLoading && !currentData?.latest ? (
              <div className="animate-pulse">
                <div className="h-10 bg-gray-700/50 rounded w-1/2 mx-auto"></div>
                <div className="h-4 bg-gray-700/50 rounded w-3/4 mx-auto mt-2"></div>
              </div>
            ) : (
              <>
                <p className="text-4xl font-bold text-yellow-500 transition-all duration-300 ease-in-out">{formatNumber(currentPower, 2)} kW</p>
                {currentData?.latest && (
                  <p className="text-gray-400 text-xs mt-1">Last updated: {formatTime(currentData.latest.ts)}</p>
                )}
              </>
            )}
          </div>
        </div>

        {/* Today's Total Production */}
        <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700">
          <div className="flex items-center gap-2 mb-2">
            <h3 className="text-base font-semibold text-white">Today's Production</h3>
            <Tooltip text="Total energy generated today in kilowatt-hours (kWh)">
              <InformationCircleIcon className="w-4 h-4 text-gray-400 cursor-help" />
            </Tooltip>
          </div>
          <div className="mt-4 text-center">
            {isCurrentDataLoading && todayTotal === 0 ? (
              <div className="animate-pulse">
                <div className="h-10 bg-gray-700/50 rounded w-1/2 mx-auto"></div>
                <div className="h-4 bg-gray-700/50 rounded w-3/4 mx-auto mt-2"></div>
              </div>
            ) : (
              <p className="text-4xl font-bold text-green-500 transition-all duration-300 ease-in-out">{formatNumber(todayTotal, 2)} kWh</p>
            )}
          </div>
        </div>

        {/* Average Production */}
        <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700">
          <div className="flex items-center gap-2 mb-2">
            <h3 className="text-base font-semibold text-white">Average Production</h3>
            <Tooltip text="Average daily energy production over the last 30 days in kilowatt-hours (kWh)">
              <InformationCircleIcon className="w-4 h-4 text-gray-400 cursor-help" />
            </Tooltip>
          </div>
          <div className="mt-4 text-center">
            {isDailyDataLoading && convertedDailyData.length === 0 ? (
              <div className="animate-pulse">
                <div className="h-10 bg-gray-700/50 rounded w-1/2 mx-auto"></div>
                <div className="h-4 bg-gray-700/50 rounded w-3/4 mx-auto mt-2"></div>
              </div>
            ) : (
              <p className="text-4xl font-bold text-blue-500 transition-all duration-300 ease-in-out">{formatNumber(avgDailyPower, 2)} kWh</p>
            )}
          </div>
        </div>
      </div>

      {/* Power Production Chart */}
      <div className="bg-gray-700/30 rounded-lg p-4 border border-gray-700">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <h3 className="text-sm text-gray-400">Power Production (kW)</h3>
            <Tooltip text={`Real-time solar power output over time\n${timeframe !== '12h' && timeframe !== 'day' ? 'Click on the chart to zoom in to a specific period' : ''}`}>
              <InformationCircleIcon className="w-4 h-4 text-gray-400 cursor-help" />
            </Tooltip>
            <span className="text-xs text-gray-500">(Times shown in {browserTimezone})</span>
          </div>
          <div className="flex gap-2">
            {['2m', '15m', '1h', '4h'].map((resolution) => {
              // Disable 2m option when timeframe is not 12h or day
              // Disable 15m option when timeframe is month
              const isDisabled = 
                (resolution === '2m' && (timeframe !== '12h' && timeframe !== 'day')) ||
                (resolution === '15m' && 
                  (timeframe === 'month' || pendingTimeframe === 'month'));
              const isSelected = pendingResolution === resolution || 
                (histogramResolution === resolution && !pendingResolution);
              
              return (
                <button
                  key={resolution}
                  onClick={() => !isDisabled && handleResolutionChange(resolution as ResolutionOption)}
                  className={`px-3 py-1 rounded-lg text-sm ${
                    isSelected 
                      ? 'bg-blue-600 text-white' 
                      : isDisabled
                      ? 'bg-gray-800 text-gray-500 cursor-not-allowed' 
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                >
                  {resolution}
                </button>
              );
            })}
          </div>
        </div>
        
        {/* Date range display with navigation controls */}
        <div className="mb-4 flex items-center justify-center space-x-2">
          <button
            onClick={() => {
              // Move backward in time
              const newRefDate = new Date(referenceDate);
              if (timeframe === '12h') {
                newRefDate.setHours(newRefDate.getHours() - 12);
              } else if (timeframe === 'day') {
                newRefDate.setDate(newRefDate.getDate() - 1);
              } else if (timeframe === 'week') {
                newRefDate.setDate(newRefDate.getDate() - 7);
              } else if (timeframe === 'month') {
                newRefDate.setMonth(newRefDate.getMonth() - 1);
              }
              setReferenceDate(newRefDate);
              setIsViewingCurrent(false);
              // Trigger data fetch with new date range
              updateHistogramData();
            }}
            className="bg-gray-700 hover:bg-gray-600 text-gray-200 p-1 rounded-lg"
            title="Previous time period"
          >
            <ChevronLeftIcon className="w-5 h-5" />
          </button>
          
          <AdaptiveDatePicker
            timeframe={timeframe}
            referenceDate={referenceDate}
            isViewingCurrent={isViewingCurrent}
            onDateChange={(newDate) => {
              setReferenceDate(newDate);
              setIsViewingCurrent(false);
              updateHistogramData();
            }}
            onSetCurrent={() => {
              setReferenceDate(new Date());
              setIsViewingCurrent(true);
              updateHistogramData();
            }}
            gatewayTimezone={gateway.timeZone}
          />
          
          <button
            onClick={() => {
              // Move forward in time
              const newRefDate = new Date(referenceDate);
              const now = new Date();
              
              if (timeframe === '12h') {
                newRefDate.setHours(newRefDate.getHours() + 12);
              } else if (timeframe === 'day') {
                newRefDate.setDate(newRefDate.getDate() + 1);
              } else if (timeframe === 'week') {
                newRefDate.setDate(newRefDate.getDate() + 7);
              } else if (timeframe === 'month') {
                newRefDate.setMonth(newRefDate.getMonth() + 1);
              }
              
              // Check if we've reached current time
              if (newRefDate >= now) {
                setReferenceDate(now);
                setIsViewingCurrent(true);
              } else {
                setReferenceDate(newRefDate);
                setIsViewingCurrent(false);
              }
              
              // Trigger data fetch with new date range
              updateHistogramData();
            }}
            className={`p-1 rounded-lg ${
              isViewingCurrent 
                ? 'bg-gray-800 text-gray-500 cursor-not-allowed' 
                : 'bg-gray-700 hover:bg-gray-600 text-gray-200'
            }`}
            disabled={isViewingCurrent}
            title={isViewingCurrent ? "Already at current time" : "Next time period"}
          >
            <ChevronRightIcon className="w-5 h-5" />
          </button>
          
          {!isViewingCurrent && (
            <button
              onClick={() => {
                // Reset to current time
                setReferenceDate(new Date());
                setIsViewingCurrent(true);
                // Trigger data fetch with current time
                updateHistogramData();
              }}
              className="ml-2 bg-blue-600 hover:bg-blue-700 text-white px-2 py-1 rounded-lg text-xs"
              title="Return to current time"
            >
              Go to Now
            </button>
          )}
        </div>
        
        <div className="h-[300px] relative">
          {/* More visible loading indicator when changing parameters */}
          {isHistogramLoading && (pendingTimeframe || pendingResolution) && (
            <div className="absolute inset-0 bg-gray-900/10 flex items-center justify-center z-10">
              <div className="bg-gray-800/80 rounded-lg p-3 flex items-center space-x-3">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-yellow-500"></div>
                <span className="text-gray-200 text-sm">Loading new data...</span>
              </div>
            </div>
          )}
          
          {/* Simple loading indicator in the corner for regular updates */}
          {isHistogramLoading && !pendingTimeframe && !pendingResolution && (
            <div className="absolute top-2 right-2 z-10">
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-yellow-500"></div>
            </div>
          )}
          
          {memoizedHistogramData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart
                data={memoizedHistogramData}
                margin={{ top: 5, right: 0, left: -10, bottom: 10 }}
                onClick={handleChartClick}
                style={{ cursor: timeframe !== '12h' && timeframe !== 'day' ? 'pointer' : 'default' }}
              >
                <defs>
                  <linearGradient id={`colorPower-${gateway.id}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#FCD34D" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#FCD34D" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                <XAxis 
                  dataKey="ts"
                  tickFormatter={(ts) => {
                    // The date is already in browser timezone when converted to string
                    const localTime = new Date(ts);
                    return localTime.toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit'
                    });
                  }}
                  stroke="#9CA3AF"
                  tick={(props) => {
                    const { x, y, payload } = props;
                    // The date is already in browser timezone when converted to string
                    const localTime = new Date(payload.value);
                    return (
                      <g transform={`translate(${x},${y})`}>
                        <text
                          x={0}
                          y={0}
                          dy={-7}
                          dx={10}
                          textAnchor="end"
                          fill="#9CA3AF"
                          transform="rotate(-45)"
                          style={{ fontSize: '11px' }}
                        >
                          {localTime.toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </text>
                      </g>
                    );
                  }}
                  height={45}
                  tickMargin={15}
                  interval={(() => {
                    // Use pending state if available, otherwise use current state
                    const effectiveTimeframe = pendingTimeframe || timeframe;
                    const effectiveResolution = pendingResolution || histogramResolution;
                    
                    // Calculate appropriate interval based on timeframe and resolution
                    if (effectiveTimeframe === 'month') {
                      if (effectiveResolution === '15m') return 96; // Show tick every 24 hours (96 points)
                      if (effectiveResolution === '1h') return 24; // Show tick every 24 hours
                      return 6; // For 4h resolution
                    } else if (effectiveTimeframe === 'week') {
                      if (effectiveResolution === '15m') return 24; // Every 6 hours
                      if (effectiveResolution === '1h') return 6; // Every 6 hours 
                      return 3; // For 4h resolution
                    } else if (effectiveTimeframe === 'day') { 
                      if (effectiveResolution === '2m') return 30; // Every hour (30 points at 2m)
                      if (effectiveResolution === '15m') return 8; // Every 2 hours
                      if (effectiveResolution === '1h') return 2; // Every 2 hours
                      return 1; // For 4h resolution
                    } else { // 12h
                      if (effectiveResolution === '2m') return 15; // Every 30 minutes (15 points at 2m)
                      if (effectiveResolution === '15m') return 4; // Every hour
                      if (effectiveResolution === '1h') return 1; // Every hour
                      return 1; // For 4h resolution
                    }
                  })()}
                />
                <YAxis 
                  stroke="#9CA3AF"
                  tick={{ fill: '#9CA3AF' }}
                  tickFormatter={(value) => {
                    // If the value is exactly a whole number, show it without decimals
                    if (value % 1 === 0) return value.toString();
                    
                    // For values between 0 and 1, always show one decimal
                    if (value < 1) return value.toFixed(1);
                    
                    // For values between 1 and 10, show one decimal to avoid ambiguity
                    if (value < 10) return value.toFixed(1);
                    
                    // For larger values, show no decimals
                    return value.toFixed(0);
                  }}
                />
                <RechartsTooltip
                  contentStyle={{
                    backgroundColor: '#1F2937',
                    border: '1px solid #374151',
                    borderRadius: '0.375rem'
                  }}
                  labelStyle={{ color: '#9CA3AF' }}
                  itemStyle={{ color: '#E5E7EB' }}
                  labelFormatter={(ts: string) => {
                    // The date is already in browser timezone
                    const localTime = new Date(ts);
                    return `${localTime.toLocaleString()} ${browserTimezone}`;
                  }}
                  formatter={(value: number) => [`${value.toFixed(3)} kW`, 'Power']}
                  isAnimationActive={false}
                />
                <Area
                  type="monotone"
                  dataKey="power"
                  name="Power Output"
                  stroke="#FCD34D"
                  fillOpacity={1}
                  fill={`url(#colorPower-${gateway.id})`}
                  dot={false}
                  strokeWidth={2}
                  isAnimationActive={false}
                />
                
                {/* Highlight the hovered day with a background reference area */}
                {/* This creates a visual connection between the daily chart and power chart */}
                {hoveredDay && hoveredDayData.length > 0 && (
                  <ReferenceArea
                    x1={hoveredDayData[0].ts}
                    x2={hoveredDayData[hoveredDayData.length - 1].ts}
                    fill="#10B981"
                    fillOpacity={0.2}
                    strokeWidth={0}
                  />
                )}
              </AreaChart>
            </ResponsiveContainer>
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-500">No data available for the selected period</p>
            </div>
          )}
        </div>
        <div className="flex justify-center mt-1">
          <div className="flex gap-2">
            {['12h', 'day', 'week', 'month'].map((period) => {
              const isSelected = pendingTimeframe === period || 
                (timeframe === period && !pendingTimeframe);
                
              return (
                <button
                  key={period}
                  onClick={() => {
                    handleTimeframeChange(period as TimeframeOption);
                  }}
                  className={`px-3 py-1 rounded-lg text-sm ${
                    isSelected
                      ? 'bg-green-600 text-white' 
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                >
                  {period === '12h' ? '12h' : period === 'day' ? '24h' : period === 'week' ? '7 days' : '30 days'}
                </button>
              );
            })}
          </div>
        </div>
        <p className="text-xs text-gray-500 text-right mt-2">
          Last updated: {
            (() => {
              const lastDataPoint = memoizedHistogramData.length > 0 ? memoizedHistogramData[memoizedHistogramData.length - 1] : null;
              return lastDataPoint?.ts
                ? new Date(lastDataPoint.ts).toLocaleString()
                : 'No data available';
            })()
          }
        </p>
      </div>

      {/* Daily Production Bar Chart */}
      <div className="bg-gray-700/30 rounded-lg p-4 border border-gray-700">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <h3 className="text-sm text-gray-400">Daily Production (kWh)</h3>
            <Tooltip text={`Total energy produced each day in kilowatt-hours (kWh)\nShowing last 30 days\nClick on a day to view detailed power data in gateway timezone (${gateway.timeZone || 'Unknown'})`}>
              <InformationCircleIcon className="w-4 h-4 text-gray-400 cursor-help" />
            </Tooltip>
            <span className="text-xs text-gray-500">(Days shown in gateway timezone: {gateway.timeZone || 'Unknown'})</span>
          </div>
          {hoveredDay && (
            <div className="text-xs text-green-400 flex items-center gap-1">
              <div className="w-2 h-2 rounded-full bg-green-500"></div>
              Highlighting: {new Date(hoveredDay).toLocaleDateString()}
            </div>
          )}
        </div>
        <div className="h-[300px]">
          {isDailyDataLoading && convertedDailyData.length === 0 ? (
            <div className="flex items-center justify-center h-full">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-500"></div>
            </div>
          ) : convertedDailyData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={dailyChartData}
                margin={{ top: 5, right: 0, left: -10, bottom: 20 }}
                style={{ cursor: 'pointer' }}
                onMouseMove={(data) => {
                  if (data && data.activePayload && data.activePayload.length > 0) {
                    const payload = data.activePayload[0].payload;
                    if (payload && payload.date) {
                      // Use originalTs to get the correct date in gateway timezone
                      const dateToUse = payload.originalTs || payload.date;
                      // Format date as ISO string for consistency (YYYY-MM-DD)
                      const formattedDate = new Date(dateToUse).toISOString().split('T')[0];
                      setHoveredDay(formattedDate);
                    }
                  }
                }}
                onMouseLeave={() => {
                  setHoveredDay(null);
                }}
                onClick={handleDailyChartClick}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                <XAxis
                  dataKey="date"
                  stroke="#9CA3AF"
                  interval={1}
                  tick={(props) => {
                    const { x, y, payload } = props;
                    return (
                      <g transform={`translate(${x},${y})`}>
                        <text
                          x={0}
                          y={0}
                          dy={-7}
                          dx={10}
                          textAnchor="end"
                          fill="#9CA3AF"
                          transform="rotate(-45)"
                          style={{ fontSize: '11px' }}
                        >
                          {new Date(payload.value).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })}
                        </text>
                      </g>
                    );
                  }}
                  height={50}
                  tickMargin={20}
                />
                <YAxis
                  stroke="#9CA3AF"
                  tick={{ fill: '#9CA3AF' }}
                  tickFormatter={(value) => {
                    // If the value is exactly a whole number, show it without decimals
                    if (value % 1 === 0) return value.toString();
                    
                    // For values between 0 and 1, always show one decimal
                    if (value < 1) return value.toFixed(1);
                    
                    // For values between 1 and 10, show one decimal to avoid ambiguity
                    if (value < 10) return value.toFixed(1);
                    
                    // For larger values, show no decimals
                    return value.toFixed(0);
                  }}
                />
                <RechartsTooltip
                  contentStyle={{
                    backgroundColor: '#1F2937',
                    border: '1px solid #374151',
                    borderRadius: '0.375rem'
                  }}
                  labelStyle={{ color: '#9CA3AF' }}
                  itemStyle={{ color: '#E5E7EB' }}
                  labelFormatter={(date) => {
                    return new Date(date).toLocaleDateString([], {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    });
                  }}
                  formatter={(value: number) => [`${value.toFixed(2)} kWh`, 'Energy']}
                  cursor={{ fill: hoveredDay ? '#10B981' : '#4B5563', fillOpacity: 0.3 }}
                />
                <Legend 
                  verticalAlign="top"
                  height={36}
                  content={() => {
                    return (
                      <div className="flex justify-center gap-6 mb-2 text-xs">
                        <div className="flex items-center gap-1">
                          <div className="w-3 h-3 rounded-sm bg-[#3B82F6]"></div>
                          <span className="text-gray-300">Low</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <div className="w-3 h-3 rounded-sm bg-[#22C55E]"></div>
                          <span className="text-gray-300">Average</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <div className="w-3 h-3 rounded-sm bg-[#FCD34D]"></div>
                          <span className="text-gray-300">High</span>
                        </div>
                      </div>
                    );
                  }}
                />
                <Bar 
                  dataKey="power" 
                  radius={[4, 4, 0, 0]}
                  activeBar={{ fill: 'white', stroke: 'white', strokeWidth: 1 }}
                >
                  {dailyChartData.map((entry, index) => {
                    // Use pre-calculated statistics
                    const { avg, stdDev } = dailyDataStats;
                    
                    // Determine color based on value
                    let fill = '#22C55E'; // Default green for average
                    if (entry.power > avg + stdDev * 0.5) {
                      fill = '#FCD34D'; // Yellow for high (matches the power production color)
                    } else if (entry.power < avg - stdDev * 0.5) {
                      fill = '#3B82F6'; // Blue for low
                    }
                    
                    return <Cell key={`cell-${index}`} fill={fill} />;
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-500">No daily data available for the selected period</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Tooltip component
const Tooltip: FC<{text: string; children: React.ReactNode}> = ({ text, children }) => {
  return (
    <div className="relative group">
      {children}
      <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 px-2 py-1 bg-gray-800 text-xs text-gray-300 rounded whitespace-pre opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity z-50 w-max max-w-xs">
        {text}
        <div className="absolute top-full left-1/2 -translate-x-1/2 border-4 border-transparent border-t-gray-800"></div>
      </div>
    </div>
  );
}; 