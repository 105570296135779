import { FC, useState, useCallback, useEffect } from 'react';
import { useBle } from "../../context/BleContext";
import { useWalletContext } from '../../context/WalletContext';
import { useGateways } from "../../context/GatewayContext";
import { toast } from "react-hot-toast";
import { FaSpinner } from "react-icons/fa";
import InfoRow from "../../components/InfoRow";
import * as ble from "../../services/ble/";
import { ActionButton } from "../../components/ui/ActionButton";

interface GatewayInfo {
  id: string;
  name: string;
}

interface WalletStepProps {
  gatewayInfo: GatewayInfo;
  onRegistrationComplete: (isRegistered: boolean) => void;
}

const WalletStep: FC<WalletStepProps> = ({ gatewayInfo, onRegistrationComplete }) => {
  const bleApi = useBle();
  const { publicKey } = useWalletContext();
  const { refreshGateways, claimNewGateway } = useGateways();
  const [isChecking, setIsChecking] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [gatewayData, setGatewayData] = useState<{
    wallet?: string;
    h3Index?: string;
  } | null>(null);

  const checkGatewayOwnership = useCallback(async () => {
    setIsChecking(true);
    
    try {
      const query = JSON.stringify({
        query: `{
          gateway {
            gateway(id: "${gatewayInfo.id}") {
              wallet
              h3Index
            }
          }
        }`
      });

      const response = await fetch('https://api.srcful.dev/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: query
      });

      const data = await response.json();
      const gatewayDetails = data.data.gateway.gateway;
      setGatewayData(gatewayDetails);

      // Check if the gateway is registered to the current wallet
      const isRegisteredToCurrentWallet = gatewayDetails?.wallet === publicKey?.toString();
      setIsRegistered(isRegisteredToCurrentWallet);
      onRegistrationComplete(isRegisteredToCurrentWallet);
    } catch (error) {
      console.error('Failed to check gateway ownership:', error);
      onRegistrationComplete(false);
    } finally {
      setIsChecking(false);
    }
  }, [gatewayInfo.id, publicKey, onRegistrationComplete]);

  // Initial check
  useEffect(() => {
    checkGatewayOwnership();
  }, [checkGatewayOwnership]);

  const handleRegister = async () => {
    if (!publicKey) {
      toast.error('Please connect your wallet first');
      return;
    }

    setIsSubmitting(true);
    const toastId = toast.loading('Registering gateway...');

    try {
      // First, do the BLE operation
      const response = await bleApi.fetch(ble.API_INITIALIZE, ble.Method.POST, {
        wallet: publicKey.toString()
      });

      if (!response.payload.initialized) {
        throw new Error('Failed to register wallet via BLE');
      }

      // After successful BLE operation, verify with backend
      await new Promise(resolve => setTimeout(resolve, 2000)); // Give backend time to update
      
      // Refresh the gateways list
      await refreshGateways();

      // Claim the newly registered gateway
      await claimNewGateway(gatewayInfo.id);
      
      // Verify registration was successful
      await checkGatewayOwnership();

      // Force a state update
      bleApi.fetch(ble.API_STATE_UPDATE, ble.Method.GET);

    } catch (error) {
      console.error('Registration failed:', error);
      toast.error('Failed to register gateway');
    } finally {
      toast.dismiss(toastId);
      setIsSubmitting(false);
    }
  };

  if (isChecking) {
    return (
      <div className="space-y-6 text-center">
        <h2 className="text-2xl font-bold text-white">Checking Registration Status</h2>
        <div className="flex justify-center">
          <FaSpinner className="animate-spin text-4xl text-blue-500" />
        </div>
      </div>
    );
  }

  if (isRegistered) {
    return (
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-white">Gateway Registered</h2>
        <div className="bg-green-500/10 p-4 rounded-lg border border-green-500/20">
          <InfoRow
            label="Gateway ID"
            value={gatewayInfo.id}
            isCopyable
          />
          <InfoRow
            label="Wallet"
            value={gatewayData?.wallet || ''}
            displayValue={gatewayData?.wallet ? 
              `${gatewayData.wallet.slice(0, 4)}...${gatewayData.wallet.slice(-4)}` : 
              ''
            }
            isCopyable
          />
          <p className="mt-2 text-sm text-green-300">
            Your gateway is successfully registered to your wallet.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white">Register Gateway</h2>
      <div className="bg-yellow-500/10 p-4 rounded-lg border border-yellow-500/20">
        <p className="text-yellow-200">
          Register this gateway to your wallet to start earning rewards.
        </p>
      </div>
      <ActionButton
        onClick={handleRegister}
        disabled={isSubmitting || !publicKey}
        isLoading={isSubmitting}
        loadingText="Registering..."
        className="w-full"
      >
        Register Gateway
      </ActionButton>
    </div>
  );
};

export default WalletStep; 