import { FC, useState } from 'react';
import { ClipboardDocumentIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';

interface DerSerialNumberProps {
  sn: string;
  lastSeen: string;
  className?: string; // Add optional className prop for additional styling
}

export const DerSerialNumber: FC<DerSerialNumberProps> = ({ sn, lastSeen, className = '' }) => {
  const [copiedSerial, setCopiedSerial] = useState<string | null>(null);

  // Function to truncate serial number
  const truncateSerial = (serial: string) => {
    if (serial.length <= 12) return serial;
    return `${serial.slice(0, 6)}...${serial.slice(-4)}`;
  };

  // Function to handle copy
  const handleCopy = async (serial: string) => {
    try {
      await navigator.clipboard.writeText(serial);
      setCopiedSerial(serial);
      setTimeout(() => setCopiedSerial(null), 2000); // Reset after 2 seconds
    } catch (err) {
      // Silently fail if copy is not supported
    }
  };

  return (
    <div className={`inline-flex flex-col bg-gray-800/50 rounded-lg p-3 border border-gray-700 ${className}`}>
      <div className="flex items-center gap-2">
        <p className="text-sm text-gray-400">SN: {truncateSerial(sn)}</p>
        <button
          onClick={() => handleCopy(sn)}
          className="p-1 hover:bg-gray-700 rounded-lg transition-colors"
          title="Copy serial number"
        >
          {copiedSerial === sn ? (
            <ClipboardDocumentCheckIcon className="w-4 h-4 text-green-500" />
          ) : (
            <ClipboardDocumentIcon className="w-4 h-4 text-gray-400" />
          )}
        </button>
      </div>
      <p className={`text-sm ${
        new Date(lastSeen).getTime() < Date.now() - 15 * 60 * 1000 
          ? 'text-red-500' 
          : 'text-green-500'
      }`}>
        Last seen: {new Date(lastSeen).toLocaleString()}
      </p>
    </div>
  );
}; 