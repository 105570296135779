import { FC } from 'react';
import { ActionButton } from '../../ui/ActionButton';
import { StepProps } from '../types';

interface ErrorStepProps extends StepProps {
  message: string;
  gatewayId?: string;
}

const ErrorStep: FC<ErrorStepProps> = ({ message, gatewayId, onBack }) => {
  return (
    <div className="space-y-8">
      <div className="space-y-4">
        <h2 className="text-xl font-semibold text-red-500">Registration Failed</h2>
        <div className="p-4 rounded-lg bg-red-900/50 border border-red-500/50">
          <p className="text-red-200">{message}</p>
        </div>
        {gatewayId && (
          <div className="space-y-2">
            <p className="text-gray-300">This site is already registered as gateway:</p>
            <div className="p-4 bg-gray-800 rounded-lg">
              <a
                href={`https://explorer.sourceful.energy/egw/${gatewayId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="font-mono text-blue-400 hover:text-blue-300 flex items-center gap-2"
              >
                {gatewayId}
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                </svg>
              </a>
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-between">
        <ActionButton
          onClick={onBack}
          variant="secondary"
        >
          Back
        </ActionButton>
      </div>
    </div>
  );
};

export default ErrorStep; 