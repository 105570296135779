import { FC, useState, useCallback, useEffect, useMemo } from 'react';
import { useWalletContext } from '../context/WalletContext';
import { router } from '../router';
import { AuthLayout } from '../components/layout/AuthLayout';
import { FaDiscord } from 'react-icons/fa';
import { HomeIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import { APP_VERSION } from '../config/version';
import { toast } from 'react-hot-toast';
import { BifrostService } from '../services/BifrostService';
import {
  ExternalWalletLogin,
  PrivateKeyLogin,
  DelegatedLogin,
  SavedKeyVerification,
  GuestWalletLogin
} from '../components/login';

// Storage keys with names completely unrelated to wallets or keys
const PRIVATE_KEY_STORAGE_KEY = 'srcful_user_preferences';
const HAS_ENCRYPTED_KEY = 'srcful_dark_mode_enabled';
const SESSION_KEY = 'srcful_session_preference';
const SESSION_FLAG = 'srcful_session_theme';

const LoginPage: FC = () => {
  const { isConnected, isConnecting, resetConnection, setWallet, disconnect } = useWalletContext();
  const [isPrivateKeyMode, setIsPrivateKeyMode] = useState(false);
  const [isVerificationMode, setIsVerificationMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPrivateKeyOption, setShowPrivateKeyOption] = useState(false);
  const [isDelegatedMode, setIsDelegatedMode] = useState(false);
  const [isGuestMode, setIsGuestMode] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const bifrostService = useMemo(() => new BifrostService(), []);
  
  // For secret version click feature
  const [versionClicks, setVersionClicks] = useState(0);
  const [firstClickTime, setFirstClickTime] = useState<number | null>(null);

  // Enhance disconnect to also clear private key from local storage
  const enhancedDisconnect = useCallback(() => {
    // First clear the private key with debug logging
    console.log('[Disconnect] Clearing secure storage keys');
    // Clear from both localStorage and sessionStorage
    localStorage.removeItem(PRIVATE_KEY_STORAGE_KEY);
    localStorage.removeItem(HAS_ENCRYPTED_KEY);
    sessionStorage.removeItem(SESSION_KEY);
    sessionStorage.removeItem(SESSION_FLAG);
    // Then call the original disconnect function
    disconnect();
  }, [disconnect]);

  // Check for stored encrypted key on mount
  useEffect(() => {
    // First check localStorage
    let hasEncryptedKey = localStorage.getItem(HAS_ENCRYPTED_KEY) === 'true';
    let encryptedKey = localStorage.getItem(PRIVATE_KEY_STORAGE_KEY);
    
    // If not found in localStorage, try sessionStorage
    if ((!hasEncryptedKey || !encryptedKey) && sessionStorage.getItem(SESSION_FLAG) === 'true') {
      console.log('[Startup] Keys not found in localStorage, checking sessionStorage');
      
      // Get from sessionStorage
      encryptedKey = sessionStorage.getItem(SESSION_KEY);
      hasEncryptedKey = sessionStorage.getItem(SESSION_FLAG) === 'true';
      
      if (hasEncryptedKey && encryptedKey) {
        console.log('[Startup] Found keys in sessionStorage, restoring to localStorage');
        // Restore to localStorage
        localStorage.setItem(PRIVATE_KEY_STORAGE_KEY, encryptedKey);
        localStorage.setItem(HAS_ENCRYPTED_KEY, 'true');
      }
    }
    
    console.log('[Startup] Checking secure storage:', { 
      hasFlag: hasEncryptedKey, 
      hasKey: !!encryptedKey 
    });
    
    // Only show verification mode if both the flag and the actual key exist
    if (hasEncryptedKey && encryptedKey) {
      console.log('[Startup] Found encrypted key, showing verification mode');
      // We have an encrypted key, need to prompt for verification code
      setIsVerificationMode(true);
      setShowPrivateKeyOption(true);
      setIsPrivateKeyMode(true);
    } else if (hasEncryptedKey && !encryptedKey) {
      // The flag is set but the key is missing, clean up the inconsistent state
      console.log('[Startup] Found flag but no key, cleaning up');
      localStorage.removeItem(HAS_ENCRYPTED_KEY);
      sessionStorage.removeItem(SESSION_FLAG);
    }
  }, []);

  useEffect(() => {
    if (isConnected) {
      router.navigate({ 
        to: '/',
        replace: true
      });
    }
  }, [isConnected]);
  
  const handleVersionClick = useCallback(() => {
    const currentTime = Date.now();
    
    // If this is the first click or if it's been more than 10 seconds since the first click, reset
    if (firstClickTime === null || currentTime - firstClickTime > 10000) {
      setVersionClicks(1);
      setFirstClickTime(currentTime);
    } else {
      // Increment the click count
      const newClickCount = versionClicks + 1;
      setVersionClicks(newClickCount);
      
      // If we've reached 7 clicks, show the private key option
      if (newClickCount >= 7 && !showPrivateKeyOption) {
        setShowPrivateKeyOption(true);
        // Optional: provide subtle feedback
        toast.success('Developer options unlocked', {
          icon: '🔓',
          duration: 2000
        });
      }
    }
  }, [versionClicks, firstClickTime, showPrivateKeyOption]);

  const toggleLoginMode = () => {
    if (localStorage.getItem(HAS_ENCRYPTED_KEY) === 'true' && 
        localStorage.getItem(PRIVATE_KEY_STORAGE_KEY) && 
        !isVerificationMode) {
      // If we have an encrypted key and we're not already in verification mode,
      // switch to verification mode instead of regular private key mode
      setIsVerificationMode(true);
      setIsPrivateKeyMode(true);
      setVerificationCode('');
      return;
    }
    
    // Normal toggle behavior
    setIsPrivateKeyMode(!isPrivateKeyMode);
    setIsDelegatedMode(false);
    setIsGuestMode(false);
    setVerificationCode('');
    setIsVerificationMode(false);
  };

  const toggleDelegatedMode = () => {
    setIsDelegatedMode(!isDelegatedMode);
    setIsPrivateKeyMode(false);
    setIsGuestMode(false);
    setVerificationCode('');
    setIsVerificationMode(false);
  };

  const toggleGuestMode = () => {
    setIsGuestMode(!isGuestMode);
    setIsPrivateKeyMode(false);
    setIsDelegatedMode(false);
    setVerificationCode('');
    setIsVerificationMode(false);
  };

  const handleLogout = () => {
    // Use the enhanced disconnect which will also clear the private key
    enhancedDisconnect();
    // Reset state
    setIsVerificationMode(false);
    setVerificationCode('');
    // Show feedback
    toast.success('Wallet disconnected and encrypted key removed');
  };

  const renderLoginPanel = () => {
    if (isVerificationMode) {
      return (
        <SavedKeyVerification
          onSuccess={setWallet}
          onLogout={handleLogout}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          verificationCode={verificationCode}
          setVerificationCode={setVerificationCode}
        />
      );
    }
    
    if (isPrivateKeyMode) {
      return (
        <PrivateKeyLogin
          onSuccess={setWallet}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      );
    }

    if (isDelegatedMode) {
      return (
        <DelegatedLogin
          onSuccess={setWallet}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          bifrostService={bifrostService}
        />
      );
    }

    if (isGuestMode) {
      return (
        <GuestWalletLogin
          onSuccess={setWallet}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      );
    }
    
    return <ExternalWalletLogin />;
  };

  return (
    <AuthLayout>
      <h1 className="text-3xl font-bold text-white">Sourceful Energy Configurator</h1>
      <p className="text-center text-gray-300">
        Please connect your Solana wallet to continue,<br/>we recommend using <a href="https://phantom.app" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300 decoration-blue-400/30 hover:decoration-blue-300 transition-colors">Phantom</a>.
      </p>
      
      {renderLoginPanel()}
      
      {isConnecting && !isPrivateKeyMode && !isVerificationMode && (
        <button
          onClick={resetConnection}
          className="text-sm text-gray-400 hover:text-white"
        >
          Reset Connection
        </button>
      )}

      <div className="mt-6 text-center">
        <div className="text-gray-600 mb-2">or</div>
        {showPrivateKeyOption && !isVerificationMode && (
          <>
            {isPrivateKeyMode ? (
              <button
                onClick={toggleLoginMode}
                className="text-blue-400 hover:text-blue-300 transition-colors"
              >
                Connect with Wallet
              </button>
            ) : (
              <button
                onClick={toggleLoginMode}
                className="text-blue-400 hover:text-blue-300 transition-colors"
              >
                Login with Private Key
              </button>
            )}
            <div className="text-gray-600 my-2">or</div>
            {isDelegatedMode ? (
              <button
                onClick={toggleDelegatedMode}
                className="text-blue-400 hover:text-blue-300 transition-colors"
              >
                Connect with Wallet
              </button>
            ) : (
              <button
                onClick={toggleDelegatedMode}
                className="text-blue-400 hover:text-blue-300 transition-colors"
              >
                Login with Mobile App
              </button>
            )}
            <div className="text-gray-600 my-2">or</div>
            {isGuestMode ? (
              <button
                onClick={toggleGuestMode}
                className="text-blue-400 hover:text-blue-300 transition-colors"
              >
                Connect with Wallet
              </button>
            ) : (
              <button
                onClick={toggleGuestMode}
                className="text-blue-400 hover:text-blue-300 transition-colors"
              >
                Login as Guest
              </button>
            )}
          </>
        )}
        {/* Add an option to enter verification mode if we have an encrypted key but aren't showing it */}
        {localStorage.getItem(HAS_ENCRYPTED_KEY) === 'true' && 
         localStorage.getItem(PRIVATE_KEY_STORAGE_KEY) && 
         !isVerificationMode && !isPrivateKeyMode && (
          <>
            <div className="text-gray-600 mb-2">or</div>
            <button
              onClick={() => {
                setIsVerificationMode(true);
                setIsPrivateKeyMode(true);
                setShowPrivateKeyOption(true);
              }}
              className="text-blue-400 hover:text-blue-300 transition-colors"
            >
              Login with Saved Key
            </button>
          </>
        )}
      </div>

      <div className="mt-4 text-center">
        <a 
          href="https://app.sourceful.energy" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-flex items-center space-x-2 px-4 py-2 bg-gray-800/50 hover:bg-gray-700/50 text-white rounded-md transition-colors"
        >
          <span className="text-gray-300">Get the Native App</span>
        </a>
        <p className="text-gray-400 text-sm mt-3">Available for iOS and Android</p>
      </div>
      
      {/* Footer with links */}
      <div className="mt-auto pt-8">
        <div className="flex flex-col sm:flex-row justify-center sm:space-x-8 space-y-4 sm:space-y-0">
          <a
            href="https://sourceful.energy"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center space-x-2 text-blue-400 hover:text-blue-300 transition-colors"
          >
            <HomeIcon className="h-5 w-5" />
            <span>Sourceful Energy</span>
          </a>
          <a
            href="https://discord.com/invite/srcful"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center space-x-2 text-blue-400 hover:text-blue-300 transition-colors"
          >
            <FaDiscord className="h-5 w-5" />
            <span>Discord</span>
          </a>
          <a
            href="https://support.sourceful.energy"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center space-x-2 text-blue-400 hover:text-blue-300 transition-colors"
          >
            <ChatBubbleLeftRightIcon className="h-5 w-5" />
            <span>Support</span>
          </a>
        </div>
      </div>
      <div 
        className="fixed bottom-2 right-2 text-[10px] text-gray-600 select-none"
        onClick={handleVersionClick}
      >
        {APP_VERSION}
      </div>
    </AuthLayout>
  );
};

export default LoginPage; 