import { FC, useState, useEffect } from 'react';
import { SolarIcon } from '../../components/icons/icons';
import { SunIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useGateways } from '../../context/GatewayContext';
import { SolarGatewayData } from '../../components/SolarGatewayData';
import { useRouter } from "@tanstack/react-router";
import { ArrowLeftIcon } from '@heroicons/react/24/solid';

const SolarPage: FC = () => {
  const { gateways, isLoading: isLoadingGateways } = useGateways();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error] = useState<string | null>(null);
  const [expandedGateway, setExpandedGateway] = useState<string | null>(null);

  // Helper function to toggle detailed view of a gateway
  const toggleExpandGateway = (gatewayId: string) => {
    if (expandedGateway === gatewayId) {
      setExpandedGateway(null);
    } else {
      setExpandedGateway(gatewayId);
    }
  };
  
  // Initial data loading
  useEffect(() => {
    setIsLoading(isLoadingGateways);
  }, [isLoadingGateways]);

  // If loading, show loading UI
  if (isLoading) {
    return (
      <div className="container mx-auto px-1 sm:px-4 py-4 sm:py-8">
        <div className="mb-4 sm:mb-8">
          <div className="flex items-center gap-4 mb-2 sm:mb-4">
            <button
              onClick={() => router.navigate({ to: '/' })}
              className="p-2 rounded-full hover:bg-gray-800 transition-colors"
            >
              <ArrowLeftIcon className="w-6 h-6 text-gray-400" />
            </button>
            <div className="flex items-center gap-4">
              <SolarIcon className="w-8 h-8" state="active" />
              <h1 className="text-3xl font-bold text-white">Solar Data</h1>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-500"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-1 sm:px-4 py-4 sm:py-8">
        <div className="mb-4 sm:mb-8">
          <div className="flex items-center gap-4 mb-2 sm:mb-4">
            <button
              onClick={() => router.navigate({ to: '/' })}
              className="p-2 rounded-full hover:bg-gray-800 transition-colors"
            >
              <ArrowLeftIcon className="w-6 h-6 text-gray-400" />
            </button>
            <div className="flex items-center gap-4">
              <SolarIcon className="w-8 h-8" state="active" />
              <h1 className="text-3xl font-bold text-white">Solar Data</h1>
            </div>
          </div>
        </div>
        <div className="bg-red-900/30 border border-red-500/50 rounded-lg p-2 sm:p-4">
          <p className="text-red-400">{error}</p>
        </div>
      </div>
    );
  }

  if (gateways.length === 0) {
    return (
      <div className="container mx-auto px-1 sm:px-4 py-4 sm:py-8">
        <div className="mb-4 sm:mb-8">
          <div className="flex items-center gap-4 mb-2 sm:mb-4">
            <button
              onClick={() => router.navigate({ to: '/' })}
              className="p-2 rounded-full hover:bg-gray-800 transition-colors"
            >
              <ArrowLeftIcon className="w-6 h-6 text-gray-400" />
            </button>
            <div className="flex items-center gap-4">
              <SolarIcon className="w-8 h-8" state="active" />
              <h1 className="text-3xl font-bold text-white">Solar Data</h1>
            </div>
          </div>
        </div>
        <div className="bg-glass rounded-xl p-2 sm:p-6">
          <div className="text-center mb-6">
            <SunIcon className="w-16 h-16 text-gray-600 mx-auto mb-4" />
            <h2 className="text-xl font-semibold text-gray-300 mb-2">No Solar Data Available</h2>
            <p className="text-gray-400">
              The solar API didn't return any data for the gateways in your wallet.
            </p>
          </div>
          
          {/* Gateway debugging information */}
          {gateways.length > 0 ? (
            <div className="mt-6 border-t border-gray-700 pt-6">
              <h3 className="text-lg font-semibold text-gray-300 mb-4">Wallet Gateways (Debugging Info)</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"></th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">ID</th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Name</th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Type</th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">DERs</th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">H3 Index</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700 bg-gray-800/30">
                    {gateways.map(gateway => (
                      <>
                        <tr key={gateway.id} className="hover:bg-gray-700/30 cursor-pointer" onClick={() => toggleExpandGateway(gateway.id)}>
                          <td className="px-4 py-2 text-sm text-gray-300">
                            {expandedGateway === gateway.id ? 
                              <ChevronUpIcon className="w-5 h-5" /> : 
                              <ChevronDownIcon className="w-5 h-5" />
                            }
                          </td>
                          <td className="px-4 py-2 text-sm text-gray-300">{gateway.id.substring(0, 10)}...</td>
                          <td className="px-4 py-2 text-sm text-gray-300">{gateway.name || '-'}</td>
                          <td className="px-4 py-2 text-sm text-gray-300">{gateway.typeOf || '-'}</td>
                          <td className="px-4 py-2 text-sm text-gray-300">
                            {gateway.ders ? 
                              gateway.ders.map(der => der.type).join(', ') :
                              'No DERs'
                            }
                          </td>
                          <td className="px-4 py-2 text-sm text-gray-300">{gateway.h3Index || '-'}</td>
                        </tr>
                        {expandedGateway === gateway.id && (
                          <tr>
                            <td colSpan={6} className="px-4 py-4 bg-gray-800/50">
                              <div className="bg-gray-900 p-4 rounded-md overflow-auto max-h-64">
                                <pre className="text-xs text-gray-300 whitespace-pre-wrap break-all">
                                  {JSON.stringify(gateway, null, 2)}
                                </pre>
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
              
              <div className="mt-4 bg-amber-900/30 border border-amber-500/50 rounded-lg p-2 sm:p-4">
                <p className="text-amber-400 text-sm">
                  <strong>Note:</strong> We're no longer filtering gateways at the page level.
                  <ul className="list-disc ml-5 mt-2">
                    <li>
                      All gateways associated with the wallet are displayed in this table.
                    </li>
                    <li className="mt-1">
                      If you see gateways here but no solar data is shown, it may be because the Solar API cannot find data for these gateways.
                    </li>
                    <li className="mt-1">
                      The SolarGatewayData component handles missing data internally when attempting to display solar information.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          ) : (
            <div className="mt-6 border-t border-gray-700 pt-6">
              <p className="text-gray-400 text-center">No gateways found at all in the wallet.</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-1 sm:px-4 py-4 sm:py-8">
      <div className="mb-4 sm:mb-8">
        <div className="flex items-center gap-4 mb-2 sm:mb-4">
          <button
            onClick={() => router.navigate({ to: '/' })}
            className="p-2 rounded-full hover:bg-gray-800 transition-colors"
          >
            <ArrowLeftIcon className="w-6 h-6 text-gray-400" />
          </button>
          <div className="flex items-center gap-4">
            <SolarIcon className="w-8 h-8" state="active" />
            <h1 className="text-3xl font-bold text-white">Solar Data</h1>
          </div>
        </div>
      </div>

      <div className="space-y-4 sm:space-y-8">
        {gateways.map((gateway, index) => (
          <div key={gateway.id}>
            <SolarGatewayData gateway={gateway} />
            
            {/* Add a divider except for the last gateway */}
            {index < gateways.length - 1 && (
              <hr className="border-gray-700/50 my-4 sm:my-6" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SolarPage; 