import {
  RootRoute,
  Route,
  Router,
  Outlet,
} from "@tanstack/react-router";
import { useWalletContext } from "./context/WalletContext";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import Gateway from "./pages/Gateway";
import GatewayOverview from "./pages/GatewayOverview";
import GatewayLocation from "./pages/GatewayLocation";
import OnboardGW from "./pages/OnboardGW";
import ConnectGW from "./pages/ConnectGW";
import Aside from "./components/layout/Aside";
import { NavigationProvider, useNavigation } from "./context/NavigationContext";
import Header from "./components/Header";
import { useRef, useEffect, useState } from "react";
import DebugPage from "./pages/Debug";
import GatewayDevices from "./pages/GatewayDevices";
import GatewayNetwork from "./pages/GatewayNetwork";
import OnboardingWizard from "./pages/OnboardingWizard";
import AltOnboardingWizard from "./pages/AltOnboardingWizard";
import SwipeableContent from "./components/SwipeableContent";
import CloudOnboardingWizard from './pages/CloudOnboardingWizard';
import GatewayStatusCheck from './components/GatewayStatusCheck';
import EchoPage from './pages/EchoPage';
import GatewayLocationTool from './pages/GatewayLocationTool';
import MeterPage from "./pages/data/MeterPage";
import SolarPage from "./pages/data/SolarPage";
import BatteryPage from "./pages/data/BatteryPage";
import { authLoader } from './auth/authLoader';
import RecoverWallet from './pages/RecoverWallet';
import RewardsPage from './pages/RewardsPage';
import NotificationsPage from './pages/NotificationsPage';

// Root component as a proper React component
const RootComponent = () => {
  const { isConnected } = useWalletContext();
  const { navOpen, toggleNav } = useNavigation();
  const asideRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (window.innerWidth < 1024 && navOpen && asideRef.current && !asideRef.current.contains(event.target as Node)) {
        toggleNav();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navOpen, toggleNav]);
  
  if (!isConnected) {
    return (
      <div className="flex h-screen bg-[#1d1d1d]">
        <main className="flex-1 overflow-y-auto">
          <Outlet />
        </main>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen flex-col bg-[#1d1d1d]">
      <Header />
      <div className="relative flex flex-1 w-full overflow-hidden">
        {navOpen && (
          <div className="fixed inset-0 z-30 bg-black/50 lg:hidden" />
        )}
        <div
          ref={asideRef}
          className={`${
            navOpen ? "translate-x-0" : "-translate-x-full"
          } fixed inset-y-0 left-0 z-40 w-full max-w-[24rem] overflow-hidden border-r border-gray-600/80 bg-[#1d1d1d] transition-transform duration-300 lg:relative lg:w-96 lg:translate-x-0`}
        >
          <Aside />
        </div>
        <main className="flex-1 min-w-0 overflow-auto">
          <div className="min-h-full">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

const rootRoute = new RootRoute({
  component: () => (
    <NavigationProvider>
      <RootComponent />
    </NavigationProvider>
  )
});

const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/",
  beforeLoad: authLoader,
  component: HomePage
});

const loginRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/login",
  component: LoginPage,
});

const onboardGwRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/onboard-gw",
  beforeLoad: authLoader,
  component: OnboardGW
});

const connectGwRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/connect-gw",
  beforeLoad: authLoader,
  component: ConnectGW
});

const gatewayRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/gateway/$serial",
  beforeLoad: authLoader,
  component: Gateway
});

// Create a GatewayLayout component for gateway-specific routes
const GatewayLayout = () => {
  const [enterDirection, setEnterDirection] = useState<'left' | 'right'>('right');

  return (
    <SwipeableContent 
      enterDirection={enterDirection} 
      onDirectionChange={setEnterDirection}
    >
      <Outlet />
    </SwipeableContent>
  );
};

// Create a gateway parent route with proper parameter typing
const gatewayLayoutRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'gateway/$gatewayId',
  component: GatewayLayout,
  beforeLoad: authLoader,
  validateSearch: (search: Record<string, unknown>) => {
    return search;
  }
});

// Update gateway routes to be children of gatewayLayoutRoute with proper typing
export const gatewayOverviewRoute = new Route({
  getParentRoute: () => gatewayLayoutRoute,
  path: '/',
  beforeLoad: authLoader,
  component: GatewayOverview,
  validateSearch: (search: Record<string, unknown>) => {
    return search;
  }
});

export const gatewayLocationRoute = new Route({
  getParentRoute: () => gatewayLayoutRoute,
  path: '/location',
  beforeLoad: authLoader,
  component: GatewayLocation,
  validateSearch: (search: Record<string, unknown>) => {
    return search;
  }
});

export const gatewayDevicesRoute = new Route({
  getParentRoute: () => gatewayLayoutRoute,
  path: '/devices',
  beforeLoad: authLoader,
  component: GatewayDevices,
  validateSearch: (search: Record<string, unknown>) => {
    return search;
  }
});

export const gatewayNetworkRoute = new Route({
  getParentRoute: () => gatewayLayoutRoute,
  path: '/network',
  beforeLoad: authLoader,
  component: GatewayNetwork,
  validateSearch: (search: Record<string, unknown>) => {
    return search;
  }
});

const debugRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/debug",
  beforeLoad: authLoader,
  component: DebugPage
});

const onboardingWizardRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/onboarding-wizard",
  beforeLoad: authLoader,
  component: OnboardingWizard
});

const altOnboardingWizardRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/alt-onboarding-wizard",
  beforeLoad: authLoader,
  component: AltOnboardingWizard
});

// Add the cloud onboarding wizard route
const cloudOnboardingWizardRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/onboarding/cloud",
  beforeLoad: authLoader,
  component: CloudOnboardingWizard
});

// Add the gateway status check route
const gatewayStatusCheckRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/gateway-status",
  beforeLoad: authLoader,
  component: GatewayStatusCheck
});

// Add the echo route
const echoRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/echo",
  beforeLoad: authLoader,
  component: EchoPage
});

// Add the new route after the other tool routes
const gatewayLocationToolRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/gateway-location-tool',
  beforeLoad: authLoader,
  component: GatewayLocationTool
});

// Add data routes
const meterDataRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/data/meter",
  beforeLoad: authLoader,
  component: MeterPage
});

const solarDataRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/data/solar",
  beforeLoad: authLoader,
  component: SolarPage
});

const batteryDataRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/data/battery",
  beforeLoad: authLoader,
  component: BatteryPage
});

// Add the new route for recover wallet
const recoverWalletRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/recover-wallet',
  beforeLoad: authLoader,
  component: RecoverWallet
});

// Add the new route for rewards page
const rewardsPageRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/rewards',
  beforeLoad: authLoader,
  component: RewardsPage
});

// Add the new route for notifications
const notificationsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/notifications',
  component: NotificationsPage,
  beforeLoad: authLoader,
});

// Update route tree
const routeTree = rootRoute.addChildren([
  indexRoute,
  loginRoute,
  onboardGwRoute,
  connectGwRoute,
  gatewayRoute,
  gatewayLayoutRoute.addChildren([
    gatewayOverviewRoute,
    gatewayLocationRoute,
    gatewayDevicesRoute,
    gatewayNetworkRoute
  ]),
  debugRoute,
  onboardingWizardRoute,
  altOnboardingWizardRoute,
  cloudOnboardingWizardRoute,
  echoRoute,
  gatewayStatusCheckRoute,
  gatewayLocationToolRoute,
  recoverWalletRoute,
  rewardsPageRoute,
  notificationsRoute,
  meterDataRoute,
  solarDataRoute,
  batteryDataRoute
]);

// Create the router instance
export const router = new Router({ 
  routeTree,
  defaultPreload: 'intent'
});

// Type declaration for the router
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

// Default export for backward compatibility
export default router; 