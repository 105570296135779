import { FC, useState, useCallback, useEffect } from 'react';
import { useWalletContext } from '../../context/WalletContext';
import { toast } from "react-hot-toast";
import InfoRow from "../../components/InfoRow";
import { ActionButton } from "../../components/ui/ActionButton";

interface GatewayInfo {
  id: string;
  name: string;
}

interface WalletStepProps {
  gatewayInfo: GatewayInfo;
  onRegistrationComplete: (isRegistered: boolean) => void;
  onRegister: () => Promise<false | undefined>;
}

const WalletStep: FC<WalletStepProps> = ({ gatewayInfo, onRegistrationComplete, onRegister }) => {
  const { publicKey } = useWalletContext();
  const [isChecking, setIsChecking] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [gatewayData, setGatewayData] = useState<{
    wallet?: string;
    h3Index?: string;
  } | null>(null);

  const checkGatewayOwnership = useCallback(async () => {
    setIsChecking(true);
    
    try {
      const query = JSON.stringify({
        query: `{
          gateway {
            gateway(id: "${gatewayInfo.id}") {
              wallet
              h3Index
            }
          }
        }`
      });

      const response = await fetch('https://api.srcful.dev/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: query
      });

      const data = await response.json();
      const gatewayDetails = data.data.gateway.gateway;
      setGatewayData(gatewayDetails);

      // Check if the gateway is registered to the current wallet
      const isRegisteredToCurrentWallet = gatewayDetails?.wallet === publicKey?.toString();
      setIsRegistered(isRegisteredToCurrentWallet);
      onRegistrationComplete(isRegisteredToCurrentWallet);
    } catch (error) {
      console.error('Failed to check gateway ownership:', error);
      onRegistrationComplete(false);
    } finally {
      setIsChecking(false);
    }
  }, [gatewayInfo.id, publicKey, onRegistrationComplete]);

  // Initial check
  useEffect(() => {
    checkGatewayOwnership();
  }, [checkGatewayOwnership]);

  const handleRegister = async () => {
    if (!publicKey) {
      toast.error('Please connect your wallet first');
      return;
    }

    setIsSubmitting(true);
    try {
      const success = await onRegister();
      if (success === false) {
        throw new Error('Registration failed');
      }
      await checkGatewayOwnership();
      toast.success('Gateway registered successfully');
    } catch (error) {
      console.error('Failed to register gateway:', error);
      toast.error('Failed to register gateway. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isChecking) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
      </div>
    );
  }

  if (isRegistered) {
    return (
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-white">Gateway Registered</h2>
        <div className="bg-green-500/10 p-4 rounded-lg border border-green-500/20">
          <InfoRow
            label="Gateway ID"
            value={gatewayInfo.id}
            isCopyable
          />
          <InfoRow
            label="Wallet"
            value={gatewayData?.wallet || ''}
            displayValue={gatewayData?.wallet ? 
              `${gatewayData.wallet.slice(0, 4)}...${gatewayData.wallet.slice(-4)}` : 
              ''
            }
            isCopyable
          />
          <p className="mt-2 text-sm text-green-300">
            Your gateway is successfully registered to your wallet.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white">Register Gateway</h2>
      <div className="bg-yellow-500/10 p-4 rounded-lg border border-yellow-500/20">
        <p className="text-yellow-200">
          Register this gateway to your wallet to start earning rewards.
        </p>
      </div>
      <ActionButton
        onClick={handleRegister}
        disabled={isSubmitting || !publicKey}
        isLoading={isSubmitting}
        loadingText="Registering..."
        className="w-full"
      >
        Register Gateway
      </ActionButton>
    </div>
  );
};

export default WalletStep; 