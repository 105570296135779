import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import ContentFramework from "../components/ContentFramework";
import { useBle } from "../context/BleContext";
import { useGateways } from "../context/GatewayContext";
import { toast } from "react-hot-toast";
import * as ble from "../services/ble/";
import {
  IntroStep,
  BluetoothStep,
  CryptoInfoStep,
  WalletStep,
  LocationStep,
  DeviceScanStep,
  NetworkStep,
  CryptoInfo
} from '../components/onboarding-wizard';
import { NetworkState, NetworkData, checkGatewayName, scanNetworks } from '../components/onboarding-wizard/NetworkStep';
import { useRouter } from "@tanstack/react-router";
import { ActionButton } from '../components/ui/ActionButton';
import { useWalletContext } from "../context/WalletContext";
import GatewayPageHeader from '../components/GatewayPageHeader';
import PageContainer from '../components/layout/PageContainer';
import { fetchGatewayStatus } from '../services/backend/SrcfulPublicApi';

interface Step {
  title: string;
  description: string;
  component: FC<any>;
}

interface GatewayInfo {
  id: string;
  name: string;
}

const OnboardingWizard: FC = () => {
  const { publicKey } = useWalletContext();
  const [currentStep, setCurrentStep] = useState(0);
  const [isBluetoothConnected, setIsBluetoothConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [hasConnectionFailed, setHasConnectionFailed] = useState(false);
  const [isRegisteredToOtherWallet, setIsRegisteredToOtherWallet] = useState(false);
  const [isWalletRegistered, setIsWalletRegistered] = useState(false);
  const [gatewayId, setGatewayId] = useState<string | null>(null);
  const [gatewayInfo, setGatewayInfo] = useState<GatewayInfo | null>(null);
  const [isFirstNetworkCheck, setIsFirstNetworkCheck] = useState(true);
  const [cryptoInfo, setCryptoInfo] = useState<CryptoInfo>({
    serialNumber: '',
    publicKey: ''
  });
  const [networkData, setNetworkData] = useState<NetworkData>({
    state: NetworkState.CHECKING_NAME,
    networks: [],
    selectedNetwork: null,
    password: '',
    gatewayName: undefined
  });
  const [isLocationSet, setIsLocationSet] = useState(false);
  const [gatewayExists, setGatewayExists] = useState(true);
  
  const bleApi = useBle();
  const { setSuppressSigningStep } = useGateways();
  const router = useRouter();

  const handleDisconnect = useCallback(() => {
    console.log("Disconnecting from gateway...");
    setIsBluetoothConnected(false);
    setHasConnectionFailed(true);
    setIsConnecting(false);
    bleApi.manager.disconnect();
  }, [bleApi.manager]);

  // Fetch crypto info when needed
  const fetchCryptoInfo = useCallback(async () => {
    try {
      const response = await bleApi.fetch(
        ble.API_CRYPTO,
        ble.Method.GET,
        {},
      );
      const newCryptoInfo = {
        serialNumber: response.payload["serialNumber"],
        publicKey: response.payload["publicKey"],
      };
      setCryptoInfo(newCryptoInfo);
      setGatewayId(response.payload["serialNumber"]);
      return newCryptoInfo;
    } catch (error) {
      console.error('Failed to fetch crypto info:', error);
      toast.error('Failed to fetch device information');
      handleDisconnect();
      return null;
    }
  }, [bleApi, handleDisconnect]);

  useEffect(() => {
    function observerCallback(m: ble.IManager, status: string) {
      console.log("Connection status changed: ", status);
      if (m.isConnected()) {
        if (isBluetoothConnected === false) {
          // setIsBluetoothConnected(true);
        }
      } else if (!isConnecting) {
        // setIsBluetoothConnected(false);
        // Reset crypto info when disconnected
        // If we lose connection and we're past the Bluetooth step, go back to it
        if (currentStep > 1) {
          setCryptoInfo({ serialNumber: '', publicKey: '' });
          setCurrentStep(1);
          toast.error('Bluetooth connection lost. Please reconnect.');
        }
      }
    }

    bleApi.manager.subscribeToConnectionStatus(observerCallback);

    return () => {
      bleApi.manager.unsubscribeFromConnectionStatus(observerCallback);
    };
  }, [bleApi, isBluetoothConnected, currentStep, isConnecting]);

  // Set suppress flag when component mounts
  useEffect(() => {
    setSuppressSigningStep(true);
    return () => setSuppressSigningStep(false); // Clean up when unmounting
  }, [setSuppressSigningStep]);

  const handleNetworkStepSuccess = useCallback((name: string) => {
    if (gatewayId) {
      setGatewayInfo({
        id: gatewayId,
        name: name
      });
    }
  }, [gatewayId]);

  // Network state management
  useEffect(() => {
    const processNetworkStep = async () => {
      if (currentStep !== 3) return; // Only run for network step

      if (networkData.state === NetworkState.CHECKING_NAME) {
        // Use 2 checks for first execution, 12 for subsequent executions
        const checkCount = isFirstNetworkCheck ? 2 : 12;
        const result = await checkGatewayName(bleApi, networkData.state, 0, checkCount, 4000);
        
        // Set first check to false after first execution
        if (isFirstNetworkCheck) {
          setIsFirstNetworkCheck(false);
        }
        
        if (result.name) {
          handleNetworkStepSuccess(result.name);
          setNetworkData(prev => ({ 
            ...prev, 
            state: result.newState,
            gatewayName: result.name
          }));
        } else {
          setNetworkData(prev => ({ ...prev, state: result.newState }));
        }
      } else if (networkData.state === NetworkState.SCANNING_WIFI) {
        const result = await scanNetworks(bleApi, networkData.state);
        setNetworkData(prev => ({
          ...prev,
          state: result.newState,
          networks: result.networks || []
        }));
      }
    };

    processNetworkStep();
  }, [networkData.state, currentStep, bleApi, handleNetworkStepSuccess, isFirstNetworkCheck]);

  
  // Add this effect to fetch crypto info when entering step 2
  useEffect(() => {
    if (currentStep === 2 && isBluetoothConnected) {
      fetchCryptoInfo();
    }
  }, [currentStep, isBluetoothConnected, fetchCryptoInfo]);

  const handleFinish = useCallback(() => {
    // Disconnect BLE
    bleApi.manager.disconnect();
    // Navigate to device overview page using TanStack router
    router.navigate({ to: '/gateway/' + gatewayId });
  }, [bleApi.manager, router, gatewayId]);

  const checkGatewayWallet = useCallback(async (gatewayId: string) => {
    try {
      const query = JSON.stringify({
        query: `{
          gateway {
            gateway(id: "${gatewayId}") {
              wallet
            }
          }
        }`
      });

      const response = await fetch('https://api.srcful.dev/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: query
      });

      const data = await response.json();
      const gatewayWallet = data.data.gateway.gateway?.wallet;

      if (!gatewayWallet) {
        // Case 1: No wallet - continue with wizard
        return;
      } else if (gatewayWallet === publicKey?.toString()) {
        // Case 2: Current wallet - continue with wizard
        return;
      } else {
        // Case 3: Different wallet - show message
        setIsRegisteredToOtherWallet(true);
        setCurrentStep(2); // Move to the crypto info step to show the message
      }
    } catch (error) {
      console.error('Failed to check gateway wallet:', error);
      toast.error('Failed to check gateway status');
    }
  }, [publicKey]);

  const checkGatewayStatus = useCallback(async (gatewayId: string) => {
    try {
      const status = await fetchGatewayStatus(gatewayId);
      // status.exists = false; hard coded for testing
      setGatewayExists(status.exists);
      
      if (status.exists) {
        // Only check wallet registration if the gateway exists
        await checkGatewayWallet(gatewayId);
      }
    } catch (error) {
      console.error('Failed to check gateway status:', error);
      toast.error('Failed to check gateway status');
      handleDisconnect();
    }
  }, [checkGatewayWallet, handleDisconnect]);

  const handleConnect = useCallback(async () => {
    if (bleApi.manager.isConnected() || isConnecting) return;

    console.log("Connecting to gateway...");
    
    setIsConnecting(true);
    try {
      console.log("Awaiting connection...");
      const connected = await bleApi.manager.connect();
      console.log("Connection result:", connected);
      if (connected) {
        setIsBluetoothConnected(true);
        // After successful connection, fetch crypto info and check gateway status
        const info = await fetchCryptoInfo();
        if (info?.serialNumber) {
          await checkGatewayStatus(info.serialNumber);
        }
      } else {
        toast.error("Failed to connect to gateway.");
        setHasConnectionFailed(true);
        handleDisconnect();
      }
    } catch (error) {
      console.error('Failed to connect:', error);
      setHasConnectionFailed(true);
      handleDisconnect();
    }
    setIsConnecting(false);
  }, [bleApi.manager, isConnecting, handleDisconnect, fetchCryptoInfo, checkGatewayStatus]);

  const steps: Step[] = useMemo(() => [
    {
      title: "Introduction",
      description: "Overview of the setup process",
      component: IntroStep
    },
    {
      title: "Bluetooth Connection",
      description: "Connect to your gateway",
      component: () => (
        <BluetoothStep 
          onConnect={handleConnect}
          isConnecting={isConnecting}
          isConnected={isBluetoothConnected}
          hasConnectionFailed={hasConnectionFailed}
        />
      )
    },
    {
      title: "Device Information",
      description: "View gateway details",
      component: () => (
        <CryptoInfoStep 
          cryptoInfo={cryptoInfo}
          isLoading={currentStep === 2 && (!cryptoInfo.serialNumber || !cryptoInfo.publicKey)}
          isRegisteredToOtherWallet={isRegisteredToOtherWallet}
          gatewayExists={gatewayExists}
        />
      )
    },
    {
      title: "Internet Connection",
      description: "Set up internet connectivity",
      component: () => (
        <NetworkStep 
          state={networkData.state}
          networks={networkData.networks}
          selectedNetwork={networkData.selectedNetwork}
          password={networkData.password}
          gatewayName={networkData.gatewayName}
          bleApi={bleApi}
          setState={(newState) => setNetworkData(prev => ({ ...prev, state: newState }))}
        />
      )
    },
    {
      title: "Wallet Registration",
      description: "Register gateway ownership",
      component: () => (
        gatewayInfo ? (
          <WalletStep 
            gatewayInfo={gatewayInfo} 
            onRegistrationComplete={setIsWalletRegistered}
          />
        ) : null
      )
    },
    {
      title: "Location Setup",
      description: "Set gateway location",
      component: () => (
        gatewayId ? (
          <LocationStep 
            gatewayId={gatewayId} 
            onLocationSet={() => setIsLocationSet(true)}
          />
        ) : null
      )
    },
    {
      title: "Device Scan",
      description: "Scan for devices",
      component: DeviceScanStep
    }
  ], [
    cryptoInfo, 
    currentStep,
    networkData,
    gatewayInfo,
    gatewayId,
    isBluetoothConnected,
    isConnecting,
    hasConnectionFailed,
    handleConnect,
    isRegisteredToOtherWallet,
    bleApi,
    gatewayExists
  ]);

  // Memoize the current step component to prevent unnecessary re-renders
  const CurrentStepComponent = useMemo(() => steps[currentStep].component, [steps, currentStep]);

  const handleNext = useCallback(() => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep, steps.length]);

  const isNextDisabled = () => {
    if (currentStep === 1 && !isBluetoothConnected) return true;
    // Disable next if gateway doesn't exist
    if (currentStep === 2 && !gatewayExists) return true;
    // Only enable next on network step when we're connected
    if (currentStep === 3 && networkData.state !== NetworkState.CONNECTED) return true;
    // Only enable next on wallet step when gateway is registered to current wallet
    if (currentStep === 4 && !isWalletRegistered) return true;
    // Only enable next on location step when location is set
    if (currentStep === 5 && !isLocationSet) return true;
    // Disable next if the gateway is registered to another wallet
    if (isRegisteredToOtherWallet) return true;
    return false;
  };

  return (
    <ContentFramework>
      <PageContainer>
        <GatewayPageHeader 
          gatewayName="Onboard Gateway"
        />

        {/* Progress indicator */}
        <div className="mb-8">
          {/* Step titles - visible only on larger screens */}
          <div className="hidden sm:flex justify-between mb-4 px-4">
            {steps.map((step, index) => (
              <div 
                key={`title-${index}`}
                className={`text-sm font-medium text-center w-24 transition-colors
                  ${index === currentStep ? 'text-blue-400' : 
                    index < currentStep ? 'text-green-400' : 'text-gray-500'}`}
              >
                {step.title}
              </div>
            ))}
          </div>
          
          {/* Progress bar and numbers */}
          <div className="relative flex items-center justify-between">
            {/* Background line */}
            <div className="absolute left-0 right-0 h-[2px] bg-gray-700" />
            
            {/* Progress line */}
            <div 
              className="absolute left-0 h-[2px] bg-green-500 transition-all duration-300"
              style={{ 
                width: `${(currentStep / (steps.length - 1)) * 100}%`,
              }}
            />
            
            {/* Step indicators */}
            <div className="relative flex justify-between w-full">
              {steps.map((step, index) => (
                <div 
                  key={`step-${index}`}
                  className={`w-6 h-6 sm:w-8 sm:h-8 rounded-full flex items-center justify-center text-sm 
                    transition-colors duration-200 border-2
                    ${index === currentStep ? 'bg-blue-500 border-blue-500' : 
                      index < currentStep ? 'bg-green-500 border-green-500' : 
                      'bg-gray-800 border-gray-700'}`}
                >
                  {index + 1}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Step content */}
        <div className="mb-8">
          <CurrentStepComponent />
        </div>

        {/* Navigation and actions */}
        <div className="flex justify-between items-center space-x-4">
          {(isBluetoothConnected || currentStep === 1) ? (
            <ActionButton
              onClick={handleDisconnect}
              variant="destructive"
            >
              Disconnect
            </ActionButton>
          ) : (
            <div />
          )}
          
          <ActionButton
            onClick={currentStep === steps.length - 1 ? handleFinish : handleNext}
            disabled={isNextDisabled()}
            variant="primary"
          >
            {currentStep === steps.length - 1 ? 'Finish Setup' : 'Next'}
          </ActionButton>
        </div>
      </PageContainer>
    </ContentFramework>
  );
};

export default OnboardingWizard;

