import { FC } from 'react';
import { ActionButton } from '../../ui/ActionButton';
import { Brand, StepProps } from '../types';
import { SolarEdgeIcon, HuaweiIcon } from '../../icons/brand_icons';
import { isFeatureEnabled } from '../../../config/featureFlags';

interface IntroStepProps extends StepProps {
  selectedBrand: Brand | null;
  onSelectBrand: (brand: Brand) => void;
}

const IntroStep: FC<IntroStepProps> = ({ onNext, selectedBrand, onSelectBrand }) => (
  <div className="space-y-8">
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <h2 className="text-xl font-semibold">Onboard Your Cloud Gateway</h2>
      </div>
      <div className="space-y-4">
        <p className="text-gray-300">Select your inverter brand to begin:</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 max-w-xl mx-auto">
          <button
            onClick={() => onSelectBrand('solaredge')}
            className={`p-6 rounded-lg border-2 transition-all ${
              selectedBrand === 'solaredge'
                ? 'border-green-500 bg-green-500/10'
                : 'border-gray-700 hover:border-gray-600 bg-gray-800/50'
            }`}
          >
            <SolarEdgeIcon height={40} className="text-white mx-auto" />
          </button>
          <button
            onClick={() => onSelectBrand('huawei')}
            className={`p-6 rounded-lg border-2 transition-all ${
              selectedBrand === 'huawei'
                ? 'border-green-500 bg-green-500/10'
                : 'border-gray-700 hover:border-gray-600 bg-gray-800/50'
            }`}
          >
            <HuaweiIcon height={40} className="text-white mx-auto" />
          </button>
          {isFeatureEnabled('ENABLE_DIMO_GATEWAY') && (
            <button
              onClick={() => onSelectBrand('dimo')}
              className={`p-6 rounded-lg border-2 transition-all ${
                selectedBrand === 'dimo'
                  ? 'border-green-500 bg-green-500/10'
                  : 'border-gray-700 hover:border-gray-600 bg-gray-800/50'
              }`}
            >
              <div className="text-white text-2xl font-bold">DIMO</div>
            </button>
          )}
        </div>
      </div>
      <div className="space-y-1 text-gray-300">
        <p>Connect your site to the Sourceful Energy Network. Click 'Next' to begin the setup process.</p>
      </div>
    </div>

    <div className="flex justify-end">
      <ActionButton
        onClick={onNext}
        variant="primary"
        disabled={!selectedBrand}
      >
        Next
      </ActionButton>
    </div>
  </div>
);

export default IntroStep; 