import { FC, useRef, useState, useEffect } from 'react';
import { KeyboardEvent } from 'react';

interface OTPInputProps {
  length: number;
  value: string;
  onChange: (value: string) => void;
  onComplete?: () => void;
  placeholder?: string;
  maskDelay?: number;
}

const OTPInput: FC<OTPInputProps> = ({ 
  length, 
  value, 
  onChange, 
  onComplete, 
  placeholder = "•", 
  maskDelay = 1000 
}) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [maskedIndices, setMaskedIndices] = useState<boolean[]>(Array(length).fill(false));
  const timeoutsRef = useRef<number[]>([]);
  
  // Initialize refs array
  if (inputRefs.current.length !== length) {
    inputRefs.current = Array(length).fill(null);
  }

  // Fill in values from the parent state
  const values = value.split('');
  
  // Clear timeouts on unmount
  useEffect(() => {
    return () => {
      timeoutsRef.current.forEach(id => window.clearTimeout(id));
    };
  }, []);

  // Auto-submit when all digits are entered
  useEffect(() => {
    if (value.length === length && onComplete && /^\d{6}$/.test(value)) {
      onComplete();
    }
  }, [value, length, onComplete]);
  
  // Handle input change
  const handleChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    
    // Only accept digits
    if (!/^\d*$/.test(val)) return;
    
    // Only take the last character if multiple are pasted or entered
    const digit = val.slice(-1);
    
    // Update the value in our parent's state
    const newValue = [...values];
    newValue[index] = digit;
    onChange(newValue.join(''));
    
    // Set up masking after delay
    if (digit) {
      // Clear any existing timeout for this index
      if (timeoutsRef.current[index]) {
        window.clearTimeout(timeoutsRef.current[index]);
      }
      
      // First unmask it to show the digit
      setMaskedIndices(prev => {
        const updated = [...prev];
        updated[index] = false;
        return updated;
      });
      
      // Then set timeout to mask it after delay
      timeoutsRef.current[index] = window.setTimeout(() => {
        setMaskedIndices(prev => {
          const updated = [...prev];
          updated[index] = true;
          return updated;
        });
      }, maskDelay);
      
      // If we entered a digit and we're not at the last box, move to the next box
      if (index < length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };
  
  // Handle key press for navigation
  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      // If current box is empty and we press backspace, move to previous box
      if (!values[index] && index > 0) {
        // Update parent state to remove the previous digit
        const newValue = [...values];
        newValue[index - 1] = '';
        onChange(newValue.join(''));
        
        // Move focus to previous box
        inputRefs.current[index - 1]?.focus();
      }
    } else if (e.key === 'ArrowLeft' && index > 0) {
      // Move focus left
      inputRefs.current[index - 1]?.focus();
    } else if (e.key === 'ArrowRight' && index < length - 1) {
      // Move focus right
      inputRefs.current[index + 1]?.focus();
    }
  };
  
  // Handle paste to distribute digits across the inputs
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    
    // Filter out non-digits
    const digits = pastedData.replace(/\D/g, '').slice(0, length);
    
    if (digits) {
      // Reset all masked states since we're getting new values
      setMaskedIndices(Array(length).fill(false));
      
      // Clear all existing timeouts
      timeoutsRef.current.forEach(id => window.clearTimeout(id));
      timeoutsRef.current = [];
      
      // Set up a new timeout for each pasted digit
      for (let i = 0; i < digits.length; i++) {
        timeoutsRef.current[i] = window.setTimeout(() => {
          setMaskedIndices(prev => {
            const updated = [...prev];
            updated[i] = true;
            return updated;
          });
        }, maskDelay);
      }
      
      onChange(digits.padEnd(value.length, value.slice(digits.length)).slice(0, length));
      
      // Focus the next empty input or the last one
      const nextIndex = Math.min(digits.length, length - 1);
      inputRefs.current[nextIndex]?.focus();
    }
  };
  
  return (
    <div className="flex justify-center gap-2">
      {Array.from({ length }).map((_, index) => (
        <input
          key={index}
          ref={el => inputRefs.current[index] = el}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength={1}
          value={maskedIndices[index] ? (values[index] ? '•' : '') : (values[index] || '')}
          onChange={e => handleChange(index, e)}
          onKeyDown={e => handleKeyDown(index, e)}
          onPaste={handlePaste}
          className="w-10 h-12 text-center text-lg bg-gray-700 text-gray-200 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder={placeholder}
          autoComplete="one-time-code"
        />
      ))}
    </div>
  );
};

export default OTPInput; 