import { FC } from 'react';
import { LoginWithDimo } from "@dimo-network/login-with-dimo";
import { DimoService } from '../services/DimoService';
import { toast } from 'react-hot-toast';
import type { DimoAuthData } from './cloud-onboarding/types';

interface DimoLoginButtonProps {
  onSuccess: (data: DimoAuthData) => void;
  className?: string;
}

const DimoLoginButton: FC<DimoLoginButtonProps> = ({ onSuccess, className }) => {
  const handleAuthSuccess = async (authData: { token: string }) => {
    try {
      console.log("DIMO login successful", authData);
      const dimoService = DimoService.getInstance();
      
      // Extract ethereum address
      const ethereumAddress = dimoService.extractEthereumAddress(authData.token);

      // Fetch vehicles
      const vehicleData = await dimoService.getVehicles(ethereumAddress);

      if (vehicleData.success) {
        onSuccess({
          token: authData.token,
          vehicles: vehicleData.vehicles,
          ethereumAddress
        });
      } else {
        throw new Error('Failed to fetch vehicles');
      }
    } catch (error) {
      console.error('DIMO login failed:', error);
      toast.error('Failed to authenticate with DIMO');
    }
  };

  return (
    <div className={className}>
      <LoginWithDimo
        mode="popup"
        permissionTemplateId="2"
        onSuccess={handleAuthSuccess}
        onError={(error) => {
          console.error('DIMO login error:', error);
          toast.error('Failed to connect with DIMO');
        }}
      />
    </div>
  );
};

export default DimoLoginButton; 