import { FC, useState } from 'react';
import ContentFramework from "../components/ContentFramework";
import GatewayPageHeader from '../components/GatewayPageHeader';
import PageContainer from '../components/layout/PageContainer';
import { ActionButton } from '../components/ui/ActionButton';
import { useRouter } from "@tanstack/react-router";
import { toast } from "react-hot-toast";
import SolarEdgeService from '../services/SolarEdgeService';
import mapboxgl from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import { createSolarEdgeCloudGateway } from '../services/backend/SrcfulPublicApi';
import { useGateways } from '../context/GatewayContext';
import { useWalletContext } from '../context/WalletContext';
import InfoRow from '../components/InfoRow';
import { initializeDimoSDK, DimoAuthProvider } from "@dimo-network/login-with-dimo";
import { DimoService } from '../services/DimoService';

// Import our modular components
import IntroStep from '../components/cloud-onboarding/steps/IntroStep';
import LocationStep from '../components/cloud-onboarding/steps/LocationStep';
import ErrorStep from '../components/cloud-onboarding/steps/ErrorStep';
import type { Brand, LocationData, SolarEdgeCredentials, DimoAuthData } from '../components/cloud-onboarding/types';
import { 
  SolarEdgeCredentialsStep, 
  HuaweiCredentialsStep, 
  DimoCredentialsStep 
} from '../components/cloud-onboarding/steps/CredentialsStep';

// Mapbox access token
mapboxgl.accessToken =
  "pk.eyJ1IjoiamFjb2ItazI4OSIsImEiOiJjbHNrbnJubjQwNGtwMmtyMDd3cTBmem5nIn0.-D_8MJk7ENckqUh9PD_g2A";

// Initialize DIMO SDK
const dimoConfig = {
  clientId: process.env.REACT_APP_DIMO_CLIENT_ID || '0x624a81ceaDbAFd51ACA8CbFE9826d3dFa5F5bb10',
  redirectUri: process.env.REACT_APP_DIMO_REDIRECT_URI || 'https://daab-79-136-106-88.ngrok-free.app/redirect'
};

console.log('Initializing DIMO SDK with config:', {
  clientId: dimoConfig.clientId,
  redirectUri: dimoConfig.redirectUri,
  envVars: {
    REACT_APP_DIMO_CLIENT_ID: process.env.REACT_APP_DIMO_CLIENT_ID,
    REACT_APP_DIMO_REDIRECT_URI: process.env.REACT_APP_DIMO_REDIRECT_URI
  }
});

initializeDimoSDK(dimoConfig);

const CloudOnboardingWizard: FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [credentials, setCredentials] = useState<SolarEdgeCredentials>({
    siteId: '',
    apiKey: ''
  });
  const [location, setLocation] = useState<LocationData>({
    latitude: '',
    longitude: ''
  });
  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [createdGatewayId, setCreatedGatewayId] = useState<string | null>(null);
  const [registrationError, setRegistrationError] = useState<{ message: string; gatewayId?: string } | null>(null);
  const { publicKey } = useWalletContext();
  const { refreshGateways, claimNewGateway } = useGateways();
  const router = useRouter();
  const [dimoAuthData, setDimoAuthData] = useState<DimoAuthData | null>(null);
  const [isCreatingSession, setIsCreatingSession] = useState(false);

  const createDimoGateway = async () => {
    if (!publicKey || !dimoAuthData?.ethereumAddress) {
      toast.error('Missing required data for gateway creation');
      return;
    }

    try {
      const dimoService = DimoService.getInstance();
      const response = await dimoService.createGateway({
        dimo_wallet: dimoAuthData.ethereumAddress,
        longitude: parseFloat(location.longitude),
        latitude: parseFloat(location.latitude),
        wallet: publicKey.toString()
      });

      if (response.success && response.gateway_id) {
        setCreatedGatewayId(response.gateway_id);
        await claimNewGateway(response.gateway_id);
        await refreshGateways();
        setCurrentStep(prev => prev + 1);
      } else {
        // Handle error response from DIMO API
        const errorMessage = response.error || response.message || 'Failed to create DIMO gateway';
        
        // Check for specific error cases
        if (errorMessage.includes('already registered')) {
          setRegistrationError({
            message: 'This DIMO wallet is already registered with a gateway.',
          });
        } else if (!response.gateway_id && response.success) {
          setRegistrationError({
            message: 'Gateway was created but no gateway ID was returned. Please try again.',
          });
        } else {
          setRegistrationError({
            message: errorMessage,
          });
        }
        setCurrentStep(4); // Error step
      }
    } catch (error: any) {
      console.error('Failed to create DIMO gateway:', error);
      // Handle network/unexpected errors
      setRegistrationError({
        message: error.message || 'Failed to create DIMO gateway. Please try again.',
      });
      setCurrentStep(4); // Error step
    }
  };

  const handleNext = async () => {
    if (currentStep === 1) {
      // Location step
      setCurrentStep(prev => prev + 1);
    } else if (currentStep === 2) {
      // Credentials step
      if (selectedBrand === 'dimo') {
        // Create DIMO gateway when clicking Create Gateway
        await createDimoGateway();
        return;
      }

      // For SolarEdge
      if (selectedBrand === 'solaredge') {
        setIsValidating(true);
        setValidationError(null);

        try {
          const result = await SolarEdgeService.getSiteDetails(credentials.siteId, credentials.apiKey);
          if ('success' in result && result.success) {
            if (!publicKey) {
              toast.error('Please connect your wallet first');
              return;
            }

            try {
              const gatewayId = await createSolarEdgeCloudGateway(
                publicKey.toString(),
                credentials.siteId,
                credentials.apiKey,
                location.latitude,
                location.longitude
              );
              
              setCreatedGatewayId(gatewayId);
              await claimNewGateway(gatewayId);
              await refreshGateways();
              setCurrentStep(prev => prev + 1);
            } catch (error: any) {
              console.error('Failed to create cloud gateway:', error);
              
              const match = error.message.match(/Gateway already registered by ([\w-]+)/);
              const existingGatewayId = match ? match[1] : undefined;
              const isSolarEdgeCredentialError = error.message.includes('Invalid SolarEdge API credentials');
              
              setRegistrationError({
                message: existingGatewayId 
                  ? 'This SolarEdge site has already been registered.'
                  : isSolarEdgeCredentialError
                    ? 'The SolarEdge API credentials are not valid. Please verify your Site ID and API Key.'
                    : error.message === 'Failed to sign message'
                      ? 'Failed to sign the registration message. Please try again.'
                      : 'Failed to create cloud gateway. Please try again.',
                gatewayId: existingGatewayId
              });
              setCurrentStep(4); // Error step
            }
          } else {
            setValidationError('Failed to validate credentials. Please check your Site ID and API Key.');
          }
        } catch (error) {
          console.error('Failed to validate SolarEdge credentials:', error);
          setValidationError(
            'Failed to validate your SolarEdge credentials. Please check your Site ID and API Key and try again.'
          );
          toast.error('Invalid credentials');
        } finally {
          setIsValidating(false);
        }
      }
    } else if (currentStep === 3 && selectedBrand === 'dimo') {
      // Create DIMO gateway after successful authentication
      await createDimoGateway();
    } else {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    setValidationError(null);
    setRegistrationError(null);
    // If we're in the error step (4), go back to credentials step (2)
    if (currentStep === 4) {
      setCurrentStep(2);
    } else {
      setCurrentStep(prev => prev - 1);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <IntroStep 
            onNext={handleNext} 
            onBack={handleBack}
            selectedBrand={selectedBrand}
            onSelectBrand={setSelectedBrand}
          />
        );
      case 1:
        return (
          <LocationStep
            location={location}
            setLocation={setLocation}
            onBack={handleBack}
            onNext={handleNext}
          />
        );
      case 2:
        if (!selectedBrand) {
          setCurrentStep(0);
          return null;
        }

        switch (selectedBrand) {
          case 'solaredge':
            return (
              <SolarEdgeCredentialsStep 
                credentials={credentials}
                setCredentials={setCredentials}
                onBack={handleBack}
                onNext={handleNext}
                isValidating={isValidating}
                validationError={validationError}
              />
            );
          case 'huawei':
            return (
              <HuaweiCredentialsStep
                onBack={handleBack}
                onNext={handleNext}
                publicKey={publicKey?.toString() || null}
                location={location}
                isCreatingSession={isCreatingSession}
                setIsCreatingSession={setIsCreatingSession}
              />
            );
          case 'dimo':
            return (
              <DimoCredentialsStep
                onBack={handleBack}
                onNext={handleNext}
                setDimoAuthData={setDimoAuthData}
                setCurrentStep={setCurrentStep}
                dimoAuthData={dimoAuthData}
              />
            );
          default:
            return null;
        }
      case 3:
        return (
          <div className="space-y-6">
            <h2 className="text-2xl font-bold text-white">Gateway Created Successfully!</h2>
            <div className="bg-green-500/10 p-4 rounded-lg border border-green-500/20">
              <InfoRow
                label="Gateway ID"
                value={createdGatewayId || ''}
                isCopyable
              />
              <InfoRow
                label="Wallet"
                value={publicKey?.toString() || ''}
                displayValue={publicKey ? 
                  `${publicKey.toString().slice(0, 4)}...${publicKey.toString().slice(-4)}` : 
                  ''
                }
                isCopyable
              />
              <p className="mt-2 text-sm text-green-300">
                Your gateway is successfully registered to your wallet.
              </p>
            </div>

            <div className="flex justify-end">
              <ActionButton
                onClick={() => {
                  if (createdGatewayId) {
                    router.navigate({ to: '/gateway/$gatewayId', params: { gatewayId: createdGatewayId } });
                  }
                }}
                variant="primary"
              >
                Finish
              </ActionButton>
            </div>
          </div>
        );
      case 4:
        if (registrationError) {
          return (
            <ErrorStep
              message={registrationError.message}
              gatewayId={registrationError.gatewayId}
              onBack={handleBack}
              onNext={handleNext}
            />
          );
        }
        return null;
      default:
        return null;
    }
  };

  return (
    <DimoAuthProvider>
      <ContentFramework>
        <PageContainer>
          <GatewayPageHeader 
            gatewayName="Onboard Cloud Gateway"
          />
          
          {/* Progress indicator */}
          <div className="mt-8 mb-8">
            <div className="relative flex justify-between">
              {/* Step labels */}
              <div className="absolute left-0 right-0 -top-6">
                <div className="flex justify-between">
                  {['Introduction', 'Location', 'Credentials', 'Complete'].map((step) => (
                    <span key={step} className="text-sm font-medium text-gray-400">
                      {step}
                    </span>
                  ))}
                </div>
              </div>

              {/* Progress line background */}
              <div className="absolute left-0 top-4 h-0.5 w-full bg-gray-700" />
              
              {/* Progress line */}
              <div
                className="absolute left-0 top-4 h-0.5 bg-green-500 transition-all duration-500"
                style={{ 
                  width: `${(Math.min(currentStep, 3) / 3) * 100}%`
                }}
              />

              {/* Step circles */}
              <div className="relative flex w-full justify-between">
                {['Introduction', 'Location', 'Credentials', 'Complete'].map((step, index) => (
                  <div
                    key={step}
                    className={`flex h-8 w-8 items-center justify-center rounded-full border-2 text-sm font-semibold transition-colors duration-500 ${
                      index < currentStep
                        ? 'border-green-500 bg-green-500 text-white'
                        : index === currentStep
                        ? 'border-blue-500 bg-blue-500 text-white'
                        : 'border-gray-700 bg-gray-800 text-gray-400'
                    }`}
                  >
                    {index + 1}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {renderStep()}
        </PageContainer>
      </ContentFramework>
    </DimoAuthProvider>
  );
};

export default CloudOnboardingWizard; 